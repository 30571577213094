import React from "react";
import { Col, Row } from "react-bootstrap";
import { version } from "config";

const Footer = () => (
	<footer className="footer">
		<Row className="justify-content-between text-center fs--1 mt-4 mb-3">
			<Col sm="auto">
				<p className="mb-0 text-600">
					INSIGHT a platform by Mobile Systems International
					{/*<span className="d-none d-sm-inline-block">| </span>*/}
					<br className="d-sm-none" /> &copy; {new Date().getFullYear()}
				</p>
			</Col>
		</Row>
	</footer>
);

export default Footer;
