import React, { useState, useContext } from "react";
import AppContext from "context/Context";
import { Accordion, Card, Col, Container, Form, Row } from "react-bootstrap";
import FaqAccordionItem from "../faq/faq-accordion/FaqAccordionItem";
import { faqAccordions as faqsData } from "data/faqs";
import NavbarStandard from "./NavbarStandard";
import { useTranslation } from "react-i18next";
import Footer from "./Footer";
import styles from "./landing.module.css";

document.addEventListener("DOMContentLoaded", function () {
	const cssToRemove = document.getElementById("css-to-remove");
	if (cssToRemove) {
		cssToRemove.parentNode.removeChild(cssToRemove);
	}
});
const Faq = () => {
	const {
		config: { isDark, isRTL },
	} = useContext(AppContext);
	const [t, i18n] = useTranslation();
	const [faqs] = useState(faqsData);
	return (
		<div className={`${styles.backgroundWhite} ${styles.marginTop100}`}>
			<NavbarStandard />
			<Container className={`${styles.padding35em} `}>
				<h2 className={`${isRTL ? styles.landingTitleAr : styles.landingTitle}`}>{t("FAQ")}</h2>
				<Accordion id="accordionFaq" className="overflow-hidden">
					<FaqAccordionItem
						key={0}
						id={0}
						title={t("1. What is an electronic manifest?")}
						description={t(
							"The electronic manifest document for the Waste Management Sector is a form for tracking the movement of waste and its disposal from the point of production to the point of storage, treatment or safe disposal. Its main objectives include accurate tracking and documentation of waste, increasing compliance, collecting data on various waste shipments across the Kingdom, monitoring and preventing illegal and irregular practices, protecting citizens and the environment. The An electronic waste manifest is an essential tool for ensuring the safe and responsible management of e-waste, protecting the environment and promoting sustainability."
						)}
						isFirst={true}
						isLast={false}
					/>
					<FaqAccordionItem
						key={1}
						id={1}
						title={t("2. How can I access the e-Manifest system?")}
						description={t(
							"The e-Manifest system is a module component of the MWAN Waste Management Platform, an IT solution used by MWAN, and the waste management sector to track MWAN regulated waste activity. To access the eManifest system, register for a free account at https://emanifest.mwan.gov.sa/."
						)}
						isFirst={false}
						isLast={false}
					/>
					<FaqAccordionItem
						key={2}
						id={2}
						title={t("3. Is the e-Manifest system free?")}
						description={t("Users are not charged a fee for registering or using the e-Manifest.")}
						isFirst={false}
						isLast={true}
					/>
				</Accordion>
			</Container>
			<Footer pageName="Landing"></Footer>
		</div>
	);
};

export default Faq;
