(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("lodash"));
	else if(typeof define === 'function' && define.amd)
		define("formio-export", ["_"], factory);
	else if(typeof exports === 'object')
		exports["formio-export"] = factory(require("lodash"));
	else
		root["formio-export"] = factory(root["_"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__) {
return 