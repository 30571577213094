import React, { useState, useContext } from "react";

import PropTypes from "prop-types";
import AppContext from "context/Context";
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Col, Modal, Row, Button, Image } from "react-bootstrap";
import IconButton from "components/common/IconButton";
import * as Constants from "common/constants";
import * as General from "common/general";
import Loader from "components/custom/Loader";
import { useTranslation } from "react-i18next";
import * as em from "common/ErrorMessages";
import LoaderIcon from "components/custom/Preloader";
import { toast } from "react-toastify";


const UpdatePassword = ({ userID, email, showComponent, hideUpdatePassword,successCallBackFunction }) => {
	const [errorContainerVisiblity, showErrorContainer] = useState(false);
	const [loaderContainerVisibility, showLoaderContainer] = useState(false);
	const [t, i18n] = useTranslation();
	const [isFinish, setIsFinish] = useState(false);
	const [newPassword, updateNewPassword] = useState("");
	const [newPasswordConfirm, updateNewPasswordConfirm] = useState("");

	const newPasswordInput = document.getElementById("txtNewPassword");
	const newPasswordConfirmInput = document.getElementById("txtNewPasswordConfirm");

	const handleCancelEdit = () => {
		hideUpdatePassword();
		setIsFinish(false)
	};
2
	const handleUpdatePassword = async () => {

		var errorContainerConfirmPassword = document.getElementById("errortxtNewPasswordConfirm");
		var errorContainerPassword = document.getElementById("errortxtNewPassword");
		errorContainerConfirmPassword.innerHTML = "&nbsp;";
		errorContainerPassword.innerHTML = "&nbsp;";

		var newPasswordInput = document.getElementById("txtNewPassword");
		var newPasswordConfirmInput = document.getElementById("txtNewPasswordConfirm");

		newPasswordConfirmInput.classList.remove("is-invalid");

		var isValidForm = true;
		newPasswordInput.classList.remove("is-invalid");
		newPasswordConfirmInput.classList.remove("is-invalid");

		if (newPassword === "") {
			errorContainerPassword.innerText = t(em.password_required);
			newPasswordInput.classList.add("is-invalid");
			isValidForm = false;
		}
		if (newPasswordConfirm === "") {
			errorContainerConfirmPassword.innerText = t(em.password_required);
			newPasswordConfirmInput.classList.add("is-invalid");
			isValidForm = false;
		}

		if (isValidForm == false) {
			
		} else {
			setIsFinish(true)
			if (newPassword != newPasswordConfirm) {
				setIsFinish(false);
				newPasswordConfirmInput.classList.add("is-invalid");
				newPasswordInput.classList.add("is-invalid");
				errorContainerConfirmPassword.innerText = t(em.password_no_match);

			} else {
				const UpdatePasswordSuccess = (responseObject) => {
					toast.success(t(em.password_Changed_Successfully_with_Logout), {
						position: toast.POSITION.TOP_RIGHT,
						autoClose: 3000,
					});
					setTimeout(() => {
						setIsFinish(false);
					hideUpdatePassword();
					successCallBackFunction();
					}, 3000);
					
				};

				const UpdatePasswordFailure = (error) => {};

				var UpdatePasswordEndPoint = Constants.UpdatePasswordEndPoint+ userID;
				var UpdatePasswordJSON = '{"userCredentials":{"type": "password","value": "' + newPassword + '","temporary": false} } ';
				General.PostToEndPoint(UpdatePasswordEndPoint, null, UpdatePasswordJSON, UpdatePasswordSuccess, UpdatePasswordFailure);
			}
		}
	};

	const {
		config: { isRTL },
	} = useContext(AppContext);

	return (
		<>
		{isFinish && <LoaderIcon />}
		<Modal show={showComponent} onHide={handleCancelEdit} size="lg">
			<Modal.Header closeButton className="border-200">
				<Modal.Title as="h5">
					<Flex alignItems="center">
						<div className="icon-item bg-soft-success shadow-none">
							<FontAwesomeIcon icon="user" className="text-success" />
						</div>
						<div className="ms-2">{t(em.Change_Password)}</div>
					</Flex>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row className="g-0 align-items-center">
					<Col>
						<div className="mb-3">
							<label className="form-label">{t("User ID")}</label>
							<input type="text" className="form-control" value={userID} disabled="true" />
						</div>
						<div className="mb-3">
							<label className="form-label">{t("Email")}</label>
							<input type="text" className="form-control" value={email} disabled="true" />
						</div>

						<div className="mb-3">
							<label className="form-label">{t("New Password")}</label>
							<span id="errortxtNewPassword" className="loginAlert popupAlert inlineError">
								&nbsp;
							</span>
							<input
								type="password"
								className="form-control"
								id="txtNewPassword"
								value={newPassword}
								placeholder={t("New Password")}
								onChange={(event) => updateNewPassword(event.target.value)}
							/>
						</div>

						<div className="mb-3">
							<label className="form-label">{t("Confirm New Password")}</label>
							<span id="errortxtNewPasswordConfirm" className="loginAlert popupAlert inlineError">
								&nbsp;
							</span>
							<input
								type="password"
								className="form-control"
								id="txtNewPasswordConfirm"
								value={newPasswordConfirm}
								placeholder={t("Confirm New Password")}
								onChange={(event) => updateNewPasswordConfirm(event.target.value)}
								
							/>
						</div>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer className="border-200">
					<div>
						<Button size="sm" onClick={handleCancelEdit} variant="secondary">
							{t("Cancel")}
						</Button>
						<IconButton size="sm" className="ms-2" variant="primary" icon="check"  onClick={handleUpdatePassword}>
							{t("Confirm")}
						</IconButton>
					</div>
				
			</Modal.Footer>
		</Modal>
		</>
	);
};

UpdatePassword.propTypes = {
	userID: PropTypes.string,
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	email: PropTypes.string,
	showComponent: PropTypes.bool,
};

export default UpdatePassword;
