import React, { useEffect, useState } from "react";
import * as Constants from "common/constants";
import * as General from "common/general";
const LoadIFrame = () => {
	const [iframeUrl, setIframeUrl] = useState("");
	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);
		const code = queryParams.get("code");
		const GetIframeUrlSuccess = (responseObject) => {
			if (responseObject.active) {
				setIframeUrl(responseObject.iframeURL);
			}
		};
		// Callback for failed API response
		const GetIframeUrlFailure = () => {};
		const endPoint = `${Constants.GetEndPoint}/tbl_menus/code/${code}`;
		General.GetFromEndPoint(endPoint, null, null, GetIframeUrlSuccess, GetIframeUrlFailure);
	}, [window.location.search]);
	return <div>{iframeUrl && <iframe src={iframeUrl} title="Embedded Content" width="100%" height="1000" />}</div>;
};
export default LoadIFrame;
