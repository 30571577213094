import ReactBpmn from "react-bpmn";

import { emptyBpmn } from "./asset/empty.bpmn";

import { Link, useNavigate, useLocation } from "react-router-dom";

import "./WorkflowDiagramViewer.css";

import { useEffect, useState } from "react";

function WorkflowDiagramViewer() {
	const state = useLocation().state;
	const [diagram, setDiagram] = useState("");
	const onShown = () => {
		console.log("diagram shown");
	};
	const onLoading = () => {
		console.log("diagram loading");
	};
	const onError = (err) => {
		console.log("failed to show diagram");
	};
	useEffect(() => {
		//Runs only on the first render
		setDiagram(state.data);
	}, []);
	return (
		<ReactBpmn
			//url="./pizzaDiagram.bpmn"
			onShown={onShown}
			onLoading={onLoading}
			onError={onError}
			diagramXML={diagram}
		/>
	);
}

export default WorkflowDiagramViewer;
