import React, { useState, useEffect, Suspense } from "react";
import * as General from "common/general";
import * as Constants from "./common/constants";
//import LoginPage from './LoginPage';

const domainName = window.location.hostname;

const MasterLoginPage = () => {
	// State to hold the component to be rendered and loading state
	const [loginComponent, setLoginComponent] = useState("./InspectorLoginPage");

	// Callback for successful API response
	const GetLoginPageSuccess = (responseObject) => {
		if (responseObject.LoginComponent) {
			const LoginComponent = responseObject.LoginComponent;
			localStorage.setItem("domain", responseObject.DomainName);
			localStorage.setItem("domain_id", responseObject.id);
			setLoginComponent(LoginComponent);
		}
	};

	// Callback for failed API response
	const GetLoginPageFailure = () => {};

	useEffect(() => {
		// Make the API call here using useEffect hook to fetch the login component
		const endPoint = `${Constants.GetEndPoint}/LoginPages/DomainName/${domainName}`;
		General.GetFromEndPoint(endPoint, null, null, GetLoginPageSuccess, GetLoginPageFailure);
		//localStorage.setItem('domain', domainName);
	}, []);
	if (loginComponent == "LoginPage") {
		localStorage.setItem("platform", "manifest");
		const loadComponent = () => import("./LoginPage");
		const LazyComponent = React.lazy(loadComponent);
		return (
			<div>
				<Suspense>
					<LazyComponent />
				</Suspense>
			</div>
		);
	}

	if (loginComponent == "InspectorLoginPage") {
		localStorage.setItem("platform", "inspection");
		const loadComponent = () => import("./InspectorLoginPage");
		const LazyComponent = React.lazy(loadComponent);
		return (
			<div>
				<Suspense>
					<LazyComponent />
				</Suspense>
			</div>
		);
	}
};

export default MasterLoginPage;
