export const emailTemplates = {
	createmanifest:
		"اهلا {{USERNAME}}،\nتم إنشاء وثيقة النقل تحت رقم {{MANIFESTNUMBER}} بنجاح.\nشكرًا لك.\n\nHello {{USERNAME}},\nYou have successfully created the manifest number {{MANIFESTNUMBER}}.\nThank you.",

	transporteradmin:
		"اهلا {{USERNAME}}،\n تلقيت وثيقة نقل جديدة من {{GENERATORFACILITYNAME}} تحت رقم {{MANIFESTNUMBER}}.\nشكرًا لك.\n\nHello {{USERNAME}},\nYou have received a new manifest from {{GENERATORFACILITYNAME}} under the number {{MANIFESTNUMBER}}.\nThank you.",
	transporterdriver:
		"اهلا {{USERNAME}}،\nلديك شحنة نفايات جديدة من {{GENERATORFACILITYNAME}} بموجب وثيقة رقم {{MANIFESTNUMBER}} تم اسنادها لك من قبل {{TRANSPORTERADMINNAME}}.\nموقع الشحنة (رابط إلى خرائط جوجل متضمنه إحداثيات الشحنة).\nشكرًا لك.\n\nHello {{USERNAME}},\nYou have a waste load to pick up from {{GENERATORFACILITYNAME}} under the manifest number {{MANIFESTNUMBER}} assigned to you by {{TRANSPORTERADMINNAME}}.\nPick-up location (link to google maps including the pickup coordinates) \nThank you.",
	transporterreceived:
		"اهلا {{USERNAME}}،\nتم استلام حمولة النفايات من قبل {{TRANSPORTERFACILITYNAME}} متجه الى {{FACILITYNAME}} بموجب وثيقة رقم {{MANIFESTNUMBER}}.\nشكرًا لك.\n\nHello {{USERNAME}},\nThe waste load has been received by {{TRANSPORTERFACILITYNAME}} headed to {{FACILITYNAME}} under the manifest number {{MANIFEST NUMBER}}.\nThank you.",
	facilityadmin:
		"اهلا {{USERNAME}}،\nلديك شحنة نفايات جديدة متجهة إلى منشأتك من {{GENERATORFACILITYNAME}} بواسطة  {{TRANSPORTERFACILITYNAME}} بموجب وثيقة رقم {{MANIFESTNUMBER}}. \nيرجى الدخول إلى المنصة لتعيين المستلم.\nشكرًا لك.\n\nHello {{USERNAME}},\nYou have a waste load headed to your facility from {{GENERATORFACILITYNAME}} transported by {{TRANSPORTERFACILITYNAME}} under the manifest number {{MANIFESTNUMBER}}.\nPlease access the portal to assign a recipient. \nThank you.",
	facilityrecipient:
		"Hello {{USERNAME}},\nYou have a waste load to recieve from {{GENERATORFACILITYNAME}} transported by {{TRANSPORTERFACILITYNAME}} under the manifest number {{MANIFESTNUMBER}} assigned to you by {{FACILITYADMINNAME}}.\nThank you.",
	facilityreceived:
		"اهلا {{USERNAME}}،\nتم تسليم حمولة النفايات من قبل {{FACILITYNAME}} بموجب وثيقة رقم {{MANIFESTNUMBER}}.\nشكرًا لك.\n\nHello {{USERNAME}},\nThe waste load has been received by {{FACILITYNAME}} under the manifest number {{MANIFESTNUMBER}}.\nThank you.",
	adduseradmin:
		"اهلا {{ADMINNAME}}،\nتم إضافة المستخدم {{NEWUSERNAME}} بنجاح.\nشكرًا لك.\n\nHello {{ADMINNAME}},\nThe user {{NEWUSERNAME}} has been added successfully.\nThank you.",
	adduser:
		"اهلا {{USERNAME}}،\n تم تسجيلك في منصة وثيقة النقل الالكترونية لقطاع إدارة النفايات من موان.\nيمكنك الآن الدخول إلى النظام على الرابط التالي: https://emanifest.mwan.gov.saتحتا ج مساعدة؟ تواصل معنا على emanifest@mwan.gov.sa \n\nHello {{USERNAME}},\nYou have been registered to MWAN E-Manifest Waste Transport Platform.\nYou can now access the system on the following link: https://emanifest.mwan.gov.sa.\nNeed help? Contact us at emanifest@mwan.gov.sa",
};
