import React, { Component } from "react";
import { useState } from "react";
import ActionButton from "components/common/ActionButton";
import CardTextDropdown from "components/common/CardTextDropdown";
import { Dropdown, Col, Modal, Row, Button, Image } from "react-bootstrap";
import Flex from "components/common/Flex";
import IconButton from "components/common/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Constants from "common/constants";
import * as General from "common/general";
import DeleteConfirmation from "components/custom/forms/DeleteConfirmation";
import SaveFormAlert from "components/custom/forms/SaveFormAlert";
import Loader from "components/custom/Loader";
import { Link } from "react-router-dom";
import { FormBuilder as FormioFormBuilder } from "@formio/react";
import Formio from "formiojs/Formio";
import { Alert } from "reactstrap";
import "../../styles/Builder.css";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";

class AddForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loggedInEmail: "",
			showFormSavedAlert: false,
			errorOccuredVisibility: false,
			loaderContainerVisibility: false,
			jsonSchema: { components: [] },
			title: "",
			display: "form",
			type: "form",
			name: "",
			path: "",
			showAlert: false,
			translationUrl: "",
			languages: [],
		};
	}

	//ON LOAD
	async componentDidMount() {
		this.setState({
			loggedInEmail: localStorage.getItem(Constants.LoggedInEmailOrUserNameKeyInLocalStorage),
		});
		//this.bindForms();
	}
	/*************** COMMON FUNCTIONS START ************************/
	showErrorContainer = (shown) => {
		this.setState({ errorOccuredVisibility: shown });
	};

	showLoaderContainer = (shown) => {
		this.setState({ loaderContainerVisibility: shown });
	};

	showFormCreated = (createdForm) => {
		console.log("createdForm", createdForm);
		this.setState({
			FormID: createdForm._id,
			FormTitle: createdForm.title,
			FormName: createdForm.name,
			FormPath: createdForm.path,
			//status: createdForm.status,
			//message:createdForm.message,
			showFormSavedAlert: true,
		});
	};

	/*************** COMMON FUNCTIONS END ************************/

	/*************** SAVE FORM START ************************/
	saveForm = () => {
		var formData = {
			title: this.state.title,
			display: this.state.display,
			type: this.state.type,
			name: this.state.name,
			path: this.state.path,
			components: this.state.jsonSchema.components,
			settings: {
				languages: this.state.languages,
				translationUrl: this.state.translationUrl,
			},
		};
		const AddFormSuccess = (responseObject) => {
			var isSuccess = false;
			if (responseObject.status == Constants.SuccessStatus) {
				if (responseObject.data != null) {
					isSuccess = true;
					// General.SaveToken(responseObject);
					this.setState({ showAlert: true });
					// handleCancelNew();
					this.showFormCreated();
					//successCallBackFunction();
				}
			}

			if (isSuccess == false) {
				this.showErrorContainer(true);
				setTimeout(() => {
					this.showLoaderContainer(false);
					// this.showHideButtons(true);
					var errorContainer = document.getElementById("errorContainer");
					if (responseObject.message != null) {
						if (responseObject.message != "") {
							errorContainer.innerHTML = responseObject.message;
						}
					}
					if (errorContainer.innerHTML == "") {
						errorContainer.innerHTML = "An error occured, please try again later.";
					}
				}, 1000);
			} else {
				this.showErrorContainer(false);
				this.showLoaderContainer(false);
				// this.showHideButtons(true);
			}
		};

		const AddFormFailure = (error) => {
			// this.showErrorContainer(true);
			// setTimeout(function () {
			//     var errorContainer = document.getElementById('errorContainer');
			//     if (errorContainer.innerHTML == "") {
			//         errorContainer.innerHTML = "An error occured, please try again later." + error;
			//     }
			//     this.showLoaderContainer(false);
			//     this.showHideButtons(true);
			// }, 1000)
		};

		var AddFormJson = JSON.stringify(formData);
		var AddFormEndPoint = Constants.CreateFormEndPoint;
		if (this.state.languages.length !== 0) {
			if (this.state.translationUrl.replaceAll(" ", "") === "") {
				toast.error("Translation Url is required", { autoClose: 3000 });
				return;
			}
		}
		if (this.state.translationUrl.replaceAll(" ", "") !== "") {
			if (this.state.languages.length === 0) {
				toast.error("Languages are required", { autoClose: 3000 });
				return;
			}
		}
		General.PostToEndPoint(AddFormEndPoint, null, AddFormJson, AddFormSuccess, AddFormFailure);
	};
	/*************** SAVE FORM END ************************/

	/*************** CHANGE FORM DISPLAY START (FORM/WIZARD)************************/
	handleDisplayChange = (code) => {
		this.setState({ display: code });
	};
	/*************** CHANGE FORM DISPLAY END (FORM/WIZARD)************************/

	/*************** HANDLE SCHEMA CHANGES START ************************/
	handleSchemaChange = (schema) => {
		this.setState({ jsonSchema: schema });
	};
	/*************** HANDLE SCHEMA CHANGES END ************************/

	/*************** HANDLE NAME CHANGE START ************************/
	handleNameChange = (name) => {
		this.setState({ name: name, title: name, path: name.replaceAll(" ", "") });
	};
	/*************** HANDLE NAME CHANGE END ************************/

	handleTranslationUrlChange = (url) => {
		this.setState({ translationUrl: url });
	};
	handleLanguagesChange = (lngs) => {
		this.setState({ languages: lngs });
	};

	render() {
		const {
			loggedInEmail,
			errorOccuredVisibility,
			loaderContainerVisibility,
			showFormSavedAlert,
			jsonSchema,
			title,
			display,
			type,
			name,
			path,
			showAlert,
			dropdownOpen,
			languages,
			translationUrl,
		} = this.state;
		return (
			<div className="card">
				<div className="row">
					<div className="col-sm-7">
						<input type="text" className="form-control" placeholder="Form Title" onChange={(e) => this.handleNameChange(e.target.value)} />
					</div>
					<div className="col-sm-1">
						<CardTextDropdown customtext="Display">
							<div className="py-2">
								<Dropdown.Item onClick={() => this.handleDisplayChange("form")}>Form</Dropdown.Item>
								<Dropdown.Item onClick={() => this.handleDisplayChange("wizard")}>Wizard</Dropdown.Item>
							</div>
						</CardTextDropdown>
					</div>
					<div className="col-sm-4 savebtn">
						<button disabled={loaderContainerVisibility} className="btn btn-outline-secondary" type="submit" onClick={() => this.saveForm()}>
							Save Form
						</button>
					</div>
				</div>

				<div className="row mt-3">
					<div className="col-sm-7">
						<input type="text" className="form-control" placeholder="Translation Url" onChange={(e) => this.handleTranslationUrlChange(e.target.value)} />
					</div>
					<div className="col-sm-5">
						<Select closeMenuOnSelect={false} isMulti options={Constants.FormLanguages} value={languages} onChange={this.handleLanguagesChange} />
					</div>
				</div>
				<FormioFormBuilder
					options={{
						builder: {
							custom: {
								title: "Custom",
								weight: 10,
								components: {
									photoCapture: true,
									facilitiesCustomComp: true,
									geolocation: true,
									conditionalDropDownCustomComp: true,
									companyCustomComp: true,
									companyUsersCustomComp: true,
									companyRelationCustomComp: true,
									getAllCustomComp: true,
									normalSelectCustomComp: true,
									loggedInUserDetailsCustomComp: true,
									textFieldValidationCustomComp: true,
									mapCustomComp: true,
									qrcodeCustomComp:true,
									radioButtonCustomComp:true,
									dynamicTableDataComp:true,

									// generalAPICustomComp: true,
								},
							},
						//	advanced: false,
						},
					}}
					className="formstyle"
					form={(jsonSchema, { display: display })}
					onChange={(schema) => this.handleSchemaChange(schema)}
				/>

				{errorOccuredVisibility ? <div id="errorContainer" className="loginAlert"></div> : ""}
				{loaderContainerVisibility ? <Loader></Loader> : ""}

				{showFormSavedAlert && (
					<SaveFormAlert
						successCallBackFunction={this.showFormSavedAlert}
						hideDeleteConfirmation={this.handleCancelDelete}
						showComponent={showFormSavedAlert}
						formTitle={title}
						formName={name}
						formPath={path}
					></SaveFormAlert>
				)}

				{showAlert && (
					<Alert variant="success" onClose={() => this.setState({ showAlert: false })}>
						Form Saved!
					</Alert>
				)}
			</div>
		);
	}
}

export default AddForm;
