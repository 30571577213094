import React from "react";
import "../../styles/Builder.css";
import { useState, useEffect, useRef } from "react";
//import axios from 'axios';
import { Form } from "@formio/react";
import * as Constants from "common/constants";
import jwtDecode from "jwt-decode";
//import { FormBuilder as FormioFormBuilder } from '@formio/react';
import { Card, Col, Row } from "react-bootstrap";
import { atomOneDark, CopyBlock, dracula } from "react-code-blocks";
import { useParams } from "react-router-dom";
import { Link, useNavigate, useLocation, Navigate } from "react-router-dom";
import { Alert } from "reactstrap";
import { event } from "jquery";
import { FORM_RESET } from "../../../node_modules/@formio/react/lib/modules/form/constants";
import { resetForm } from "../../../node_modules/@formio/react/lib/modules/form/actions";
import ToastifyNotification from "../toastifyNotification/ToastifyNotification";
import { ToastContainer, toast } from "react-toastify";
import store from "reducers/store";
import { DECREMENT_WORKFLOW } from "reducers/flagReducer";
import { DECREMENT_WORKFLOW_UNASSIGNED } from "reducers/flagUnassignedReducer";

import * as em from "common/ErrorMessages";
import {
	AccessTokenKeyInLocalStorage,
	CompleteTaskEndPoint,
	GetExternalServiceTaskEndPoint,
	GetFormByIdEndPoint,
	GetFormByUserTaskEndPoint,
	GetServiceTask,
	LoggedInEmailOrUserNameKeyInLocalStorage,
	LoginEndPoint,
	PostEndPoint,
} from "common/constants";
import jQuery from "jquery";
import LoaderIcon from "components/custom/Preloader";
import {
	getFormByUserTask,
	getExternalServiceTask,
	fetchAndLock,
	completeExternalTask,
	getFormById,
	getAllWorkflows,
	post,
	sendNotification,
	getFormByUserTaskWithAssignee,
	putProcessInstanceVariable,
	createUser,
	getTaskVariables,
	modifyProcessInstanceVariable,
	patch,
	claimTask,
	createGroup,
	createSubmission,
	getAllDatabaseRecords,
	getWorkflowsHistoryByProcessInstance,
	formatDate,
	getExecutionLocalVariables,
	createCompany,
	sendOtp,
	sendSms,
	replacePlaceholders,
	getCountTaskByAssignee,
	SubmitWorkflowData,
} from "components/workflow/WorkflowHelper";
import { PostToEndPoint } from "common/general";
import moment from "moment-timezone";
import Formioform from "./FormioForm";
import queryString from "query-string";
import db from "indexedDB";
import { EncryptStorage } from "encrypt-storage";
import keycloakService from "keycloakService";
import { useTranslation } from "react-i18next";
import { emailTemplates } from "common/SmsTemplates";

function FormViewRenderer() {
	const navigate = useNavigate();
	const { data } = useParams();
	const location = useLocation();
	const [jsonSchema, setSchema] = useState([]);
	const [query, setQuery] = useState("");
	const [formName, setFormName] = useState("");
	const [showAlert, setShowAlert] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [showNotification, setShowNotification] = useState(false);
	const [showPreloader, setshowPreloader] = useState(false);
	const [submissionData, setSubmissionData] = useState({});
	const [showFailAlert, setShowFailAlert] = useState(false);
	const [alertmessage, setAlertMessage] = useState("");
	const [nextPage,setNextPage]=useState(false);
	const handleChange = (event) => {
		setQuery(event.target.value);
	};

	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});

	const { index } = useParams();

	const state = useLocation().state;
	const [form, setForm] = useState(null);
	const [t, i18n] = useTranslation();

	const queryParams = queryString.parse(location.search);

	useEffect(() => {
		if (state != null) {
			if (state.data.scheduled === "true") {
				state.data.settings.data.isEditable = true;
			} else if (state.data?.settings?.data) {
				state.data.settings.data.initiallyEnabled = true;
			}
			setForm(state.data);
		}
		//console.log(1)
		if (submitted) {
			setSubmitted(false);
			window.location.reload(false);
		}
		//window.location.reload(false);
	}, [location.key]);
	let onlineStatusFormData = null;

	useEffect(() => {
		var accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);
		if (accessToken) {
			const decodedAccessToken = jwtDecode(accessToken);

			setInterval(() => {
				keycloakService.renewSession();
			}, decodedAccessToken.exp - 120000);
		}

		window.addEventListener("online", async () => {
			const encryptStorage1 = new EncryptStorage("secret-key-value", {
				prefix: "@mwan",
			});
			console.log("enteredd onlineEventHandler");
			const tempSubmissionData = await db.submission_data.toArray();
			var isLastIteration = false;
			for (let i = 0; i < tempSubmissionData.length; i = i + 1) {
				onlineStatusFormData = await db.pending_tasks.get(parseInt(JSON.parse(tempSubmissionData[i].submissionData).index, 10));
				onlineStatusFormData = JSON.parse(onlineStatusFormData.pendingTasks);
				const { index, ...newObject } = JSON.parse(tempSubmissionData[i].submissionData);
				if (i === tempSubmissionData.length - 1) {
					await db.table("submission_data").clear();
					await db.table("pending_tasks").clear();
					isLastIteration = true;
				}
				handleTaskSubmit(
					newObject,
					onlineStatusFormData,
					setSubmitted,
					setshowPreloader,
					form,
					location,
					setShowNotification,
					queryParams,
					navigate,
					state,
					encryptStorage1,
					t,
					isLastIteration
				);

				const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
				await delay(2000);
			}
		});
	}, []);

	if (form == null && state == null) {
		return <Navigate to="/login" replace="true" />;
	}
	return (
		<Card className="p-4">
			{showPreloader && <LoaderIcon />}
			<Formioform
				form={form}
				onSubmit={(data) => handleTaskSubmit(data, null, setSubmitted, setshowPreloader, form, location, setShowNotification, queryParams, navigate, state, encryptStorage1, t,false,setNextPage)}
				submission={{
					data: submissionData,
				}}
				nextPage={nextPage}
				state={state.data}
			/>
			{showNotification && <ToastifyNotification show={showNotification} />}
		</Card>
	);
}

export const handleTaskSubmit = async (
	submission,
	onlineStatusFormData,
	setSubmitted,
	setshowPreloader,
	form,
	location,
	setShowNotification,
	queryParams,
	navigate,
	state,
	encryptStorage1,
	t,
	isLastIteration,
	setNextPage
) => {
	if (!encryptStorage1) {
		return;
	}
	await db.current_workflow_step.clear;
	localStorage.removeItem(Constants.CurrentWorkflowStep);

	const userInfo = encryptStorage1.getItem(Constants.LoggedInUserInfoKeyInLocalStorage);
	const loggedInUser = userInfo.userId;
	let companyType = null;
	let step = null;
	let formKey = null;
	let processInstanceID = null;
	let creationDate = null;
	let modificationDate = null;
	if (location !== null && location !== undefined) {
		if (location.hasOwnProperty("state")) {
			if (location.state !== null) {
				companyType = location.state.companyType;
				step = location.state.data.step;
				formKey = location.state.data.formKey;
				processInstanceID = location.state.data.processInstanceId;
				creationDate = location.state.data.creationDate;
				modificationDate = location.state.data.modificationDate;
			}
		}
	}
	submission.data.formKey = formKey;
	submission.data.step = step;
	submission.data.processInstanceId = processInstanceID;

	if (!navigator.onLine) {
		toast.success(t("Form is being Submitted. Please Wait a moment"), {
			position: "top-right",
			autoClose: 3000,
		});

		submission.index = queryParams.index;
		db.submission_data.add({
			submissionData: JSON.stringify(submission),
		});
		store.dispatch({ type: DECREMENT_WORKFLOW });
		navigate("/userTasks");

		return;
	} else {
		setshowPreloader(true);
		toast.success(t("Form is being Submitted. Please Wait a moment"), {
			position: "top-right",
			autoClose: 3000,
		});
		var companyId = encryptStorage1.getItem(Constants.SelectedCompany);
		var lang = localStorage.getItem("i18nextLng");
		var submitedWorkflow = await SubmitWorkflowData(loggedInUser, submission.data, form ?? onlineStatusFormData, companyId, lang);
		setshowPreloader(false);
		if (submitedWorkflow.status == 2) {
			navigate("/form_view_renderer", {
				replace: true,
				state: {
					data: JSON.parse(submitedWorkflow.message),
					assignee: loggedInUser,
				},
			});
			setNextPage(true)
			return;
		}
	}

	store.dispatch({ type: DECREMENT_WORKFLOW });
	var redirectionPage = null;
	if (form.taskVariables?.find((variable) => variable.name === "redirectionPage")) {
		redirectionPage = form.taskVariables.find((variable) => variable.name === "redirectionPage").value;
	}

	if (redirectionPage) {
		navigate("/" + redirectionPage);
	} else {
		navigate("/userTasks");
	}

	return;
};

export default FormViewRenderer;
