export const SET_ROUTE_OBJECT = 'SET_ROUTE_OBJECT';

  const routeReducer = (state =[], action) => {
    switch (action.type) {
  
      case SET_ROUTE_OBJECT:
        return {
          ...state,
          data: action.payload.data,
        };
  
      default:
        return state;
  
    }
  }
  
  export default routeReducer;