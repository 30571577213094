import React, { useState, useEffect } from 'react';
import { EncryptStorage } from 'encrypt-storage';
import * as Constants from "common/constants";
import _ from 'lodash';
import "../../../../styles/Builder.css";
import { useTranslation } from "react-i18next";

const DynamicTable = ({ component, form, onChange, options, setComponentState, setValidationErrors }) => {
    const [myState, setMyState] = useState({ rows: [], ...options });
    const [selectedRows, setSelectedRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10); // Default rows per page
    const [selectedCount, setSelectedCount] = useState(0); // State for selected items count
    const [responseData, setResponseData] = useState([]);
    const encryptStorage1 = new EncryptStorage('secret-key-value', { prefix: '@mwan' });
    const [t, i18n] = useTranslation();
    const [errors, setErrors] = useState({});

    function isPureNumber(value) {
        if (typeof value === 'string') {
            // Check if the string contains only digits (0-9) and optionally starts with a sign (+ or -)
            return /^-?\d+$/.test(value);
        }
        return false;  // If it's not a string, return false
    }

    function isString(value) {
        return typeof value === 'string';
    }

    const updateValue = (index, key, value) => {
        const updatedRows = [...myState.rows];
        updatedRows[index][key] = value;
        const column = component.columns.find(column => column.key === key);

        // Validation
        if (column && column.isValidation) {
            const fisrtCondition = updatedRows[index][column.fisrtCondition]; // Accessing updated value from updatedRows
            const secondCondition = updatedRows[index][column.secondCondition]; // Accessing updated value from updatedRows
            const typeCondition = column.typeCondition;
            const newErrors = { ...errors };
            if (typeCondition === "number") {
                if (isPureNumber(value)) {
                    if (parseInt(value) < parseInt(fisrtCondition) || parseInt(value) > parseInt(secondCondition)) {
                        newErrors[index] =`${t("Amount should be between")} ${fisrtCondition} ${t("and")} ${secondCondition}`;
                    } else {
                        delete newErrors[index];
                    }
                } else {
                    newErrors[index] = `${t("value should be only a number")}`;
                }
            } else {
                // if string
            }

            setErrors(newErrors);
            setValidationErrors(newErrors);
        }
        const newState = { ...myState, rows: updatedRows };
        setMyState(newState);
        onChange(newState, null);
        setComponentState(newState);
    };

    const initialize = () => {
        let state = { ...myState };
        const initialValue = component.defaultValue || form._data[component.key];

        if (initialValue) {
            state = initialValue;
        }

        state.initialDisabled = component.disabled;

        if (!state.loaded && !component.disabled) {
            loadData(state);
        } else {
           
            const rows = [];

            const addRowsFromSource = (source, isSelected = false) => {
                source.forEach((item) => {
                    const newRow = {};
                    component.columns.forEach((column) => {
                        newRow[column.key] = item[column.key];
                    });
                    if (isSelected) {
                        newRow.selected = true;
                    }
                    rows.push(newRow);
                });
            };
            if ('selected' in state) {
                addRowsFromSource(state.selected, true);
                
            }
            if ('unselected' in state) {
                addRowsFromSource(state.unselected);
            }
            if (!('selected' in state) && !('unselected' in state) && 'rows' in state) {
                addRowsFromSource(state.rows);
            }
            if (!('selected' in state) && !('unselected' in state) && !('rows' in state)) {
                addRowsFromSource(state);
            }

           // onChange(rows, null);
            state.rows = rows;
            state.loaded = true;
            onChange(state, null);
            setMyState(state);
            setComponentState(state);
        }
    };

    const updateCheckbox = (index, isChecked) => {
        const updatedRows = [...myState.rows];
        updatedRows[index].selected = isChecked;

        const updatedSelectedRows = isChecked
            ? [...selectedRows, updatedRows[index]]
            : selectedRows.filter((row) => row !== updatedRows[index]);

        const updatedUnSelectedRows = updatedRows.filter(row => !row.selected);

        setMyState({ ...myState, rows: updatedRows });
        setSelectedRows(updatedSelectedRows);
        setSelectedCount(updatedSelectedRows.length);

        // Pass both selected and unselected rows via onChange
        onChange({ selected: updatedSelectedRows, unselected: updatedUnSelectedRows }, null);
    };

    const evaluateCondition = (value1, operator, value2) => {
        switch (operator) {
            case '>':
                return parseInt(value1) > parseInt(value2);
            case '<':
                return parseInt(value1) < parseInt(value2);
            case '=':
                return parseInt(value1) === parseInt(value2);
            default:
                return false;
        }
    };

    useEffect(() => {
        initialize();
    }, []);

    const loadData = async (state) => {

        if(!component.isLocal){
            const queryParamsFieldPath = component.queryParamsFromForm[0]?.queryParamsFieldPath;
            const queryParamsFieldName = component.queryParamsFromForm[0]?.queryParamsFieldName;
            if (queryParamsFieldPath && form._form.settings?.data.hasOwnProperty(queryParamsFieldName)) {
            let responseDataPath = component.dataPath;
            let jsonField = component.jsonfields;
            let jsonFieldsecond = component.jsonfieldssecond || "";
            let fullUrl = Constants.base_url+component.requestUrl + "/" + "&&" + component.queryParamsFromForm[0].queryParamsFieldPath + "=eq." + form._form?.settings?.data[queryParamsFieldName];

            var accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);
            var headers = { "Content-Type": "application/json" };

            if (accessToken) {
                var headerToken = { Authorization: "Bearer " + accessToken };
                headers = { ...headers, ...headerToken };
            }
            if (fullUrl) {
                fetch(fullUrl, {
                    method: "GET",
                    headers: { ...headers },
                })
                    .then((response) => response.json())
                    .then((res) => {
                        let result = responseDataPath ? _.get(res, responseDataPath, "") : res;
                        if (jsonField) {
                            if (typeof result === "string") {
                                result = JSON.parse(result);
                            }
                        }

                        const rows = [];
                        result.forEach(item => {
                            if (component.jsonfields) {
                                if (item[jsonField] != null && Array.isArray(JSON.parse(item[jsonField]))) {
                                    const responseParce = JSON.parse(item[jsonField]);
                                    if (responseParce.length > 0) {
                                        if (component.isHiddenPagination) {
                                            setRowsPerPage(responseParce.length);
                                        }
                                        setResponseData(responseParce);
                                        responseParce.forEach((item, index) => {
                                            const newRow = {};
                                            component.columns.forEach(column => {
                                                if (jsonFieldsecond !== "") {
                                                    newRow[column.key] = item[jsonFieldsecond][column.key];
                                                } else {
                                                    newRow[column.key] = item[column.key];
                                                }
                                            });
                                            rows.push(newRow);
                                        });
                                        onChange(...myState.rows, null);
                                    }
                                }
                            } else {
                                component.columns.forEach(column => {
                                    let row = {};
                                    row[column.key] = item[column.key];
                                    rows.push(row);
                                });
                            }
                        });

                        state.rows = rows;
                        state.loaded = true;
                        setMyState(state);
                        setComponentState(state);

                    });
                }
            } //if Url exist
        } else if (component.isLocal) {
            const rows = [];
            const islocalData = form._form.settings?.data.hasOwnProperty(component.fieldLocalPath);
            if (islocalData) {
                const localData = JSON.parse(form._form.settings?.data[component.fieldLocalPath]);

                const newRow = {};
                component.columns.forEach(column => {
                    if (localData.hasOwnProperty(column.key)) {
                        newRow[column.key] = localData[column.key];
                    }
                });

                rows.push(newRow);

                onChange(...myState.rows, null);
                state.rows = rows;
                state.loaded = true;
                setMyState(state);
                setComponentState(state);

            }
        } else {
            console.log("Field name does not exist in the form data.");
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const currentRows = myState.rows ? myState.rows.slice(startIndex, endIndex) : [];

    const totalPages = Math.ceil((myState.rows ? myState.rows.length : 0) / rowsPerPage);

    return (
        <div className='table-responsive' style={{ width: '100%' }}>
            <table className="dynamic-table" style={{ width: '100%' }}>
                <thead style={{ border: '1px solid #ddd', backgroundColor: '#009e4f' }}>
                    <tr>
                        {!component.isHidden && <th></th>}
                        {component.columns.map((column) => (
                            column.isHiddenfields ? null : (
                                <th key={column.key} style={{ fontFamily: "Cairo-Bold", margin: '0 10px', color: '#fff', textAlign: 'center' }}>{t(column.label)}</th>
                            )
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {currentRows.map((row, index) => (
                        <tr key={index}>
                            {!component.isHidden && (
                                <td key={`checkbox-${startIndex + index}`}>
                                    {evaluateCondition(row[component.fieldConditionCheckBox], component.operatorCondition, component.valueConditionCheckBox) && (
                                        <input
                                            type="checkbox"
                                            checked={row.selected || false}
                                            onChange={(e) => updateCheckbox(startIndex + index, e.target.checked)}
                                            disabled={component.disabled}
                                            value={t(row[component.columns.key])}
                                        />
                                    )}
                                </td>
                            )}
                            {component.columns.map((column) => (
                                column.isHiddenfields ? null : (
                                    <td key={`${column.key}-${startIndex + index}`}>
                                        {column.isEditable && !component.disabled ? (
                                            <>
                                                <input
                                                    type="text"
                                                    value={t(row[column.key]) || ''}
                                                    onChange={(e) => updateValue(startIndex + index, column.key, e.target.value)}
                                                    disabled={!column.isEditable}
                                                    className={errors[index] ? "error-input" : ""}
                                                />
                                                {errors[index] && <span style={{ color: 'red' }}>{errors[index]}</span>}
                                            </>
                                        ) : (
                                            <p style={{ fontFamily: "Cairo-Regular" }}>{t(row[column.key]) || ''}</p>
                                        )}
                                    </td>
                                )
                            ))}
                        </tr>
                    ))}
                </tbody>
                {!component.isHiddenPagination && (
                    <tfoot>
                        <tr>
                            <td colSpan={component.columns.length + 1} style={{ padding: '10px 0' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <span style={{ margin: '0 10px' }}>{t("Selected Items:")}{` ${selectedCount}`}</span>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <button onClick={() => setCurrentPage(1)} disabled={currentPage === 1} style={{ fontFamily: "Cairo-Bold", backgroundColor: '#009e4f', color: '#fff', border: 'none', borderRadius: '5px', padding: '5px 10px', cursor: 'pointer', margin: '0 5px', width: '50px' }}>
                                            &lt;&lt; {/* Double left arrow */}
                                        </button>
                                        <button onClick={handlePreviousPage} disabled={currentPage === 1} style={{ fontFamily: "Cairo-Bold", backgroundColor: '#009e4f', color: '#fff', border: 'none', borderRadius: '5px', padding: '5px 10px', cursor: 'pointer', margin: '0 5px', width: '50px' }}>
                                            &lt; {/* Left arrow */}
                                        </button>
                                        <span style={{ margin: '0 10px' }}>{t("Page")}{` ${currentPage}`} {t("of")} {` ${totalPages}`}</span>
                                        <select
                                            value={rowsPerPage}
                                            onChange={(e) => setRowsPerPage(Number(e.target.value))}
                                            style={{ fontFamily: "Cairo-Bold", backgroundColor: '#fff', color: '#000', border: '1px solid #ddd', borderRadius: '5px', padding: '5px', cursor: 'pointer', margin: '0 5px' }}
                                        >
                                            <option value={5}>5</option>
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                            <option value={50}>50</option>
                                        </select>
                                        <button onClick={handleNextPage} disabled={currentPage === totalPages} style={{ fontFamily: "Cairo-Bold", backgroundColor: '#009e4f', color: '#fff', border: 'none', borderRadius: '5px', padding: '5px 10px', cursor: 'pointer', margin: '0 5px', width: '50px' }}>
                                            &gt; {/* Right arrow */}
                                        </button>
                                        <button onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages} style={{ fontFamily: "Cairo-Bold", backgroundColor: '#009e4f', color: '#fff', border: 'none', borderRadius: '5px', padding: '5px 10px', cursor: 'pointer', margin: '0 5px', width: '50px' }}>
                                            &gt;&gt; {/* Double right arrow */}
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                )}
            </table>
        </div>
    );
};

export default DynamicTable;
