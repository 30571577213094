import React, { useState, useContext } from "react";

import PropTypes from "prop-types";
import AppContext from "context/Context";
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Col, Modal, Row, Button, Image } from "react-bootstrap";
import IconButton from "components/common/IconButton";
import * as Constants from "common/constants";
import * as General from "common/general";
import Loader from "components/custom/Loader";

import { useTranslation } from "react-i18next";

const DeleteConfirmation = ({ formID, formTitle, formName, formPath, showComponent, hideDeleteConfirmation, successCallBackFunction }) => {
	const [errorContainerVisiblity, showErrorContainer] = useState(false);
	const [loaderContainerVisibility, showLoaderContainer] = useState(false);
	const [buttonsVisibility, showHideButtons] = useState(true);
	const [t, i18n] = useTranslation();

	const handleCancelDelete = (mode) => {
		hideDeleteConfirmation(mode);
	};

	const handleConfirmDelete = async () => {
		showLoaderContainer(true);
		showHideButtons(false);
		showErrorContainer(false);

		const DeleteFormSuccess = (responseObject) => {
			var isSuccess = false;
			if (responseObject.status == Constants.SuccessStatus) {
				if (responseObject.data != null) {
					isSuccess = true;
					handleCancelDelete(true);
				}
			}

			if (isSuccess == false) {
				showErrorContainer(true);
				setTimeout(function () {
					var errorContainer = document.getElementById("errorContainer");
					if (responseObject.message != null) {
						if (responseObject.message != "") {
							errorContainer.innerHTML = responseObject.message;
						}
					}
					if (errorContainer.innerHTML == "") {
						errorContainer.innerHTML = "An error occured, please try again later.";
					}
					showLoaderContainer(false);
					showHideButtons(true);
				}, 1000);
			} else {
				showErrorContainer(false);
				showLoaderContainer(false);
				showHideButtons(true);
			}
		};

		const DeleteFormFailure = (error) => {
			showErrorContainer(true);
			setTimeout(function () {
				var errorContainer = document.getElementById("errorContainer");
				if (errorContainer.innerHTML == "") {
					errorContainer.innerHTML = "An error occured, please try again later. " + error;
				}
				showLoaderContainer(false);
				showHideButtons(true);
			}, 1000);
		};

		var deleteEndpoint = Constants.DeleteFormEndPoint.replace("#FORMPATH#", formPath);

		General.DeleteFromEndPoint(deleteEndpoint, null, null, DeleteFormSuccess, DeleteFormFailure);
	};

	const {
		config: { isRTL },
	} = useContext(AppContext);

	return (
		<Modal show={showComponent} onHide={() => handleCancelDelete(false)} size="lg">
			<Modal.Header closeButton className="border-200">
				<Modal.Title as="h5">
					<Flex alignItems="center">
						<div className="icon-item bg-soft-success shadow-none">
							<FontAwesomeIcon icon="form" className="text-success" />
						</div>
						<div className="ms-2">{t("Delete Form")}</div>
					</Flex>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row className="g-0 align-items-center">
					<Col>
						<div className="mb-3">
							{t("Are you sure you want to delete this form?")}
							<br />
							{t("Form title")}: <strong>{formTitle}</strong>
							<br />
							{t("Form name")}: <strong>{formName}</strong>
							<br />
							{t("Form path")}: <strong>{formPath}</strong>
							<br />
							{t("Form ID")}: <strong>{formID}</strong>
							<br />
						</div>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer className="border-200">
				{buttonsVisibility && (
					<div>
						<Button onClick={() => handleCancelDelete(false)} size="sm" variant="secondary">
							{t("No")}
						</Button>
						<IconButton onClick={handleConfirmDelete} icon="" size="sm" className="ms-2" variant="primary">
							{t("Yes")}
						</IconButton>
					</div>
				)}

				{loaderContainerVisibility && <Loader></Loader>}

				{errorContainerVisiblity && <div id="errorContainer" className="loginAlert popupAlert"></div>}
			</Modal.Footer>
		</Modal>
	);
};

DeleteConfirmation.propTypes = {
	formID: PropTypes.string,
	formName: PropTypes.string,
	formPath: PropTypes.string,
	formTitle: PropTypes.string,
	showComponent: PropTypes.bool,
};

export default DeleteConfirmation;
