import React, { Component, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { ReactComponent, Utils } from "@formio/react";
import settingsForm from "./GetAll.settingsFrom";
import _ from "lodash";
import { createRoot } from "react-dom/client";
import * as General from "common/general";
import { getRandomId } from "helpers/utils";
import * as Constants from "common/constants";
import { EncryptStorage } from "encrypt-storage";
import { useTranslation } from "react-i18next";

/*
This component behaves as searchable dropdown where user can search for a certain field from the result we got from api 
*/
const GetAllCustomComp = ({ ...props }) => {
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});
	const inputRef = useRef();
	const [t, i18n] = useTranslation();

	// Update the setValue method to handle onChange event
	const updateValue = (e) => {
		if (props.form._form.settings && props.form._form.settings.toEdit !== true) {
			let state = props.oldState;

			const newValue = e;
			let json = state.selectedValues;
			json.value = e;

			// Get the selected values from the event
			let selectedValues = props.oldState.options.filter((v) => v.value === newValue)[0];
			if (selectedValues) {
				if (props.component.companyGrid) {
					for (let i = 0; i < props.component.companyGrid.length; i++) {
						json[props.component.companyGrid[i].companyFieldPath] = selectedValues[props.component.companyGrid[i].companyFieldPath];
					}
				}
				state["selectedValues"] = json;
			} else {
				let valid = true;
				for (let i = 0; i < props.component.companyGrid?.length; i++) {
					if (!state["selectedValues"][props.component.companyGrid[i].companyFieldPath]) {
						valid = false;
					} else {
						if (valid) {
							valid =
								props.component.companyGrid[i]["companyFieldValidation"] === "email"
									? General.isValidEmail(state["selectedValues"][props.component.companyGrid[i]["companyFieldPath"]])
									: props.component.companyGrid[i]["companyFieldValidation"] === "phoneNumber"
									? General.isKSAPhoneNumber(state["selectedValues"][props.component.companyGrid[i]["companyFieldPath"]])
									: true;
						}
					}
				}
				if (!valid || newValue.trim() === "") {
					props.setOldState(state);
					props.form._data[props.component.key] = json;

					props.onChange("", null); // Call onChange with the new value
				} else {
					props.setOldState(state);
					props.form._data[props.component.key] = json;
					props.onChange(json, null);
				}
			}
		}
	};

	useEffect(() => {
		let state = props.oldState;

		if (props.component.defaultValue !== "" && props.component.defaultValue && state.valuesRendered === false) {
			state["valuesRendered"] = true;
			state["selectedValues"] =
				props.component.defaultValue.value || props.component.defaultValue.value === "" ? props.component.defaultValue : JSON.parse(props.component.defaultValue);
			if (props.component.companyGrid) {
				let valid = true;
				for (let i = 0; i < props.component.companyGrid.length; i++) {
					if (!state.selectedValues[props.component.companyGrid[i].companyFieldPath]) {
						state.selectedValues[props.component.companyGrid[i].companyFieldPath] = "";
						valid = false;
					} else {
						if (valid) {
							valid =
								props.component.companyGrid[i]["companyFieldValidation"] === "email"
									? General.isValidEmail(state["selectedValues"][props.component.companyGrid[i]["companyFieldPath"]])
									: props.component.companyGrid[i]["companyFieldValidation"] === "phoneNumber"
									? General.isKSAPhoneNumber(state["selectedValues"][props.component.companyGrid[i]["companyFieldPath"]])
									: true;
							if (!valid) {
								state["errors"][props.component.companyGrid[i].companyFieldPath] = t("Validation Error");
							}
						}
					}
					if (!valid || !state["selectedValues"].value) {
						props.onChange("", null); // Call onChange with the new value
					}
				}
			}
		} else {
			if (props.component.companyGrid) {
				for (let i = 0; i < props.component.companyGrid.length; i++) {
					if (!state.selectedValues[props.component.companyGrid[i].companyFieldPath]) {
						state.selectedValues[props.component.companyGrid[i].companyFieldPath] = "";
					}
				}
			}
		}
		props.setOldState(state);
	}, []);

	const loadData = (e) => {
		let valid = true;

		if (
			props.component.requestUrl !== "" &&
			props.component.requestUrl &&
			props.component.dataPath &&
			props.component.dataPath !== "" &&
			props.component.searchedFieldName &&
			props.component.searchedFieldName !== ""
		) {
			var accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);
			var headers = { "Content-Type": "application/json" };

			if (accessToken) {
				var headerToken = { Authorization: "Bearer " + accessToken };
				headers = { ...headers, ...headerToken };
			}
			if (e.toString().length >= 2) {
				let state = props.oldState;

				state["loading"] = true;
				props.setOldState(state);

				fetch(props.component.requestUrl + "/" + props.component.searchedFieldName + "=ilike.*" + e + "*&&select=*", { method: "GET", headers: headers })
					.then((response) => response.json())
					.then((res) => {
						if (res) {
							let path = props.component.dataPath;
							let result = path ? _.get(res, path, "") : res;
							let state = props.oldState;

							let val = result?.map((e) => ({ ...e }));
							let val2 = [];
							let mainJson = {};

							for (let i = 0; i < val.length; i++) {
								let json2 = JSON.parse(val[i][props.component.key]) || val[i][props.component.key];
								Object.keys(json2).map((key) => {
									mainJson[key] = json2[key];
								});
								val2.push(mainJson);
								mainJson = {};
							}
							let tempSelectedValuesIndex = val2.findIndex((s) => s.value === e);
							let json = {};

							if (tempSelectedValuesIndex >= 0) {
								if (props.component.companyGrid) {
									for (let i = 0; i < props.component.companyGrid.length; i++) {
										json[props.component.companyGrid[i].companyFieldPath] = val2[tempSelectedValuesIndex][props.component.companyGrid[i].companyFieldPath];
									}
								}
								json[props.component.searchedFieldName] = val2[tempSelectedValuesIndex].value;
								json["defaultValue"] = e;
								json["value"] = e;

								state["selectedValues"] = json;
							}

							state["options"] = val2;
							state["loading"] = false;

							props.setOldState(state);
							props.onChange(json, null);

							if (props.form._form.settings && props.form._form.settings.toEdit !== true) {
								props.component.values = val;
							}
						}
					})
					.then(() => {})
					.catch((error) => {
						console.error("Error:", error);
					});
			} else {
				let state = props.oldState;
				for (let i = 0; i < props.component.companyGrid?.length; i++) {
					if (!state["selectedValues"][props.component.companyGrid[i].companyFieldPath]) {
						valid = false;
					}
					if (valid) {
						valid =
							props.component.companyGrid[i]["companyFieldValidation"] === "email"
								? General.isValidEmail(state["selectedValues"][props.component.companyGrid[i]["companyFieldPath"]])
								: props.component.companyGrid[i]["companyFieldValidation"] === "phoneNumber"
								? General.isKSAPhoneNumber(state["selectedValues"][props.component.companyGrid[i]["companyFieldPath"]])
								: true;
					}
				}
				if (!valid) {
					props.onChange("", null); // Call onChange with the new value
				}
			}
		}
	};
	//add page data in global form data
	useEffect(() => {
		if (props.data) {
			let keys = Object.keys(props.data);
			for (let i = 0; i < keys.length; i++) {
				props.form._data[keys[i]] = props.data[keys[i]];
				props.form._submission.data[keys[i]] = props.data[keys[i]];
			}

			if (props.insideGrid === true) {
				delete props.form._data[props.component.key];
				delete props.form._submission.data[props.component.key];
			}
		}
	}, [props.data]);
	return (
		<div className="getAllCustomComp">
			<div className="row">
				<div className="col-sm-6 mt-3">
					<label>
						{props.form._form.settings && props.form._form.settings.translatedData
							? props.form._form.settings.translatedData[props.form._form.settings.language]
								? props.form._form.settings.translatedData[props.form._form.settings.language][props.component.dropdownLabel] || props.component.dropdownLabel
								: props.component.dropdownLabel
							: props.component.dropdownLabel}
					</label>

					<div className="searchable-dropdown">
						<input
							autoFocus={props.oldState["lastFocusedId"] === props.component.id + "_main"}
							ref={inputRef}
							placeholder={t("Search") + "..."}
							list={props.component.key + "_datalist"}
							className="form-control"
							onChange={(e) => {
								if (e.target.value.trim() === "") {
									let state = props.oldState;
									state["selectedValues"] = {};
									props.setOldState(state);
									state["lastFocusedId"] = props.component.id + "_main";
									props.onChange("", null); // Call onChange with the new value
								} else {
									let updatedVal = e.target.value;
									let state = props.oldState;
									state["selectedValues"]["value"] = updatedVal;
									props.setOldState(state);
									state["lastFocusedId"] = props.component.id + "_main";
									loadData(updatedVal);
								}
							}}
							disabled={props.component.disabled && props.component.disabled === true}
							type="text"
							value={props.oldState["selectedValues"].value}
							onBlur={() => updateValue(props.oldState["selectedValues"].value)}
						/>
						<datalist className={""} id={props.component.key + "_datalist"}>
							{props.oldState.options?.map((option) => {
								return <option value={option[props.component.searchedFieldName]}>{option[props.component.searchedFieldName]}</option>;
							})}
						</datalist>
					</div>
				</div>

				{props.component.companyGrid?.map((grid, index) => {
					return (
						grid.companyFieldPath.replaceAll(" ", "") !== "" &&
						grid.companyFieldName.replaceAll(" ", "") !== "" && (
							<div key={props.component.key + "_" + index} className="mt-3 col-sm-6">
								<label>
									{props.form._form.settings && props.form._form.settings.translatedData
										? props.form._form.settings.translatedData[props.form._form.settings.language]
											? props.form._form.settings.translatedData[props.form._form.settings.language][grid.companyFieldName] || grid.companyFieldName
											: grid.companyFieldName
										: grid.companyFieldName}
								</label>
								<input
									type="text"
									className="form-control"
									autoFocus={props.oldState["lastFocusedId"] === props.component.id + "_" + grid.companyFieldPath}
									style={{
										border: props.oldState.errors[grid.companyFieldPath] ? "1px solid red" : "",
									}}
									onChange={(e) => {
										if (grid.companyFieldPath !== "") {
											let state = props.oldState;

											if (e.target.value.trim() === "") {
												state["selectedValues"][grid.companyFieldPath] = e.target.value;
												// state.mainError = t("All Fields are required")
												state["errors"][grid.companyFieldPath] = "";
												state["lastFocusedId"] = props.component.id + "_" + grid.companyFieldPath;
												props.setOldState(state);
												props.onChange("", null); // Call onChange with the new value
											} else {
												let value = e.target.value;
												let isValid =
													grid["companyFieldValidation"] === "email" ? General.isValidEmail(value) : grid["companyFieldValidation"] === "phoneNumber" ? General.isKSAPhoneNumber(value) : true;
												if (isValid) {
													state["selectedValues"][grid.companyFieldPath] = e.target.value;
													state["errors"][grid.companyFieldPath] = "";
													state["lastFocusedId"] = props.component.id + "_" + grid.companyFieldPath;
													props.setOldState(state);
													for (let i = 0; i < props.component.companyGrid?.length; i++) {
														if (isValid) {
															if (!state["selectedValues"][props.component.companyGrid[i].companyFieldPath]) {
																isValid = false;
															} else {
																isValid =
																	props.component.companyGrid[i]["companyFieldValidation"] === "email"
																		? General.isValidEmail(state["selectedValues"][props.component.companyGrid[i].companyFieldPath])
																		: props.component.companyGrid[i]["companyFieldValidation"] === "phoneNumber"
																		? General.isKSAPhoneNumber(state["selectedValues"][props.component.companyGrid[i].companyFieldPath])
																		: true;
															}
														}
													}
													if (isValid) {
														props.onChange(state["selectedValues"], null); // Call onChange with the new value
													} else {
														props.onChange("", null); // Call onChange with the new value
													}
												} else {
													state["selectedValues"][grid.companyFieldPath] = e.target.value;
													// state.mainError = t("All Fields are required")
													state["errors"][grid.companyFieldPath] = t("Validation Error");
													state["lastFocusedId"] = props.component.id + "_" + grid.companyFieldPath;
													props.setOldState(state);
													props.onChange("", null); // Call onChange with the new value
												}
											}
										}
									}}
									value={props.oldState["selectedValues"] ? props.oldState["selectedValues"][grid.companyFieldPath] || "" : ""}
									disabled={props.component.disabled && props.component.disabled === true}
								/>
								<div style={{ width: "100%", color: "red" }}>{props.oldState.errors && props.oldState.errors[grid.companyFieldPath]}</div>
							</div>
						)
					);
				})}
				{/* <div style={{ width: "100%", color: "red" }}>{props.oldState.errors && props.oldState.errors["mainError"]}</div> */}
			</div>
		</div>
	);
};

export default class GetAll extends ReactComponent {
	static shouldSetValue = false; // Define shouldSetValue as a static property

	/**
	 * This is the first phase of component building where the component is instantiated.
	 *
	 * @param component - The component definition created from the settings form.
	 * @param options - Any options passed into the renderer.
	 * @param data - The submission data where this component's data exists.
	 */

	constructor(component, options, data) {
		options["oldState"] = {
			options: [],
			value: null,
			requestUrl: "",
			searchedName: "",
			dropdownSelection: false,
			errors: {},
			valuesRendered: false,
			lastFocusedId: "",
			isFocused: false,
			selectedValues: {
				value: "",
			},
		};
		super(component, options, data);
		this.reactInstance = null;
		this.dirty = false;
		this.state = {
			updated: "",
		};
	}

	/**
	 * This function is the default settings for the component. At a minimum you want to set the type to the registered
	 * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
	 *
	 * @param sources
	 * @returns {*}
	 */
	static schema(...extend) {
		return ReactComponent.schema({
			type: "getAllCustomComp",
			label: "",
			errorLabel: "Fields Required",
		});
	}
	static get builderInfo() {
		return {
			title: "Get All",
			icon: "cubes",
			group: "Basic",
			documentation: "",
			weight: -10,
			schema: GetAll.schema(),
		};
	}
	static editForm = settingsForm;

	/**
	 * This method is called any time the component needs to be rebuilt. It is most frequently used to listen to other
	 * components using the this.on() function.
	 */
	init() {
		return super.init();
	}

	/**
	 * This method is called before the component is going to be destroyed, which is when the component instance is
	 * destroyed. This is different from detach which is when the component instance still exists but the dom instance is
	 * removed.
	 */
	destroy() {
		return super.destroy();
	}
	/**
	 * This method is called before a form is submitted.
	 * It is used to perform any necessary actions or checks before the form data is sent.
	 *
	 */

	beforeSubmit() {
		return super.beforeSubmit();
	}

	/**
	 * The second phase of component building where the component is rendered as an HTML string.
	 *
	 * @returns {string} - The return is the full string of the component
	 */
	render() {
		// For react components, we simply render as a div which will become the react instance.
		// By calling super.render(string) it will wrap the component with the needed wrappers to make it a full component.
		return super.render(`<div ref="react-${this.id}"></div>`);
	}

	/**
	 * The third phase of component building where the component has been attached to the DOM as 'element' and is ready
	 * to have its javascript events attached.
	 *
	 * @param element
	 * @returns {Promise<void>} - Return a promise that resolves when the attach is complete.
	 */
	attach(element) {
		super.attach(element);
		let randomId = getRandomId();
		// The loadRefs function will find all dom elements that have the "ref" setting that match the object property.
		// It can load a single element or multiple elements with the same ref.
		this.loadRefs(element, {
			[`react-${randomId}`]: "single",
		});

		if (this.refs[`react-${randomId}`]) {
			this.attachReact(this.refs[`react-${randomId}`], this.setReactInstance.bind(this));
			if (this.shouldSetValue) {
				this.setValue(this.dataForSetting);
				this.updateValue(this.dataForSetting);
			}
		}
		return Promise.resolve();
	}
	/**
	 * The fourth phase of component building where the component is being removed from the page. This could be a redraw
	 * or it is being removed from the form.
	 */
	detach() {
		return super.detach();
	}

	/**
	 * Override this function to insert your custom component.
	 *
	 * @param element
	 * @param ref - callback ref
	 */
	attachReact(element, ref) {
		const rootForm = this.getRoot(); // Get the root form object
		let insideGrid = false;
		let key = this.component.key;
		const root = createRoot(element);

		Utils.eachComponent(
			rootForm.components,
			function (component) {
				if (component.component.type === "editgrid") {
					Utils.eachComponent(
						component.component.components,
						function (component2) {
							if (!insideGrid) {
								insideGrid = component2.key === key;
							}
						},
						true
					);
				}
			},
			true
		);
		const setOldState = (value) => {
			this.options["oldState"] = value;
			this.updateOnChange({}, true);
			root.render(
				<GetAllCustomComp
					component={this.component} // These are the component settings if you want to use them to render the component.
					onChange={this.updateValue} // Pass the onChange event handler
					value={this.dataValue}
					data={this.data}
					dirty={this.dirty}
					updateOnChange={this.updateOnChange}
					element={this}
					form={rootForm}
					insideGrid={insideGrid}
					setOldState={setOldState}
					oldState={this.options["oldState"]}
				/>
			);
		};
		root.render(
			<GetAllCustomComp
				component={this.component} // These are the component settings if you want to use them to render the component.
				onChange={this.updateValue} // Pass the onChange event handler
				value={this.dataValue}
				data={this.data}
				dirty={this.dirty}
				updateOnChange={this.updateOnChange}
				element={this}
				form={rootForm}
				insideGrid={insideGrid}
				setOldState={setOldState}
				oldState={this.options["oldState"]}
			/>
		);
	}

	/**
	 * Something external has set a value and our component needs to be updated to reflect that. For example, loading a submission.
	 *
	 * @param value
	 */
	setValue(value) {
		if (this.reactInstance) {
			this.reactInstance.setState({
				value: value,
			});
			this.shouldSetValue = false;
		} else {
			this.shouldSetValue = true;
			this.dataForSetting = value;
		}
	}

	/**
	 * The user has changed the value in the component and the value needs to be updated on the main submission object and other components notified of a change event.
	 *
	 * @param value
	 */
	updateValue = (value, flags) => {
		flags = flags || {};
		if (value !== null) {
			const newValue = value === undefined || value === null ? this.getValue() : value;
			const changed = newValue !== undefined ? this.hasChanged(newValue, this.dataValue) : false;
			this.dataValue = Array.isArray(newValue) ? [...newValue] : newValue;

			this.updateOnChange(flags, changed);
			return changed;
		}
	};

	/**
	 * Get the current value of the component. Should return the value set in the react component.
	 *
	 * @returns {*}
	 */
	getValue() {
		if (this.reactInstance) {
			return this.reactInstance.state.value;
		}
		return this.defaultValue;
	}

	/**
	 * Override normal validation check to insert custom validation in react component.
	 *
	 * @param data
	 * @param dirty
	 * @param rowData
	 * @returns {boolean}
	 */
	checkValidity(data, dirty, rowData) {
		const valid = super.checkValidity(data, dirty, rowData);
		if (!valid) {
			return false;
		}
		return this.validate(data, dirty, rowData);
	}

	/**
	 * Do custom validation.
	 *
	 * @param data
	 * @param dirty
	 * @param rowData
	 * @returns {boolean}
	 */
	// validate(data, dirty, rowData) {

	//     const getValidation = (component, tempData, dataValue) => {
	//         let errorFound = false;
	//         let grid = component.companyGrid;

	//         if ((tempData === null || (tempData.value === null || tempData.value === "")) && component.validate.required) {
	//             let json = this.options["oldState"]["errors"];
	//             json["mainError"] = "All fields are required";
	//             this.options["oldState"]["errors"] = json

	//             return false;
	//         }
	//         if (grid && tempData) {
	//             for (let i = 0; i < grid.length; i++) {
	//                 if (grid[i]["companyFieldValidation"]) {

	//                     if (component.validate.required || tempData[grid[i]["companyFieldPath"]]) {
	//                         let isValid = grid[i]["companyFieldValidation"] === "email" ? General.isValidEmail(tempData[grid[i]["companyFieldPath"]]) :
	//                             grid[i]["companyFieldValidation"] === "phoneNumber" ? General.isKSAPhoneNumber(tempData[grid[i]["companyFieldPath"]]) : false;
	//                         if (!isValid) {
	//                             errorFound = true;
	//                             let json = this.options["oldState"]["errors"];
	//                             json[grid[i]["companyFieldPath"]] = "Validation Error";
	//                             this.options["oldState"]["errors"] = json
	//                         }
	//                         else {
	//                             let json = this.options["oldState"]["errors"];
	//                             json[grid[i]["companyFieldPath"]] = "";
	//                             this.options["oldState"]["errors"] = json
	//                         }
	//                     }

	//                 }
	//             }
	//         }
	//         if (dataValue !== null && dataValue !== "") {
	//             for (let i = 0; i < grid.length; i++) {
	//                 if (!tempData[grid[i]["companyFieldPath"]] || !tempData[grid[i]["companyFieldPath"]]?.toString().replaceAll(" ", "") === "" && component.validate.required) {
	//                     errorFound = true;
	//                     let json = this.options["oldState"]["errors"];
	//                     json["mainError"] = "All fields are required";
	//                     this.options["oldState"]["errors"] = json
	//                     return false;
	//                 }

	//             }
	//             if (!errorFound) {
	//                 let json = this.options["oldState"]["errors"];
	//                 json["mainError"] = "";
	//                 this.options["oldState"]["errors"] = json
	//             }
	//         }

	//         return true;
	//     }

	//     let tempData = null;
	//     try {
	//         tempData = JSON.parse(this.dataValue);
	//     }
	//     catch (e) {
	//         tempData = this.dataValue;
	//         return getValidation(this.component, tempData, this.dataValue);

	//     }
	//     return getValidation(this.component, tempData, this.dataValue);

	// }
}
