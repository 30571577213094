import React, { useState, useContext } from "react";

import PropTypes from "prop-types";
import AppContext from "context/Context";
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Col, Modal, Row, Button, Image } from "react-bootstrap";
import IconButton from "components/common/IconButton";
import * as Constants from "common/constants";
import * as General from "common/general";
import Loader from "components/custom/Loader";
import { useTranslation } from "react-i18next";

const ForgotPasswordRequest = ({ showComponent, hideForgotPasswordRequest }) => {
	const [errorContainerVisiblity, showErrorContainer] = useState(false);
	const [loaderContainerVisibility, showLoaderContainer] = useState(false);
	const [buttonsVisibility, showHideButtons] = useState(true);

	const [email, updateEmail] = useState("");

	const [t, i18n] = useTranslation();

	const handleCancelForgotPasswordRequest = () => {
		hideForgotPasswordRequest();
	};

	const handleForgotPasswordRequest = async () => {
		var successContainer = document.getElementById("successContainer");
		successContainer.innerHTML = "";

		var emailInput = document.getElementById("txtEmailForgotPasswordRequest");

		showLoaderContainer(true);
		showHideButtons(false);
		showErrorContainer(false);

		var isValidForm = true;
		emailInput.classList.remove("is-invalid");

		if (email === "") {
			emailInput.classList.add("is-invalid");
			isValidForm = false;
		}

		if (!General.isValidEmail(email)) {
			emailInput.classList.add("is-invalid");
			isValidForm = false;
		}

		if (isValidForm == false) {
			showLoaderContainer(false);
			showHideButtons(true);
			showErrorContainer(false);
		} else {
			const ForgotPasswordRequestSuccess = (responseObject) => {
				var isSuccess = false;
				if (responseObject.status == Constants.SuccessStatus) {
					isSuccess = true;

					successContainer.innerHTML = responseObject.message;
					showErrorContainer(false);
					showLoaderContainer(false);
					showHideButtons(false);
				}

				if (isSuccess == false) {
					showErrorContainer(true);
					setTimeout(function () {
						var errorContainer = document.getElementById("errorContainer");
						if (responseObject.message != null) {
							if (responseObject.message != "") {
								errorContainer.innerHTML = responseObject.message;
							}
						}
						if (errorContainer.innerHTML == "") {
							errorContainer.innerHTML = "An error occured, please try again later.";
						}
						showLoaderContainer(false);
						showHideButtons(true);
					}, 1000);
				}
			};

			const ForgotPasswordRequestFailure = (error) => {
				showErrorContainer(true);
				setTimeout(function () {
					var errorContainer = document.getElementById("errorContainer");
					if (errorContainer.innerHTML == "") {
						errorContainer.innerHTML = "An error occured, please try again later." + error;
					}
					showLoaderContainer(false);
					showHideButtons(true);
				}, 1000);
			};

			var ForgotPasswordRequestEndPoint = Constants.ForgotPasswordRequestEndPoint;
			var ForgotPasswordRequestJSON = '{"language":"' + Constants.Language + '", "forgotPasswordRequestData":{"userNameOrEmail": "' + email + '" } } ';
			General.PostToEndPoint(ForgotPasswordRequestEndPoint, null, ForgotPasswordRequestJSON, ForgotPasswordRequestSuccess, ForgotPasswordRequestFailure);
		}
	};

	const {
		config: { isRTL },
	} = useContext(AppContext);

	return (
		<Modal show={showComponent} onHide={handleCancelForgotPasswordRequest} size="lg">
			<Modal.Header closeButton className="border-200">
				<Modal.Title as="h5">
					<Flex alignItems="center">
						<div className="icon-item bg-soft-success shadow-none">
							<FontAwesomeIcon icon="user" className="text-success" />
						</div>
						<div className="ms-2">{t("Forgot Password?")}</div>
					</Flex>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row className="g-0 align-items-center">
					<Col>
						<div className="mb-3">
							<label className="form-label">Insert your email address</label>
							<input
								type="text"
								className="form-control"
								id="txtEmailForgotPasswordRequest"
								value={email}
								placeholder="Email Address"
								onChange={(event) => updateEmail(event.target.value)}
							/>
						</div>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer className="border-200">
				{buttonsVisibility && (
					<div>
						<Button size="sm" onClick={handleCancelForgotPasswordRequest} variant="secondary">
							Cancel
						</Button>
						<IconButton size="sm" className="ms-2" variant="primary" onClick={handleForgotPasswordRequest}>
							Submit
						</IconButton>
					</div>
				)}

				{loaderContainerVisibility && <Loader></Loader>}

				{errorContainerVisiblity && <div id="errorContainer" className="loginAlert popupAlert"></div>}

				<div id="successContainer" className="successMessage"></div>
			</Modal.Footer>
		</Modal>
	);
};

export default ForgotPasswordRequest;
