import React, { Component, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { ReactComponent, Utils } from "@formio/react";
import settingsForm from "./Company.settingsForm";
import _ from "lodash";
import { createRoot } from "react-dom/client";
import { getRandomId } from "helpers/utils";
import * as Constants from "common/constants";
import CustomSelect from "../customSelect";
import { EncryptStorage } from "encrypt-storage";

/*
This component is responsible to get company details of loggedin user using email and fill them in text fields according to the 
fields requested by the user
*/

const CompanyCustomComp = ({ ...props }) => {
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});
	const [coordinates, setCoordinates] = useState({ long: "", lat: "" });

	//get user location coordinates on load by calling getPosition
	useEffect(() => {
		const fetchCoordinates = async () => {
			if (navigator.geolocation) {
				const coords = await getPosition();
				if (coords.long !== "") {
					setCoordinates(coords);
				}
			}
		};
		if (coordinates.long === "" && props.oldState.loadingCoordinates === false) {
			let state = props.oldState;
			state.loadingCoordinates = true;
			props.setOldState(state);
			fetchCoordinates();
		}
	}, []); // Empty dependency array to run the effect only once
	//get user position then start rendering the component in processComponent()
	const getPosition = () => {
		return new Promise((resolve, reject) => {
			const watchId = navigator.geolocation.watchPosition(
				(position) => {
					processComponent({
						long: position.coords.longitude,
						lat: position.coords.latitude,
					});
					resolve({
						long: position.coords.longitude,
						lat: position.coords.latitude,
					});

					navigator.geolocation.clearWatch(watchId); // Stop watching after getting the position
				},
				(error) => {
					processComponent({
						long: -1,
						lat: -1,
					});
				}
			);
		});
	};
	// Update the setValue method to handle onChange event
	const updateValue = (e, refreshForm) => {
		if (props.form._form.settings && props.form._form.settings.toEdit !== true) {
			if (props.component.validate.required) {
				if (Object.keys(e).filter((key) => e[key] !== null && e[key].toString().trim() === "").length > 0) {
					props.onChange("", null);
				} else {
					props.onChange(e, null);
				}
			} else {
				props.onChange(e, null);
			}
			if (refreshForm) {
				props.form.triggerRedraw();
			}
		}
	};
	//fetch data for auto complete dropdowns
	const fetchData = async (requestedTable) => {
		try {
			var accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);
			var headers = { "Content-Type": "application/json" };

			if (accessToken) {
				var headerToken = { Authorization: "Bearer " + accessToken };
				headers = { ...headers, ...headerToken };
			}
			let url = Constants.GetAllEndPoint + "/" + requestedTable;

			const response = await fetch(url, {
				method: "GET",
				headers: headers,
			});

			const res = await response.json();

			if (res) {
				return res.data.dataBaseContent;
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			throw error; // Propagate the error to the calling function
		}
	};

	const processComponent = (coordinates) => {
		let state = props.oldState;
		state.loadingCoordinates = false;
		//check the data that should be rendered and fill them in state with their options if the field is dropdown
		const renderCompanyData = async () => {
			let tempCompanyGridFields = [];
			if (props.component.companyGrid) {
				if (props.component.companyGrid.length !== 0) {
					let grid = props.component.companyGrid;
					for (let i = 0; i < grid.length; i++) {
						let companyFieldPath = grid[i].companyFieldPath;
						let companyFieldName = grid[i].companyFieldName;
						let companyFieldType = grid[i].companyFieldType || "textfield";
						let companyFieldRequestedTable = grid[i].companyFieldRequestedTable;
						let responseValuePath = grid[i].responseValuePath;
						let responseLabelPath = grid[i].responseLabelPath;

						if (companyFieldType === "dropdown") {
							let data = [];
							if (companyFieldRequestedTable) {
								if (props.form._form.settings && props.form._form.settings.toEdit !== true) {
									try {
										if (props.component.disabled === true) {
											let formData = props.form._data;

											let value = formData[props.component.key][companyFieldPath];
											data.push(value);
										} else {
											data = await fetchData(companyFieldRequestedTable);
											for (let i = 0; i < data.length; i++) {
												data[i]["value"] = _.get(data[i], responseValuePath, "");
												if (props.form._form.settings) {
													if (props.form._form.settings.translatedData) {
														if (props.form._form.settings.translatedData[props.form._form.settings.language]) {
															data[i]["label"] = props.form._form.settings.translatedData[props.form._form.settings.language][data[i][responseLabelPath]] || data[i][responseLabelPath];
														}
													}
												}
											}
										}
									} catch (error) {
										console.error("Error fetching dropdown data:", error);
									}
								}
							}

							tempCompanyGridFields.push({
								companyFieldPath,
								companyFieldName,
								companyFieldType,
								data,
							});
						} else {
							tempCompanyGridFields.push({
								companyFieldPath,
								companyFieldName,
								companyFieldType,
								data: [],
							});
						}
						state["values"][companyFieldPath] = "";
						state["tempValues"][companyFieldPath] = "";
					}
					state["companyGrid"] = tempCompanyGridFields;
					//check if the component in initially enabled and thus user can edit its fields
					checkIfInitiallyEnabled(state);
				}
			}
		};

		const checkIfInitiallyEnabled = (myState) => {
			let initiallyEnabled = true;
			if (!props.component.companyRequestUrl) {
				myState["initiallyEnabled"] = true;
				for (let i = 0; i < myState["companyGrid"].length; i++) {
					myState["companyGrid"][i].enabled = true;
				}
				manipulateComponentValue(myState, coordinates);
			} else if (props.form._form.settings && props.component.initiallyEnabled) {
				if (props.component.initiallyEnabled.length !== 0) {
					let grid = props.component.initiallyEnabled;
					let formData = props.form._form.settings.data;
					for (let i = 0; i < grid.length; i++) {
						let formDataFieldPath = grid[i].formDataFieldPath;
						let formDataFieldValue = grid[i].formDataFieldValue;
						let actualFormDataFieldValue = _.get(formData, formDataFieldPath, "");
						if (formDataFieldValue.toString() !== actualFormDataFieldValue.toString()) {
							initiallyEnabled = false;
							break;
						}
					}
					myState["initiallyEnabled"] = initiallyEnabled;
					for (let i = 0; i < myState["companyGrid"].length; i++) {
						myState["companyGrid"][i].enabled = initiallyEnabled;
					}
				} else {
					initiallyEnabled = false;
					myState["initiallyEnabled"] = false;
					for (let i = 0; i < myState["companyGrid"].length; i++) {
						myState["companyGrid"][i].enabled = false;
					}
				}
			} else {
				initiallyEnabled = false;
				myState["initiallyEnabled"] = false;
				for (let i = 0; i < myState["companyGrid"].length; i++) {
					myState["companyGrid"][i].enabled = false;
				}
			}
			//if initially enabled get company data only and then load the component else check if initially disbaled and user can edit it
			if (!initiallyEnabled) {
				checkIfIsEditable(myState, coordinates);
			} else {
				manipulateComponentValue(myState, coordinates);
			}
		};
		//check if component is initially disabled
		const checkIfIsEditable = (myState, coordinates) => {
			let isEditable = true;
			if (props.form._form.settings && props.component.editableConditions) {
				if (props.component.editableConditions.length !== 0) {
					let grid = props.component.editableConditions;
					let formData = props.form._form.settings.data;
					for (let i = 0; i < grid.length; i++) {
						let formDataFieldPath = grid[i].formDataFieldPath;
						let formDataFieldValue = grid[i].formDataFieldValue;
						let actualFormDataFieldValue = _.get(formData, formDataFieldPath, "");
						if (formDataFieldValue.toString() !== actualFormDataFieldValue.toString()) {
							isEditable = false;
							break;
						}
					}
					myState["editable"] = isEditable;
					manipulateComponentValue(state, coordinates);
				} else {
					manipulateComponentValue(state, coordinates);
				}
			} else {
				manipulateComponentValue(state, coordinates);
			}
		};
		//manipulate component by getting its data and fill them in state
		const manipulateComponentValue = (state, coordinates) => {
			//if component has default value set its field's values in state
			if (props.component.defaultValue !== "" && props.component.defaultValue && JSON.stringify(props.component.defaultValue) !== "{}" && props.oldState["loaded"] === false) {
				state["values"] = props.component.defaultValue;
				state["tempValues"] = props.component.defaultValue;

				state["loaded"] = true;
				if (state.editable === true || state.initiallyEnabled === true) {
					getData(state, coordinates, true);
				} else {
					setCoordinatesInState(JSON.parse(JSON.stringify(state)), coordinates);
				}
			} else {
				if (!props.component.disabled) {
					//if no default value and company information not loaded
					if (state["loaded"] === false) {
						getData(state, coordinates);
					} else {
						props.setOldState(state);
						//set component's fields values
						updateValue(state["values"]);
					}
				}
			}
		};
		if (state["loaded"] === false && !props.oldState.loading) {
			state["loading"] = true;
			props.setOldState(state);
			renderCompanyData();
		}
	};
	const getFieldOptions = (companyFieldPath) => {
		if (props.oldState.companyGrid) {
			return props.oldState.companyGrid.filter((c) => c.companyFieldPath === companyFieldPath)[0]?.data || [];
		}
	};
	//on field change set its value in state (i.e: if dropdown get the associated record from value passed else set the value as it is)
	const modifyFieldValue = (companyFieldPath, e, isDropdown = false) => {
		if (!checkIfDisbaled()) {
			let state = props.oldState;
			let value = e.target.value;
			if (isDropdown) {
				if (value !== "") {
					let selectedValue = state.companyGrid.filter((c) => c.companyFieldPath === companyFieldPath)[0].data.filter((d) => d.value === value)[0];
					value = { label: selectedValue.label, value: selectedValue.value };
				}
			}
			if (state.initiallyEnabled) {
				state["values"][companyFieldPath] = value;
			} else {
				state["tempValues"][companyFieldPath] = value;
			}

			if (state.initiallyEnabled) {
				state["tempValues"] = state["values"];
			}
			if (state.initiallyEnabled) {
				updateComponentValue(state);
			} else {
				props.setOldState(state);
			}
		}
	};

	//add page data in global form data
	useEffect(() => {
		if (props.data) {
			let keys = Object.keys(props.data);
			for (let i = 0; i < keys.length; i++) {
				props.form._data[keys[i]] = props.data[keys[i]];
				props.form._submission.data[keys[i]] = props.data[keys[i]];
			}

			if (props.insideGrid === true) {
				delete props.form._data[props.component.key];
				delete props.form._submission.data[props.component.key];
			}
		}
	}, [props.data]);
	const fetchCompanyData = async (requestedUrl) => {
		try {
			var accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);
			var headers = { "Content-Type": "application/json" };

			if (accessToken) {
				var headerToken = { Authorization: "Bearer " + accessToken };
				headers = { ...headers, ...headerToken };
			}
			const response = await fetch(requestedUrl, {
				method: "GET",
				headers: headers,
			});

			if (!response) {
				console.log("error");
			}

			const data = await response.json();
			return data;
		} catch (error) {
			console.log(error);
			return null; // You may want to handle errors appropriately
		}
	};
	const renderState = (myState, res, initialValue, coordinates) => {
		let path = props.component.dataPath;
		let result = path ? _.get(res, path, "") : res;

		let json = {};
		if (myState.companyGrid && result && JSON.stringify(result) !== "{}") {
			for (let i = 0; i < myState.companyGrid.length; i++) {
				let value = result.length > 0 ? result[0][myState.companyGrid[i].companyFieldPath] : "";
				if (myState.companyGrid[i].data.length > 0) {
					let record = myState.companyGrid[i].data.filter((d) => d.value === value)[0];
					value = { label: record.label, value: record.value };
				}

				json[myState.companyGrid[i].companyFieldPath] = value;
			}
			myState["loaded"] = true;
			myState["api"] = props.component.key;
			//set initial value of the component
			if (myState.initialValue === null) {
				myState.initialValue = JSON.parse(JSON.stringify(json));
			}
			if (initialValue === false) {
				myState["values"] = JSON.parse(JSON.stringify(json));
				//flagsNotifications is a grid used to add its fields to component value if user edits its information
				let grid = props.component.flagsNotifications;
				if (grid) {
					for (let i = 0; i < grid.length; i++) {
						let formDataFieldName = grid[i].formDataFieldName;
						let fieldInitialValue = grid[i].fieldInitialValue;
						myState["values"][formDataFieldName] = fieldInitialValue;
					}
				}
				myState["tempValues"] = JSON.parse(JSON.stringify(json));
				setCoordinatesInState(JSON.parse(JSON.stringify(myState)), coordinates);
			} else {
				props.setOldState(myState);
				props.form.triggerRedraw();
			}
		} else {
			if (initialValue === false) {
				myState["loaded"] = true;
				setCoordinatesInState(JSON.parse(JSON.stringify(myState)), coordinates);
			} else {
				props.setOldState(myState);
				props.form.triggerRedraw();
			}
		}
	};
	//get company data from api
	//initialValue is responsible to decide if we should update component value in state or not
	//if component default value is set no need to set its value from api
	const getData = async (state, coordinates, initialValue = false) => {
		let myState = JSON.parse(JSON.stringify(state));
		if (props.component.companyRequestUrl) {
			let companyID = encryptStorage1.getItem("selectedCompany") || "GeneratorCompany";
			let requestedUrl = props.component.companyRequestUrl + "/";
			let licenseCompanyRequestUrl = props.component.licenseCompanyRequestUrl;

			if (!props.component.queryParamsFromForm || props.component.queryParamsFromForm.length === 0) {
				requestedUrl += "tbl_company/companyId=eq." + companyID;
			} else {
				if (props.form._form.settings && props.component.queryParamsFromForm) {
					for (let i = 0; i < props.component.queryParamsFromForm.length; i++) {
						let queryParamName = props.component.queryParamsFromForm[i].queryParamsFieldName;
						let queryParamValue = _.get(props.form._form.settings.data, props.component.queryParamsFromForm[i].queryParamsFieldPath, "");
						requestedUrl += (i !== 0 ? "&&" : "") + queryParamName + "=eq." + queryParamValue;
					}
				}
			}
			let res = await fetchCompanyData(licenseCompanyRequestUrl || requestedUrl);
			if (res && JSON.stringify(res) !== "{}") {
				renderState(myState, res, initialValue, coordinates);
			} else {
				let res2 = await fetchCompanyData(requestedUrl);
				renderState(myState, res2, initialValue, coordinates);
			}
		} else {
			let json = {};
			if (myState.companyGrid) {
				for (let i = 0; i < myState.companyGrid.length; i++) {
					json[myState.companyGrid[i].companyFieldPath] = "";
				}
				myState["loaded"] = true;
				myState["api"] = props.component.key;
				//set initial value of the component
				if (myState.initialValue === null) {
					myState.initialValue = JSON.parse(JSON.stringify(json));
				}
				if (initialValue === false) {
					myState["values"] = JSON.parse(JSON.stringify(json));

					myState["tempValues"] = JSON.parse(JSON.stringify(json));
					setCoordinatesInState(JSON.parse(JSON.stringify(myState)), coordinates);
				} else {
					props.setOldState(myState);
				}
			}
		}
	};
	//if component is already disbaled and editable user can enable it
	const setFormEditable = () => {
		let state = props.oldState;
		state.inEditMode = true;
		if (state.companyGrid) {
			if (state.companyGrid.length !== 0) {
				for (let i = 0; i < state.companyGrid.length; i++) {
					state.companyGrid[i].enabled = true;
				}
			}
		}
		props.setOldState(state);
	};
	//responsible to reset component value if user changes any field and want to return to the original one
	const cancelForm = () => {
		let state = props.oldState;
		state.inEditMode = false;
		let keys = Object.keys(state.values);
		for (let i = 0; i < keys.length; i++) {
			state.tempValues[keys[i]] = state.values[keys[i]];
		}
		if (state.companyGrid) {
			if (state.companyGrid.length !== 0) {
				for (let i = 0; i < state.companyGrid.length; i++) {
					state.companyGrid[i].enabled = false;
				}
			}
		}
		props.setOldState(state);
	};
	//after editing set values in state from tempValues that is a temporary field in state and used when user saves the values
	const saveForm = () => {
		let state = props.oldState;
		state.inEditMode = false;
		if (state.companyGrid) {
			if (state.companyGrid.length !== 0) {
				for (let i = 0; i < state.companyGrid.length; i++) {
					state.companyGrid[i].enabled = false;
				}
			}
		}
		let keys = Object.keys(state.tempValues);
		for (let i = 0; i < keys.length; i++) {
			state.values[keys[i]] = state.tempValues[keys[i]];
		}

		updateComponentValue(state);
	};
	//check if the fields inside the component are disbaled or not
	const checkIfDisbaled = () => {
		let state = props.oldState;

		if (state.editable === true) {
			if (state.inEditMode === true) {
				return false;
			}
		} else if (state.initiallyEnabled === true) {
			return false;
		}
		return true;
	};
	//this function is responsible to compare the current value with the initial one if so fields in flagsNotifications grid are set
	const updateComponentValue = (state) => {
		let keys = Object.keys(state.values);
		let isValid = true;
		for (let i = 0; i < keys.length; i++) {
			if (state.values[keys[i]] === "" && props.component.validate.required === true) {
				isValid = false;
			}
		}
		let updatedValue = state.values;
		if (props.component.flagsNotifications && props.component.companyRequestUrl && props.component.companyRequestUrl !== "") {
			if (props.component.flagsNotifications.length !== 0 && state.initialValue !== null) {
				let isModified = false;
				if (isValid) {
					let initialValueKeys = Object.keys(state.initialValue);
					for (let i = 0; i < initialValueKeys.length; i++) {
						let key = initialValueKeys[i];
						if (state.initialValue[key]) {
							if (state.initialValue[key]?.label) {
								if (state.initialValue[key].label !== updatedValue[key].label) {
									isModified = true;
								}
							} else {
								if (state.initialValue[key].toString().trim() !== updatedValue[key].toString().trim()) {
									isModified = true;
								}
							}
						}
					}
				} else {
					isModified = true;
				}
				let grid = props.component.flagsNotifications;
				for (let i = 0; i < grid.length; i++) {
					let formDataFieldName = grid[i].formDataFieldName;
					let formDataFieldValue = grid[i].formDataFieldValue;
					let fieldInitialValue = grid[i].fieldInitialValue;
					if (isModified) {
						updatedValue[formDataFieldName] = formDataFieldValue;
					} else {
						updatedValue[formDataFieldName] = fieldInitialValue;
					}
				}
			}
		}
		state["loading"] = false;
		let refreshForm = !state.componentRendered;
		if (!state.componentRendered) {
			state.componentRendered = true;
		}
		props.setOldState(state);
		updateValue(updatedValue, refreshForm);
	};
	//update company data value if it contains coordinates
	const setCoordinatesInState = (state, coordinates) => {
		if (state.tempValues && state.companyGrid.length > 0 && state.loaded === true && props.component.disabled !== true) {
			let keys = Object.keys(state.tempValues);

			for (let i = 0; i < keys.length; i++) {
				let key = keys[i];
				let keyFieldType = state.companyGrid.filter((c) => c.companyFieldPath === key)[0]?.companyFieldType;
				if (keyFieldType === "x-coordinate") {
					if (!state.tempValues[key] && coordinates.long !== "") {
						state.tempValues[key] = coordinates.long;
						state.values[key] = coordinates.long;
					}
				}
				if (keyFieldType === "y-coordinate") {
					if (!state.tempValues[key] && coordinates.lat !== "") {
						state.tempValues[key] = coordinates.lat;
						state.values[key] = coordinates.lat;
					}
				}
			}

			updateComponentValue(state);
		}
	};
	return (
		<div className="row companyCustomComp">
			{props.component.companyGrid?.map((grid, index) => {
				return (
					!grid.isHidden && <div key={props.component.id + "_" + index} className="mt-3 col-sm-6">
						<label>
							{props.form._form.settings && props.form._form.settings.translatedData
								? props.form._form.settings.translatedData[props.form._form.settings.language]
									? props.form._form.settings.translatedData[props.form._form.settings.language][grid.companyFieldName] || grid.companyFieldName
									: grid.companyFieldName
								: grid.companyFieldName}
						</label>
						{
							(grid.companyFieldType === "dropdown" ? (
								<CustomSelect
									component={props.component}
									disabled={checkIfDisbaled()}
									value={props.oldState.tempValues[grid.companyFieldPath]?.value || ""}
									options={getFieldOptions(grid.companyFieldPath)}
									updateValue={(e) => modifyFieldValue(grid.companyFieldPath, e, true)}
								/>
							) : (
								<input
									type="text"
									className="form-control"
									value={props.oldState.tempValues[grid.companyFieldPath] || ""}
									disabled={checkIfDisbaled()}
									onChange={(e) => modifyFieldValue(grid.companyFieldPath, e)}
								/>
							))
						}
					</div>
				);
			})}
			{props.oldState.editable && props.component.disabled !== true && (
				<div className="d-flex mt-3">
					{!props.oldState.inEditMode && (
						<input
							className="btn btn-primary btn-wizard-nav-submit"
							type="button"
							value={
								props.form._form.settings && props.form._form.settings.translatedData
									? props.form._form.settings.translatedData[props.form._form.settings.language]
										? props.form._form.settings.translatedData[props.form._form.settings.language]["Edit"] || "Edit"
										: "Edit"
									: "Edit"
							}
							onClick={() => setFormEditable()}
						/>
					)}
					{props.oldState.inEditMode && (
						<div className="d-flex">
							<input
								className="btn btn-primary btn-wizard-nav-submit"
								type="button"
								value={
									props.form._form.settings && props.form._form.settings.translatedData
										? props.form._form.settings.translatedData[props.form._form.settings.language]
											? props.form._form.settings.translatedData[props.form._form.settings.language]["Save"] || "Save"
											: "Save"
										: "Save"
								}
								onClick={() => saveForm()}
							/>
							<input
								className="btn btn-danger btn-wizard-nav-submit mx-3"
								type="button"
								value={
									props.form._form.settings && props.form._form.settings.translatedData
										? props.form._form.settings.translatedData[props.form._form.settings.language]
											? props.form._form.settings.translatedData[props.form._form.settings.language]["Cancel"] || "Cancel"
											: "Cancel"
										: "Cancel"
								}
								onClick={() => cancelForm()}
							/>
						</div>
					)}
				</div>
			)}
			<div id={props.component.key + "_error"} style={{ width: "100%", color: "red" }}></div>
		</div>
	);
};

export default class Company extends ReactComponent {
	/**
	 * This is the first phase of component building where the component is instantiated.
	 *
	 * @param component - The component definition created from the settings form.
	 * @param options - Any options passed into the renderer.
	 * @param data - The submission data where this component's data exists.
	 */

	constructor(component, options, data) {
		options["oldState"] = {
			values: {},
			value: null,
			requestUrl: "",
			loading: false,
			companyGrid: [],
			loaded: false,
			initiallyEnabled: false,
			editable: false,
			initialValue: null,
			tempValues: {},
			inEditMode: false,
			error: "",
			loadingCoordinates: false,
			componentRendered: false,
		};
		super(component, options, data);
		this.reactInstance = null;
	}

	/**
	 * This function is the default settings for the component. At a minimum you want to set the type to the registered
	 * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
	 *
	 * @param sources
	 * @returns {*}
	 */
	static schema(...extend) {
		return ReactComponent.schema({
			type: "companyCustomComp",
			label: "",
		});
	}
	static get builderInfo() {
		return {
			title: "Company",
			icon: "cubes",
			group: "Basic",
			documentation: "",
			weight: -10,
			schema: Company.schema(),
		};
	}
	static editForm = settingsForm;

	/**
	 * The second phase of component building where the component is rendered as an HTML string.
	 *
	 * @returns {string} - The return is the full string of the component
	 */
	render() {
		// For react components, we simply render as a div which will become the react instance.
		// By calling super.render(string) it will wrap the component with the needed wrappers to make it a full component.
		return super.render(`<div ref="react-${this.id}"></div>`);
	}
	/**
	 * The third phase of component building where the component has been attached to the DOM as 'element' and is ready
	 * to have its javascript events attached.
	 *
	 * @param element
	 * @returns {Promise<void>} - Return a promise that resolves when the attach is complete.
	 */
	attach(element) {
		super.attach(element);
		let randomId = getRandomId();
		// The loadRefs function will find all dom elements that have the "ref" setting that match the object property.
		// It can load a single element or multiple elements with the same ref.
		this.loadRefs(element, {
			[`react-${randomId}`]: "single",
		});

		if (this.refs[`react-${randomId}`]) {
			this.attachReact(this.refs[`react-${randomId}`], this.setReactInstance.bind(this));
			if (this.shouldSetValue) {
				this.setValue(this.dataForSetting);
				this.updateValue(this.dataForSetting);
			}
		}
		return Promise.resolve();
	}

	/**
	 * Override this function to insert your custom component.
	 *
	 * @param element
	 * @param ref - callback ref
	 */
	attachReact(element, ref) {
		const rootForm = this.getRoot(); // Get the root form object
		let insideGrid = false;
		let key = this.component.key;
		const root = createRoot(element);

		Utils.eachComponent(
			rootForm.components,
			function (component) {
				if (component.component.type === "editgrid") {
					Utils.eachComponent(
						component.component.components,
						function (component2) {
							if (!insideGrid) {
								insideGrid = component2.key === key;
							}
						},
						true
					);
				}
			},
			true
		);
		const setOldState = (value) => {
			this.options["oldState"] = value;
			this.updateOnChange({}, true);
			root.render(
				<CompanyCustomComp
					component={this.component} // These are the component settings if you want to use them to render the component.
					onChange={this.updateValue} // Pass the onChange event handler
					value={this.dataValue}
					data={this.data}
					form={rootForm}
					insideGrid={insideGrid}
					setOldState={setOldState}
					oldState={this.options["oldState"]}
				/>
			);
		};
		root.render(
			<CompanyCustomComp
				component={this.component} // These are the component settings if you want to use them to render the component.
				onChange={this.updateValue} // Pass the onChange event handler
				value={this.dataValue}
				data={this.data}
				form={rootForm}
				insideGrid={insideGrid}
				setOldState={setOldState}
				oldState={this.options["oldState"]}
			/>
		);
	}
}
