import React, { useState, useContext, useEffect } from "react";

import PropTypes from "prop-types";
import AppContext from "context/Context";
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Col, Modal, Row, Button, Image } from "react-bootstrap";
import IconButton from "components/common/IconButton";
import * as Constants from "common/constants";
import * as General from "common/general";
import { GetAllWorflowsEndpoint } from "common/constants";
import Loader from "components/custom/Loader";
import { useTranslation } from "react-i18next";

const CreateRole = ({ showComponent, hideCreateRole, successCallBackFunction }) => {
	const [errorContainerVisiblity, showErrorContainer] = useState(false);
	const [loaderContainerVisibility, showLoaderContainer] = useState(false);
	const [buttonsVisibility, showHideButtons] = useState(true);

	const [roleName, updateRoleName] = useState(roleName);
	const roleNameInput = document.getElementById("txtRoleName");

	const [t, i18n] = useTranslation();

	//var attributes = [
	//    { id: "Inspector", name: "Inspector", checked: false },
	//    { id: "Supervisor", name: "Supervisor", checked: false }
	//];

	//var attributes = [
	//    { id: "Inspector", name: "Inspector", checked: false },
	//    { id: "Supervisor", name: "Supervisor", checked: false },
	//    { id: "Dashboards", name: "Dashboards", checked: false },
	//    { id: "Users", name: "Users", checked: false },
	//    { id: "Roles", name: "Roles", checked: false },
	//    { id: "Forms", name: "Forms", checked: false },
	//    { id: "Create-Workflow", name: "Create-Workflow", checked: false },
	//    { id: "Workflow-List", name: "Workflow-List", checked: false },
	//    { id: "User-Tasks", name: "User-Tasks", checked: false },
	//    { id: "Workflow-History", name: "Workflow-History", checked: false },
	//    { id: "Queries", name: "Queries", checked: false },
	//    { id: "Settings", name: "Settings", checked: false },
	//    { id: "Manage-Transporters", name: "Manage-Transporters", checked: false },
	//    { id: "Manage-Facilities", name: "Manage-Facilities", checked: false }
	//];

	//const [attributes, setAttributes] = useState([]);
	const [rolesAttributes, setRolesAttributes] = useState([]);
	const [workflowsList, setWorkflowsList] = useState([]);

	const [rolesCompanyAttributes, setRolesCompanyAttributes] = useState([]);

	const handleCancelCreateRole = () => {
		hideCreateRole();
	};

	const handleCompanyAttributesCheckboxChange = (event) => {
		const { value, checked } = event.target;

		const updatedRolesAttr = rolesCompanyAttributes.map((roleAttr) => {
			if (roleAttr.id === value) {
				return {
					...roleAttr,
					checked: checked,
				};
			}
			return roleAttr;
		});

		setRolesCompanyAttributes(updatedRolesAttr);
	};

	const handleWorkflowsListCheckboxChange = (event) => {
		const { value, checked } = event.target;

		const updatedWorkflowsListAttr = workflowsList.map((roleAttr) => {
			if (roleAttr.name === value) {
				return {
					...roleAttr,
					checked: checked,
				};
			}
			return roleAttr;
		});

		setWorkflowsList(updatedWorkflowsListAttr);
	};

	const handleCheckboxChange = (event) => {
		const { value, checked } = event.target;

		const updatedRolesAttr = rolesAttributes.map((roleAttr) => {
			if (roleAttr.name === value) {
				return {
					...roleAttr,
					checked: checked,
				};
			}
			return roleAttr;
		});

		setRolesAttributes(updatedRolesAttr);
	};

	const handleCreateRole = async () => {
		showLoaderContainer(true);
		showHideButtons(false);
		showErrorContainer(false);

		var isValidForm = true;
		roleNameInput.classList.remove("is-invalid");

		if (roleName === "") {
			roleNameInput.classList.add("is-invalid");
			isValidForm = false;
		}

		if (isValidForm == false) {
			showLoaderContainer(false);
			showHideButtons(true);
			showErrorContainer(false);
		} else {
			const CreateRoleSuccess = (responseObject) => {
				var isSuccess = false;
				if (responseObject.status == Constants.SuccessStatus) {
					if (responseObject.data != null) {
						if (responseObject.data.token != null) {
							if (responseObject.data.token.accessToken != "") {
								isSuccess = true;
								General.SaveToken(responseObject);
								handleCancelCreateRole();
								successCallBackFunction();
							}
						}
					}
				}

				if (isSuccess == false) {
					showErrorContainer(true);
					setTimeout(function () {
						var errorContainer = document.getElementById("errorContainer");
						if (responseObject.message != null) {
							if (responseObject.message != "") {
								errorContainer.innerHTML = responseObject.message;
							}
						}
						if (errorContainer.innerHTML == "") {
							errorContainer.innerHTML = "An error occured, please try again later2.";
						}
						showLoaderContainer(false);
						showHideButtons(true);
					}, 1000);
				} else {
					showErrorContainer(false);
					showLoaderContainer(false);
					showHideButtons(true);
				}
			};

			const CreateRoleFailure = (error) => {
				showErrorContainer(true);
				setTimeout(function () {
					var errorContainer = document.getElementById("errorContainer");
					if (errorContainer.innerHTML == "") {
						errorContainer.innerHTML = "An error occured, please try again later3." + error;
					}
					showLoaderContainer(false);
					showHideButtons(true);
				}, 1000);
			};

			var attributes = {};
			for (var i = 0; i < rolesAttributes.length; i++) {
				if (rolesAttributes[i].checked) {
					attributes[rolesAttributes[i].name] = ["true"];
				}
			}

			var companyAttributes = {};
			for (var i = 0; i < rolesCompanyAttributes.length; i++) {
				if (rolesCompanyAttributes[i].checked) {
					companyAttributes[rolesCompanyAttributes[i].id] = ["true"];
				}
			}

			var workflowsAttributes = {};
			for (var i = 0; i < workflowsList.length; i++) {
				if (workflowsList[i].checked) {
					workflowsAttributes[workflowsList[i].id] = ["workflow"];
				}
			}

			var CreateRoleJSON = {
				language: Constants.Language,
				RoleData: {
					Id: "-",
					Name: roleName,
					Attributes: attributes,
					CompanyAttributes: companyAttributes,
					WorkflowsAttributes: workflowsAttributes,
				},
			};

			var CreateRoleEndPoint = Constants.CreateRoleEndPoint;
			//var CreateRoleJSON = '{"language":"' + Constants.Language + '","RoleData":{  "Id":"-", "Name":"' + roleName + '", "Attributes":  }}';
			General.PostToEndPoint(CreateRoleEndPoint, null, JSON.stringify(CreateRoleJSON), CreateRoleSuccess, CreateRoleFailure);
		}
	};

	const {
		config: { isRTL },
	} = useContext(AppContext);

	useEffect(() => {
		bindCompanyTypes();
		bindWorkflowsList();
		bindMenuAttributes();
	}, []);

	/*************** LOAD Menu Attributes START ************************/

	const bindMenuAttributes = async () => {
		const GetAllMenuAttributesSuccess = (responseObject) => {
			var isSuccess = false;
			if (responseObject.status == Constants.SuccessStatus) {
				if (responseObject.data != null) {
					if (responseObject.data.token != null) {
						if (responseObject.data.token.accessToken != "") {
							var menuItems = responseObject.data.dataBaseContent;
							const uniqueAttributes = new Set();
							var menuAttributes = [
								{ id: "Inspector", name: "Inspector", checked: false },
								{ id: "Supervisor", name: "Supervisor", checked: false },
							];

							menuItems.forEach((item) => {
								if (!uniqueAttributes.has(item.attribute) && item.attribute != null) {
									uniqueAttributes.add(item.attribute);
									const attribute = {
										id: `${item.attribute}`,
										name: `${item.attribute}`,
										checked: false,
									};
									menuAttributes.push(attribute);
								}
							});

							setRolesAttributes(menuAttributes);
							isSuccess = true;
							General.SaveToken(responseObject);
						}
					}
				}
			}

			if (isSuccess == false) {
			} else {
			}
		};

		const GetAllMenuAttributesFailure = (error) => {};

		var endPoint = Constants.GetAllEndPoint + "/" + Constants.SiteMapsTableName;
		General.GetFromEndPoint(endPoint, null, null, GetAllMenuAttributesSuccess, GetAllMenuAttributesFailure);
	};

	/*************** LOAD  Menu Attributes END ************************/

	/*************** LOAD Workflows List START ************************/

	const bindWorkflowsList = async () => {
		const response = await fetch(GetAllWorflowsEndpoint, {
			method: "GET",
		});
		const workflowsData = await response.json();
		if (!workflowsData.httpResponse.status == 0) {
			setWorkflowsList(workflowsData.workflowsResponseList);
		}
	};

	/*************** LOAD Workflows List END ************************/

	/*************** LOAD COMPANY TYPES START ************************/
	const bindCompanyTypes = () => {
		const GetAllCompanyTypesSuccess = (responseObject) => {
			var isSuccess = false;
			if (responseObject.status == Constants.SuccessStatus) {
				if (responseObject.data != null) {
					if (responseObject.data.token != null) {
						if (responseObject.data.token.accessToken != "") {
							var companyAttributes = [];
							for (var i = 0; i < responseObject.data.dataBaseContent.length; i++) {
								companyAttributes.push({
									id: responseObject.data.dataBaseContent[i].id,
									name: responseObject.data.dataBaseContent[i].name,
									checked: false,
								});
							}
							setRolesCompanyAttributes(companyAttributes);
							isSuccess = true;
							General.SaveToken(responseObject);
						}
					}
				}
			}

			if (isSuccess == false) {
				setTimeout(() => {
					var errorContainer = document.getElementById("errorContainer");
					if (responseObject.message != null) {
						if (responseObject.message != "") {
							errorContainer.innerHTML = responseObject.message;
						}
					}
					if (errorContainer.innerHTML == "") {
						errorContainer.innerHTML = "An error occured, please try again later.";
					}
					showLoaderContainer(false);
				}, 1000);
			} else {
				showErrorContainer(false);
				showLoaderContainer(false);
			}
		};

		const GetAllCompanyTypesFailure = (error) => {
			showErrorContainer(true);
			setTimeout(() => {
				var errorContainer = document.getElementById("errorContainer");
				if (errorContainer.innerHTML == "") {
					errorContainer.innerHTML = "An error occured, please try again later.";
				}
				showLoaderContainer(false);
			}, 1000);
		};

		General.GetFromEndPoint(Constants.GetAllEndPoint + "/" + Constants.CompanyTypesTableName, null, null, GetAllCompanyTypesSuccess, GetAllCompanyTypesFailure);
	};

	/*************** LOAD COMPANY TYPES END ************************/

	return (
		<Modal show={showComponent} onHide={handleCancelCreateRole} size="lg">
			<Modal.Header closeButton className="border-200">
				<Modal.Title as="h5">
					<Flex alignItems="center">
						<div className="icon-item bg-soft-success shadow-none">
							<FontAwesomeIcon icon="user" className="text-success" />
						</div>
						<div className="ms-2">{t("Add new role")}</div>
					</Flex>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row className="g-0 align-items-center">
					<Col>
						<div className="mb-3 ">
							<label className="form-label">{t("Company Type")}</label>
							<div className="form-check row ">
								{rolesCompanyAttributes != undefined &&
									rolesCompanyAttributes.length > 0 &&
									rolesCompanyAttributes.map((attribute) => (
										<div key={attribute.id} className="checkBoxItem col-md-4 displayInlineBlock">
											<input
												type="checkbox"
												className="form-check-input"
												id={`checkbox-${attribute.name}`}
												value={attribute.id}
												checked={attribute.checked}
												onChange={handleCompanyAttributesCheckboxChange}
											/>
											<label className="form-check-label" htmlFor={`checkbox-${attribute.name}`}>
												{t(attribute.name)}
											</label>
										</div>
									))}
							</div>
						</div>
						<div className="mb-3">
							<label className="form-label">{t("Role Name")}</label>
							<input type="text" className="form-control" id="txtRoleName" value={roleName} placeholder={t("Role Name")} onChange={(event) => updateRoleName(event.target.value)} />
						</div>
						<div className="mb-3">
							<label className="form-label">{t("Permissions")}</label>
							<div className="form-check row">
								{rolesAttributes != undefined &&
									rolesAttributes.length > 0 &&
									rolesAttributes.map((attribute) => (
										<div key={attribute.id} className="checkBoxItem col-md-3 displayInlineBlock">
											<input
												type="checkbox"
												className="form-check-input"
												id={`checkbox-${attribute.name}`}
												value={attribute.name}
												checked={attribute.checked}
												onChange={handleCheckboxChange}
											/>
											<label className="form-check-label" htmlFor={`checkbox-${attribute.name}`}>
												{t(attribute.name)}
											</label>
										</div>
									))}
							</div>
						</div>
						<div className="mb-3">
							<label className="form-label">{t("Workflows")}</label>
							<div className="form-check row">
								{workflowsList != undefined &&
									workflowsList.length > 0 &&
									workflowsList.map((workflow) => (
										<div key={workflow.id} className="checkBoxItem col-md-6 displayInlineBlock">
											<input
												type="checkbox"
												className="form-check-input"
												id={`checkbox-${workflow.name}`}
												value={workflow.name}
												checked={workflow.checked}
												onChange={handleWorkflowsListCheckboxChange}
											/>
											<label className="form-check-label" htmlFor={`checkbox-${workflow.name}`}>
												{t(workflow.name)}
											</label>
										</div>
									))}
							</div>
						</div>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer className="border-200">
				{buttonsVisibility && (
					<div>
						<Button size="sm" onClick={handleCancelCreateRole} variant="secondary">
							{t("Cancel")}
						</Button>
						<IconButton size="sm" className="ms-2" variant="primary" onClick={handleCreateRole}>
							{t("Confirm")}
						</IconButton>
					</div>
				)}

				{loaderContainerVisibility && <Loader></Loader>}

				{errorContainerVisiblity && <div id="errorContainer" className="loginAlert popupAlert"></div>}
			</Modal.Footer>
		</Modal>
	);
};

CreateRole.propTypes = {
	roleName: PropTypes.string,
	showComponent: PropTypes.bool,
};

export default CreateRole;
