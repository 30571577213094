import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import * as General from "common/general";
import { Dropdown } from "react-bootstrap";
import team3 from "assets/img/team/avatar.png";
import Avatar from "components/common/Avatar";
import * as Constants from "common/constants";
import AppContext from "context/Context";
import { useTranslation } from "react-i18next";
import EditProfile from "components/custom/users/EditProfile";
import { EncryptStorage } from "encrypt-storage";
import jwtDecode from "jwt-decode";
import keycloakService from "keycloakService";
import UpdatePassword from "components/custom/users/UpdatePassword";
import * as em from "common/ErrorMessages";

function ProfileDropdown() {
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});

	const [showModal, setShowModal] = useState(false);
	const [showModalChangePassword, setShowModalChangePassword] = useState(false);
	//const { i18n } = useTranslation();
	const [t, i18n] = useTranslation();
	const [fullName, setFullName] = useState("");
	const [userID, setUserID] = useState("");
	const [fName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [enabled, setEnabled] = useState("");
	const [mobileNumber, setMobileNumber] = useState("");
	const [idNumber, setIDNumber] = useState("");
	const [idType, setIDType] = useState("");
	const [selectedGroups, setSelectedGroups] = useState("");
	const [onRefresh, setOnRefresh] = useState(null);
	const [isRTL, setIsRTL] = useState(JSON.parse(localStorage.getItem("isRTL")) || false);
	const { setConfig } = useContext(AppContext);

	var accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);
	const decodedAccessToken = jwtDecode(accessToken);
	const userFullName = decodedAccessToken.name;

	const logout = () => {
		if ("serviceWorker" in navigator) {
			navigator.serviceWorker.ready.then((registration) => {
				registration.active.postMessage({ action: "deleteAllCache" });
			});
		}
		keycloakService.logout();

		// keycloak.logout();

		// window.location.href = "/";
	};

	// const saveDataLocalStorage = ({ values }) => {

	//   let LocalDataJson = localStorage.getItem(
	//     Constants.LoggedInUserInfoKeyInLocalStorage
	//   );
	//   let LocalData = JSON.parse(LocalDataJson);
	//   let USERINFO = {
	//     companyID: LocalData.companyID,
	//     companyLicenseNumber: LocalData.companyLicenseNumber,
	//     createdDateTime: LocalData.createdDateTime,
	//     createdTimestamp: LocalData.createdTimestamp,
	//     email: values?.email?.toLowerCase(),
	//     emailVerified: LocalData.emailVerified, // ask about
	//     enabled: values.enabled,
	//     firstName: values.firstName,
	//     fullName: values.firstName + " " + values.lastName,
	//     groups: values.groups,
	//     id: values.id,
	//     idNumber: values.idNumber,
	//     idType: values.idType,
	//     lastName: values.lastName,
	//     mobileNumber: values.mobileNumber,
	//   };
	//   localStorage.setItem(
	//     Constants.LoggedInUserInfoKeyInLocalStorage,
	//     JSON.stringify(USERINFO)
	//   );
	// };

	// const GetUserSuccess = (responseObject) => {
	//   var isSuccess = false;
	//   if (responseObject.status == Constants.SuccessStatus) {
	//     if (responseObject.data != null) {
	//       saveDataLocalStorage({ values: responseObject.data.user });
	// setUserID(responseObject.data.user.id);
	// setFirstName(responseObject.data.user.firstName || "");
	// setLastName(responseObject.data.user.lastName || "");
	// setEmail(responseObject.data.user?.email?.toLowerCase() || "");
	// setEnabled(responseObject.data.user.enabled || "");
	// setMobileNumber(responseObject.data.user.mobileNumber || "");
	// setIDNumber(responseObject.data.user.idNumber || "");
	// setIDType(responseObject.data.user.idType || "");
	// setSelectedGroups(responseObject.data.user.groups || "");
	// setFullName(
	//   (responseObject.data.user.firstName ?? "") +
	//   " " +
	//   (responseObject.data.user.lastName ?? "")
	// );
	//     }
	//   }
	// };
	// const GetDataUserLocal = () => {
	//   let LocalDataJson = JSON.parse(
	//     localStorage.getItem(Constants.LoggedInUserInfoKeyInLocalStorage)
	//   );
	//   setUserID(LocalDataJson.id);
	//   setFirstName(LocalDataJson.firstName || "");
	//   setLastName(LocalDataJson.lastName || "");
	//   setEmail(LocalDataJson?.email?.toLowerCase() || "");
	//   setEnabled(LocalDataJson.enabled || "");
	//   setMobileNumber(LocalDataJson.mobileNumber || "");
	//   setIDNumber(LocalDataJson.idNumber || "");
	//   setIDType(LocalDataJson.idType || "");
	//   setSelectedGroups(LocalDataJson.groups || "");
	//   setFullName(
	//     (LocalDataJson.firstName ?? "") + " " + (LocalDataJson.lastName ?? "")
	//   );
	// };

	// const GetUserFailure = (error) => {
	//   setTimeout(() => { }, 1000);
	// };
	const changeLanguage = () => {
		setIsRTL((prevIsRTL) => {
			const newIsRTL = !prevIsRTL;
			localStorage.setItem("isRTL", JSON.stringify(newIsRTL));
			setConfig("isRTL", newIsRTL);

			if (newIsRTL) {
				i18n.changeLanguage("ar");
				// keycloakService.changeLanguage("ar");
			} else {
				i18n.changeLanguage("en");
				// keycloakService.changeLanguage("en");
			}

			return newIsRTL;
		});

		window.location.reload();
	};
	const handlerefresh = (value) => {
		setOnRefresh(value);
	};

	const fetchData = () => {
		try {
		
			const storedLoggedInUserInfo = encryptStorage1.getItem(Constants.LoggedInUserInfoKeyInLocalStorage);

			setUserID(storedLoggedInUserInfo.userId);
			setFirstName(decodedAccessToken.given_name || "");
			setLastName(decodedAccessToken.family_name || "");
			setEmail(decodedAccessToken.email?.toLowerCase() || "");
			setEnabled(decodedAccessToken.email_verified || "");
			setMobileNumber(storedLoggedInUserInfo.mobileNumber || "");
			setIDNumber(storedLoggedInUserInfo.idNumber || "");
			setIDType(storedLoggedInUserInfo.idType || "");
			// setSelectedGroups(responseObject.data.user.groups || "");
			setFullName(decodedAccessToken.name);
			//   setOnRefresh(null)
		} catch (error) {
			// Handle error
			console.error("Error fetching user data:", error);
		}
	};

	useEffect(() => {
		let isMounted = true;
		setFullName(userFullName);

		// if (storedLoggedInUserInfo.fullName) {
		//   setFullName("storedLoggedInUserInfo.fullName");
		// }
		fetchData();
	}, [showModal,showModalChangePassword]);

	return (
		<>
			<Dropdown navbar={true} as="li">
				<Dropdown.Toggle
					as={"button"}
					bsPrefix="toggle"
					id="nav-link-toggle-button"
					// as={Link}
					style={{ backgroundColor: "transparent", border: "none" }}
					className="pe-0 ps-2 pt-1"
				>
					<Avatar src={team3} />
				</Dropdown.Toggle>

				<Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
					<div className="bg-white rounded-2 py-2 dark__bg-1000">
						<label className="userLabel">{userFullName}</label>
						<Dropdown.Divider />
						<Dropdown.Item  onClick={changeLanguage}>
							{isRTL ? "English" : "عربي"}
						</Dropdown.Item>
						<Dropdown.Item  onClick={() => setShowModal(true)}>
							{t("Edit Profile")}
						</Dropdown.Item>
						<Dropdown.Item onClick={() => setShowModalChangePassword(true)}>
							{t(em.Change_Password)}
						</Dropdown.Item>
						<Dropdown.Item  onClick={logout}>
							{t("Logout")}
						</Dropdown.Item>
					</div>
				</Dropdown.Menu>
			</Dropdown>

			<EditProfile
				userID={userID}
				firstName={fName}
				lastName={lastName}
				email={email}
				enabled={enabled}
				mobileNumber={mobileNumber}
				IDNumber={idNumber}
				IDType={idType}
				SelectedGroups={selectedGroups}
				showComponent={showModal}
				hideEditUser={() => setShowModal(false)}
				successCallBackFunction={handlerefresh}
				dropdownVisible
			/>
			<UpdatePassword
				userID={userID}
			//	firstName={fName}
			//	lastName={lastName}
				showComponent={showModalChangePassword}
				email={email}
				hideUpdatePassword={() => setShowModalChangePassword(false)}
				successCallBackFunction={logout}
			/>
		</>
	);
}



export default ProfileDropdown;
