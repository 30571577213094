export const bgWhiteIcons = [
	{ icon: ["fab", "facebook-f"], color: "facebook", bg: "white", href: "#!" },
	{ icon: ["fab", "twitter"], color: "twitter", bg: "white", href: "#!" },
	{
		icon: ["fab", "google-plus-g"],
		color: "google-plus",
		bg: "white",
		href: "#!",
	},
	{ icon: ["fab", "linkedin-in"], color: "linkedin", bg: "white", href: "#!" },
	{ icon: ["fab", "medium-m"], color: "gray-700", bg: "white", href: "#!" },
];
export default [
	{ icon: ["fab", "facebook-f"], color: "facebook", href: "#!" },
	{ icon: ["fab", "twitter"], color: "twitter", href: "#!" },
	{
		icon: ["fab", "google-plus-g"],
		color: "google-plus",
		href: "#!",
	},
	{ icon: ["fab", "linkedin-in"], color: "linkedin", href: "#!" },
	{ icon: ["fab", "medium-m"], color: "gray-700", href: "#!" },
];
