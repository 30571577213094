import React, { useContext } from "react";
import Slider from "react-slick";
import AppContext from "context/Context";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./landing.module.css";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CarouselPartners = ({ items }) => {
	const {
		config: { isDark, isRTL },
	} = useContext(AppContext);
	const [t, i18n] = useTranslation();
	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 5, // Show 3 items at a time
		slidesToScroll: 5,
		responsive: [
			{
				breakpoint: 1024, // If screen width is less than 1024px, show 2 items
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					dots: false,
				},
			},
			{
				breakpoint: 600, // If screen width is less than 600px, show 1 item
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
		],
	};
	return (
		<Slider {...settings} dotsClass={`slick-dots custom-dots" ${styles.dotsLanding}`} className={styles.sliderLanding}>
			{items.map((item) => (
				<div key={item.id} className={styles.carouselPartnercontainer} style={{ padding: "20px", margin: "10px" }}>
					<img className={styles.carouselPartnersImage} src={item.image} />
				</div>
			))}
		</Slider>
	);
};

export default CarouselPartners;
