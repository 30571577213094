import React, { useState, useContext, useEffect } from "react";

import * as Constants from "common/constants";
import * as General from "common/general";
import * as em from "common/ErrorMessages";
import { Row, Col } from "react-bootstrap";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableFooter from "components/common/advance-table/AdvanceTableFooter";
import AdvanceTableSearchBox from "components/common/advance-table/AdvanceTableSearchBox";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";

import IconButton from "components/common/IconButton";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import LoaderIcon from "components/custom/Preloader";
import AddTemplate from "components/custom/templates/AddTemplate";
import DeleteTemplate from "components/custom/templates/DeleteTemplate";

const ManageTemplates = () => {
	const [t, i18n] = useTranslation();
	const [showAddTemplateModal, setShowAddTemplateModal] = useState(false);
	const [showDeleteTemplateModal, setShowDeleteTemplateModal] = useState(false);
	const [processedTemplateId, setProcessedTemplateId] = useState(-1);
	const [templates, setTemplates] = useState([]);
	const [loading, setLoading] = useState(true);
	async function fetchData() {
		const GetAllTemplatesSuccess = (responseObject) => {
			let arrayOfWorkflows = Object.values(responseObject.data.dataBaseContent);
			const pendingTasks2 = arrayOfWorkflows.flatMap((task) => {
				let parsedTask;
				try {
					parsedTask = typeof task === "string" ? JSON.parse(task) : task;
				} catch (e) {
					console.error("Error parsing task:", task, e);
					return []; // Skip this task if it can't be parsed
				}
				parsedTask.templateName = t(parsedTask.templateName);
				parsedTask.templateId = t(parsedTask.templateId);
				parsedTask.action = (
					<>
						<OverlayTrigger
							overlay={
								<Tooltip style={{ position: "fixed" }} id="abletodelete">
									{t(em.delete_icon)}
								</Tooltip>
							}
						>
							<div className="theme-control-toggle-label icon-position">
								<IconButton
									className="btn btn-danger"
									iconClassName="fs--2"
									variant=""
									size="sm"
									icon="fa-solid fa-trash"
									style={{ margin: "auto 0.25rem" }}
									onClick={() => deleteTemplateModal(task.templateId)}
								></IconButton>
							</div>
						</OverlayTrigger>

						<OverlayTrigger
							overlay={
								<Tooltip style={{ position: "fixed" }} id="abletodelete">
									{t(em.DownloadTemplate)}
								</Tooltip>
							}
						>
							<div className="theme-control-toggle-label icon-position">
								<IconButton
									className="btn btn-danger"
									iconClassName="fs--2"
									variant=""
									size="sm"
									icon="fa-solid fa-eye"
									style={{ margin: "auto 0.25rem" }}
									onClick={() => downloadTemplate(task.templateId, task.templateName, task.extension)}
								></IconButton>
							</div>
						</OverlayTrigger>
					</>
				);
				return parsedTask;
			});
			setTemplates(pendingTasks2);
			setLoading(false);
		};

		const GetAllTemplatesFailure = (error) => {
			setLoading(false);
		};
		var link = Constants.GetAllTemplatesEndPoint;

		General.GetFromEndPoint(link, null, null, GetAllTemplatesSuccess, GetAllTemplatesFailure);
	}
	const columns = [
		{
			accessor: "templateName",
			Header: t("Name"),
		},
		{
			accessor: "templateId",
			Header: t("Id"),
		},
		{
			accessor: "action",
			Header: t("Action"),
		},
	];
	useEffect(() => {
		fetchData();
	}, []);

	// useEffect(() => {
	// 	if (!showDeleteTemplateModal) {
	// 		setProcessedTemplateId(-1);
	// 	}
	// }, [showDeleteTemplateModal]);

	function deleteTemplateModal(formId) {
		setProcessedTemplateId(formId);
		setShowDeleteTemplateModal(true);
	}
	const downloadTemplate = (templateId, templateName, extension) => {
		const DownloadTemplateSuccess = (responseObject) => {
			const byteCharacters = atob(responseObject.message);
			const byteNumbers = new Array(byteCharacters.length);
			for (let i = 0; i < byteCharacters.length; i++) {
				byteNumbers[i] = byteCharacters.charCodeAt(i);
			}
			const byteArray = new Uint8Array(byteNumbers);

			// Create Blob object from binary data
			const blob = new Blob([byteArray], { type: "application/octet-stream" });

			// Create Blob URL
			const blobUrl = URL.createObjectURL(blob);

			// Create a link element
			const link = document.createElement("a");
			link.href = blobUrl;
			link.download = templateName + "." + extension;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			URL.revokeObjectURL(blobUrl);

			setLoading(false);
		};

		const DownloadTemplateFailure = (error) => {
			setLoading(false);
		};
		var link = Constants.DownloadTemplateEndPoint;

		General.GetFromEndPoint(link + "/" + templateId, null, null, DownloadTemplateSuccess, DownloadTemplateFailure);
	};
	return (
		<div className="card">
			{loading && <LoaderIcon />}
			<div className="tool-header">
				<OverlayTrigger
					overlay={
						<Tooltip style={{ position: "fixed" }} id="newcontract">
							{t(`New Template`)}
						</Tooltip>
					}
				>
					<div className="theme-control-toggle-label icon-position end-text">
						<IconButton
							className="btn-primary botton-circle"
							iconClassName="fs--5"
							size="sm"
							icon="fa-solid fa-circle-plus"
							style={{ margin: "auto 0.25rem", padding: "9px 10px 10px" }}
							onClick={() => setShowAddTemplateModal(true)}
						></IconButton>
					</div>
				</OverlayTrigger>
			</div>
			<div className="card-body">
				<div className="mb-3">
					<AdvanceTableWrapper columns={columns} data={templates} sortable pagination perPage={10}>
						<Row className="flex-end-center mb-3">
							<Col xs="auto" sm={6} lg={4}>
								<label>{t("Search")}</label>
								<AdvanceTableSearchBox table placeholder={t("Search")} />
							</Col>
						</Row>
						<AdvanceTable
							table
							headerClassName="bg-200 text-900 text-nowrap align-middle"
							rowClassName="align-middle white-space-nowrap"
							tableProps={{
								bordered: true,
								striped: true,
								className: "fs--1 mb-0 overflow-hidden",
							}}
						/>
						<div className="mt-3">
							<AdvanceTableFooter rowCount={templates.length} table rowInfo navButtons rowsPerPageSelection />
						</div>
						{/* <div className="mt-3">
									<AdvanceTablePagination table />
								</div> */}
					</AdvanceTableWrapper>
				</div>
			</div>

			{showAddTemplateModal && <AddTemplate setShowAddTemplateModal={setShowAddTemplateModal} addComplete={fetchData} />}
			{showDeleteTemplateModal && <DeleteTemplate processedTemplateId={processedTemplateId} setShowDeleteTemplateModal={setShowDeleteTemplateModal} addComplete={fetchData} />}
		</div>
	);
};

export default ManageTemplates;
