import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Dropdown, Navbar, Nav, OverlayTrigger, Tooltip } from "react-bootstrap";
import handleNavbarTransparency from "helpers/handleNavbarTransparency";
import AppContext from "context/Context";
// import logo from "assets/img/logo/mwanLogo-ver.png";
import logo from "assets/img/logo/mwanLogo.png";
import { useTranslation } from "react-i18next";
import styles from "./landing.module.css";
import NavbarDropdown from "components/navbar/top/NavbarDropdown";
import { useNavigate } from "react-router-dom";
import NavbarTopDropDownMenus from "components/navbar/top/NavbarTopDropDownMenus";
import LandingRightSideNavItem from "./LandingRightSideNavItem";
import { topNavbarBreakpoint } from "config";
import NavbarNavLink from "components/navbar/top/NavbarNavLink";
import * as Constants from "../../../common/constants";
import { faUser as faUserRegular } from "@fortawesome/free-regular-svg-icons"; // Regular icon

const NavbarStandard = () => {
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});
	var accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);
	const storedLoggedInUserInfo = encryptStorage1.getItem(Constants.LoggedInUserInfoKeyInLocalStorage);

	const [t, i18n] = useTranslation();
	const [isRTL, setIsRTL] = useState(JSON.parse(localStorage.getItem("isRTL")) || false);
	const { setConfig } = useContext(AppContext);
	const navigate = useNavigate();
	const [navbarCollapsed, setNavbarCollapsed] = useState(true);
	const handleLoginRedirect = () => {
		navigate("/login"); // Redirect to login page
		window.location.reload();
	};
	const handleRedirect = () => {
		navigate("/userTasks"); // Redirect to login page
		window.location.reload();
	};
	const changeLanguage = () => {
		setIsRTL((prevIsRTL) => {
			const newIsRTL = !prevIsRTL;
			localStorage.setItem("isRTL", JSON.stringify(newIsRTL));
			setConfig("isRTL", newIsRTL);

			if (newIsRTL) {
				i18n.changeLanguage("ar");
				// keycloakService.changeLanguage("ar");
			} else {
				i18n.changeLanguage("en");
				// keycloakService.changeLanguage("en");
			}

			return newIsRTL;
		});

		window.location.reload();
	};
	const {
		config: { isDark },
	} = useContext(AppContext);

	return (
		<>
			<Navbar
				variant={isDark ? "light" : "dark"}
				fixed="top"
				expand={topNavbarBreakpoint}
				className={classNames(
					"navbar-standard navbar-theme",
					{
						"bg-100": !navbarCollapsed && isDark,
						"bg-100": !navbarCollapsed && !isDark,
					},
					styles.backgroundWhite,

					styles.menuShadow
				)}
			>
				<Container>
					<Navbar.Toggle onClick={() => setNavbarCollapsed(!navbarCollapsed)} />
					<Navbar.Brand className="text-white dark__text-white" as={Link} to="/">
						<img src={logo} className={`${styles.logosize}`} />
					</Navbar.Brand>

					<Navbar.Collapse className={`ms-auto ${isRTL ? styles.rowJustifyRight : styles.rowJustifyLeft}`}>
						<Dropdown className={styles.menuDropDown}>
							<NavbarNavLink
								className={`text-500 ${styles.mainPageMenu}`}
								//title={t("Main Page")}
								route={{ name: t("Main Page"), to: "/" }} // Link to the main page
							></NavbarNavLink>
						</Dropdown>

						<Dropdown className={styles.menuDropDown}>
							<NavbarDropdown className={styles.menuDropDown} title={t("About the platform")}>
								<Dropdown.Item className={`text-500 ${styles.menuHover}`}>{t("About the platform")}</Dropdown.Item>
							</NavbarDropdown>
						</Dropdown>
					</Navbar.Collapse>
					<Navbar.Collapse className={`scrollbar ${isRTL ? styles.rowJustifyLeft : styles.rowJustifyRight}`}>
						<button className={`${isRTL ? styles.buttonDownload2 : styles.buttonDownload}`} onClick={""}>
							<FontAwesomeIcon icon="fa-file-arrow-down" className={``} transform="shrink-2" />
							{t("User Manual")}
						</button>
						{storedLoggedInUserInfo && (
							<button className={`${styles.loginButton3}`} onClick={handleRedirect}>
								{t("User-Tasks")}
							</button>
						)}
						{!storedLoggedInUserInfo && (
							<button className={`${isRTL ? styles.loginButton2 : styles.loginButton}`} onClick={handleLoginRedirect}>
								<FontAwesomeIcon icon={faUserRegular} className={``} transform="shrink-2" />
								{t("Login")}
							</button>
						)}

						<Nav>
							<NavbarDropdown classes={styles.languageDropDown} title={isRTL ? "عربي" : "English"}>
								<Dropdown.Item className={`text-500 ${styles.languageOptions}`} onClick={changeLanguage}>
									{isRTL ? "English" : "عربي"}
								</Dropdown.Item>
							</NavbarDropdown>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</>
	);
};

export default NavbarStandard;
