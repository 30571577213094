import React, { Component, useEffect, useState } from "react";
import { ReactComponent, Utils } from "@formio/react";
import settingsForm from "./LoggedInUserDetails.settingsForm";
import _ from "lodash";
import { createRoot } from "react-dom/client";

/*
This component get user information from local storage and fill them in text fields as per user request
*/
const LoggedInUserDetailsCustomComp = ({ ...props }) => {
	// Update the setValue method to handle onChange event
	const updateValue = (e) => {
		if (props.form._form.settings && props.form._form.settings.toEdit !== true) {
			props.onChange(e, null);
		}
	};

	useEffect(() => {
		let state = props.oldState;
		if (props.component.defaultValue && props.component.defaultValue !== "") {
			let path0 = props.component.userInfo[0]["userFieldPath"];
			//handle case when value is not a json
			props.setOldState(props.component.defaultValue[path0] ? props.component.defaultValue : JSON.parse(props.component.defaultValue));
			return;
		} else if (props.component.userInfo) {
			let data = {};
			for (var i = 0; i < localStorage.length; i++) {
				var key = localStorage.key(i);
				var item = null;
				//handle case when local storage value is not a json
				try {
					item = JSON.parse(localStorage.getItem(key));
				} catch (e) {
					item = localStorage.getItem(key);
				} finally {
					data[key] = item;
				}
			}

			for (let i = 0; i < props.component.userInfo.length; i++) {
				let value = _.get(data, props.component.userInfo[i]["userFieldPath"]);
				state[props.component.userInfo[i]["userFieldPath"]] = value;
			}
			props.setOldState(state);

			updateValue(state);
		}
	}, []);
	//add page data in global form data
	useEffect(() => {
		if (props.data) {
			let keys = Object.keys(props.data);
			for (let i = 0; i < keys.length; i++) {
				props.form._data[keys[i]] = props.data[keys[i]];
				props.form._submission.data[keys[i]] = props.data[keys[i]];
			}
			if (props.insideGrid === true) {
				delete props.form._data[props.component.key];
				delete props.form._submission.data[props.component.key];
			}
		}
	}, [props.data]);

	return (
		<div className="loggedInUserDetailsCustomComp">
			<div className="row ">
				{props.component.userInfo?.map((grid) => {
					return (
						grid.userFieldPath.replaceAll(" ", "") !== "" &&
						grid.userFieldName.replaceAll(" ", "") !== "" && (
							<div className="col-sm-6 mt-3">
								<label>{grid.userFieldName}</label>

								<input type="text" className="form-control" value={props.oldState[grid.userFieldPath]} disabled={true} />
							</div>
						)
					);
				})}
			</div>
		</div>
	);
};

export default class LoggedInUserDetails extends ReactComponent {
	static shouldSetValue = false; // Define shouldSetValue as a static property

	/**
	 * This is the first phase of component building where the component is instantiated.
	 *
	 * @param component - The component definition created from the settings form.
	 * @param options - Any options passed into the renderer.
	 * @param data - The submission data where this component's data exists.
	 */

	constructor(component, options, data) {
		options["oldState"] = {};
		super(component, options, data);
		this.reactInstance = null;
	}

	/**
	 * This function is the default settings for the component. At a minimum you want to set the type to the registered
	 * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
	 *
	 * @param sources
	 * @returns {*}
	 */
	static schema(...extend) {
		return ReactComponent.schema({
			type: "loggedInUserDetailsCustomComp",
			label: "User Information",
		});
	}
	static get builderInfo() {
		return {
			title: "LoggedIn User Details",
			icon: "cubes",
			group: "Basic",
			documentation: "",
			weight: -10,
			schema: LoggedInUserDetails.schema(),
		};
	}
	static editForm = settingsForm;

	/**
	 * This method is called any time the component needs to be rebuilt. It is most frequently used to listen to other
	 * components using the this.on() function.
	 */
	init() {
		return super.init();
	}

	/**
	 * This method is called before the component is going to be destroyed, which is when the component instance is
	 * destroyed. This is different from detach which is when the component instance still exists but the dom instance is
	 * removed.
	 */
	destroy() {
		return super.destroy();
	}
	/**
	 * This method is called before a form is submitted.
	 * It is used to perform any necessary actions or checks before the form data is sent.
	 *
	 */

	beforeSubmit() {
		return super.beforeSubmit();
	}

	/**
	 * The second phase of component building where the component is rendered as an HTML string.
	 *
	 * @returns {string} - The return is the full string of the component
	 */
	render() {
		// For react components, we simply render as a div which will become the react instance.
		// By calling super.render(string) it will wrap the component with the needed wrappers to make it a full component.
		return super.render(`<div ref="react-${this.id}"></div>`);
	}

	/**
	 * Callback ref to store a reference to the node.
	 *
	 * @param element - the node
	 */
	setReactInstance(element) {
		this.reactInstance = element;
	}

	/**
	 * The third phase of component building where the component has been attached to the DOM as 'element' and is ready
	 * to have its javascript events attached.
	 *
	 * @param element
	 * @returns {Promise<void>} - Return a promise that resolves when the attach is complete.
	 */
	attach(element) {
		super.attach(element);

		// The loadRefs function will find all dom elements that have the "ref" setting that match the object property.
		// It can load a single element or multiple elements with the same ref.
		this.loadRefs(element, {
			[`react-${this.id}`]: "single",
		});

		if (this.refs[`react-${this.id}`]) {
			this.attachReact(this.refs[`react-${this.id}`], this.setReactInstance.bind(this));
			if (this.shouldSetValue) {
				this.setValue(this.dataForSetting);
				this.updateValue(this.dataForSetting);
			}
		}
		return Promise.resolve();
	}

	/**
	 * The fourth phase of component building where the component is being removed from the page. This could be a redraw
	 * or it is being removed from the form.
	 */
	detach() {
		if (this.refs[`react-${this.id}`]) {
			this.detachReact(this.refs[`react-${this.id}`]);
		}
		super.detach();
	}

	/**
	 * Override this function to insert your custom component.
	 *
	 * @param element
	 * @param ref - callback ref
	 */
	attachReact(element, ref) {
		let insideGrid = false;

		const root = createRoot(element);
		let key = this.component.key;

		const rootForm = this.getRoot(); // Get the root form object

		Utils.eachComponent(
			rootForm.components,
			function (component) {
				if (component.component.type === "editgrid") {
					Utils.eachComponent(
						component.component.components,
						function (component2) {
							if (!insideGrid) {
								insideGrid = component2.key === key;
							}
						},
						true
					);
				}
			},
			true
		);

		const setOldState = (value) => {
			this.options["oldState"] = value;
			this.updateOnChange({}, true);
			root.render(
				<LoggedInUserDetailsCustomComp
					component={this.component} // These are the component settings if you want to use them to render the component.
					onChange={this.updateValue} // Pass the onChange event handler
					value={this.dataValue}
					data={this.data}
					form={rootForm}
					setOldState={setOldState}
					oldState={this.options["oldState"]}
					insideGrid={insideGrid}
				/>
			);
		};

		root.render(
			<LoggedInUserDetailsCustomComp
				component={this.component} // These are the component settings if you want to use them to render the component.
				onChange={this.updateValue} // Pass the onChange event handler
				value={this.dataValue}
				data={this.data}
				form={rootForm}
				setOldState={setOldState}
				oldState={this.options["oldState"]}
				insideGrid={insideGrid}
			/>
		);
	}

	/**
	 * Override this function.
	 */
	detachReact(element) {
		return;
	}

	/**
	 * Something external has set a value and our component needs to be updated to reflect that. For example, loading a submission.
	 *
	 * @param value
	 */
	setValue(value) {
		if (this.reactInstance) {
			this.reactInstance.setState({
				value: value,
			});
			this.shouldSetValue = false;
		} else {
			this.shouldSetValue = true;
			this.dataForSetting = value;
		}
	}

	/**
	 * Override normal validation check to insert custom validation in react component.
	 *
	 * @param data
	 * @param dirty
	 * @param rowData
	 * @returns {boolean}
	 */
	checkValidity(data, dirty, rowData) {
		const valid = super.checkValidity(data, dirty, rowData);
		if (!valid) {
			return false;
		}
		return this.validate(data, dirty, rowData);
	}

	/**
	 * Do custom validation.
	 *
	 * @param data
	 * @param dirty
	 * @param rowData
	 * @returns {boolean}
	 */
	validate(data, dirty, rowData) {
		return true;
	}
}
