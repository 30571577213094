import React, { useRef } from "react";
import "../../styles/Builder.css";
import { useState, useEffect } from "react";
//import axios from 'axios';
import { Form } from "@formio/react";
import Formio from "formiojs/Formio";
//import { FormBuilder as FormioFormBuilder } from '@formio/react';
import { Card, Col, Row } from "react-bootstrap";
import { atomOneDark, CopyBlock, dracula } from "react-code-blocks";
import { useLocation, useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { Alert } from "reactstrap";
import { Formiojs } from "formiojs";
import * as Constants from "common/constants";
import * as General from "common/general";
import Formioform from "./FormioForm";
import LoaderIcon from "components/custom/Preloader";
import { useTranslation } from "react-i18next";

function FormViewer() {
	const [t, i18n] = useTranslation();

	const [form, setForm] = useState({});
	const navigate = useNavigate();
	const { id } = useParams();
	const [jsonSchema, setJsonSchema] = useState({ components: [] });
	const [formDisplay, setFormDisplay] = useState("");
	const [submittedData, setSubmittedData] = useState({});
	const [formReady, setFormReady] = useState(false);

	const { state } = useLocation();
	//const [showAlert, setShowAlert] = useState(false);
	//const [showFailAlert, setShowFailAlert] = useState(false);
	//const [alertmessage, setAlertMessage] = useState("");
	const [toPDF, setToPDF] = useState(false);
	const toPDFRef = useRef(false);
	const formdata = {
		title: "",
		display: formDisplay,
		type: "form",
		name: "",
		path: "",
		components: jsonSchema.components,
	};

	const getForm = (data, pageNumber, initialPage, readOnly = false) => {
		const GetFormByIdSuccess = (responseObject) => {
			var isSuccess = false;
			if (responseObject.status == Constants.SuccessStatus) {
				if (responseObject.data != null) {
					if (responseObject.data.token != null) {
						if (responseObject.data.token.accessToken != "") {
							var mydata = JSON.parse(responseObject.data.formData);
							setForm(mydata);

							formdata.title = mydata.title;
							formdata.type = mydata.type;
							formdata.name = mydata.name;
							formdata.path = mydata.path;
							/* console.log("formdata.path", formdata.path);*/
							formdata.components = mydata.components;
							formdata.display = mydata.display;
						}
					}
				}
			}

			if (isSuccess == false) {
				console.log(responseObject);
			} else {
			}
		};

		const GetFormByIdFailure = (error) => {
			// this.showErrorContainer(true);
			// setTimeout(() => {
			//     var errorContainer = document.getElementById('errorContainer');
			//     if (errorContainer.innerHTML == "") {
			//         errorContainer.innerHTML = "An error occured, please try again later.";
			//     }
			//     this.showLoaderContainer(false);
			// }, 1000)
			console.log(error);
		};
		var GetFormByIdEndPoint =
			Constants.GetFormByIdEndPoint +
			id +
			"?dataReadOnly=false&readOnly=" +
			readOnly +
			"&hideHeader=true&toEdit=false" +
			(pageNumber !== null ? "&pageNumber=" + pageNumber : "") +
			(initialPage !== null ? "&initialPage=" + initialPage : "");
		General.PostToEndPoint(GetFormByIdEndPoint, null, data, GetFormByIdSuccess, GetFormByIdFailure);
	};
	useEffect(() => {

		if (state) {
			if (state.toPDF) {
				getForm(state.data, null, null, true);
			} else if (state.isTesting === true) {
				getForm(
					state ? (state.data ? JSON.parse(state.data) : {}) : {},
					state ? (state.pageNumber ? state.pageNumber : 1) : 1,
					state ? (state.initialPage ? state.initialPage : null) : null
				);
			} else {
				getForm({}, null, null);
			}
		} else {
			getForm({
				violationsGrid: [
					{
						violation: {
							label: "violation-MH0101",
							value: "MH0101"
						}, evidence: ""
					},
					{
						violation: {
							label: "violation-MA0102",
							value: "MA0102"
						}, evidence: ""
					},
					{
						violation: {
							label: "violation-MH0115",
							value: "MH0115"
						}, evidence: ""
					},
					{
						violation: {
							label: "violation-MA0360",
							value: "MA0360"
							}, evidence: ""
					},
					{
						violation: {
							label: "violation-MH/MA0703",
							value: "MH/MA0703"
							}, evidence: ""
					},
				]
			}, null, null, false);
		}
	}, []);
	const handlePrint = () => {
		toPDFRef.current = true;
		var originalContents = document.body.innerHTML;
		var printContents = document.getElementById("div").innerHTML;

		document.body.innerHTML = printContents;
		window.print();
		document.body.innerHTML = originalContents;
	};
	useEffect(() => {
		if (formReady) {
			if (state?.toPDF) {
				setTimeout(() => {
					setToPDF(true);
					handlePrint();
				}, 5000);
			}
		}
	}, [formReady]);

	const handleSubmission = (sub) => {
		console.log(sub);
		if (state) {
			if (state.isTesting === true) {
				let data = {};
				if (state.data) {
					data = JSON.parse(state.data);
				}
				data = { ...data, ...sub.data };
				let newPage = state ? (state.pageNumber ? state.pageNumber + 1 : 2) : 2;

				if (newPage <= form.totalNumberOfPages) {
					navigate("/formView/" + id, {
						state: {
							data: JSON.stringify(data),
							pageNumber: newPage,
							isTesting: true,
						},
					});

					window.location.reload();
				} else {
					navigate("/formView/" + id, {
						state: {
							data: data,
							pageNumber: form.totalNumberOfPages,
							initialPage: 1,
							isTesting: true,
						},
					});

					window.location.reload();
				}
			}
		}
	};

	return (
		<>
			{/* {state?.toPDF && (<LoaderIcon />)} */}

			<div id="div">
				<Card title="Result" className="p-4">
					<Formioform
						setFormReady={(e) => setFormReady(e)}
						withWizard={state?.toPDF ? false : true}
						form={form}
						onNextPage={(e) => {
							console.log(e);
						}}
						onSubmit={(sub) => handleSubmission(sub)}
					/>
				</Card>
			</div>
		</>
	);
}

export default FormViewer;
