import React, { useState, useEffect } from "react";
import * as Constants from "common/constants";
import jwtDecode from "jwt-decode";
import { Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import { Container } from "react-bootstrap";
import { EncryptStorage } from "encrypt-storage";
import keycloakService from "keycloakService";
import "components/workflow/WorkflowList.css";

export function TimerSessions() {
	const [lastActivityTime, setLastActivityTime] = useState(Date.now());

	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});

	const handlecontinue = () => {
		keycloakService.renewSession();
		setShowDialog(false);
	};
	const handlelogout = () => {
		keycloakService.logout();
	};

	const [showDialog, setShowDialog] = useState(false);

	useEffect(() => {
		const activityHandler = () => {
			// Update last activity time whenever activity is detected
			setLastActivityTime(Date.now());
		};

		// Attach event listeners to detect user activity
		document.addEventListener("mousemove", activityHandler);
		document.addEventListener("keydown", activityHandler);

		const startTokenRefreshTimer = () => {
			const refreshToken = encryptStorage1.getItem(Constants.RefreshTokenKeyInLocalStorage);
			const accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);
			const decodedAccessToken = jwtDecode(accessToken);
			if (accessToken) {
				const tokenRefreshInterval = decodedAccessToken.exp - Date.now() / 1000 - 60; // 60 seconds before expiration
				const timerId = setTimeout(() => {
					const currentTime = Date.now();
					const timeSinceLastActivity = (currentTime - lastActivityTime) / 1000 / 60; // Convert to minutes

					if (timeSinceLastActivity >= 59) {
						if (!window.location.pathname.includes("form_view_renderer")) {
							setShowDialog(true);
						}
					} else {
						keycloakService.renewSession();
						startTokenRefreshTimer();
					}
				}, tokenRefreshInterval * 1000);

				encryptStorage1.setItem("timerId", timerId);
			}
		};
		if (encryptStorage1.getItem("timerId")) {
			clearInterval(encryptStorage1.getItem("timerId"));
		}
		// startTokenRefreshTimer();
	}, []);

	// useEffect(() => {
	//   const refreshToken = encryptStorage1.getItem(Constants.RefreshTokenKeyInLocalStorage);
	//   const accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);
	//   const decodedAccessToken = jwtDecode(accessToken);

	//   if (refreshToken) {

	//     setInterval(() => {

	//       const decodedRefreshToken = jwtDecode(refreshToken);
	//       if (decodedRefreshToken.exp > Date.now() / 1000) {

	//         const decodedAccessToken = jwtDecode(accessToken);

	//         if (decodedAccessToken.exp > Date.now() / 1000) {
	//           setShowDialog(false);
	//         }
	//         else {
	//           setShowDialog(true);
	//         }

	//       }
	//       else {
	//         setShowDialog(false);
	//         keycloakService.logout();

	//       }

	//     }, decodedAccessToken.exp - 60000);
	//   }
	//   else {
	//     console.log("expired");
	//   }
	// }, []);

	return (
		<Container>
			<Modal isOpen={showDialog} keyboard={true} className="WorkflowList">
				<ModalHeader>Session Expired</ModalHeader>
				<ModalBody>Do you want to continue?</ModalBody>
				<ModalFooter>
					<button color="primary" onClick={handlecontinue}>
						Yes
					</button>
					<button color="secondary" onClick={handlelogout}>
						Logout
					</button>
				</ModalFooter>
			</Modal>
		</Container>
	);
}

export default TimerSessions;
