export default [
	{
		id: 0,
		name: "Avengers",
		members: [
			{
				userId: 1,
				designation: "Admin",
			},
			{
				userId: 2,
				designation: "Moderator",
			},
			{
				userId: 3,
				designation: "Member",
			},
			{
				userId: 4,
				designation: "Member",
			},
			{
				userId: 5,
				designation: "Member",
			},
		],
	},
];
