const { default: LoaderIcon } = require("components/custom/Preloader");
const { default: Translation } = require("./Translation");
const { default: WizardStepper } = require("./WizardStepper");
import { Form, Utils } from "@formio/react";
import classNames from "classnames";
import Loader from "components/custom/Loader";
import { createContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import db from "indexedDB";
import _ from "lodash";
import Dexie from "dexie";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { EncryptStorage } from "encrypt-storage";
import { useLocation, useNavigate } from "react-router-dom";
import IconButton from "components/common/IconButton";
import * as General from "common/general";
import { isEmpty } from "lodash";
export const FormDataContext = createContext();
const Formioform = ({ form, withWizard,nextPage,state, toPDF = false, ...props }) => {
	const [myForm, setMyForm] = useState({});

	const formioFormRef = useRef();
	const navigate = useNavigate();
	const encryptStorage = new EncryptStorage("secret-key-value", { prefix: "@mwan" });

	const [navigateIntoPage, setNavigateIntoPage] = useState(-1);
	const [formKey, setFormKey] = useState(0); // Add a key state
	const [parentStep, setParentStep] = useState(-1); // Add a key state
	const [formLoaded, setFormLoaded] = useState(false);
	const [formKeyUpdated, setFormKeyUpdated] = useState(false);
	const [currentPage, setCurrentPage] = useState(withWizard === false ? 0 : -1);
	const [t, i18n] = useTranslation();
	const [dataRendered, setDataRendered] = useState(false);
	const dataRenderedRef = useRef(false);
	const location = useLocation();
	const [initializing, setInitializing] = useState(true);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const language = i18n.resolvedLanguage;
	let [resources, setResources] = useState({});
	const [labelNextButton, setLabelNextButton] = useState("Next");
	const [labelSubmitButton, setLabelSubmitButton] = useState("Submit");
	const [notificationNextButton, setNotificationNextButton] = useState("null");
	const [allRoles, setAllRoles] = useState([]);
	const [loadingRoles, setLoadingRoles] = useState(true);
	const [nextPageLocal,setNextPageLocal]=useState(false)

	useEffect(() => {
		setNextPageLocal(nextPage);
		if (form && form.settings) {
			let tempResources = {};
			Object.keys(i18n.store.data).map((key) => {
				tempResources[key] = i18n.store.data[key].translations;
			});
			form.settings["translatedData"] = tempResources;
			form.settings["language"] = i18n.resolvedLanguage;

			const userCompanyDictionary = encryptStorage.getItem("loggedInUserInfo").userCompanyDictionary;

			let roles = {};

			Object.keys(userCompanyDictionary).forEach((company) => {
				userCompanyDictionary[company].forEach((role) => {
					roles[`is${role.roleName}`] = true;
				});
			});
			form.settings["roles"] = roles;
			setResources(tempResources);
		}
	}, [form]);

	useEffect(() => {
		if (form && form._id && i18n.resolvedLanguage !== "") {
			if (props.setFormReady) {
				props.setFormReady(true);
			}
		}
	}, [form, language]);
	function mergeJSONObjects(obj1, obj2) {
		const result = { ...obj1 };

		for (const key in obj2) {
			result[key] = obj2[key];
		}
		return result;
	}
	//render component values from local storage after refresh
	useEffect(() => {
		async function afterRefresh() {
			try {
				var isClear = false;
				var myData = await db.temp_form_data.toArray();
				myData = myData[0] === undefined ? JSON.stringify({}) : JSON.parse(myData[0].tempFormData);
				if (!dataRendered) {
					if (form && form._id) {
						if (myData !== "{}") {
							if (myData["formId"] === form._id) {
								if (myData["recordInTableId"]) {
									if (myData["recordInTableId"] !== form?.executionLocalVariables?.recordInTableId) {
										await db.table("temp_form_data").clear();
										isClear = true;
									}
								} else if (myData["processInstanceId"]) {
									if (myData["processInstanceId"] !== form?.processInstanceId) {
										await db.table("temp_form_data").clear();
										isClear = true;
									}
								}
								if (!isClear) {
									Utils.eachComponent(
										form.components,
										function (component) {
											if (component.input && myData[component.key] !== null && myData[component.key] !== undefined) {
												component.defaultValue = myData[component.key];
												if (component.type === "textarea") {
													component.defaultValue = component.defaultValue.toString();
												}
												if (component.type === "textfield" && component.defaultValue) {
													component.defaultValue = form.settings.data[component.key] ? form.settings.data[component.key].toString() : component.defaultValue;
												}
											} else if (component.type === "columns") {
												Utils.eachComponent(
													component.columns,
													function (component2) {
														if (component2.input && myData[component2.key] !== null && myData[component2.key] !== undefined) {
															component2.defaultValue = myData[component2.key];
															if (component2.type === "textarea") {
																component2.defaultValue = component2.defaultValue.toString();
															}
															if (component2.type === "textfield" && component2.defaultValue) {
																component2.defaultValue = form.settings.data[component2.key] ? form.settings.data[component2.key].toString() : component2.defaultValue;
															}
														}
													},
													true
												);
											}
										},
										true
									);
								}

								dataRenderedRef.current = true;
								setDataRendered(true);
								// setFormKey(prevKey => prevKey + 1);
							} else {
								dataRenderedRef.current = true;
								setDataRendered(true);
							}
						} else {
							Utils.eachComponent(
								form.components,
								function (component) {
									if (component.input) {
										if (component.type === "textarea" && component.defaultValue) {
											component.defaultValue = component.defaultValue.toString();
										}
										if (component.type === "textfield" && component.defaultValue) {
											component.defaultValue = form.settings.data[component.key] ? form.settings.data[component.key].toString() : component.defaultValue;
										}
										if (component.type === "datetime" && component.defaultValue) {
											component.customOptions = {};
											component.widget.minDate = null;
										}
									} else if (component.type === "columns") {
										Utils.eachComponent(
											component.columns,
											function (component2) {
												if (component2.input && component2.defaultValue) {
													if (component2.type === "textarea") {
														component2.defaultValue = component2.defaultValue.toString();
													}
													if (component2.type === "textfield" && component2.defaultValue) {
														component2.defaultValue = form.settings.data[component2.key] ? form.settings.data[component2.key]?.toString() : component2.defaultValue;
													}
													if (component2.type === "datetime" && component2.defaultValue) {
														component2.customOptions = {};
														component2.widget.minDate = null;
													}
												}
											},
											true
										);
									}
								},
								true
							);
							dataRenderedRef.current = true;
							setDataRendered(true);
						}
					}
				}
			} catch (e) {
				console.error("Error during afterRefresh:", e);

				// db.delete().then(() => {
				//     window.location.reload();
				// })
			}
		}
		if (!toPDF) {
			afterRefresh();
		}
	}, [form]);

	// //take latest form updates from formio scripts
	useEffect(() => {
		if (formioFormRef.current && formLoaded && dataRendered && initializing) {
			for (let i = 0; i < form.components.length; i++) {
				if (form.components[i].components !== null && form.components[i].components !== undefined) {
					form.components[i] = formioFormRef.current.components[i].component;
					if (form.components[i].skip === null || form.components[i].skip === undefined) {
						form.components[i].skip = false;
					} else {
						if (form.components[i].skip === true) {
							Utils.eachComponent(
								form.components[i].components,
								function (component) {
									if (component.input) {
										component.validate.required = false;
									} else if (component.type === "columns") {
										Utils.eachComponent(
											form.components[i].columns,
											function (component2) {
												if (component2.input) {
													component2.validate.required = false;
												}
											},
											true
										);
									}
								},
								true
							);
						}
					}
				}
			}

			form.components = form.components.filter((p) => p.components !== null && p.components !== undefined);
			setMyForm({ ...form });
			setInitializing(false);
		}
		//	if(formioFormRef.current){
		//console.log("current",formioFormRef.current._form.settings.data.buttonNext);
		//	setLabelNextButton(formioFormRef.current._form.settings.data.buttonNext);
		//	}
	}, [formioFormRef.current, formLoaded, dataRendered]);
	//save updated form data along with current form page in indexedDB
	const updateFormDataInDB = async (currentPage, e) => {
		if (form && form._id && language !== "" && dataRendered && !isSubmitting) {
			var myData = await db.temp_form_data.toArray();
			let data = myData[0] === undefined ? {} : JSON.parse(myData[0].tempFormData);
			if (data !== undefined) {
				if (e) {
					data = mergeJSONObjects(data, e.data);
				}
			}

			data["formId"] = form._id;
			data["processInstanceId"] = form?.processInstanceId;
			data["recordInTableId"] = form?.executionLocalVariables?.recordInTableId;
			if (currentPage !== -1) {
				data["pageNumber"] = currentPage;
			}

			let newFormData = JSON.stringify(data);
			if (newFormData !== "{}") {
				await db.table("temp_form_data").clear();
				await db.temp_form_data.add({ tempFormData: newFormData });
			}
		}
	};

	useEffect(() => {
		if(nextPageLocal===true){
			setCurrentPage(parseInt(form.skipToStep)-1);
			setMyForm(state);
			setNextPageLocal(false);
		}
		if (currentPage !== -1 && formioFormRef.current) {
			localStorage.setItem("formPage", currentPage);

			formioFormRef.current.setPage(currentPage);
			updateFormDataInDB(currentPage);
		}
	}, [currentPage, formioFormRef.current]);

	useEffect(() => {
		if (currentPage !== -1 && form && form.components) {
			let nextButtonLabel = form.components[currentPage]?.properties?.nextButton || "Next";
			let submitButtonLabel = form.components[currentPage]?.properties?.submitButton || "Submit";
			let notificationMessages = form.components[currentPage]?.properties?.notificationNextButton || "null";
			setNotificationNextButton(notificationMessages);
			setLabelNextButton(nextButtonLabel);
			setLabelSubmitButton(submitButtonLabel);
		}
	}, [currentPage, form]);

	const submitFunction = async () => {
		const isValid = formioFormRef.current.checkValidity(formioFormRef.current.submission.data, true, formioFormRef.current.submission.data);

		if (!isValid) {
			// If not valid, display error messages
			formioFormRef.current.showErrors();
			toast.error(t("Please correct the errors before submitting."), {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 3000,
			});
			return; // Stop the function from proceeding
		}
		setIsSubmitting(true);
		await db.table("temp_form_data").clear();

		Utils.eachComponent(
			form.components,
			function (component) {
				if (component.input) {
					let componentKey = component.key;
					if (formioFormRef.current._form.settings.notifications) {
						let toBeNotified = formioFormRef.current._form.settings.notifications.findIndex((f) => f.fieldApiKey === componentKey) >= 0 && component.disabled === false;
						if (toBeNotified) {
							let valueOfComponent = formioFormRef.current._submission.data[component.key];
							let details = formioFormRef.current._form.settings.notifications.filter((f) => f.fieldApiKey === componentKey)[0];
							if (details.singleSubmissionNotification || details.fieldSubmissionNotifications) {
								let isErrorNotification =
									details.singleSubmissionNotification?.isErrorNotification ||
									details.fieldSubmissionNotifications?.filter((c) => c.fieldValue + "" === valueOfComponent + "")[0]?.isErrorNotification ||
									false;
								let message =
									details.singleSubmissionNotification.trim() !== ""
										? details.singleSubmissionNotification.trim().indexOf("{{value}}") >= 0
											? details.singleSubmissionNotification.trim().split("{{value}}")
											: details.singleSubmissionNotification.trim()
										: details.fieldSubmissionNotifications.filter((c) => c.fieldValue + "" === valueOfComponent + "")[0]?.fieldSubmissionNotification;
								if (message) {
									let isSplitted = details.singleSubmissionNotification.trim() !== "" ? true : false;
									let translatedMessage = "";
									if (isSplitted) {
										translatedMessage = t(message[0].trim()) + " " + (valueOfComponent.label || valueOfComponent) + " " + t(message[1].trim());
									} else {
										translatedMessage = t(message);
									}
									if (isErrorNotification) {
										toast.error(translatedMessage, {
											position: toast.POSITION.TOP_RIGHT,
											autoClose: 3000,
										});
									} else {
										toast.success(translatedMessage, {
											position: toast.POSITION.TOP_RIGHT,
											autoClose: 3000,
										});
									}
								}
							}
						}
					}
					if (
						component.type === "datetime" &&
						component.properties.type === "date_of_submission" && component.disabled===false &&
						(formioFormRef.current._submission.data[component.key] === null ||
							formioFormRef.current._submission.data[component.key] === "" ||
							formioFormRef.current._submission.data[component.key] === undefined)
					) {
						component.defaultValue = moment().format("YYYY-MM-DD HH:mm:ss").toString();
						formioFormRef.current._submission.data[component.key] = moment().format("YYYY-MM-DD HH:mm:ss").toString();
					}
					if(component.type === "userGeolocation" && component.properties.type === "pick_on_submission"){
						navigator.geolocation.watchPosition((position) => {
							component.defaultValue={latitude:position.coords.latitude,longitude:position.coords.longitude};		
							formioFormRef.current._submission.data[component.key] ={latitude:position.coords.latitude,longitude:position.coords.longitude};						
						});
						
					}
				} else if (component.type === "columns") {
					Utils.eachComponent(
						component.columns,
						function (component2) {
							if (component2.input) {
								let componentKey = component2.key;
								if (formioFormRef.current._form.settings.notifications) {
									let toBeNotified = formioFormRef.current._form.settings.notifications.findIndex((f) => f.fieldApiKey === componentKey) >= 0 && component2.disabled === false;
									if (toBeNotified) {
										let valueOfComponent = formioFormRef.current._submission.data[component2.key];
										let details = formioFormRef.current._form.settings.notifications.filter((f) => f.fieldApiKey === componentKey)[0];
										if (details.singleSubmissionNotification || details.fieldSubmissionNotifications) {
											let isErrorNotification =
												details.singleSubmissionNotification?.isErrorNotification ||
												details.fieldSubmissionNotifications?.filter((c) => c.fieldValue + "" === valueOfComponent + "")[0]?.isErrorNotification ||
												false;
											let message =
												details.singleSubmissionNotification.trim() !== ""
													? details.singleSubmissionNotification.trim().indexOf("{{value}}") >= 0
														? details.singleSubmissionNotification.trim().split("{{value}}")
														: details.singleSubmissionNotification.trim()
													: details.fieldSubmissionNotifications.filter((c) => c.fieldValue + "" === valueOfComponent + "")[0]?.fieldSubmissionNotification;
											if (message) {
												let isSplitted = details.singleSubmissionNotification.trim() !== "" ? true : false;
												let translatedMessage = "";
												if (isSplitted) {
													translatedMessage = t(message[0].trim()) + " " + (valueOfComponent.label || valueOfComponent) + " " + t(message[1].trim());
												} else {
													translatedMessage = t(message);
												}
												if (isErrorNotification) {
													toast.error(translatedMessage, {
														position: toast.POSITION.TOP_RIGHT,
														autoClose: 3000,
													});
												} else {
													toast.success(translatedMessage, {
														position: toast.POSITION.TOP_RIGHT,
														autoClose: 3000,
													});
												}
											}
										}
									}
								}
								if (
									component2.type === "datetime" &&
									component2.properties.type === "date_of_submission" && component2.disabled===false &&
									(formioFormRef.current._submission.data[component2.key] === null ||
										formioFormRef.current._submission.data[component2.key] === "" ||
										formioFormRef.current._submission.data[component2.key] === undefined)
								) {
									component2.defaultValue = moment().format("YYYY-MM-DD HH:mm:ss").toString();
									formioFormRef.current._submission.data[component2.key] = moment().format("YYYY-MM-DD HH:mm:ss").toString();
								}
								if(component2.type === "userGeolocation" && component2.properties.type === "pick_on_submission"){
									navigator.geolocation.watchPosition((position) => {
									component2.defaultValue={latitude:position.coords.latitude,longitude:position.coords.longitude};	
									formioFormRef.current._submission.data[component2.key] ={latitude:position.coords.latitude,longitude:position.coords.longitude};
									});	
								}
							}
						},
						true
					);
				}
			},
			true
		);
		if (formioFormRef.current._form.settings.notificationsOnSubmit) {
			Utils.eachComponent(
				form.components,
				function (component) {
					if (component.input && component.disabled === false) {
						let index = formioFormRef.current._form.settings.notificationsOnSubmit.findIndex((c) => c.fieldKey === component.key);
						if (index >= 0) {
							toast.success(t(formioFormRef.current._form.settings.notificationsOnSubmit[index].notifications), {
								position: toast.POSITION.TOP_RIGHT,
								autoClose: 3000,
							});
						}
					} else if (component.type === "columns") {
						Utils.eachComponent(
							component.columns,
							function (component2) {
								if (component2.input && component2.disabled === false) {
									let index = formioFormRef.current._form.settings.notificationsOnSubmit.findIndex((c) => c.fieldKey === component2.key);
									if (index >= 0) {
										toast.success(t(formioFormRef.current._form.settings.notificationsOnSubmit[index].notifications), {
											position: toast.POSITION.TOP_RIGHT,
											autoClose: 3000,
										});
									}
								}
							},
							true
						);
					}
				},
				true
			);
		}
		props.onSubmit(formioFormRef.current._submission);
	};

	return (
		<>
			<a href="#" dir={i18n.language === "ar" ? "ltr" : "rtl"} style={{ fontSize: "inherit", fontFamily: "Cairo-Regular" }} onClick={() => navigate(-1)}>
				{t("Back to Previous page")}
			</a>

			{(form?.display === null || form?.display === undefined || initializing) && <LoaderIcon />}
			{withWizard !== false && (
				<WizardStepper
					currentPage={currentPage}
					myForm={myForm}
					setCurrentPage={setCurrentPage}
					parentStep={parentStep}
					setParentStep={setParentStep}
					formKey={formKey}
					language={language}
					formioFormRef={formioFormRef}
					setFormKey={setFormKey}
					readyState={true}
					resources={i18n.store.data[i18n.resolvedLanguage]?.translations}
					form={form}
					navigateIntoPage={navigateIntoPage}
					nextPage={nextPage}
				/>
			)}

			<div>
				{form && form._id && language !== "" && dataRendered && (
					<Form
						form={form}
						onRender={(e) => {
							//on error list click
							if (e.page !== null && e.page !== undefined && formioFormRef.current && formioFormRef.current.alert) {
								setNavigateIntoPage(e.page);
							}
						}}
						onChange={(e) => {
							try {
								if (!toPDF) {
									if (e.changed) {
										updateFormDataInDB(-1, e);
									}
								}
							} catch (e) {
								console.error("Error during save data:", e);
							}
						}}
						onFormLoad={() => {}}
						formReady={(finalForm) => {
							formioFormRef.current = finalForm;
							setFormLoaded(true);
						}}
						options={{
							i18n: {
								...resources,
							},
						}}
					/>
				)}
			</div>
			{currentPage + 1 == form?.components?.filter((p) => p.id !== null && p.id !== undefined && p.hidden === false).length &&
				form?.settings?.readOnly !== true &&
				form?.settings?.toEdit === false && (
					<div
						className={classNames("w-100", {
							"justify-content-start": i18n.language === "ar",
							"justify-content-end": i18n.language !== "ar",
						})}
					>
						<button
							style={{ width: "max-content" }}
							className="btn btn-primary"
							onClick={(e) => {
								submitFunction();
							}}
						>
							{t(labelSubmitButton)}
						</button>
					</div>
				)}

			{withWizard !== false && currentPage + 1 < form?.components?.filter((p) => p.id !== null && p.id !== undefined && p.hidden === false).length && (
				/*add next button to navigate between pages*/
				<div
					className={classNames("w-100", {
						"justify-content-start": i18n.language === "ar",
						"justify-content-end": i18n.language !== "ar",
					})}
				>
					<button
						style={{ width: "max-content" }}
						className="btn btn-primary"
						onClick={(e) => {
							Utils.eachComponent(
								form.components,
								function (component) {
									if (component.input) {
										if (component.type === "datetime" && component.properties.type === "date_of_next" &&component.disabled===false && form?.settings?.readOnly !== true && form?.settings?.toEdit === false) {
											component.defaultValue = moment().format("YYYY-MM-DD HH:mm:ss").toString();
											formioFormRef.current._submission.data[component.key] = moment().format("YYYY-MM-DD HH:mm:ss").toString();
										}
										if(component.type === "userGeolocation" && component.properties.type === "pick_on_next"){
											navigator.geolocation.watchPosition((position) => {
												component.defaultValue={latitude:position.coords.latitude,longitude:position.coords.longitude};		
												formioFormRef.current._submission.data[component.key] ={latitude:position.coords.latitude,longitude:position.coords.longitude};						
											});
											
										}
									} else if (component.type === "columns") {
										Utils.eachComponent(
											component.columns,
											function (component2) {
												if (component2.input) {
													if (component2.type === "datetime" && component2.properties.type === "date_of_next" &&component2.disabled===false && form?.settings?.readOnly !== true && form?.settings?.toEdit === false) {
														component2.defaultValue = moment().format("YYYY-MM-DD HH:mm:ss").toString();
														formioFormRef.current._submission.data[component2.key] = moment().format("YYYY-MM-DD HH:mm:ss").toString();
													}
													if(component2.type === "userGeolocation" && component2.properties.type === "pick_on_next"){
														navigator.geolocation.watchPosition((position) => {
														component2.defaultValue={latitude:position.coords.latitude,longitude:position.coords.longitude};	
														formioFormRef.current._submission.data[component2.key] ={latitude:position.coords.latitude,longitude:position.coords.longitude};
														});	
													}

												}
											},
											true
										);
									}
								},
								true
							);

							Utils.eachComponent(
								form.components,
								function (component) {
									if (component.input) {
										let componentKey = component.key;
										if (formioFormRef.current._form.settings.notifications) {
											let toBeNotified = formioFormRef.current._form.settings.notifications.findIndex((f) => f.fieldApiKey === componentKey) >= 0 && component.disabled === false;
											if (toBeNotified) {
												if (form?.settings?.readOnly !== true && form?.settings?.toEdit === false) {
													if (notificationNextButton != "null") {
														formioFormRef.current._form.settings.notifications.forEach((notification) => {
															notification.fieldSubmissionNotifications.forEach((fsn) => {
																const message = fsn.fieldSubmissionNotification || "No message";
																if (fsn.isErrorNotification) {
																	toast.error(t(message), {
																		position: toast.POSITION.TOP_RIGHT,
																		autoClose: 3000,
																	});
																} else {
																	toast.success(t(message), {
																		position: toast.POSITION.TOP_RIGHT,
																		autoClose: 3000,
																	});
																}
															});
														});
													}
												}
											}
										}
									} else if (component.type === "columns") {
										Utils.eachComponent(
											component.columns,
											function (component2) {
												if (component2.input) {
													let componentKey = component2.key;
													if (formioFormRef.current._form.settings.notifications) {
														let toBeNotified = formioFormRef.current._form.settings.notifications.findIndex((f) => f.fieldApiKey === componentKey) >= 0 && component2.disabled === false;
														if (toBeNotified) {
															if (form?.settings?.readOnly !== true && form?.settings?.toEdit === false) {
																if (notificationNextButton != "null") {
																	formioFormRef.current._form.settings.notifications.forEach((notification) => {
																		notification.fieldSubmissionNotifications.forEach((fsn) => {
																			const message = fsn.fieldSubmissionNotification || "No message";
																			if (fsn.isErrorNotification) {
																				toast.error(t(message), {
																					position: toast.POSITION.TOP_RIGHT,
																					autoClose: 3000,
																				});
																			} else {
																				toast.success(t(message), {
																					position: toast.POSITION.TOP_RIGHT,
																					autoClose: 3000,
																				});
																			}
																		});
																	});
																}
															}
														}
													}
												}
											},
											true
										);
									}
								},
								true
							);

							let totalNumberOfPages = form.components.filter((p) => p.id !== null && p.id !== undefined && p.hidden === false).length;
							let newPage = currentPage + 1;
							if (form.components[newPage] && form.components[newPage].skip === true) {
								newPage += 1;
							}
							if (newPage < totalNumberOfPages) {
								setCurrentPage(newPage);
								window.scrollTo(0, 0);
							}
						}}
					>
						{t(labelNextButton)}
					</button>
				</div>
			)}
		</>
	);
};
export default Formioform;
