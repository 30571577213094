import React, { useEffect, useState, useContext } from "react";

import PropTypes from "prop-types";
import AppContext from "context/Context";
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Col, Modal, Row, Button, Image } from "react-bootstrap";
import IconButton from "components/common/IconButton";
import * as Constants from "common/constants";
import * as General from "common/general";
import Loader from "components/custom/Loader";
import { useTranslation } from "react-i18next";

const DeleteRelatedCompanyConfirmation = ({ company, showComponent, hideDeleteConfirmation, successCallBackFunction, LoggedInCompanyID, tblCompanie }) => {
	const [errorContainerVisiblity, showErrorContainer] = useState(false);
	const [loaderContainerVisibility, showLoaderContainer] = useState(false);
	const [buttonsVisibility, showHideButtons] = useState(true);

	const [RelatedCompanytypeName, setRelatedCompanytypeName] = useState("");
	const [RelatedCompanytypeIcon, setRelatedCompanytypeIcon] = useState("");
	const [RelatedCompanytypeID, setRelatedCompanytypeID] = useState("");

	const [companyID, setCompanyID] = useState("");
	const [tblCompanieReceived, setTblComapanieReceived] = useState("");

	const [t, i18n] = useTranslation();

	//first function that's called
	useEffect(() => {
		if (tblCompanie === "Facility") {
			setTblComapanieReceived("tbl_company_facility_relationship");
			//   setCompanyID(RelatedCompany.facilityCompanyId);
		} else {
			setTblComapanieReceived("tbl_company_transporter_relationship");
			// setCompanyID(RelatedCompany.transporterCompanyId)
		}

		var companyInfo = General.getCompanyTypeInfo(company.CompanyTypeID);
		setRelatedCompanytypeID(company.generatorCompanyId);
		// setRelatedCompanytypeIcon(companyInfo.icon);
		// setRelatedCompanytypeName(companyInfo.name);
	}, []);

	const handleCancelDelete = () => {
		hideDeleteConfirmation();
	};

	const handleConfirmDelete = async () => {
		showLoaderContainer(true);
		showHideButtons(false);
		showErrorContainer(false);

		const DeleteRelatedCompanySuccess = (responseObject) => {
			var isSuccess = false;
			if (responseObject.status == Constants.SuccessStatus) {
				isSuccess = true;
				//General.SaveToken(responseObject);
				handleCancelDelete();

				successCallBackFunction("true");
				//if (responseObject.data != null) {
				//    if (responseObject.data.token != null) {
				//        if (responseObject.data.token.accessToken != "") {
				//        }
				//    }
				//}
			}

			if (isSuccess == false) {
				showErrorContainer(true);
				setTimeout(function () {
					var errorContainer = document.getElementById("errorContainer");
					if (responseObject.message != null) {
						if (responseObject.message != "") {
							errorContainer.innerHTML = responseObject.message;
						}
					}
					if (errorContainer.innerHTML == "") {
						errorContainer.innerHTML = "An error occured, please try again later.";
					}
					showLoaderContainer(false);
					showHideButtons(true);
				}, 1000);
			} else {
				showErrorContainer(false);
				showLoaderContainer(false);
				showHideButtons(true);
			}
		};

		const DeleteRelatedCompanyFailure = (error) => {
			showErrorContainer(true);
			setTimeout(function () {
				var errorContainer = document.getElementById("errorContainer");
				if (errorContainer.innerHTML == "") {
					errorContainer.innerHTML = "An error occured, please try again later. " + error;
				}
				showLoaderContainer(false);
				showHideButtons(true);
			}, 1000);
		};

		var deleteEndpoint = Constants.DeleteDBEndPoint.replace("#TABLENAME#", tblCompanieReceived).replace("#ITEMID#", company.id);

		General.DeleteFromEndPoint(deleteEndpoint, null, null, DeleteRelatedCompanySuccess, DeleteRelatedCompanyFailure);
	};

	const {
		config: { isRTL },
	} = useContext(AppContext);

	return (
		<Modal show={showComponent} onHide={handleCancelDelete} size="lg">
			<Modal.Header closeButton className="border-200">
				<Modal.Title as="h5">
					<Flex alignItems="center">
						<div className="icon-item bg-soft-success shadow-none">
							<FontAwesomeIcon icon={RelatedCompanytypeIcon} className="text-success" />
						</div>
						<div className="ms-2">{t(`Remove Related ${RelatedCompanytypeName}`)}</div>
					</Flex>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row className="g-0 align-items-center">
					<Col>
						<div className="mb-3">
							{t(`Are you sure you want to remove this related ${RelatedCompanytypeName}?`)}
							<br /> <br />
							{t(`${RelatedCompanytypeName} name`)}: <strong>{company.transporterCompanyId}</strong>
							<br />
							{t("Contract Number")}: <strong>{company.contractNumber}</strong>
							<br />
						</div>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer className="border-200">
				{buttonsVisibility && (
					<div>
						<Button onClick={handleCancelDelete} size="sm" variant="secondary">
							{t("No")}
						</Button>
						<IconButton onClick={handleConfirmDelete} icon="" size="sm" className="ms-2" variant="danger">
							{t("Yes")}
						</IconButton>
					</div>
				)}

				{loaderContainerVisibility && <Loader></Loader>}

				{errorContainerVisiblity && <div id="errorContainer" className="loginAlert popupAlert"></div>}
			</Modal.Footer>
		</Modal>
	);
};

DeleteRelatedCompanyConfirmation.propTypes = {
	company: PropTypes.object,
	showComponent: PropTypes.bool,
};

export default DeleteRelatedCompanyConfirmation;
