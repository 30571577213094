import baseEditForm from "formiojs/components/_classes/component/Component.form";

export default (...extend) => {
  return baseEditForm(
    [
      {
        label: "Display",
        key: "display",
        weight: 0,
        components: [],
      },
      {
        label: "Data",
        key: "data",
        weight: 20,
        components: [
          {
            type: "checkbox",
            label: "Local Data",
            key: "isLocal",
            input: true,
            inline: true,
          },
          {
            type: "textfield",
            label: "Field Local Path",
            key: "fieldLocalPath",
            input: true,
            inline: true,
          },
          {
            type: "textfield",
            label: "Request Url",
            key: "requestUrl",
            input: true,
            inline: true,
          },
          {
            type: "textfield",
            label: "Data Path",
            key: "dataPath",
            input: true,
            inline: true,
          },
          {
            type: "textfield",
            label: "json field",
            key: "jsonfields",
            input: true,
            inline: true,
          },
          {
            type: "textfield",
            label: "json field second",
            key: "jsonfieldssecond",
            input: true,
            inline: true,
          },
          {
            type: "editgrid",
            label: "Query Parameters from Form Data",
            key: "queryParamsFromForm",
            templates: {
              header:
                '<div class="row">\n      {% util.eachComponent(components, function(component) { %}\n        {% if (displayValue(component)) { %}\n          <div class="col-sm-4">{{ t(component.label) }}</div>\n        {% } %}\n      {% }) %}\n    </div>',
              tableHeader:
                '\n      <tr>\n        {% util.eachComponent(components, function(component) { %}\n          {% if (!component.hasOwnProperty(\'tableView\') || component.tableView) { %}\n            <td class="editgrid-table-column">{{ component.label }}</td>\n          {% } %}\n        {% }) %}\n        {% if (!instance.options.readOnly && !instance.disabled) { %}\n          <td class="editgrid-table-column">Actions</td>\n        {% } %}\n      </tr>\n    ',
              row: '<div class="row">\n      {% util.eachComponent(components, function(component) { %}\n        {% if (displayValue(component)) { %}\n          <div class="col-sm-4">\n            {{ isVisibleInRow(component) ? getView(component, row[component.key]) : \'\'}}\n          </div>\n        {% } %}\n      {% }) %}\n      {% if (!instance.options.readOnly && !instance.disabled) { %}\n        <div class="col-sm-4">\n          <div class="btn-group pull-right">\n            <button class="btn btn-default btn-light btn-sm editRow"><i class="{{ iconClass(\'edit\') }}"></i></button>\n            {% if (!instance.hasRemoveButtons || instance.hasRemoveButtons()) { %}\n              <button class="btn btn-danger btn-sm removeRow"><i class="{{ iconClass(\'trash\') }}"></i></button>\n            {% } %}\n          </div>\n        </div>\n      {% } %}\n    </div>',
              tableRow:
                '\n      {% util.eachComponent(components, function(component) { %}\n          {% if (!component.hasOwnProperty(\'tableView\') || component.tableView) { %}\n            <td class="editgrid-table-column">\n              {{ getView(component, row[component.key]) }}\n            </td>\n          {% } %}\n        {% }) %}\n        {% if (!instance.options.readOnly && !instance.disabled) { %}\n          <td class="editgrid-table-column">\n            <div class="btn-group">\n              <button class="btn btn-default btn-light btn-sm editRow" aria-label="{{ t(\'Edit row\') }}"><i class="{{ iconClass(\'edit\') }}"></i></button>\n              {% if (!instance.hasRemoveButtons || instance.hasRemoveButtons()) { %}\n              <button class="btn btn-danger btn-sm removeRow" aria-label="{{ t(\'Remove row\') }}"><i class="{{ iconClass(\'trash\') }}"></i></button>\n              {% } %}\n            </div>\n          </td>\n        {% } %}\n    ',
              footer: "",
            },
            input: true,
            inline: true,
            components: [
              {
                type: "columns",
                label: "Data",
                key: "columns",
                input: true,
                inline: true,
                columns: [
                  {
                    currentWidth: 6,
                    width: 6,
                    components: [
                      {
                        type: "textfield",
                        label: "Field Path",
                        key: "queryParamsFieldPath",
                        input: true,
                        inline: true,
                        validate: {
                          required: true,
                        },
                      },
                    ],
                  },

                  {
                    currentWidth: 6,
                    width: 6,
                    components: [
                      {
                        type: "textfield",
                        label: "Field Name",
                        key: "queryParamsFieldName",
                        input: true,
                        inline: true,
                        validate: {
                          required: true,
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: "checkbox",
            label: "Hide CheckBox",
            key: "isHidden",
            input: true,
            inline: true,
          },
          {
            "type": "columns",
            "columns": [
              {
                "components": [
                  {
                    "type": "textfield",
                    "label": "Field Condition checkBox",
                    "key": "fieldConditionCheckBox",
                    "input": true
                  }
                ],
                "width": 4
              },
              {
                "components": [
                  {
                    "type": "select",
                    "label": "Operator Condition",
                    "key": "operatorCondition",
                    "input": true,
                    "data": {
                      "values": [
                        {
                          "label": ">",
                          "value": ">"
                        },
                        {
                          "label": "<",
                          "value": "<"
                        },
                        {
                          "label": "=",
                          "value": "="
                        }
                      ]
                    }
                  }
                ],
                "width": 4
              },
              {
                "components": [
                  {
                    "type": "textfield",
                    "label": "Value Condition checkBox",
                    "key": "valueConditionCheckBox",
                    "input": true
                  }
                ],
                "width": 4
              }
            ]
          },

          {
            type: "checkbox",
            label: "Hide pagination",
            key: "isHiddenPagination",
            input: true,
            inline: true,
          },
          {
            "type": "textfield",
            "label": "style Table",
            "key": "styleTable",
            "input": true
          },
          {
            type: "editgrid",
            label: "Fields",
            key: "columns",
            input: true,
            inline: true,
            templates: {
              header:
                '<div class="row">\n      {% util.eachComponent(components, function(component) { %}\n        {% if (displayValue(component)) { %}\n          <div class="col-sm-4">{{ t(component.label) }}</div>\n        {% } %}\n      {% }) %}\n    </div>',
              tableHeader:
                '\n      <tr>\n        {% util.eachComponent(components, function(component) { %}\n          {% if (!component.hasOwnProperty(\'tableView\') || component.tableView) { %}\n            <td class="editgrid-table-column">{{ component.label }}</td>\n          {% } %}\n        {% }) %}\n        {% if (!instance.options.readOnly && !instance.disabled) { %}\n          <td class="editgrid-table-column">Actions</td>\n        {% } %}\n      </tr>\n    ',
              row: '<div class="row">\n      {% util.eachComponent(components, function(component) { %}\n        {% if (displayValue(component)) { %}\n          <div class="col-sm-4">\n            {{ isVisibleInRow(component) ? getView(component, row[component.key]) : \'\'}}\n          </div>\n        {% } %}\n      {% }) %}\n      {% if (!instance.options.readOnly && !instance.disabled) { %}\n        <div class="col-sm-4">\n          <div class="btn-group pull-right">\n            <button class="btn btn-default btn-light btn-sm editRow"><i class="{{ iconClass(\'edit\') }}"></i></button>\n            {% if (!instance.hasRemoveButtons || instance.hasRemoveButtons()) { %}\n              <button class="btn btn-danger btn-sm removeRow"><i class="{{ iconClass(\'trash\') }}"></i></button>\n            {% } %}\n          </div>\n        </div>\n      {% } %}\n    </div>',
              tableRow:
                '\n      {% util.eachComponent(components, function(component) { %}\n          {% if (!component.hasOwnProperty(\'tableView\') || component.tableView) { %}\n            <td class="editgrid-table-column">\n              {{ getView(component, row[component.key]) }}\n            </td>\n          {% } %}\n        {% }) %}\n        {% if (!instance.options.readOnly && !instance.disabled) { %}\n          <td class="editgrid-table-column">\n            <div class="btn-group">\n              <button class="btn btn-default btn-light btn-sm editRow" aria-label="{{ t(\'Edit row\') }}"><i class="{{ iconClass(\'edit\') }}"></i></button>\n              {% if (!instance.hasRemoveButtons || instance.hasRemoveButtons()) { %}\n              <button class="btn btn-danger btn-sm removeRow" aria-label="{{ t(\'Remove row\') }}"><i class="{{ iconClass(\'trash\') }}"></i></button>\n              {% } %}\n            </div>\n          </td>\n        {% } %}\n    ',
              footer: "",
            },
            components: [
              {
                type: "columns",
                label: "Data",
                key: "columns",
                input: true,
                inline: true,
                columns: [
                  {
                    currentWidth: 6,
                    width: 6,
                    components: [
                      {
                        type: "textfield",
                        label: "Field Path",
                        key: "key",
                        input: true,
                        inline: true,

                      },
                    ],
                  },
                  {
                    currentWidth: 6,
                    width: 6,
                    components: [
                      {
                        type: "textfield",
                        label: "Field Name",
                        key: "label",
                        input: true,
                        inline: true,
                        validate: {
                          required: true,
                        },
                      },
                    ],
                  },
                  {
                    currentWidth: 4,
                    width: 4,
                    components: [
                      {
                        type: "checkbox",
                        label: "editable",
                        key: "isEditable",
                        input: true,
                        inline: true,
                      },
                    ],
                  },
                  {
                    currentWidth: 4,
                    width: 4,
                    components: [
                      {
                        type: "checkbox",
                        label: "Validation",
                        key: "isValidation",
                        input: true,
                        inline: true,
                      },
                    ],
                  },
                  {
                    currentWidth: 4,
                    width: 4,
                    components: [
                      {
                        type: "checkbox",
                        label: "Hidden",
                        key: "isHiddenfields",
                        input: true,
                        inline: true,
                      },
                    ],
                  },
                  {
                    "components": [
                      {
                        type: "select",
                        label: "Type Condition",
                        key: "typeCondition",
                        input: true,
                        data: {
                          values: [
                            {
                              label: "Text",
                              value: "text"
                            },
                            {
                              label: "Number",
                              value: "number"
                            },
                            
                          ]
                        }
                      }
                    ],
                    width: 4
                  },
                  {
                    currentWidth: 4,
                    width: 4,
                    components: [
                      {
                        type: "textfield",
                        label: "First Condition",
                        key: "fisrtCondition",
                        input: true,
                        inline: true,

                      },
                    ],
                  },
                  {
                    currentWidth: 4,
                    width: 4,
                    components: [
                      {
                        type: "textfield",
                        label: "Second Condition",
                        key: "secondCondition",
                        input: true,
                        inline: true,

                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        label: "Validation",
        key: "validation",
        weight: 20,
        components: [],
      },
      {
        label: "API",
        key: "api",
        weight: 30,
        components: [],
      },
      {
        label: "Logic",
        key: "logic",
        weight: 50,
        components: [],
      },
      {
        label: "Layout",
        key: "layout",
        weight: 60,
        components: [],
      },
    ],
    ...extend
  );
};
