import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "components/common/Flex";
import SoftBadge from "components/common/SoftBadge";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { getCountTaskByAssignee,getWorkflowListByGroups,getWorkflowListByUsers } from "components/workflow/WorkflowHelper";
import * as Constants from "common/constants";
import { EncryptStorage } from "encrypt-storage";
import { useDispatch, useSelector, connect } from "react-redux";
import store from "reducers/store";
import { SET_COUNT } from "reducers/flagReducer";
import { SET_COUNT_UNASSIGNED } from "reducers/flagUnassignedReducer";

const NavbarVerticalMenuItem = ({ route }) => {
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});

	const [countWorkflows, setCountWorkflows] = useState(0);

	const [countUnassignedWorkflows, setCountUnassignedWorkflows] = useState(0);
	const [initialCounterData, setInitialCounterData] = useState();

	let previousCounterData = store.getState().counter.data; // for previous state comparison
	let previousCounterDataUnassigned = store.getState().counterUnassigned.data; // for previous state comparison
	store.subscribe(() => {
		const currentCounterData = store.getState().counter.data; // for previous state comparison
		const currentCounterDataUnassigned = store.getState().counterUnassigned.data; // for previous state comparison
		//console.log(currentCounterData);

		if (currentCounterData !== previousCounterData) {
			if (route.to === "/userTasks") {
				setCountWorkflows(store.getState().counter.data);
			}
			previousCounterData = currentCounterData;
		}
		if (currentCounterDataUnassigned !== previousCounterDataUnassigned) {
			if (route.to === "/unassignedTasks") {
				setCountUnassignedWorkflows(store.getState().counterUnassigned.data);
			}
			previousCounterDataUnassigned = currentCounterDataUnassigned;
		}
	});

	useEffect(() => {
		if (route.to === "/userTasks") {
			getCountTaskByAssignee(encryptStorage1.getItem(Constants.LoggedInUserInfoKeyInLocalStorage).userId)
				.then((workflowCount) => {
					setCountWorkflows(workflowCount.count);

					store.dispatch({ type: SET_COUNT, payload: { data: workflowCount.count } });
				})
				.catch((error) => {
					console.log(error);
				});
		}
		if(route.to === "/unassignedTasks"){
			let totalCount=0;
			const companyId = encryptStorage1.getItem(Constants.SelectedCompany);
			const userInfo = encryptStorage1.getItem(Constants.LoggedInUserInfoKeyInLocalStorage);
			const loggedInUser = userInfo.userId;
			getWorkflowListByGroups(companyId)
			.then((workflowCountunassigned) => {
				setCountUnassignedWorkflows(workflowCountunassigned.workflowsByAssigneeList.length);
				totalCount+=workflowCountunassigned.workflowsByAssigneeList.length;
				//store.dispatch({ type: SET_COUNT_UNASSIGNED, payload: { data: workflowCountunassigned.workflowsByAssigneeList.length } });
			
			})
			.catch((error) => {
				console.log(error);
			});
			getWorkflowListByUsers(loggedInUser)
			.then((workflowCountunassigned) => {
				setCountUnassignedWorkflows(workflowCountunassigned.workflowsByAssigneeList.length);
				totalCount+=workflowCountunassigned.workflowsByAssigneeList.length
			//	store.dispatch({ type: SET_COUNT_UNASSIGNED, payload: { data: workflowCountunassigned.workflowsByAssigneeList.length } });
			
			})
			.catch((error) => {
				console.log(error);
			});

			store.dispatch({ type: SET_COUNT_UNASSIGNED, payload: { data: totalCount } });
		}
	}, []);

	library.add(fas);

	const [t, i18n] = useTranslation();
	return (
		<Flex alignItems="center">
			{route.icon && (
				<span className="nav-link-icon">
					<FontAwesomeIcon icon={route.icon} />
					{(route.to === "/userTasks" && countWorkflows > 0)  && (
						<span
							style={{
								position: "absolute",
								width: "0.5em",
								height: "0.5em",
								backgroundColor: "red",
								borderRadius: "50%",
							}}
						></span>
					)}
					{(route.to === "/unassignedTasks" && countUnassignedWorkflows > 0) && (
						<span
							style={{
								position: "absolute",
								width: "0.5em",
								height: "0.5em",
								backgroundColor: "red",
								borderRadius: "50%",
							}}
						></span>
					)}
				</span>
			)}
			<span className="nav-link-text ps-1">{t(route.name)}</span>
			{route.to == "/userTasks" && countWorkflows > 0 && (
				<span className="fa-layers fa 3x fa-fw" style={{ marginLeft: "1em" }}>
					<span className="fa-layers-counter fa-2x" style={{ minWidth: "2em", minHeight: "1.5em", fontSize: "2.5em" }}>
						{countWorkflows}
					</span>
				</span>
			)}
				{route.to == "/unassignedTasks" && countUnassignedWorkflows > 0 && (
				<span className="fa-layers fa 3x fa-fw" style={{ marginLeft: "1em" }}>
					<span className="fa-layers-counter fa-2x" style={{ minWidth: "2em", minHeight: "1.5em", fontSize: "2.5em" }}>
						{countUnassignedWorkflows}
					</span>
				</span>
			)}
			{route.badge && (
				<SoftBadge pill bg={route.badge.type} className="ms-2">
					{route.badge.text}
				</SoftBadge>
			)}
		</Flex>
	);
};

//connect(mapStateToProps)(NavbarVerticalMenuItem);
// prop-types
const routeShape = {
	active: PropTypes.bool,
	name: PropTypes.string.isRequired,
	to: PropTypes.string,
	icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};
routeShape.children = PropTypes.arrayOf(PropTypes.shape(routeShape));
NavbarVerticalMenuItem.propTypes = {
	route: PropTypes.shape(routeShape).isRequired,
};

export default React.memo(NavbarVerticalMenuItem);
