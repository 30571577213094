import { forwardRef, useImperativeHandle, useRef } from "react";
import { createPortal } from "react-dom";

const Modal = forwardRef(function Modal({ children, onClose }, ref) {
	const dialog = useRef();

	useImperativeHandle(ref, () => {
		return {
			open() {
				dialog.current.showModal();
			},
			close() {
				dialog.current.close();
			},
		};
	});
	// create Portal loads the div in another you can ad a certain div and give it an id
	return createPortal(
		<dialog style={{ width: "100%", position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} ref={dialog} className="p-0 rounded" onClose={onClose}>
			{children}
		</dialog>,
		// set where you want the model to show
		document.getElementById("modal-root")
	);
});

export default Modal;
