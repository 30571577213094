import React, { Component, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { ReactComponent, Utils } from "@formio/react";
import { createRoot } from "react-dom/client";
import { getRandomId } from "helpers/utils";

/*
This component is responsible to get the user location coordinates (longitude and latitude)
*/
const GeolocationComponent = ({ ...props }) => {
	useEffect(() => {
		let state = props.oldState;
		//if default value supported get the coordinates from it
		if (props.component.defaultValue && props.component.defaultValue !== "") {
			let details = {};
			if (props.component.defaultValue.latitude) {
				details = props.component.defaultValue;
			} else {
				details = JSON.parse(props.component.defaultValue);
			}
			props.oldState["latitude"] = details.latitude;
			props.oldState["longitude"] = details.longitude;
			props.setOldState(state);
		}
		//else get it using geolocation
		else if (navigator.geolocation) {
			navigator.geolocation.watchPosition((position) => {
				props.oldState["latitude"] = position.coords.latitude;
				props.oldState["longitude"] = position.coords.longitude;
				props.setOldState(state);
				if (props.form._form.settings && props.form._form.settings.toEdit !== true) {
					props.onChange(
						{
							latitude: position.coords.latitude,
							longitude: position.coords.longitude,
						},
						null
					);
				}
			});
		}
	}, []);
	//add page data in global form data
	useEffect(() => {
		if (props.data) {
			let keys = Object.keys(props.data);
			for (let i = 0; i < keys.length; i++) {
				props.form._data[keys[i]] = props.data[keys[i]];
				props.form._submission.data[keys[i]] = props.data[keys[i]];
			}
			if (props.insideGrid === true) {
				delete props.form._data[props.component.key];
				delete props.form._submission.data[props.component.key];
			}
		}
	}, [props.data]);

	return (
		<div className="row geolocationComponent">
			<div className="col-sm-6">
				<label>
					{" "}
					{props.form._form.settings && props.form._form.settings.translatedData
						? props.form._form.settings.translatedData[props.form._form.settings.language]
							? props.form._form.settings.translatedData[props.form._form.settings.language]["Longitude"] || "Longitude"
							: "Longitude"
						: "Longitude"}
				</label>
				<input type="text" className="form-control" value={props.oldState.longitude} disabled={true} />
			</div>
			<div className="col-sm-6">
				<label>
					{" "}
					{props.form._form.settings && props.form._form.settings.translatedData
						? props.form._form.settings.translatedData[props.form._form.settings.language]
							? props.form._form.settings.translatedData[props.form._form.settings.language]["Latitude"] || "Latitude"
							: "Latitude"
						: "Latitude"}
				</label>

				<input type="text" className="form-control" value={props.oldState.latitude} disabled={true} />
			</div>
		</div>
	);
};

export default class Geolocation extends ReactComponent {
	static shouldSetValue = false; // Define shouldSetValue as a static property

	/**
	 * This is the first phase of component building where the component is instantiated.
	 *
	 * @param component - The component definition created from the settings form.
	 * @param options - Any options passed into the renderer.
	 * @param data - The submission data where this component's data exists.
	 */
	constructor(component, options, data) {
		options["oldState"] = {
			longitude: -1,
			latitude: -1,
		};
		super(component, options, data);
		this.reactInstance = null;
	}

	/**
	 * This function is the default settings for the component. At a minimum you want to set the type to the registered
	 * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
	 *
	 * @param sources
	 * @returns {*}
	 */
	static schema(...extend) {
		return ReactComponent.schema({
			type: "geolocation",
			label: "",
		});
	}
	static get builderInfo() {
		return {
			title: "Geolocation",
			icon: "cubes",
			group: "Basic",
			documentation: "",
			weight: -10,
			schema: Geolocation.schema(),
		};
	}

	/**
	 * This method is called any time the component needs to be rebuilt. It is most frequently used to listen to other
	 * components using the this.on() function.
	 */
	init() {
		return super.init();
	}

	/**
	 * This method is called before the component is going to be destroyed, which is when the component instance is
	 * destroyed. This is different from detach which is when the component instance still exists but the dom instance is
	 * removed.
	 */
	destroy() {
		return super.destroy();
	}
	/**
	 * This method is called before a form is submitted.
	 * It is used to perform any necessary actions or checks before the form data is sent.
	 *
	 */

	beforeSubmit() {
		return super.beforeSubmit();
	}

	/**
	 * The second phase of component building where the component is rendered as an HTML string.
	 *
	 * @returns {string} - The return is the full string of the component
	 */
	render() {
		// For react components, we simply render as a div which will become the react instance.
		// By calling super.render(string) it will wrap the component with the needed wrappers to make it a full component.
		return super.render(`<div ref="react-${this.id}"></div>`);
	}

	/**
	 * Callback ref to store a reference to the node.
	 *
	 * @param element - the node
	 */
	setReactInstance(element) {
		this.reactInstance = element;
	}

	/**
	 * The third phase of component building where the component has been attached to the DOM as 'element' and is ready
	 * to have its javascript events attached.
	 *
	 * @param element
	 * @returns {Promise<void>} - Return a promise that resolves when the attach is complete.
	 */
	attach(element) {
		super.attach(element);
		let randomId = getRandomId();
		// The loadRefs function will find all dom elements that have the "ref" setting that match the object property.
		// It can load a single element or multiple elements with the same ref.
		this.loadRefs(element, {
			[`react-${randomId}`]: "single",
		});

		if (this.refs[`react-${randomId}`]) {
			this.attachReact(this.refs[`react-${randomId}`], this.setReactInstance.bind(this));
			if (this.shouldSetValue) {
				this.setValue(this.dataForSetting);
				this.updateValue(this.dataForSetting);
			}
		}
		return Promise.resolve();
	}

	// /**
	//  * The fourth phase of component building where the component is being removed from the page. This could be a redraw
	//  * or it is being removed from the form.
	//  */
	// detach() {
	//     if (this.refs[`react-${this.id}`]) {
	//         this.detachReact(this.refs[`react-${this.id}`]);
	//     }
	//     super.detach();
	// }

	/**
	 * Override this function to insert your custom component.
	 *
	 * @param element
	 * @param ref - callback ref
	 */
	attachReact(element, ref) {
		const rootForm = this.getRoot(); // Get the root form object
		let insideGrid = false;
		let key = this.component.key;
		const root = createRoot(element);

		Utils.eachComponent(
			rootForm.components,
			function (component) {
				if (component.component.type === "editgrid") {
					Utils.eachComponent(
						component.component.components,
						function (component2) {
							if (!insideGrid) {
								insideGrid = component2.key === key;
							}
						},
						true
					);
				}
			},
			true
		);
		const setOldState = (value) => {
			this.options["oldState"] = value;
			// this.updateOnChange({}, true);
			root.render(
				<GeolocationComponent
					component={this.component} // These are the component settings if you want to use them to render the component.
					onChange={this.updateValue} // Pass the onChange event handler
					value={this.dataValue}
					data={this.data}
					form={rootForm}
					insideGrid={insideGrid}
					setOldState={setOldState}
					oldState={this.options["oldState"]}
				/>
			);
		};
		root.render(
			<GeolocationComponent
				component={this.component} // These are the component settings if you want to use them to render the component.
				onChange={this.updateValue} // Pass the onChange event handler
				value={this.dataValue}
				data={this.data}
				form={rootForm}
				insideGrid={insideGrid}
				setOldState={setOldState}
				oldState={this.options["oldState"]}
			/>
		);
	}

	/**
	 * Override this function.
	 */
	detachReact(element) {
		return;
	}

	/**
	 * Something external has set a value and our component needs to be updated to reflect that. For example, loading a submission.
	 *
	 * @param value
	 */
	setValue(value) {
		if (this.reactInstance) {
			this.reactInstance.setState({
				value: value,
			});
			this.shouldSetValue = false;
		} else {
			this.shouldSetValue = true;
			this.dataForSetting = value;
		}
	}

	/**
	 * The user has changed the value in the component and the value needs to be updated on the main submission object and other components notified of a change event.
	 *
	 * @param value
	 */
	updateValue = (value, flags) => {
		flags = flags || {};
		const newValue = value === undefined || value === null ? this.getValue() : value;
		const changed = newValue !== undefined ? this.hasChanged(newValue, this.dataValue) : false;
		this.dataValue = Array.isArray(newValue) ? [...newValue] : newValue;

		this.updateOnChange(flags, changed);
		return changed;
	};

	/**
	 * Get the current value of the component. Should return the value set in the react component.
	 *
	 * @returns {*}
	 */
	getValue() {
		if (this.reactInstance) {
			return this.reactInstance.state.value;
		}
		return this.defaultValue;
	}

	/**
	 * Override normal validation check to insert custom validation in react component.
	 *
	 * @param data
	 * @param dirty
	 * @param rowData
	 * @returns {boolean}
	 */
	checkValidity(data, dirty, rowData) {
		const valid = super.checkValidity(data, dirty, rowData);
		if (!valid) {
			return false;
		}
		return this.validate(data, dirty, rowData);
	}

	/**
	 * Do custom validation.
	 *
	 * @param data
	 * @param dirty
	 * @param rowData
	 * @returns {boolean}
	 */
	validate(data, dirty, rowData) {
		// console.log("data" , data)
		// if(!data.photoCapture){
		//     return false;

		// }
		// if(!data.photoCapture.toString().startsWith("data")){
		//     return false;
		// }
		return true;
	}
}
