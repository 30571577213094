import person1 from "assets/img/team/1-thumb.png";
import person2 from "assets/img/team/25-thumb.png";
import person3 from "assets/img/team/14-thumb.png";
import person4 from "assets/img/team/3-thumb.png";

const contactBadges = [
	{ content: "Active", type: "success" },
	{ content: "Inactive", type: "secondary" },
	{ content: "Expired", type: "danger" },
	{ content: "Pending", type: "warning" },
	{ content: "N/A", type: "" },
];

export const contacts = [
	{
		name: "Emma Watson",
		avatar: {
			name: "Emma Watson",
			size: "xl",
			round: "circle",
		},
		phone: "+61 1800 861 302",
		report: "Analysis of the Top Customers",
		subscription: contactBadges[0],
		social: "Facebook",
	},
	{
		name: "Luke",
		avatar: {
			name: "Luke",
			size: "xl",
			round: "circle",
		},
		phone: "+36 1256 523 369",
		report: "Performance of a Group",
		subscription: contactBadges[1],
		social: "Twitter",
	},
	{
		name: "Finley",
		avatar: {
			img: person1,
			size: "xl",
		},
		phone: "+61 1800 861 302",
		report: "Performance of Agents",
		subscription: contactBadges[2],
		social: "Linkedin",
	},
	{
		name: "Peter Gill",
		avatar: {
			name: "Peter Gill",
			size: "xl",
			round: "circle",
		},
		phone: "+99 2536 632 566",
		report: "Handling Time (on Average)",
		subscription: contactBadges[0],
		social: "Twitter",
	},
	{
		name: "Sallie Reyes",
		avatar: {
			img: person2,
			size: "xl",
		},
		phone: "+84 3698 856 963",
		report: "Handling Time (on Average)",
		subscription: "",
		social: "Twitter",
	},
	{
		name: "Freya",
		avatar: {
			name: "Freya",
			size: "xl",
			round: "circle",
		},
		phone: "+66 3256 632 235",
		report: "Survey of Customer Satisfaction",
		subscription: "",
		social: "",
	},
	{
		name: "Morrison",
		avatar: {
			name: "Morrison",
			size: "xl",
			round: "circle",
		},
		phone: "+12 2563 223 325",
		report: "Distribution of Performance",
		subscription: contactBadges[3],
		social: "Twitter",
	},
	{
		name: "Aar Kay",
		avatar: {
			name: "Aar Kay",
			size: "xl",
			round: "circle",
		},
		phone: "+89 213 658 963",
		report: "Distribution of Performance",
		subscription: contactBadges[0],
		social: "",
	},
	{
		name: "Michele",
		avatar: {
			name: "Michele",
			size: "xl",
			round: "circle",
		},
		phone: "+39 213 658 963",
		report: "Analysis of the Top Customer",
		subscription: "",
		social: "Twitter",
	},
	{
		name: "Banneker",
		avatar: {
			img: person3,
			size: "xl",
		},
		phone: "+69 213 658 963",
		report: "Freddy's canned retort",
		subscription: contactBadges[0],
		social: "",
	},
	{
		name: "Romanin",
		avatar: {
			img: person4,
			size: "xl",
		},
		phone: "+79 213 658 963",
		report: "Emailbot Freddy",
		subscription: contactBadges[0],
		social: "",
	},
	{
		name: "Michele",
		avatar: {
			name: "Michele",
			size: "xl",
			round: "circle",
		},
		phone: "+66 3256 632 235",
		report: "Survey of Customer Satisfaction",
		subscription: "",
		social: "",
	},
	{
		name: "Morrison",
		avatar: {
			name: "Morrison",
			size: "xl",
			round: "circle",
		},
		phone: "+12 2563 223 325",
		report: "Distribution of Performance",
		subscription: contactBadges[3],
		social: "Twitter",
	},
	{
		name: "Aar Kay",
		avatar: {
			name: "Aar Kay",
			size: "xl",
			round: "circle",
		},
		phone: "+89 213 658 963",
		report: "Distribution of Performance",
		subscription: contactBadges[0],
		social: "",
	},
	{
		name: "Emma Watson",
		avatar: {
			name: "Emma Watson",
			size: "xl",
			round: "circle",
		},
		phone: "+61 1800 861 302",
		report: "Analysis of the Top Customers",
		subscription: contactBadges[0],
		social: "Facebook",
	},
	{
		name: "Banneker",
		avatar: {
			img: person3,
			size: "xl",
		},
		phone: "+36 1256 523 369",
		report: "Performance of a Group",
		subscription: contactBadges[1],
		social: "Twitter",
	},
	{
		name: "Finley",
		avatar: {
			img: person1,
			size: "xl",
		},
		phone: "+61 1800 861 302",
		report: "Performance of Agents",
		subscription: contactBadges[2],
		social: "Linkedin",
	},
	{
		name: "Peter Gill",
		avatar: {
			name: "Peter Gill",
			size: "xl",
			round: "circle",
		},
		phone: "+99 2536 632 566",
		report: "Handling Time (on Average)",
		subscription: contactBadges[0],
		social: "Twitter",
	},
	{
		name: "Sallie Reyes",
		avatar: {
			img: person2,
			size: "xl",
		},
		phone: "+84 3698 856 963",
		report: "Handling Time (on Average)",
		subscription: "",
		social: "Twitter",
	},
	{
		name: "Freya",
		avatar: {
			name: "Freya",
			size: "xl",
			round: "circle",
		},
		phone: "+66 3256 632 235",
		report: "Survey of Customer Satisfaction",
		subscription: "",
		social: "",
	},
	{
		name: "Luke",
		avatar: {
			name: "Luke",
			size: "xl",
			round: "circle",
		},
		phone: "+36 1256 523 369",
		report: "Performance of a Group",
		subscription: contactBadges[1],
		social: "Twitter",
	},
	{
		name: "Finley",
		avatar: {
			img: person1,
			size: "xl",
		},
		phone: "+61 1800 861 302",
		report: "Performance of Agents",
		subscription: contactBadges[2],
		social: "Linkedin",
	},
	{
		name: "Banneker",
		avatar: {
			img: person3,
			size: "xl",
		},
		phone: "+69 213 658 963",
		report: "Freddy's canned retort",
		subscription: contactBadges[0],
		social: "",
	},
	{
		name: "Sallie Reyes",
		avatar: {
			img: person2,
			size: "xl",
		},
		phone: "+84 3698 856 963",
		report: "Handling Time (on Average)",
		subscription: "",
		social: "Twitter",
	},
	{
		name: "Freya",
		avatar: {
			name: "Freya",
			size: "xl",
			round: "circle",
		},
		phone: "+66 3256 632 235",
		report: "Survey of Customer Satisfaction",
		subscription: "",
		social: "",
	},
	{
		name: "Luke",
		avatar: {
			name: "Luke",
			size: "xl",
			round: "circle",
		},
		phone: "+36 1256 523 369",
		report: "Performance of a Group",
		subscription: contactBadges[1],
		social: "Twitter",
	},
	{
		name: "Finley",
		avatar: {
			img: person1,
			size: "xl",
		},
		phone: "+61 1800 861 302",
		report: "Performance of Agents",
		subscription: contactBadges[2],
		social: "Linkedin",
	},
	{
		name: "Peter Gill",
		avatar: {
			name: "Peter Gill",
			size: "xl",
			round: "circle",
		},
		phone: "+99 2536 632 566",
		report: "Handling Time (on Average)",
		subscription: contactBadges[0],
		social: "Twitter",
	},
	{
		name: "Sallie Reyes",
		avatar: {
			img: person2,
			size: "xl",
		},
		phone: "+84 3698 856 963",
		report: "Handling Time (on Average)",
		subscription: "",
		social: "Twitter",
	},
	{
		name: "Emma Watson",
		avatar: {
			name: "Emma Watson",
			size: "xl",
			round: "circle",
		},
		phone: "+66 3256 632 235",
		report: "Survey of Customer Satisfaction",
		subscription: "",
		social: "",
	},
	{
		name: "Morrison",
		avatar: {
			name: "Morrison",
			size: "xl",
			round: "circle",
		},
		phone: "+12 2563 223 325",
		report: "Distribution of Performance",
		subscription: contactBadges[3],
		social: "Twitter",
	},
	{
		name: "Aar Kay",
		avatar: {
			name: "Aar Kay",
			size: "xl",
			round: "circle",
		},
		phone: "+89 213 658 963",
		report: "Distribution of Performance",
		subscription: contactBadges[0],
		social: "",
	},
	{
		name: "Romanin",
		avatar: {
			img: person4,
			size: "xl",
		},
		phone: "+79 213 658 963",
		report: "Emailbot Freddy",
		subscription: contactBadges[0],
		social: "",
	},
];
