import React, { useState, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import AppContext from "context/Context";
import { settings } from "./config";
import { getColor, getItemFromStore } from "helpers/utils";
import { configReducer } from "./reducers/configReducer";
import useToggleStyle from "./hooks/useToggleStyle";
import { useTranslation } from "react-i18next";
import jwtDecode from "jwt-decode";
import { Chart as ChartJS, registerables } from "chart.js";
import * as Constants from "common/constants";

import { EncryptStorage } from "encrypt-storage";
import keycloakService from "keycloakService";
import { use } from "i18next";

ChartJS.register(...registerables);

const Main = (props) => {
	const [t, i18n] = useTranslation();

	const configState = {
		isFluid: getItemFromStore("isFluid", settings.isFluid),
		isRTL: getItemFromStore("isRTL", settings.isRTL),
		systemProperties: getItemFromStore("systemProperties", settings.systemProperties),
		isDark: getItemFromStore("isDark", settings.isDark),
		navbarPosition: getItemFromStore("navbarPosition", settings.navbarPosition),
		disabledNavbarPosition: [],
		isNavbarVerticalCollapsed: getItemFromStore("isNavbarVerticalCollapsed", settings.isNavbarVerticalCollapsed),
		navbarStyle: getItemFromStore("navbarStyle", settings.navbarStyle),
		currency: settings.currency,
		showBurgerMenu: settings.showBurgerMenu,
		showSettingPanel: false,
		navbarCollapsed: false,
	};

	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});

	const [config, configDispatch] = useReducer(configReducer, configState);

	const { isLoaded } = useToggleStyle(config.isRTL, config.isDark, configDispatch);

	// const handleMouseMove = () => {
	//   var accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);
	//   if (accessToken) {
	//     const decodedAccessToken = jwtDecode(accessToken);

	//     const currentTime = Date.now() / 1000;

	//     if ((decodedAccessToken.exp+120000) < currentTime) {
	//       keycloakService.renewSession();
	//     }
	//   }

	// };

	const setConfig = (key, value) => {
		configDispatch({
			type: "SET_CONFIG",
			payload: {
				key,
				value,
				setInStore: ["isFluid", "isRTL", "isDark", "navbarPosition", "isNavbarVerticalCollapsed", "navbarStyle"].includes(key),
			},
		});
	};

	if (!isLoaded) {
		return (
			<div
				style={{
					position: "fixed",
					top: 0,
					right: 0,
					bottom: 0,
					left: 0,
					backgroundColor: config.isDark ? getColor("dark") : getColor("light"),
				}}
			/>
		);
	}

	return (
		<AppContext.Provider value={{ config, setConfig, configDispatch }}>
			<div>{props.children}</div>
		</AppContext.Provider>
	);
};

Main.propTypes = { children: PropTypes.node };

export default Main;
