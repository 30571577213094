const data = {
	keycloakObject: null,
	userInfo: null,
};

const sessionReducer = (state = data, action) => {
	switch (action.type) {
		case "SET_KEYCLOAK_OBJECT":
			return {
				...state,
				keycloakObject: action.keycloakObject,
			};

		case "SET_USER_INFO":
			return {
				...state,
				userInfo: action.userInfo,
			};

		default:
			return state;
	}
};

export default sessionReducer;
