import React, { Component, useEffect, useState, useRef } from "react";
import { ReactComponent, Utils } from "@formio/react";
import * as Constants from "../../../../common/constants.js";
import { GetFromEndPoint } from "../../../../common/general";
import settingsForm from "./GisMap.settingsForm";
import _ from "lodash";
import { isEmpty } from "lodash";
import { createRoot } from "react-dom/client";
import Modal from "./Modal.jsx";
import { findLastKey } from "lodash";

/*
This component renders a GisMap 
*/
const GisMapCustomComp = ({ ...props }) => {
	const [mapToken, setMapToken] = useState("");
	const [mapCode, setMapCode] = useState("");
	const [longitude, setLongitude] = useState(null);
	const [latitude, setLatitude] = useState(null);
	const [showMap, setShowMap] = useState(false);
	const iframeRef = useRef(null);
	const modal = useRef();

	const showTheMap = () => {
		modal.current.open();
	};
	const hideTheMap = () => {
		setShowMap(false);
		modal.current.close();
	};

	

	// Call to backend to generate Gis token use in map url
	useEffect(() => {
		const GetSuccess = (responseObject) => {
			if (!isEmpty(responseObject)) {
				setMapToken(responseObject.token);
			}
		};
		const GetFailure = (error) => {
			console.error(error);
		};

		var endpoint = Constants.GetGisTokenData;
		GetFromEndPoint(endpoint, null, null, GetSuccess, GetFailure);
	}, []);
	useEffect(() => {
		let gislink = "https://gis.mwan.gov.sa/MapLocator/?token=" + mapToken;
		let initialValue = props.form._data[props.component.key] || props.component.defaultValue;

		// if we reopen the form location set with pointer on map.
		if (initialValue) {
			gislink += "&long=" + initialValue.longitude + "&lat=" + initialValue.latitude;
			setLongitude(initialValue.longitude);
			setLatitude(initialValue.latitude);
		}

		setMapCode(`<iframe ref="${iframeRef}" src="${gislink}" height='500' width='100%' title='GisMap' id='includedPage'></iframe>`);
		// Where you want to render the map.
		// var element = document.getElementById("gis-map" + props.component.id);
		// if (element) {
		// 	element.innerHTML = `<iframe ref="${iframeRef}" src="${gislink}" height='500' width='100%' title='GisMap' id='includedPage'></iframe>`;
		// }
		const iframe = document.getElementById("includedPage");

		const receiveMessage = (event) => {
			// Check if the message is from the iframe
			if (event.origin === "https://gis.mwan.gov.sa") {
				// receiving the message contains long and lat data
				const { long, lat } = event.data;
				setLongitude(long);
				setLatitude(lat);
				props.onChange({ longitude: long, latitude: lat }, null);
				// console.log("Received long and lat:", long, lat);
				hideTheMap();
			}
		};
		// Ensure the iframe exists
		if (iframe) {
			// When the iframe loads

			window.addEventListener("message", receiveMessage);
		}
	}, []);

	//add page data in global form data
	useEffect(() => {
		if (props.data) {
			let keys = Object.keys(props.data);
			for (let i = 0; i < keys.length; i++) {
				props.form._data[keys[i]] = props.data[keys[i]];
				props.form._submission.data[keys[i]] = props.data[keys[i]];
			}
			if (props.insideGrid === true) {
				delete props.form._data[props.component.key];
				delete props.form._submission.data[props.component.key];
			}
		}
	}, [props.data]);

	return (
		<div id={"gis-map" + props.component.id}>
			<button onClick={showTheMap} className="btn btn-secondary">
				Add Location
			</button>
			<Modal ref={modal} buttonCaption="Okay" onClose={hideTheMap}>
				{React.createElement("div", {
					dangerouslySetInnerHTML: { __html: mapCode },
				})}
			</Modal>
			{longitude !== null && latitude !== null && (
				<div>
					<div className="flex-center row">
						<div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
							<label>Longitude:</label>
							<input className="form-control" type="text" value={longitude} readOnly disabled />
						</div>
						<div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
							<label>Latitude:</label>
							<input className="form-control" type="text" value={latitude} readOnly disabled />
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default class GisMap extends ReactComponent {
	static shouldSetValue = false; // Define shouldSetValue as a static property

	/**
	 * This is the first phase of component building where the component is instantiated.
	 *
	 * @param component - The component definition created from the settings form.
	 * @param options - Any options passed into the renderer.
	 * @param data - The submission data where this component's data exists.
	 */

	constructor(component, options, data) {
		options["oldState"] = {};
		super(component, options, data);
		this.reactInstance = null;
	}

	/**
	 * This function is the default settings for the component. At a minimum you want to set the type to the registered
	 * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
	 *
	 * @param sources
	 * @returns {*}
	 */
	static schema(...extend) {
		return ReactComponent.schema({
			type: "gisMapCustomComp",
			label: "GisMap Information",
		});
	}
	static get builderInfo() {
		return {
			title: "GisMap Details",
			icon: "cubes",
			group: "Basic",
			documentation: "",
			weight: -10,
			schema: GisMap.schema(),
		};
	}
	static editForm = settingsForm;

	/**
	  * This method is called any time the component needs to be rebuilt. It is most frequently used to listen to other
	  * components using the this.on() function.
	  */
	init() {
		return super.init();
	}

	/**
	 * This method is called before the component is going to be destroyed, which is when the component instance is
	 * destroyed. This is different from detach which is when the component instance still exists but the dom instance is
	 * removed.
	 */
	destroy() {
		return super.destroy();
	}
	/**
	 * This method is called before a form is submitted.
	 * It is used to perform any necessary actions or checks before the form data is sent.
	 *
	 */

	beforeSubmit() {
		return super.beforeSubmit();
	}

	/**
	 * The second phase of component building where the component is rendered as an HTML string.
	 *
	 * @returns {string} - The return is the full string of the component
	 */
	render() {
		// For react components, we simply render as a div which will become the react instance.
		// By calling super.render(string) it will wrap the component with the needed wrappers to make it a full component.
		return super.render(`<div ref="react-${this.id}"></div>`);
	}

	/**
	 * Callback ref to store a reference to the node.
	 *
	 * @param element - the node
	 */
	setReactInstance(element) {
		this.reactInstance = element;
	}

	/**
	 * The third phase of component building where the component has been attached to the DOM as 'element' and is ready
	 * to have its javascript events attached.
	 *
	 * @param element
	 * @returns {Promise<void>} - Return a promise that resolves when the attach is complete.
	 */
	attach(element) {
		super.attach(element);
		// let randomId = getRandomId();
		// // The loadRefs function will find all dom elements that have the "ref" setting that match the object property.
		// // It can load a single element or multiple elements with the same ref.
		// this.loadRefs(element, {
		// 	[`react-${this.id}`]: "single",
		// });

		// if (this.refs[`react-${this.id}`]) {
		// 	this.attachReact(this.refs[`react-${this.id}`], this.setReactInstance.bind(this));
		// 	if (this.shouldSetValue) {
		// 		this.setValue(this.dataForSetting);
		// 		this.updateValue(this.dataForSetting);
		// 	}
		// }
		return Promise.resolve();
	}


	/**
	 * Override this function to insert your custom component.
	 *
	 * @param element
	 * @param ref - callback ref
	 */
	attachReact(element, ref) {
		let insideGrid = false;

		const root = createRoot(element);
		let key = this.component.key;

		const rootForm = this.getRoot(); // Get the root form object

		Utils.eachComponent(
			rootForm.components,
			function (component) {
				if (component.component.type === "editgrid") {
					Utils.eachComponent(
						component.component.components,
						function (component2) {
							if (!insideGrid) {
								insideGrid = component2.key === key;
							}
						},
						true
					);
				}
			},
			true
		);

		

		root.render(
			<GisMapCustomComp
				component={this.component} // These are the component settings if you want to use them to render the component.
				onChange={this.updateValue} // Pass the onChange event handler
				value={this.dataValue}
				data={this.data}
				form={rootForm}
				oldState={this.options["oldState"]}
				insideGrid={insideGrid}
			/>
		);
	}

	/**
	 * Override this function.
	 */
	detachReact(element) {
		return;
	}

	
}
