import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import AppContext from "context/Context";
import * as Constants from "common/constants";
import IconButton from "components/common/IconButton";
import { EncryptStorage } from "encrypt-storage";
import { Dropdown, Col, Modal, Row, Button, Image, Tooltip, overlay, OverlayTrigger } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableFooter from "components/common/advance-table/AdvanceTableFooter";
import AdvanceTableSearchBox from "components/common/advance-table/AdvanceTableSearchBox";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import DeleteTrucksConfirmation from "./DeleteTrucksConfirmation";
import AddTruck from "./AddTruck";
import EditTruck from "./EditTruck";
import ViewTruck from "./ViewTruck";
import UpliftCss from "../../common/upliftcss.module.css";

const ManageTrucks = ({ RelatedCompanytypeNamePlural, CompanyID }) => {
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});
	const [fetchFlag, setFetchFlag] = useState(false);
	const [t, i18n] = useTranslation();
	const [selectedCompany, setSelectedCompany] = useState([]);
	const [selectedTrucks, setTrucks] = useState([]);
	const [addTrucksPopupVisibility, showTrucksPopup] = useState(false);
	const [editTrucksPopupVisibility, showEditTrucks] = useState(false);
	const [viewTrucksPopupVisibility, showViewTrucks] = useState(false);
	const [deleteTrucksPopupVisibility, showDeleteTrucksPopup] = useState(false);
	const columns = [
		{
			accessor: "id",
			Header: t("ID"),
		},
		{
			accessor: "plateNumber",
			Header: t("Plate Number"),
		},
		{
			accessor: "type",
			Header: t("Vehicle Type"),
		},
		{
			accessor: "subType",
			Header: t("Vehicle Subtype"),
		},
		{
			accessor: "size",
			Header: t("Size"),
		},
		{
			accessor: "tareWeight",
			Header: t("Vehicle Tare Weight"),
		},
		{
			accessor: "capacity",
			Header: t("Vehicle Capacity"),
		},
		{
			accessor: "capacityUnit",
			Header: t("Unit"),
		},
		{
			accessor: "action",
			Header: t("Action"),
		},
	];
	const [data, setData] = useState([]);
	const [onRefresh, setOnRefresh] = useState(null);

	//first function that's called
	useEffect(() => {
		setOnRefresh(null);
	}, [window.location.href, onRefresh]);

	const handlerefresh = (value) => {
		setOnRefresh(value);
		setFetchFlag(value);
	};
	const handleAddTrucks = () => {};

	const {
		config: { isRTL },
	} = useContext(AppContext);

	useEffect(() => {
		setOnRefresh(null);
		var selectedCompany = encryptStorage1.getItem(Constants.SelectedCompany);
		setSelectedCompany(selectedCompany);
	}, [window.location.href, onRefresh]);

	/*************** ADD truck START ************************/

	const handleClickAddTrucks = () => {
		showTrucksPopup(true);
	};

	const handleCancelAddTrucks = () => {
		showTrucksPopup(false);
	};
	/*************** ADD truck END ************************/
	/*************** Edit truck START ************************/

	const handleClickEditTrucks = (gen) => {
		setTrucks(gen);
		showEditTrucks(true);
	};

	const handleCancelEditTrucks = () => {
		showEditTrucks(false);
	};
	/*************** Edit truck END ************************/
	/*************** View truck START ************************/

	const handleClickViewTrucks = (gen) => {
		setTrucks(gen);
		showViewTrucks(true);
	};

	const handleCancelViewTrucks = () => {
		showViewTrucks(false);
	};
	/*************** View truck END ************************/

	/*************** delete truck START ************************/
	const handleClickDeleteGenetrator = (gen) => {
		setTrucks(gen);
		showDeleteTrucksPopup(true);
	};
	const handleCancelDelete = () => {
		showDeleteTrucksPopup(false);
	};
	/*************** delete truck END ************************/

	useEffect(() => {
		async function fetchData() {
			setFetchFlag(null);
			try {
				var accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);
				var selectedCompany = encryptStorage1.getItem(Constants.SelectedCompany);
				const response = await fetch(Constants.GetTransporterTrucksData + selectedCompany, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + accessToken,
					},
				});
				const responseText = await response.text();

				try {
					const newData = JSON.parse(responseText);
					let arrayOfTruckss = Object.values(newData);
					const trucks = arrayOfTruckss.flatMap((truck) => {
						let parsedtruck;
						try {
							parsedtruck = typeof truck === "string" ? JSON.parse(truck) : truck;
						} catch (e) {
							console.error("Error parsing task:", task, e);
							return []; // Skip this task if it can't be parsed
						}
						const originalValues = {
							id: parsedtruck.id,
							plateNumber: parsedtruck.plateNumber,
							type: parsedtruck.type,
							subType: parsedtruck.subType,
							size: parsedtruck.size,
							tareWeight: parsedtruck.tareWeight,
							capacity: parsedtruck.capacity,
							capacityUnit: parsedtruck.capacityUnit,
						};
						parsedtruck.id = t(parsedtruck.id);
						parsedtruck.plateNumber = t(parsedtruck.plateNumber);
						parsedtruck.type = t(parsedtruck.type);
						parsedtruck.subType = t(parsedtruck.subType);
						parsedtruck.size = t(parsedtruck.size);
						parsedtruck.tareWeight = t(parsedtruck.tareWeight);
						parsedtruck.capacity = t(parsedtruck.capacity);
						parsedtruck.capacityUnit = t(parsedtruck.capacityUnit);
						parsedtruck.action = (
							<>
								<OverlayTrigger
									overlay={
										<Tooltip style={{ position: "fixed" }} id="StartProcess">
											{t("Edit")}
										</Tooltip>
									}
								>
									<div className="theme-control-toggle-label icon-position">
										<IconButton
											className={`${UpliftCss.forceButtonColorwhite}`}
											iconClassName="fs--2"
											variant="falcon-default"
											size="sm"
											icon="fa-solid fa-edit"
											style={{ margin: "auto 0.25rem" }}
											onClick={() => handleClickEditTrucks(originalValues)}
										></IconButton>
									</div>
								</OverlayTrigger>
								<OverlayTrigger
									overlay={
										<Tooltip style={{ position: "fixed" }} id="StartProcess">
											{t("View")}
										</Tooltip>
									}
								>
									<div className="theme-control-toggle-label icon-position">
										<IconButton
											className={`btn btn-primary ${UpliftCss.forceButtonColorwhite}`}
											iconClassName="fs--2"
											variant="falcon-default"
											size="sm"
											icon="fa-solid fa-eye"
											style={{ margin: "auto 0.25rem" }}
											onClick={() => handleClickViewTrucks(parsedtruck)}
										></IconButton>
									</div>
								</OverlayTrigger>
								<OverlayTrigger
									overlay={
										<Tooltip style={{ position: "fixed" }} id="StartProcess">
											{t("Delete")}
										</Tooltip>
									}
								>
									<div className="theme-control-toggle-label icon-position">
										<IconButton
											className="btn-danger"
											iconClassName="fs--2"
											variant=""
											size="sm"
											icon="fa-solid fa-trash"
											style={{ margin: "auto 0.25rem" }}
											onClick={() => handleClickDeleteGenetrator(parsedtruck)}
										></IconButton>
									</div>
								</OverlayTrigger>
							</>
						);
						return parsedtruck;
					});
					setData(trucks);
				} catch (jsonError) {
					console.error("Response text:", jsonError);
				}
			} catch (networkError) {
				console.error("Fetch error:", networkError);
			}
			//}
		}

		fetchData();
	}, [fetchFlag]);
	return (
		<div>
			<div className={`mb-3 card ${UpliftCss.marginTop25}`}>
				<div className={`card-body ${isRTL ? UpliftCss.gradiantTitleAr : UpliftCss.gradiantTitle}`}>
					<div className="col-md">
						<h5 className="mb-2 mb-md-0">{t(`Manage ${RelatedCompanytypeNamePlural}`)}</h5>
					</div>
				</div>
			</div>
			<div className={`card ${UpliftCss.BodyBorder}`}>
				<div className="tool-header">
					<OverlayTrigger
						overlay={
							<Tooltip style={{ position: "fixed" }} id="newcontract">
								{t(`New Vehicle`)}
							</Tooltip>
						}
					>
						<div className="theme-control-toggle-label icon-position end-text">
							<IconButton
								className="btn-primary botton-circle"
								iconClassName="fs--5"
								size="sm"
								icon="fa-solid fa-circle-plus"
								style={{ margin: "auto 0.25rem", padding: "9px 10px 10px" }}
								onClick={handleClickAddTrucks}
							></IconButton>
						</div>
					</OverlayTrigger>
				</div>
				<div className="card-body">
					<div className={`mb-3 ${UpliftCss.tableHeader}`}>
						<AdvanceTableWrapper columns={columns} data={data} className={`${UpliftCss.tableHeader}`} sortable pagination perPage={10}>
							<Row className="flex-end-center mb-3">
								<Col xs="auto" sm={6} lg={4}>
									<AdvanceTableSearchBox className={`${UpliftCss.filterFieldBoxes}`} table placeholder={t("Search")} />
								</Col>
							</Row>
							<AdvanceTable
								table
								headerClassName="bg-200 text-900 text-nowrap align-middle"
								rowClassName="align-middle white-space-nowrap"
								tableProps={{
									bordered: true,
									striped: true,
									className: "fs--1 mb-0 overflow-hidden",
								}}
							/>
							<div className="mt-3">
								<AdvanceTableFooter rowCount={data.length} table rowInfo navButtons rowsPerPageSelection />
							</div>
						</AdvanceTableWrapper>

						{addTrucksPopupVisibility && (
							<AddTruck
								successCallBackFunction={handlerefresh}
								hideAddTruck={handleCancelAddTrucks}
								allTrucks={data}
								showComponent={addTrucksPopupVisibility}
								RelatedCompany={selectedCompany}
							></AddTruck>
						)}
						{deleteTrucksPopupVisibility && (
							<DeleteTrucksConfirmation
								successCallBackFunction={handlerefresh}
								selectedTrucks={selectedTrucks}
								hideDeleteConfirmation={handleCancelDelete}
								showComponent={deleteTrucksPopupVisibility}
								company={selectedCompany}
							></DeleteTrucksConfirmation>
						)}

						{editTrucksPopupVisibility && (
							<EditTruck
								successCallBackFunction={handlerefresh}
								selectedTrucks={selectedTrucks}
								allTrucks={data}
								hideEditTruck={handleCancelEditTrucks}
								showComponent={editTrucksPopupVisibility}
								RelatedCompany={selectedCompany}
							></EditTruck>
						)}
						{viewTrucksPopupVisibility && (
							<ViewTruck
								successCallBackFunction={handlerefresh}
								selectedTrucks={selectedTrucks}
								allTrucks={data}
								hideViewTruck={handleCancelViewTrucks}
								showComponent={viewTrucksPopupVisibility}
								RelatedCompany={selectedCompany}
							></ViewTruck>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

ManageTrucks.propTypes = {
	RelatedCompanytypeID: PropTypes.string,
	CompanyRelationshipTypeID: PropTypes.string,
};

export default ManageTrucks;
