import i18next from "i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import * as Constants from "common/constants";
import * as General from "common/general";

let json = {};
const loadResources = async (locale) => {
	const GetAllLabelsSuccess = (responseObject) => {
		return new Promise((resolve, reject) => {
			if (responseObject.status === Constants.SuccessStatus) {
				if (responseObject.data != null) {
					var labels = responseObject.data.dataBaseContent;
					resolve(labels);
				} else {
					reject(new Error("Invalid token or data"));
				}
			} else {
				reject(new Error("Request failed"));
			}
		});
	};

	var endPoint = Constants.GetAllResourcesEndPoint + "/" + Constants.LabelsTableName + "/" + i18next.language;

	try {
		const resource = await new Promise((resolve, reject) => {
			General.GetFromEndPoint(
				endPoint,
				null,
				null,
				(response) => {
					GetAllLabelsSuccess(response).then(resolve).catch(reject);
				},
				(error) => {
					reject(error);
				}
			);
		});

		return resource;
	} catch (error) {
		console.error("Error loading resources:", error);
		// Handle the error accordingly
	}
};
const backendOptions = {
	loadPath: "{{lng}}|{{ns}}",
	request: (options, url, payload, callback) => {
		try {
			json = {};
			const [lng] = url.split("|");
			loadResources(lng).then((response) => {
				let labels = null;
				if (response) {
					labels = Array.from(response);
				}
				if (Object.keys(json).length !== 0) {
					json = {};
				}
				if (labels) {
					for (let i = 0; i < labels.length; i++) {
						if (json[labels[i].labelID] === undefined) {
							json[labels[i].labelID] = labels[i].labelTranslation;
						}
					}
				}

				callback(null, {
					data: json,
					status: 200,
				});
			});
		} catch (e) {
			console.error("translation " + e);
			callback(null, {
				status: 500,
			});
		}
	},
};

i18next
	.use(HttpBackend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		backend: backendOptions,

		fallbackLng: "en",
		useSuspense: false,
		debug: false,
		load: "languageOnly",
		ns: ["translations"],
		defaultNS: "translations",
		keySeparator: false,
		interpolation: {
			escapeValue: false,
			formatSeparator: ",",
		},
		react: {
			wait: true,
		},

		supportedLngs: ["en", "ar", "fr", "gr"],
	});
export default i18next;
