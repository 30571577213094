import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import AppContext from "context/Context";
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Col, Modal, Row, Button, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import IconButton from "components/common/IconButton";
import * as Constants from "common/constants";
import * as General from "common/general";
import Loader from "components/custom/Loader";
import * as em from "common/ErrorMessages";
import { EncryptStorage } from "encrypt-storage";
import DatePicker from "react-datepicker";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import cloudUpload from "assets/img/icons/cloud-upload.svg";

import { useTranslation } from "react-i18next";
import LoaderIcon from "../Preloader";

const AddRelatedCompany = ({
	showComponent,
	RelatedCompanytypeID,
	hideAddRelatedCompany,
	successCallBackFunction,
	LoggedInCompanyID,
	LoggedInCompanyLicenseNumber,
	CompaniesAlreadyRelated,
	RelatedCompanytypeName,
	tblCompanie,
}) => {
	const [errorContainerVisiblity, showErrorContainer] = useState(false);
	const [loaderContainerVisibility, showLoaderContainer] = useState(false);
	const [buttonsVisibility, showHideButtons] = useState(true);

	const [companyID, setCompanyID] = useState("");

	const [RelatedCompanytypeIcon, setRelatedCompanytypeIcon] = useState("");

	const [CompaniesInDropDown, setCompaniesInDropDown] = useState([]);

	const [CompanyToRelateTo, updateCompanyToRelateTo] = useState("");
	const [CompanyToRelateToLicenseNumber, updateCompanyToRelateToLicenseNumber] = useState("");

	const [ContractNumber, updateContractNumber] = useState("");
	const [ContractStartDate, updateContractStartDate] = useState("");
	const [ContractEndDate, updateContractEndDate] = useState("");

	const [PaymentTerms, updatePaymentTerms] = useState("");
	const [WasteType, updateWasteType] = useState("");
	const [ServiceType, updateServiceType] = useState("");
	const [UnitOfMeasurement, updateUnitOfMeasurement] = useState("");
	const [Quantity, updateQuantity] = useState("");
	const [Frequency, updateFrequency] = useState("");
	const [tblCompanieReceived, setTblComapanieReceived] = useState("");
	const [companyTypeId, setCompanyTypeId] = useState("");
	const [t, i18n] = useTranslation();
	const [entityName, setEntityName] = useState("");
	const [fileUpload, setFileUpload] = useState(false);
	const [isFinish, setIsFinish] = useState(false);
	const timeOut = 5000;
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});

	//first function that's called
	useEffect(() => {
		setCompanyTypeId(encryptStorage1.getItem(Constants.SelectedCompany));
		//console.log("rell", "");

		if (tblCompanie === "Facility") {
			setTblComapanieReceived("tbl_company_facility_relationship");
		} else {
			setTblComapanieReceived("tbl_company_transporter_relationship");
		}

		// var companyInfo = General.getCompanyTypeInfo(RelatedCompanytypeID);
		// setRelatedCompanytypeIcon(companyInfo.icon);
		// setRelatedCompanytypeName(companyInfo.name);
		bindCompanies(tblCompanie);
	}, []);

	/*************** LOAD COMPANIES START ************************/
	const bindCompanies = (compTypeID) => {
		const GetAllCompaniesSuccess = (responseObject) => {
			console.log(responseObject);
			setCompaniesInDropDown(responseObject);
		};
		const GetAllCompanyCompaniesFailure = (error) => {
			setTimeout(() => {}, 1000);
		};
		console.log(compTypeID);
		var endPoint = Constants.GetCompaniesByType + compTypeID;
		General.GetFromEndPoint(endPoint, null, null, GetAllCompaniesSuccess, GetAllCompanyCompaniesFailure);
	};

	/*************** LOAD COMPANIES END ************************/

	const handleDropDownChange = () => {
		var ddCompanyToRelateTo = document.getElementById("ddCompanyToRelateTo");
		var ddCompanyToRelateTo2 = document.getElementById("ddCompanyToRelateTo").value;
		console.log({ ddCompanyToRelateTo });

		var ddCompanyToRelateToSelectedOption = ddCompanyToRelateTo.options[ddCompanyToRelateTo.selectedIndex];
		var ddCompanyToRelateToLicenseNumber = ddCompanyToRelateToSelectedOption.getAttribute("data-licensenumber");
		var ddCompanyToRelateToLicenseName = ddCompanyToRelateToSelectedOption.getAttribute("data-name");
		updateCompanyToRelateToLicenseNumber(ddCompanyToRelateToLicenseNumber);
		updateCompanyToRelateTo(ddCompanyToRelateTo2);
		setEntityName(ddCompanyToRelateToLicenseName);
	};

	const handleCancelNew = () => {
		hideAddRelatedCompany();
	};

	const handleAddRelatedCompany = async () => {
		setIsFinish(true);
		//  showLoaderContainer(true);
		//  showHideButtons(false);
		//  showErrorContainer(false);

		//get all elements
		var ddCompanyToRelateTo = document.getElementById("ddCompanyToRelateTo");
		var dpContractEndDate = document.getElementById("dpContractEndDate");
		var dpContractStartDate = document.getElementById("dpContractStartDate");
		var errorContainerStartDate = document.getElementById("errorContainerStartDate");
		var errorContainerDate = document.getElementById("errorContainerDate");

		var isValidForm = true;
		//remove class for invalid
		ddCompanyToRelateTo.classList.remove("is-invalid");
		dpContractEndDate.classList.remove("is-invalid");
		dpContractStartDate.classList.remove("is-invalid");

		errorContainerDate.innerHTML = "&nbsp;";
		errorContainerStartDate.innerHTML = "&nbsp;";
		//check if fields are filled and set the invalid class in case they are not

		if (CompanyToRelateTo === "") {
			setIsFinish(false);
			ddCompanyToRelateTo.classList.add("is-invalid");
			isValidForm = false;
		}

		if (fileUpload) {
			if (ContractStartDate === "" || ContractStartDate === null) {
				isValidForm = false;
				errorContainerStartDate.innerText = t(em.startDate);
				dpContractStartDate.classList.add("is-invalid");
				setIsFinish(false);
			} else {
				isValidForm = true;

				const date = new Date(ContractStartDate);

				const year = date.getFullYear();
				const month = date.getMonth() + 1; // Month is 0-indexed
				const day = date.getDate();
				var strtdate = year + "-" + month + "-" + day;

				if (ContractEndDate === "" || ContractEndDate === null) {
					isValidForm = false;
					errorContainerDate.innerText = t(em.endDate);
					dpContractEndDate.classList.add("is-invalid");
					setIsFinish(false);
				} else {
					const differenceMs = ContractEndDate - ContractStartDate;
					if (differenceMs <= 0) {
						isValidForm = false;
						errorContainerDate.innerText = t(em.diff_date);
						dpContractEndDate.classList.add("is-invalid");
						setIsFinish(false);
					} else {
						isValidForm = true;
						const date = new Date(ContractEndDate);

						const year = date.getFullYear();
						const month = date.getMonth() + 1; // Month is 0-indexed
						const day = date.getDate();
						var enddate = year + "-" + month + "-" + day;
					}
				}
			}
		}

		if (isValidForm == false) {
			//  showLoaderContainer(false);
			//  showHideButtons(true);
			//  showErrorContainer(false);
		} else {
			const AddRelatedCompanySuccess = (responseObject) => {
				var isSuccess = false;
				if (responseObject.status == Constants.SuccessStatus) {
					isSuccess = true;
					toast.success("Success");
					handleCancelNew();
					successCallBackFunction("true");
					setIsFinish(false);
				}

				if (isSuccess == false) {
					//   showErrorContainer(true);
					setIsFinish(false);
					/*   setTimeout(function () {
               var errorContainer = document.getElementById("errorContainer");
               if (responseObject.message != null) {
                 if (responseObject.message != "") {
                   errorContainer.innerHTML = responseObject.message;
                 }
               }
               if (errorContainer.innerHTML == "") {
                 errorContainer.innerHTML =
                   "An error occured, please try again later2.";
               }
               showLoaderContainer(false);
               showHideButtons(true);
             }, 1000);*/
				} else {
					//  showErrorContainer(false);
					//  showLoaderContainer(false);
					//  showHideButtons(true);
					setIsFinish(false);
				}
			};

			const AddRelatedCompanyFailure = (error) => {
				setTimeout(() => {
					setIsFinish(false);
				}, timeOut);

				toast.error("An error occured, please try again later.", {
					autoClose: timeOut,
				});
				/*  showErrorContainer(true);
          setTimeout(function () {
            var errorContainer = document.getElementById("errorContainer");
            if (errorContainer.innerHTML == "") {
              errorContainer.innerHTML =
                "An error occured, please try again later1." + error;
            }
            showLoaderContainer(false);
            showHideButtons(true);
          }, 1000);*/
			};

			var pdfname = "";
			var files = null;
			if (ContractFileName != "") {
				var now = new Date();
				var uniqueString = now.toISOString().replace(/[T:.Z]/g, "");
				//console.log("ContractFileName", ContractFileName);
				const delimiter = ".";
				const lastIndex = ContractFileName.lastIndexOf(delimiter);

				const firstPart = ContractFileName.substring(0, lastIndex);
				const secondPart = ContractFileName.substring(lastIndex + 1);
				pdfname = "/contracts/" + RelatedCompanytypeName.toLowerCase() + "/" + firstPart + "-" + uniqueString + "." + secondPart;
				files = {
					fileName: ContractFileName,
					data: ContractBase64,
				};
			}
			/*
            var strtdate = ContractStartDate;
            if (strtdate == "") {
              strtdate = "1900-1-1";
            } else {
              strtdate = General.getFormatInYMD(ContractStartDate);
            }
            //console.log("ContractEndDate1", ContractEndDate);
      
            var enddate = ContractEndDate;
            if (enddate == "") {
              enddate = "1900-1-1";
            } else {
              enddate = General.getFormatInYMD(ContractEndDate);
            }*/
			//console.log("ContractEndDate2", ContractEndDate);
			//console.log("enddate", enddate);

			if (tblCompanie === "Facility") {
				var AddCompanyJSON = {
					language: "en",
					databasePostData: {
						tableName: tblCompanieReceived,
						fields: [
							{
								generatorCompanyId: companyTypeId,
								facilityCompanyId: companyID,
								contractNumber: ContractNumber,
								contractStartDate: strtdate,
								contractEndDate: enddate,
								paymentTerms: PaymentTerms,
								wasteType: WasteType,
								serviceType: ServiceType,
								unitOfMeasurement: UnitOfMeasurement,
								quantity: Quantity,
								frequency: Frequency,
								facilityCompanyName: entityName,
								contractFile: ContractFileName,
								contratFileBase64: ContractBase64,
							},
						],
					},
				};
			} else {
				var AddCompanyJSON = {
					language: "en",
					databasePostData: {
						tableName: tblCompanieReceived,
						fields: [
							{
								generatorCompanyId: companyTypeId,
								transporterCompanyId: companyID,
								contractNumber: ContractNumber,
								contractStartDate: strtdate,
								contractEndDate: enddate,
								paymentTerms: PaymentTerms,
								wasteType: WasteType,
								serviceType: ServiceType,
								unitOfMeasurement: UnitOfMeasurement,
								quantity: Quantity,
								frequency: Frequency,
								transporterCompanyName: entityName,
								contractFile: ContractFileName,
								contratFileBase64: ContractBase64,
							},
						],
					},
				};
			}

			/*   var AddCompanyJSON = {
                language: "en",
                databasePostData: {
                    tableName: "tbl_company_transporter_relationship",
                    fields: [{
                        
                        generatorCompanyId: "GeneratorCompany",
                        transporterCompanyId: companyID,
                        // relationshiptypeid: "1",
                        contractNumber: ContractNumber,
                        contractStartDate: strtdate,
                        contractEndDate: enddate,
                        // payment_terms: PaymentTerms,
                        // waste_type: WasteType,
                        // service_type: ServiceType,
                        // unit_of_measurement: UnitOfMeasurement,
                        // quantity: Quantity,
                        frequency: Frequency,
                        transporterCompanyName: RelatedCompanytypeName,
                    }],
                    files: null
                }
            }*/
			var AddCompanyJSONString = JSON.stringify(AddCompanyJSON);
			//console.log("AddCompanyJSONString", AddCompanyJSONString);
			var AddCompanyEndPoint = Constants.PostEndPoint;
			General.PostToEndPoint(AddCompanyEndPoint, null, AddCompanyJSONString, AddRelatedCompanySuccess, AddRelatedCompanyFailure);
		}
	};

	const [ContractFileName, setContractFileName] = useState("");
	const [ContractBase64, setContractBase64] = useState("");
	const [preview, setPreview] = useState("");
	const [isView, setIsView] = useState(false);
	const [iconView, setIconView] = useState("fa-solid fa-eye");
	const fileValidation = (filePath) => {
		var allowedExtensions = /(\.pdf)$/i;
		if (!allowedExtensions.exec(filePath)) {
			toast.error(t(em.upload_file_extensions_pdf), {
				position: "top-center",
				autoClose: 3000,
			});
			return false;
		} else return true;
	};
	const handleFileChange = (file) => {
		let is_valid = fileValidation(file.name);
		if (file.size != undefined && file.size > 0) {
			if (file.size < 5242880) {
				if (is_valid) {
					console.log("ext", file.name);
					const reader = new FileReader();

					reader.onload = (event) => {
						const base64 = reader.result.split(",")[1];
						setContractBase64(base64);
						setPreview(event.target.result);
						//console.log("file.name", file.name);
						setContractFileName(file.name);
					};

					reader.readAsDataURL(file);
					setFileUpload(true);
				}
			} else {
				setFileUpload(false);
				toast.error(t("File Should be less than 5MB"), {
					position: "top-center",
					autoClose: 3000,
				});
			}
		} else {
			setFileUpload(false);
			toast.error(t(em.select_workflow_upload), {
				position: "top-center",
				autoClose: 3000,
			});
		}
	};

	const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

	/*  const files = acceptedFiles.map((file) => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    ));
  */

	const handleRemoveFile = () => {
		setContractBase64("");
		setContractFileName("");
		setFileUpload(false);
		//   acceptedFiles.length=0;
		setIsView(false);
		setIconView("fa-solid fa-eye");
	};

	const handleViewFile = () => {
		setIsView(!isView);

		if (isView == true) {
			setIconView("fa-solid fa-eye");
		} else {
			setIconView("fa-solid fa-eye-slash");
		}
	};

	const {
		config: { isRTL },
	} = useContext(AppContext);

	return (
		<Modal show={showComponent} onHide={handleCancelNew} size="lg">
			<Modal.Header closeButton className="border-200">
				<Modal.Title as="h5">
					<Flex alignItems="center">
						<div className="icon-item bg-soft-success shadow-none">
							<FontAwesomeIcon icon={RelatedCompanytypeIcon} className="text-success" />
						</div>
						<div className="ms-2">{t(`New Related ${RelatedCompanytypeName}`)}</div>
					</Flex>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row className="g-0 align-items-center">
					<Col>
						<div className="mb-3">
							<label class="form-label">{t("Generator Name")}</label>
							<input type="text" className="form-control" value={companyTypeId} disabled="true" />
						</div>
						<div className="mb-3">
							<label class="form-label">{t(RelatedCompanytypeName)}</label>
							<select
								name="productCategory"
								id="ddCompanyToRelateTo"
								class="form-select"
								onChange={(e) => {
									setCompanyID(e.target.value);
									handleDropDownChange();
								}}
							>
								<option data-licensenumber="" value="" data-name="">
									{t(`Select ${RelatedCompanytypeName}`)}
								</option>
								{CompaniesInDropDown.map((company) => (
									//CompaniesAlreadyRelated.includes(company.id) == false   ?
									<option data-licensenumber={company.crEntityNumber} data-name={company.entityName} value={company.companyId}>
										{company.entityName}
									</option> //:""
								))}
							</select>
						</div>

						<div className="mb-3">
							<label class="form-label">{t(`${RelatedCompanytypeName} Commercial License Number`)}</label>
							<input type="text" className="form-control" value={CompanyToRelateToLicenseNumber} disabled="true" />
						</div>
						<div className="mb-3">
							<label class="form-label">{t("Upload Contract")}</label>
							{!fileUpload && (
								<div {...getRootProps({ className: "dropzone-area py-6" })}>
									<input
										{...getInputProps({
											multiple: false,
											onChange: (event) => handleFileChange(event.target.files[0]),
										})}
									/>
									<Flex justifyContent="center">
										<img src={cloudUpload} alt="" width={25} className="me-2" />
										<p className="fs-0 mb-0 text-700">{t("Drop your file here")}</p>
									</Flex>
								</div>
							)}
							<div className="mt-3">
								{fileUpload && ( //acceptedFiles.length > 0
									<>
										<h6>{t("File")}</h6>
										<ul className="d-inline-flex align-items-center">
											{ContractFileName}
											<div style={{ marginLeft: "10px" }}>
												<OverlayTrigger
													overlay={
														<Tooltip style={{ position: "fixed" }} id="abletodelete">
															{t(em.delete_icon)}
														</Tooltip>
													}
												>
													<div className="theme-control-toggle-label icon-position">
														<IconButton
															className="btn btn-danger"
															iconClassName="fs--2"
															variant=""
															size="sm"
															icon="fa-solid fa-trash"
															style={{ margin: "auto 0.25rem" }}
															onClick={() => handleRemoveFile()}
														></IconButton>
													</div>
												</OverlayTrigger>
											</div>
											<div style={{ marginLeft: "10px" }}>
												<OverlayTrigger
													overlay={
														<Tooltip style={{ position: "fixed" }} id="abletodelete">
															{t(em.viewFile)}
														</Tooltip>
													}
												>
													<div className="theme-control-toggle-label icon-position">
														<IconButton
															className="btn btn-primary"
															iconClassName="fs--2"
															variant=""
															size="sm"
															icon={iconView}
															style={{ margin: "auto 0.25rem" }}
															onClick={() => handleViewFile()}
														></IconButton>
													</div>
												</OverlayTrigger>
											</div>
										</ul>
									</>
								)}
								{isView && (
									<div>
										<embed src={preview} type="application/pdf" width="100%" height="500px" />
									</div>
								)}
							</div>
						</div>
						<div className="mb-3">
							<label class="form-label">{t("Contract Number")}</label>
							<input
								type="text"
								className="form-control"
								id="txtContractNumber"
								value={ContractNumber}
								placeholder={t("Contract Number")}
								onChange={(event) => updateContractNumber(event.target.value)}
							/>
						</div>
						<div className="mb-3">
							<label class="form-label">{t("Contract Start Date")}</label>
							<span id="errorContainerStartDate" className="loginAlert popupAlert inlineError">
								&nbsp;
							</span>
							<DatePicker
								id="dpContractStartDate"
								selected={ContractStartDate}
								onChange={(date) => {
									updateContractStartDate(date);
								}}
								className="form-control"
								placeholderText="YYYY-MM-DD"
								dateFormat="yyyy-MM-dd"
							/>
						</div>
						<div className="mb-3">
							<label class="form-label">{t("Contract End Date")}</label>
							<span id="errorContainerDate" className="loginAlert popupAlert inlineError">
								&nbsp;
							</span>
							<DatePicker
								id="dpContractEndDate"
								selected={ContractEndDate}
								onChange={(date) => {
									updateContractEndDate(date);
								}}
								className="form-control"
								placeholderText="YYYY-MM-DD"
								dateFormat="yyyy-MM-dd"
							/>
						</div>
						<div className="mb-3">
							<label class="form-label">{t("Payment Terms")}</label>
							<input
								type="text"
								className="form-control"
								id="txtPaymentTerms"
								value={PaymentTerms}
								placeholder={t("Payment Terms")}
								onChange={(event) => updatePaymentTerms(event.target.value)}
							/>
						</div>
						<div className="mb-3">
							<label class="form-label">{t("Waste Type")}</label>
							<input type="text" className="form-control" id="txtWasteType" value={WasteType} placeholder={t("Waste Type")} onChange={(event) => updateWasteType(event.target.value)} />
						</div>
						<div className="mb-3">
							<label class="form-label">{t("Service Type")}</label>
							<input
								type="text"
								className="form-control"
								id="txtServiceType"
								value={ServiceType}
								placeholder={t("Service Type")}
								onChange={(event) => updateServiceType(event.target.value)}
							/>
						</div>
						<div className="mb-3">
							<label class="form-label">{t("Unit of Measurement")}</label>
							<input
								type="text"
								className="form-control"
								id="txtUnitOfMeasurement"
								value={UnitOfMeasurement}
								placeholder={t("Unit of Measurement")}
								onChange={(event) => updateUnitOfMeasurement(event.target.value)}
							/>
						</div>
						<div className="mb-3">
							<label class="form-label">{t("Quantity")}</label>
							<input type="text" className="form-control" id="txtQuantity" value={Quantity} placeholder={t("Quantity")} onChange={(event) => updateQuantity(event.target.value)} />
						</div>

						<div className="mb-3">
							<label class="form-label">{t("Frequency")}</label>
							<input type="text" className="form-control" id="txtFrequency" value={Frequency} placeholder={t("Frequency")} onChange={(event) => updateFrequency(event.target.value)} />
						</div>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer className="border-200">
				{isFinish && <LoaderIcon />}
				<div>
					<Button size="sm" onClick={handleCancelNew} variant="secondary">
						{t("Cancel")}
					</Button>
					<IconButton size="sm" icon="" className="ms-2" variant="primary" onClick={handleAddRelatedCompany}>
						{t("Confirm")}
					</IconButton>
				</div>

				{errorContainerVisiblity && <div id="errorContainer" className="loginAlert popupAlert"></div>}
			</Modal.Footer>
		</Modal>
	);
};

AddRelatedCompany.propTypes = {
	showComponent: PropTypes.bool,
};

export default AddRelatedCompany;
