import baseEditForm from "formiojs/components/_classes/component/Component.form";

export default (...extend) => {
	return baseEditForm(
		[
			{
				label: "Display",
				key: "display",
				weight: 0,
				components: [],
			},
			{
				label: "Data",
				key: "data",
				weight: 20,
				components: [
					{
						type: "textfield",
						label: "Request Url",
						key: "companyRequestUrl",
						input: true,
						inline: true,
					},
					{
						type: "textfield",
						label: "Optional Request Url (for licencing)",
						key: "licenseCompanyRequestUrl",
						input: true,
						inline: true,
					},
					{
						type: "textfield",
						label: "Data Path",
						key: "dataPath",
						input: true,
						inline: true,
					},

					{
						type: "editgrid",
						label: "Query Parameters from Form Data",
						key: "queryParamsFromForm",
						templates: {
							header:
								'<div class="row">\n      {% util.eachComponent(components, function(component) { %}\n        {% if (displayValue(component)) { %}\n          <div class="col-sm-4">{{ t(component.label) }}</div>\n        {% } %}\n      {% }) %}\n    </div>',
							tableHeader:
								'\n      <tr>\n        {% util.eachComponent(components, function(component) { %}\n          {% if (!component.hasOwnProperty(\'tableView\') || component.tableView) { %}\n            <td class="editgrid-table-column">{{ component.label }}</td>\n          {% } %}\n        {% }) %}\n        {% if (!instance.options.readOnly && !instance.disabled) { %}\n          <td class="editgrid-table-column">Actions</td>\n        {% } %}\n      </tr>\n    ',
							row: '<div class="row">\n      {% util.eachComponent(components, function(component) { %}\n        {% if (displayValue(component)) { %}\n          <div class="col-sm-4">\n            {{ isVisibleInRow(component) ? getView(component, row[component.key]) : \'\'}}\n          </div>\n        {% } %}\n      {% }) %}\n      {% if (!instance.options.readOnly && !instance.disabled) { %}\n        <div class="col-sm-4">\n          <div class="btn-group pull-right">\n            <button class="btn btn-default btn-light btn-sm editRow"><i class="{{ iconClass(\'edit\') }}"></i></button>\n            {% if (!instance.hasRemoveButtons || instance.hasRemoveButtons()) { %}\n              <button class="btn btn-danger btn-sm removeRow"><i class="{{ iconClass(\'trash\') }}"></i></button>\n            {% } %}\n          </div>\n        </div>\n      {% } %}\n    </div>',
							tableRow:
								'\n      {% util.eachComponent(components, function(component) { %}\n          {% if (!component.hasOwnProperty(\'tableView\') || component.tableView) { %}\n            <td class="editgrid-table-column">\n              {{ getView(component, row[component.key]) }}\n            </td>\n          {% } %}\n        {% }) %}\n        {% if (!instance.options.readOnly && !instance.disabled) { %}\n          <td class="editgrid-table-column">\n            <div class="btn-group">\n              <button class="btn btn-default btn-light btn-sm editRow" aria-label="{{ t(\'Edit row\') }}"><i class="{{ iconClass(\'edit\') }}"></i></button>\n              {% if (!instance.hasRemoveButtons || instance.hasRemoveButtons()) { %}\n              <button class="btn btn-danger btn-sm removeRow" aria-label="{{ t(\'Remove row\') }}"><i class="{{ iconClass(\'trash\') }}"></i></button>\n              {% } %}\n            </div>\n          </td>\n        {% } %}\n    ',
							footer: "",
						},
						input: true,
						inline: true,
						components: [
							{
								type: "columns",
								label: "Data",
								key: "columns",
								input: true,
								inline: true,
								columns: [
									{
										currentWidth: 6,
										width: 6,
										components: [
											{
												type: "textfield",
												label: "Field Path",
												key: "queryParamsFieldPath",
												input: true,
												inline: true,
												validate: {
													required: true,
												},
											},
										],
									},

									{
										currentWidth: 6,
										width: 6,
										components: [
											{
												type: "textfield",
												label: "Field Name",
												key: "queryParamsFieldName",
												input: true,
												inline: true,
												validate: {
													required: true,
												},
											},
										],
									},
								],
							},
						],
					},

					{
						type: "editgrid",
						label: "Fields To render",
						modal: true,
						key: "companyGrid",
						templates: {
							header:
								'<div class="row">\n      {% util.eachComponent(components, function(component) { %}\n        {% if (displayValue(component)) { %}\n          <div class="col-sm-2">{{ t(component.label) }}</div>\n        {% } %}\n      {% }) %}\n    </div>',
							tableHeader:
								'\n      <tr>\n        {% util.eachComponent(components, function(component) { %}\n          {% if (!component.hasOwnProperty(\'tableView\') || component.tableView) { %}\n            <td class="editgrid-table-column">{{ component.label }}</td>\n          {% } %}\n        {% }) %}\n        {% if (!instance.options.readOnly && !instance.disabled) { %}\n          <td class="editgrid-table-column">Actions</td>\n        {% } %}\n      </tr>\n    ',
							row: '<div class="row">\n      {% util.eachComponent(components, function(component) { %}\n        {% if (displayValue(component)) { %}\n          <div class="col-sm-2">\n            {{ isVisibleInRow(component) ? getView(component, row[component.key]) : \'\'}}\n          </div>\n        {% } %}\n      {% }) %}\n      {% if (!instance.options.readOnly && !instance.disabled) { %}\n        <div class="col-sm-4">\n          <div class="btn-group pull-right">\n            <button class="btn btn-default btn-light btn-sm editRow"><i class="{{ iconClass(\'edit\') }}"></i></button>\n            {% if (!instance.hasRemoveButtons || instance.hasRemoveButtons()) { %}\n              <button class="btn btn-danger btn-sm removeRow"><i class="{{ iconClass(\'trash\') }}"></i></button>\n            {% } %}\n          </div>\n        </div>\n      {% } %}\n    </div>',
							tableRow:
								'\n      {% util.eachComponent(components, function(component) { %}\n          {% if (!component.hasOwnProperty(\'tableView\') || component.tableView) { %}\n            <td class="editgrid-table-column">\n              {{ getView(component, row[component.key]) }}\n            </td>\n          {% } %}\n        {% }) %}\n        {% if (!instance.options.readOnly && !instance.disabled) { %}\n          <td class="editgrid-table-column">\n            <div class="btn-group">\n              <button class="btn btn-default btn-light btn-sm editRow" aria-label="{{ t(\'Edit row\') }}"><i class="{{ iconClass(\'edit\') }}"></i></button>\n              {% if (!instance.hasRemoveButtons || instance.hasRemoveButtons()) { %}\n              <button class="btn btn-danger btn-sm removeRow" aria-label="{{ t(\'Remove row\') }}"><i class="{{ iconClass(\'trash\') }}"></i></button>\n              {% } %}\n            </div>\n          </td>\n        {% } %}\n    ',
							footer: "",
						},
						input: true,
						inline: true,
						components: [
							{
								type: "columns",
								label: "Data",
								key: "columns",
								input: true,
								inline: true,
								columns: [
									{
										currentWidth: 3,
										width: 3,
										components: [
											{
												type: "textfield",
												label: "Field Path",
												key: "companyFieldPath",
												input: true,
												inline: true,
												validate: {
													required: true,
												},
											},
										],
									},

									{
										currentWidth: 3,
										width: 3,
										components: [
											{
												type: "textfield",
												label: "Field Name",
												key: "companyFieldName",
												input: true,
												inline: true,
												validate: {
													required: true,
												},
											},
										],
									},
									{
										currentWidth: 3,
										width: 3,
										components: [
											{
												type: "select",
												label: "Field Type",
												key: "companyFieldType",
												input: true,
												inline: true,
												data: {
													values: [
														{
															label: "Dropdown",
															value: "dropdown",
														},
														{
															label: "Text Field",
															value: "textField",
														},
														{
															label: "X-Coordinate",
															value: "x-coordinate",
														},
														{
															label: "Y-Coordinate",
															value: "y-coordinate",
														},
													],
												},
												validate: {
													required: true,
												},
											},
										],
									},

									{
										currentWidth: 3,
										width: 3,
										components: [
											{
												type: "textfield",
												label: "Request Table",
												key: "companyFieldRequestedTable",
												input: true,
												tooltip: "Get All Records from this requested table",
												inline: true,
											},
										],
									},
									{
										currentWidth: 3,
										width: 3,
										components: [
											{
												type: "textfield",
												label: "Response Label Path",
												key: "responseLabelPath",
												input: true,
												inline: true,
											},
										],
									},
									{
										currentWidth: 3,
										width: 3,
										components: [
											{
												type: "textfield",
												label: "Response Value Path",
												key: "responseValuePath",
												input: true,
												inline: true,
											},
										],
									},
									{
										currentWidth: 3,
										width: 3,
										components: [
											{
												type: "checkbox",
												label: "Hidden",
												key: "isHidden",
												input: true,
												inline: true,
											},
										],
									},
								],
							},
						],
					},
					{
						type: "editgrid",
						label: "Editable Conditions",
						key: "editableConditions",
						tooltip: "Add Form Data Values Conditions",
						templates: {
							header:
								'<div class="row">\n      {% util.eachComponent(components, function(component) { %}\n        {% if (displayValue(component)) { %}\n          <div class="col-sm-4">{{ t(component.label) }}</div>\n        {% } %}\n      {% }) %}\n    </div>',
							tableHeader:
								'\n      <tr>\n        {% util.eachComponent(components, function(component) { %}\n          {% if (!component.hasOwnProperty(\'tableView\') || component.tableView) { %}\n            <td class="editgrid-table-column">{{ component.label }}</td>\n          {% } %}\n        {% }) %}\n        {% if (!instance.options.readOnly && !instance.disabled) { %}\n          <td class="editgrid-table-column">Actions</td>\n        {% } %}\n      </tr>\n    ',
							row: '<div class="row">\n      {% util.eachComponent(components, function(component) { %}\n        {% if (displayValue(component)) { %}\n          <div class="col-sm-4">\n            {{ isVisibleInRow(component) ? getView(component, row[component.key]) : \'\'}}\n          </div>\n        {% } %}\n      {% }) %}\n      {% if (!instance.options.readOnly && !instance.disabled) { %}\n        <div class="col-sm-4">\n          <div class="btn-group pull-right">\n            <button class="btn btn-default btn-light btn-sm editRow"><i class="{{ iconClass(\'edit\') }}"></i></button>\n            {% if (!instance.hasRemoveButtons || instance.hasRemoveButtons()) { %}\n              <button class="btn btn-danger btn-sm removeRow"><i class="{{ iconClass(\'trash\') }}"></i></button>\n            {% } %}\n          </div>\n        </div>\n      {% } %}\n    </div>',
							tableRow:
								'\n      {% util.eachComponent(components, function(component) { %}\n          {% if (!component.hasOwnProperty(\'tableView\') || component.tableView) { %}\n            <td class="editgrid-table-column">\n              {{ getView(component, row[component.key]) }}\n            </td>\n          {% } %}\n        {% }) %}\n        {% if (!instance.options.readOnly && !instance.disabled) { %}\n          <td class="editgrid-table-column">\n            <div class="btn-group">\n              <button class="btn btn-default btn-light btn-sm editRow" aria-label="{{ t(\'Edit row\') }}"><i class="{{ iconClass(\'edit\') }}"></i></button>\n              {% if (!instance.hasRemoveButtons || instance.hasRemoveButtons()) { %}\n              <button class="btn btn-danger btn-sm removeRow" aria-label="{{ t(\'Remove row\') }}"><i class="{{ iconClass(\'trash\') }}"></i></button>\n              {% } %}\n            </div>\n          </td>\n        {% } %}\n    ',
							footer: "",
						},
						input: true,
						inline: true,
						components: [
							{
								type: "columns",
								label: "Data",
								key: "columns",
								input: true,
								inline: true,
								columns: [
									{
										currentWidth: 6,
										width: 6,
										components: [
											{
												type: "textfield",
												label: "Field Path",
												key: "formDataFieldPath",
												input: true,
												inline: true,
												validate: {
													required: true,
												},
											},
										],
									},

									{
										currentWidth: 6,
										width: 6,
										components: [
											{
												type: "textfield",
												label: "Field Value",
												key: "formDataFieldValue",
												input: true,
												inline: true,
												validate: {
													required: true,
												},
											},
										],
									},
								],
							},
						],
					},
					{
						type: "editgrid",
						label: "Initially Enabled",
						key: "initiallyEnabled",
						templates: {
							header:
								'<div class="row">\n      {% util.eachComponent(components, function(component) { %}\n        {% if (displayValue(component)) { %}\n          <div class="col-sm-4">{{ t(component.label) }}</div>\n        {% } %}\n      {% }) %}\n    </div>',
							tableHeader:
								'\n      <tr>\n        {% util.eachComponent(components, function(component) { %}\n          {% if (!component.hasOwnProperty(\'tableView\') || component.tableView) { %}\n            <td class="editgrid-table-column">{{ component.label }}</td>\n          {% } %}\n        {% }) %}\n        {% if (!instance.options.readOnly && !instance.disabled) { %}\n          <td class="editgrid-table-column">Actions</td>\n        {% } %}\n      </tr>\n    ',
							row: '<div class="row">\n      {% util.eachComponent(components, function(component) { %}\n        {% if (displayValue(component)) { %}\n          <div class="col-sm-4">\n            {{ isVisibleInRow(component) ? getView(component, row[component.key]) : \'\'}}\n          </div>\n        {% } %}\n      {% }) %}\n      {% if (!instance.options.readOnly && !instance.disabled) { %}\n        <div class="col-sm-4">\n          <div class="btn-group pull-right">\n            <button class="btn btn-default btn-light btn-sm editRow"><i class="{{ iconClass(\'edit\') }}"></i></button>\n            {% if (!instance.hasRemoveButtons || instance.hasRemoveButtons()) { %}\n              <button class="btn btn-danger btn-sm removeRow"><i class="{{ iconClass(\'trash\') }}"></i></button>\n            {% } %}\n          </div>\n        </div>\n      {% } %}\n    </div>',
							tableRow:
								'\n      {% util.eachComponent(components, function(component) { %}\n          {% if (!component.hasOwnProperty(\'tableView\') || component.tableView) { %}\n            <td class="editgrid-table-column">\n              {{ getView(component, row[component.key]) }}\n            </td>\n          {% } %}\n        {% }) %}\n        {% if (!instance.options.readOnly && !instance.disabled) { %}\n          <td class="editgrid-table-column">\n            <div class="btn-group">\n              <button class="btn btn-default btn-light btn-sm editRow" aria-label="{{ t(\'Edit row\') }}"><i class="{{ iconClass(\'edit\') }}"></i></button>\n              {% if (!instance.hasRemoveButtons || instance.hasRemoveButtons()) { %}\n              <button class="btn btn-danger btn-sm removeRow" aria-label="{{ t(\'Remove row\') }}"><i class="{{ iconClass(\'trash\') }}"></i></button>\n              {% } %}\n            </div>\n          </td>\n        {% } %}\n    ',
							footer: "",
						},
						input: true,
						inline: true,
						components: [
							{
								type: "columns",
								label: "Data",
								key: "columns",
								input: true,
								inline: true,
								columns: [
									{
										currentWidth: 6,
										width: 6,
										components: [
											{
												type: "textfield",
												label: "Form Data Field Path",
												key: "formDataFieldPath",
												input: true,
												inline: true,
												validate: {
													required: true,
												},
											},
										],
									},

									{
										currentWidth: 6,
										width: 6,
										components: [
											{
												type: "textfield",
												label: "Form Data Field Value",
												key: "formDataFieldValue",
												input: true,
												inline: true,
												validate: {
													required: true,
												},
											},
										],
									},
								],
							},
						],
					},
					{
						type: "editgrid",
						label: "Flags Notifications",
						key: "flagsNotifications",
						tooltip: "Responsible for appending this flag to the value if it is modified",
						templates: {
							header:
								'<div class="row">\n      {% util.eachComponent(components, function(component) { %}\n        {% if (displayValue(component)) { %}\n          <div class="col-sm-3">{{ t(component.label) }}</div>\n        {% } %}\n      {% }) %}\n    </div>',
							tableHeader:
								'\n      <tr>\n        {% util.eachComponent(components, function(component) { %}\n          {% if (!component.hasOwnProperty(\'tableView\') || component.tableView) { %}\n            <td class="editgrid-table-column">{{ component.label }}</td>\n          {% } %}\n        {% }) %}\n        {% if (!instance.options.readOnly && !instance.disabled) { %}\n          <td class="editgrid-table-column">Actions</td>\n        {% } %}\n      </tr>\n    ',
							row: '<div class="row">\n      {% util.eachComponent(components, function(component) { %}\n        {% if (displayValue(component)) { %}\n          <div class="col-sm-3">\n            {{ isVisibleInRow(component) ? getView(component, row[component.key]) : \'\'}}\n          </div>\n        {% } %}\n      {% }) %}\n      {% if (!instance.options.readOnly && !instance.disabled) { %}\n        <div class="col-sm-4">\n          <div class="btn-group pull-right">\n            <button class="btn btn-default btn-light btn-sm editRow"><i class="{{ iconClass(\'edit\') }}"></i></button>\n            {% if (!instance.hasRemoveButtons || instance.hasRemoveButtons()) { %}\n              <button class="btn btn-danger btn-sm removeRow"><i class="{{ iconClass(\'trash\') }}"></i></button>\n            {% } %}\n          </div>\n        </div>\n      {% } %}\n    </div>',
							tableRow:
								'\n      {% util.eachComponent(components, function(component) { %}\n          {% if (!component.hasOwnProperty(\'tableView\') || component.tableView) { %}\n            <td class="editgrid-table-column">\n              {{ getView(component, row[component.key]) }}\n            </td>\n          {% } %}\n        {% }) %}\n        {% if (!instance.options.readOnly && !instance.disabled) { %}\n          <td class="editgrid-table-column">\n            <div class="btn-group">\n              <button class="btn btn-default btn-light btn-sm editRow" aria-label="{{ t(\'Edit row\') }}"><i class="{{ iconClass(\'edit\') }}"></i></button>\n              {% if (!instance.hasRemoveButtons || instance.hasRemoveButtons()) { %}\n              <button class="btn btn-danger btn-sm removeRow" aria-label="{{ t(\'Remove row\') }}"><i class="{{ iconClass(\'trash\') }}"></i></button>\n              {% } %}\n            </div>\n          </td>\n        {% } %}\n    ',
							footer: "",
						},
						input: true,
						inline: true,
						components: [
							{
								type: "columns",
								label: "Data",
								key: "columns",
								input: true,
								inline: true,
								columns: [
									{
										currentWidth: 3,
										width: 3,
										components: [
											{
												type: "textfield",
												label: "Initial Value",
												key: "fieldInitialValue",
												input: true,
												inline: true,
												validate: {
													required: true,
												},
											},
										],
									},

									{
										currentWidth: 3,
										width: 3,
										components: [
											{
												type: "textfield",
												label: "Form Data Field Name",
												key: "formDataFieldName",
												input: true,
												inline: true,
												validate: {
													required: true,
												},
											},
										],
									},

									{
										currentWidth: 3,
										width: 3,
										components: [
											{
												type: "textfield",
												label: "Form Data Field Value",
												key: "formDataFieldValue",
												input: true,
												inline: true,
												validate: {
													required: true,
												},
											},
										],
									},
								],
							},
						],
					},
				],
			},
			{
				label: "Validation",
				key: "validation",
				weight: 20,
				components: [],
			},
			{
				label: "API",
				key: "api",
				weight: 30,
				components: [],
			},
			{
				label: "Logic",
				key: "logic",
				weight: 50,
				components: [],
			},
			{
				label: "Layout",
				key: "layout",
				weight: 60,
				components: [],
			},
		],
		...extend
	);
};
