import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import "./styles/Style.css";
import Loader from "components/custom/Loader";
import * as Constants from "common/constants";
import * as General from "common/general";
import { date } from "is_js";

function ResetPassword() {
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [loaderContainerVisibility, showHideLoaderContainer] = useState(false);
	const [ButtonVisibility, showHideButton] = useState(true);

	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const email = urlParams.get("email");
	const key = urlParams.get("key");
	const dateTimeString = urlParams.get("dt");

	const handleSubmit = async (event) => {
		event.preventDefault();

		const currentDate = new Date();
		const currentUtcDate = new Date(
			currentDate.getUTCFullYear(),
			currentDate.getUTCMonth(),
			currentDate.getUTCDate(),
			currentDate.getUTCHours(),
			currentDate.getUTCMinutes(),
			currentDate.getUTCSeconds(),
			currentDate.getUTCMilliseconds()
		);
		const dateTimeFromRequest = new Date(dateTimeString);
		const differenceInMilliseconds = dateTimeFromRequest - currentUtcDate;
		const millisecondsInOneDay = 1 * 60 * 60 * 1000; // 1 hour * 60 minutes * 60 seconds * 1000 milliseconds
		const isDifferenceLessThanOneHour = Math.abs(differenceInMilliseconds) < millisecondsInOneDay;

		if (isDifferenceLessThanOneHour == false) {
			showHideLoaderContainer(false);
			showHideButton(false);
			var errorContainer2 = document.getElementById("errorContainer");

			errorContainer2.innerHTML = "This link to change your password expired.";
		} else {
			var successContainer = document.getElementById("successContainer");
			var errorContainer = document.getElementById("errorContainer");
			successContainer.innerHTML = "";
			errorContainer.innerHTML = "";
			showHideLoaderContainer(true);
			showHideButton(false);

			var confirmPasswordInput = document.getElementById("txtConfirmPassword");
			var passwordInput = document.getElementById("txtPassword");
			confirmPasswordInput.classList.remove("is-invalid");
			passwordInput.classList.remove("is-invalid");

			if (confirmPassword.trim() === "" || password.trim() === "") {
				confirmPasswordInput.classList.add("is-invalid");
				passwordInput.classList.add("is-invalid");
				showHideLoaderContainer(false);
				showHideButton(true);
			} else if (confirmPassword != password) {
				confirmPasswordInput.classList.add("is-invalid");
				passwordInput.classList.add("is-invalid");
				showHideLoaderContainer(false);
				showHideButton(true);
				errorContainer.innerHTML = "Passwords don't match.";
			} else {
				var jsonBody = JSON.stringify({
					Language: "en",
					changeForgottenPasswordData: {
						userNameOrEmail: email,
						hash: key,
						newPassword: password,
						dt: dateTimeString,
					},
				});
				General.PostToEndPoint(Constants.UpdateForgottenPasswordEndPoint, null, jsonBody, UpdateForgottenPasswordSuccess, UpdateForgottenPasswordFailure);
				function UpdateForgottenPasswordSuccess(responseObject) {
					var isSuccess = false;
					if (responseObject.status == Constants.SuccessStatus) {
						setPassword("");
						setConfirmPassword("");
						var successContainer = document.getElementById("successContainer");
						isSuccess = true;
						successContainer.innerHTML = responseObject.message;

						showHideLoaderContainer(false);
					}

					if (isSuccess == false) {
						var errorContainer = document.getElementById("errorContainer");
						if (responseObject.message != null) {
							if (responseObject.message != "") {
								errorContainer.innerHTML = responseObject.message;
							}
						}

						if (errorContainer.innerHTML == "") {
							errorContainer.innerHTML = "An error occured, please try again later.";
						}

						showHideLoaderContainer(false);
						showHideButton(true);
					}
				}

				function UpdateForgottenPasswordFailure(error) {
					var errorContainer = document.getElementById("errorContainer");

					errorContainer.innerHTML = "An error occured, please try again later.";

					showHideLoaderContainer(false);
					showHideButton(true);
				}
			}
		}
	};

	return (
		<div className="container">
			<div className="flex-center min-vh-100 py-6 row">
				<div className="col-xxl-4 col-xl-5 col-lg-6 col-md-8 col-sm-10">
					<a className="text-decoration-none" href="/">
						<div className="d-flex flex-center fw-bolder fs-5 mb-4">
							<span className="font-sans-serif">Insight</span>
						</div>
					</a>
					<div className="card">
						<div className="p-4 p-sm-5 card-body">
							<div className="d-flex justify-content-between align-items-center mb-2">
								<h5>Update Password</h5>
							</div>
							<form onSubmit={handleSubmit}>
								<div className="mb-3">
									<input type="password" id="txtPassword" placeholder="Enter new password" value={password} onChange={(event) => setPassword(event.target.value)} className="form-control" />
								</div>
								<div className="mb-3">
									<input
										type="password"
										id="txtConfirmPassword"
										placeholder="Confirm new password"
										value={confirmPassword}
										onChange={(event) => setConfirmPassword(event.target.value)}
										className="form-control"
									/>
								</div>

								<div>
									{ButtonVisibility && (
										<button type="submit" color="primary" className="mt-3 w-100 btn btn-primary">
											Submit
										</button>
									)}
									{loaderContainerVisibility && <Loader></Loader>}
								</div>
							</form>

							<div id="errorContainer" className="loginAlert"></div>

							<div id="successContainer" className="successMessage"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ResetPassword;
