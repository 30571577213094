import React, { Component, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { ReactComponent, Utils } from "@formio/react";
import settingsForm from "./ConditionalDropDown.settingsForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import CustomSelect from "../customSelect";
import i18next from "i18next";
import _ from "lodash";
import { createRoot } from "react-dom/client";
import { getRandomId } from "helpers/utils";
import * as Constants from "common/constants";
import { EncryptStorage } from "encrypt-storage";

/*
This component is a dropdown where its options depends on another field value whose key supported by the user. The user can choose to add the 
options either static or using api
*/
const ConditionalDropDownCustomComp = ({ ...props }) => {
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});
	// Update the setValue method to handle onChange event
	const updateValue = (e) => {
		if (props.form._form.settings && props.form._form.settings.toEdit !== true) {
			const newValue = e.target.value; // Get the selected value from the event
			let selectedValues = props.oldState["options"].filter((v) => v.value === newValue)[0];
			let state = props.oldState;
			state["value"]["value"] = newValue;
			props.setOldState(state);

			if (selectedValues) {
				let json = { label: selectedValues?.label, value: newValue };
				if (props.component.apiFields && props.component.apiFields.length) {
					for (let i = 0; i < props.component.apiFields.length; i++) {
						json[props.component.apiFields[i].apiFieldName] = selectedValues[props.component.apiFields[i].apiFieldName];
					}
				}

				//fill value with the fields requested in state
				props.component.defaultValue = json;

				props.onChange(json, null);
				props.form._data[props.component.key] = json;
				state.previousValue = json;
			} else {
				props.component.defaultValue = "";
				props.onChange(null, null);
				props.form._data[props.component.key] = "";
			}

			if (!props.insideGrid) {
				//fill value with the fields requested in form data
				if (props.component.componentsToFill && props.component.componentsToFill.length > 0) {
					for (let i = 0; i < props.component.componentsToFill.length; i++) {
						props.form._data[props.component.componentsToFill[i]["fieldApi"]] = selectedValues?.label;
						props.form._submission.data[props.component.componentsToFill[i]["fieldApi"]] = selectedValues?.label;
					}
					props.form.triggerRedraw();
				}
			}
			Utils.eachComponent(
				props.form.components,
				function (component) {
					if (component.component.input) {
						let shouldBeTrigerred = false;

						if (props.component.componentsToFill && props.component.componentsToFill.length > 0) {
							let indexOfComponent = props.component.componentsToFill.findIndex((c) => c.fieldApi === component.component.key);
							if (indexOfComponent >= 0) {
								shouldBeTrigerred = true;
							}
						}
						if (component.component.conditionalKey?.split(",").indexOf(props.component.key) >= 0) {
							shouldBeTrigerred = true;
						}
						if (shouldBeTrigerred) {
							component.triggerRedraw();
						}
					} else if (component.component.type === "columns") {
						Utils.eachComponent(
							component.components,
							function (component2) {
								if (component2.component.input) {
									let shouldBeTrigerred = false;

									if (props.component.componentsToFill && props.component.componentsToFill.length > 0) {
										let indexOfComponent = props.component.componentsToFill.findIndex((c) => c.fieldApi === component.component.key);
										if (indexOfComponent >= 0) {
											shouldBeTrigerred = true;
										}
									}
									if (component2.component.conditionalKey?.split(",").indexOf(props.component.key) >= 0) {
										shouldBeTrigerred = true;
									}
									if (shouldBeTrigerred) {
										component2.triggerRedraw();
									}
								}
							},
							true
						);
					}
				},
				true
			);
		}
	};
	useEffect(() => {
		let state = props.oldState;
		let conditionalKeys = props.component.conditionalKey?.split(",");
		let arrayOfArrays = conditionalKeys?.map((item) => [item]);

		conditionalKeys = arrayOfArrays || [];

		if (props.component.conditionalKeysIfJoin) {
			if (props.component.conditionalKeysIfJoin.length !== 0) {
				conditionalKeys = [];
				for (let i = 0; i < props.component.conditionalKeysIfJoin.length; i++) {
					let newArray = [];
					for (let j = 0; j < props.component.conditionalKeysIfJoin[i].info.length; j++) {
						newArray.push(props.component.conditionalKeysIfJoin[i].info[j].nameInForm);
					}
					conditionalKeys.push(newArray);
				}
			}
		}

		if (props.component.defaultValue !== null && props.component.defaultValue !== undefined && props.component.defaultValue !== "") {
			let label = props.component.defaultValue.label || JSON.parse(props.component.defaultValue).label;
			if (props.form._form.settings) {
				if (props.form._form.settings.translatedData) {
					if (props.form._form.settings.translatedData[props.form._form.settings.language]) {
						label = props.form._form.settings.translatedData[props.form._form.settings.language][label] || label;
					}
				}
			}
			//if not disbaled get options
			if (props.component.disabled !== true) {
				let val = props.component.defaultValue?.value || JSON.parse(props.component.defaultValue)?.value;
				if (props.form._form.settings) {
					if (props.form._form.settings.translatedData) {
						if (props.form._form.settings.translatedData[props.form._form.settings.language]) {
							val = props.form._form.settings.translatedData[props.form._form.settings.language][val] || val;
						}
					}
				}
				state["value"]["value"] = val;
			} else {
				//else get just the label from the value already submitted no need for data
				state["value"]["value"] = label;
			}

			//if disabled fill dropdown options with labels only
			if (props.component.disabled === true) {
				if (state["mainData"].length === 0) {
					state["mainData"] = [{ label: label, value: label, isIncluded: true }];
				}
			} else {
				if (props.insideGrid === false || (props.insideGrid === true && props.isGridDisabled === false)) {
					//if referenced component value is defined
					let con1 = false;
					for (let i = 0; i < conditionalKeys.length; i++) {
						for (let j = 0; j < conditionalKeys[i].length; j++) {
							if (props.form._data[conditionalKeys[i][j]]?.value !== undefined) {
								con1 = true;
							}
						}
					}
					if (con1) {
						//if referenced component value is not set get all values and set it from form data
						if (state["mainSelectValue"].length === 0) {
							state["loading"] = true;
							props.setOldState(state);
							setTimeout(() => {
								getAllValues();
							}, 1);
							return;
						}
						let con2 = false;
						for (let i = 0; i < conditionalKeys.length; i++) {
							for (let j = 0; j < conditionalKeys[i].length; j++) {
								if (
									state["mainSelectValue"].filter((v) => v.key === conditionalKeys[i][j])[0]?.value !== props.form._data[conditionalKeys[i][j]]?.value &&
									props.form._data[conditionalKeys[i][j]] !== null &&
									props.form._data[conditionalKeys[i][j]] !== undefined
								) {
									con2 = true;
									state.indexChanged = i;
								}
							}
						}
						//if referenced component value changes get new options and reset this component value
						if (con2) {
							state["options"] = [];
							state["mainData"] = [];
							state["loading"] = true;
							props.setOldState(state);
							props.component.defaultValue = "";
							updateValue({ target: { value: {} } });

							setTimeout(() => {
								getAllValues();
							}, 1);

							return;
						}
					}
					//if referenced component value is not defined or not set empty options and reset this component value
					let con3 = true;
					for (let i = 0; i < conditionalKeys.length; i++) {
						for (let j = 0; j < conditionalKeys[i].length; j++) {
							if (props.form._data[conditionalKeys[i][j]]) {
								con3 = false;
								break;
							}
						}
					}

					if (con3) {
						state["options"] = [];
						state["mainData"] = [];
						state["mainSelectValue"] = [];
						props.setOldState(state);

						props.component.defaultValue = "";
						if (JSON.stringify(state.value.value) !== "{}" && state.value.value !== null) {
							updateValue({ target: { value: {} } });
						}
						return;
					}
					// else if options are empty get them from db
					if (state["mainData"].length === 0) {
						state["loading"] = true;
						props.setOldState(state);

						setTimeout(() => {
							getAllValues();
						}, 1);
					}
				}
			}

			props.setOldState(state);
		} else {
			let con4 = false;
			for (let i = 0; i < conditionalKeys.length; i++) {
				for (let j = 0; j < conditionalKeys[i].length; j++) {
					if (props.form._data[conditionalKeys[i][j]]?.value !== undefined) {
						con4 = true;
					}
				}
			}

			//if component value is not set on first render and referenced component value is defined reset this component value and get the options associated to it
			if (con4) {
				let con5 = false;
				for (let i = 0; i < conditionalKeys.length; i++) {
					for (let j = 0; j < conditionalKeys[i].length; j++) {
						if (
							props.form._data[conditionalKeys[i][j]] !== null &&
							props.form._data[conditionalKeys[i][j]] !== undefined &&
							state["mainSelectValue"].filter((v) => v.key === conditionalKeys[i][j])[0]?.value !== props.form._data[conditionalKeys[i][j]]?.value
						) {
							state.indexChanged = i;

							con5 = true;
						}
					}
				}

				if (con5 && !state.loading) {
					state["loading"] = true;
					state["options"] = [];
					state["mainData"] = [];

					props.setOldState(state);
					if (JSON.stringify(state.value.value) !== "{}" && state.value.value !== null) {
						props.component.defaultValue = "";
						updateValue({ target: { value: {} } });
					}
					if (props.insideGrid === false || (props.insideGrid === true && props.isGridDisabled === false)) {
						setTimeout(() => {
							getAllValues();
						}, 1);
					}
				}
			} else {
				//else if referenced component value is undefined empty options and reset value
				state["options"] = [];
				state["mainData"] = [];
				state["mainSelectValue"] = [];

				props.setOldState(state);
				if (JSON.stringify(state.value.value) !== "{}" && state.value.value !== null) {
					props.component.defaultValue = "";
					updateValue({ target: { value: {} } });
				}
			}
		}
	}, []);
	const getAllValues = () => {
		let allValues = {};

		let state = props.oldState;

		let conditionalKeys = props.component.conditionalKey.split(",");
		let arrayOfArrays = conditionalKeys?.map((item) => [item]);

		conditionalKeys = arrayOfArrays || [];

		if (props.component.conditionalKeysIfJoin) {
			if (props.component.conditionalKeysIfJoin.length !== 0) {
				conditionalKeys = [];
				for (let i = 0; i < props.component.conditionalKeysIfJoin.length; i++) {
					let newArray = [];
					for (let j = 0; j < props.component.conditionalKeysIfJoin[i].info.length; j++) {
						newArray.push(props.component.conditionalKeysIfJoin[i].info[j].nameInForm);
					}
					conditionalKeys.push(newArray);
				}
			}
		}

		//the user can choose to get data from database or set them manually
		let dataType = props.component.dataType;
		// if (props.component.startProcessing && props.data[conditionalKey] !== undefined && props.data[conditionalKey] !== null) {
		//if manual
		if (dataType === "manual" || dataType === undefined || dataType === "") {
			//get the values given by user they should be like {conditionalValue (the value of another field where its key given by user) : {label,value}}
			let mainValues = props.component.conditionalValues;
			//pass over the values given by user and fill them in array (render dropdown options according to conditionalValue that is the main field we're filtering on)
			//example: {"01" : [{label: "x" , value: "y"}, {label: "x2" , value: "y2"}] , "02" : [{label: "x3" , value: "y3"}, {label: "x4" , value: "y4"}]}
			//if the main field value is 01 then options will be either y or y2 and if 02 the options are y3 or y4 etc...
			for (let i = 0; i < mainValues?.length; i++) {
				let array = [];

				if (allValues[mainValues[i].conditionalValue]) {
					array = allValues[mainValues[i].conditionalValue];
				}
				for (let j = 0; j < mainValues[i].conditionalRenderedValues.length; j++) {
					array.push({
						label: mainValues[i].conditionalRenderedValues[j].conditionalRenderedLabel,
						value: mainValues[i].conditionalRenderedValues[j].conditionalRenderedValue,
					});
				}
				allValues[mainValues[i].conditionalValue] = array;
			}

			let allData =
				props.data && props.data[conditionalKey]
					? allValues[props.data[props.component.conditionalKey]?.value || JSON.parse(props.data[props.component.conditionalKey])?.value] || []
					: [];
			state["mainData"] = allData;
			state["conditionalKey"] = conditionalKey;
			if (props.data[props.component.conditionalKey]) {
				state["mainSelectValue"] = props.data[props.component.conditionalKey]?.value || JSON.parse(props.data[props.component.conditionalKey])?.value;
			}

			props.setOldState(state);
		}
		// else get data from db
		else if (dataType === "request" && props.component.startProcessing) {
			let requestUrl = props.component.requestUrl;
			let resLabelName = props.component.labelName;
			let resValueName = props.component.valueName;
			var accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);
			var headers = { "Content-Type": "application/json" };

			if (accessToken) {
				var headerToken = { Authorization: "Bearer " + accessToken };
				headers = { ...headers, ...headerToken };
			} else {
				headers["SuperAdminToken"] = Constants.SuperAdminToken;
			}
			let addedParams = 0;
			let proceed = true;
			if (requestUrl && requestUrl !== "") {
				let state = props.oldState;
				let query =
					"/select=" +
					(resLabelName && resValueName ? resLabelName + "," + resValueName : "*") +
					(props.component.appendQuery ? (props.component.appendQuery.split(" ")[state.indexChanged] ? "," : "") : "") +
					(props.component.appendQuery && props.component.appendQuery.split(" ")[state.indexChanged] !== "" && props.component.appendQuery.split(" ")[state.indexChanged] !== undefined
						? props.component.appendQuery.split(" ")[state.indexChanged]
						: "") +
					"&&";
				if (props.component.jsonFieldFilterPath !== "" && props.component.jsonFieldFilterPath !== undefined) {
					addedParams += 1;
					let jsonFieldFilterPaths = props.component.jsonFieldFilterPath.split(",");
					for (let i = 0; i < jsonFieldFilterPaths.length; i++) {
						query += jsonFieldFilterPaths[i] + "=eq." + _.get(props.form._data[props.component.fieldFilterName.split(",")[i]], jsonFieldFilterPaths[i], "");
						query += i !== jsonFieldFilterPaths.length - 1 ? "&&" : "";
					}
				} else {
					let addAnd = false;
					if (props.component.conditionalKeysIfJoin && props.component.conditionalKeysIfJoin.length > 0) {
						let fieldsToSend = props.component.conditionalKeysIfJoin[state.indexChanged].info;
						for (let i = 0; i < fieldsToSend.length; i++) {
							if (props.form._data[fieldsToSend[i].nameInForm] !== null && props.form._data[fieldsToSend[i].nameInForm] !== undefined) {
								if (props.form._data[fieldsToSend[i].nameInForm]) {
									addedParams += 1;

									query += fieldsToSend[i].nameInDB + "=eq." + (props.form._data[fieldsToSend[i].nameInForm].value || JSON.parse(props.form._data[fieldsToSend[i].nameInForm]).value);
									query += i !== fieldsToSend.length - 1 ? "&&" : "";
									addAnd = true;
								} else {
									if (fieldsToSend[i].isMandatory === true) {
										proceed = false;
									}
								}
							} else {
								if (fieldsToSend[i].isMandatory === true) {
									proceed = false;
								}
							}
						}
						let fieldsToSend2 = props.component.conditionalKeysIfJoin[state.indexChanged].conditionalKeysIfJoinExtra;
						if (props.component.conditionalKeysIfJoin[state.indexChanged].conditionalKeysIfJoinExtra) {
							for (let k = 0; k < fieldsToSend2.length; k++) {
								if (props.form._data[fieldsToSend2[k].conditionalNameInForm] && props.form._data[fieldsToSend2[k].nameInForm]) {
									addedParams += 1;

									query +=
										(addAnd ? "&&" : "") +
										(fieldsToSend2[k].nameInDB + "=eq." + (props.form._data[fieldsToSend2[k].nameInForm].value || JSON.parse(props.form._data[fieldsToSend2[k].nameInForm]).value));
									query += k !== fieldsToSend2.length - 1 ? "&&" : "";
								}
							}
						}
					} else {
						let fieldFilterNames = props.component.fieldFilterName.split(" ")[state.indexChanged];
						let fieldFilterNames2 = fieldFilterNames.split(",");

						for (let i = 0; i < fieldFilterNames2.length; i++) {
							addedParams += 1;

							query +=
								props.form._data[conditionalKeys[state.indexChanged]] !== null && props.form._data[conditionalKeys[state.indexChanged]] !== undefined
									? fieldFilterNames2[i] + "=eq." + (props.form._data[conditionalKeys[state.indexChanged]].value || JSON.parse(props.form._data[conditionalKeys[state.indexChanged]]).value)
									: "";
							query += i !== fieldFilterNames2.length - 1 ? "&&" : "";
						}
					}
				}

				if (addedParams > 0 && proceed) {
					// get data according to a filter given by user (fieldFilterName i.e: name in db) and its value in form data (conditionalKey)
					fetch(requestUrl.split(" ")[state.indexChanged] + query, {
						method: "GET",
						headers: { ...headers },
					})
						.then((response) => response.json())
						.then((res) => {
							if (res) {
								let gridKeyToFilter = props.component.gridKeyToFilter;
								let gridDataPath = props.component.gridDataPath;
								let path = props.component.dataPath;

								let allData = path ? _.get(res, path, "") : res;
								if (typeof allData === "string") {
									allData = JSON.parse(allData);
								}
								let finalData = allData?.map((e) => ({
									label: props.component.labelPath ? _.get(e, props.component.labelPath.split(" ")[state.indexChanged], "") : e[resLabelName],
									value: props.component.valuePath ? _.get(e, props.component.valuePath.split(" ")[state.indexChanged], "") : e[resValueName],
									...e,
									isIncluded: true,
								}));
								if (gridKeyToFilter !== null && gridKeyToFilter !== undefined && gridKeyToFilter !== "") {
									let filtersDataValues = props.form._data[gridKeyToFilter];
									if (filtersDataValues.length === 0) {
										filtersDataValues = props.form._form.settings.data[gridKeyToFilter] || [];
									}
									let tempVal = [];
									for (let i = 0; i < finalData.length; i++) {
										let recordValue = finalData[i].value;
										let recordFoundInGrid = filtersDataValues.findIndex((g) => g[gridDataPath].value === recordValue);
										if (recordFoundInGrid >= 0) {
											tempVal.push(finalData[i]);
										}
									}

									finalData = tempVal;
								}
								//never allow same value to be selected more than once in grid
								if (props.insideGrid) {
									let localGridFiltersDataValues = props.form._data[props.gridKey];
									if (localGridFiltersDataValues.length === 0) {
										localGridFiltersDataValues = props.form._form.settings.data[props.gridKey] || [];
									}
									for (let i = 0; i < finalData.length; i++) {
										let recordValue = finalData[i].value;
										let recordAlreadySelected = localGridFiltersDataValues.findIndex((g) => g[props.component.key].value === recordValue);
										finalData[i].isIncluded = false;
										if (recordAlreadySelected < 0) {
											finalData[i].isIncluded = true;
										}
									}
									if (state.value.value) {
										let indexOfValue = finalData.findIndex((e) => e.value === state.value.value);
										if ((!props.component.defaultValue && indexOfValue >= 0) || indexOfValue >= 0 || props.component.defaultValue) {
											finalData[indexOfValue].isIncluded = true;
										}
									}
								}
								state["mainData"] = finalData;
								state["conditionalKey"] = conditionalKeys;
								state["mainSelectValue"] = [];

								for (let i = 0; i < conditionalKeys.length; i++) {
									for (let j = 0; j < conditionalKeys[i].length; j++) {
										state.mainSelectValue.push({
											key: conditionalKeys[i][j],
											value: props.form._data[conditionalKeys[i][j]]?.value,
										});
									}
								}

								props.setOldState(state);
							}
						})
						.then(() => {})
						.catch((error) => {
							// state["conditionalKey"] = conditionalKeys;
							// if (props.form._data[props.component.conditionalKey]) {
							//     state["mainSelectValue"] = props.form._data[props.component.conditionalKey]?.value || JSON.parse(props.form._data[props.component.conditionalKey])?.value;
							// }
							// if (props.component.defaultValue !== "" && props.component.defaultValue) {
							//     state["conditionalKey"] = conditionalKeys;
							//     state["value"].value = props.form._data[props.component.key]?.label;
							//     state["mainData"] = [{ label: props.form._data[props.component.key]?.label, value: props.form._data[props.component.key]?.label }];
							// }
							// else {
							//     state["value"]["value"] = "";
							//     state["mainData"] = [{ label: "", value: "" }];
							// }
							// props.setOldState(state);
						});
				} else {
					if (state.indexChanged !== 0) {
						state.indexChanged = 0;
						state.mainData = [];
						props.setOldState(state);
						getAllValues();
					} else {
						state.mainSelectValue = [];
						for (let i = 0; i < conditionalKeys.length; i++) {
							for (let j = 0; j < conditionalKeys[i].length; j++) {
								state.mainSelectValue.push({
									key: conditionalKeys[i][j],
									value: props.form._data[conditionalKeys[i][j]]?.value,
								});
							}
						}
					}
				}
			} else {
				state["mainData"] = [];
				state["mainSelectValue"] = [];

				props.setOldState(state);
			}
		}
	};

	const setOptions = () => {
		let state = props.oldState;
		let includedMainData = state["mainData"].filter((e) => e.isIncluded === true);
		let allData = [];
		//mainData are the records from db, we use them to get translated options
		for (let i = 0; i < includedMainData.length; i++) {
			if (props.form._form.settings && props.form._form.settings?.translatedData && props.form._form.settings?.language) {
				allData.push({
					label: props.form._form.settings.translatedData[props.form._form.settings.language][includedMainData[i].label] || includedMainData[i].label,
					value: includedMainData[i]?.value,
				});
			} else {
				allData.push({
					label: includedMainData[i]?.label,
					value: includedMainData[i]?.value,
				});
			}

			if (props.component.apiFields && props.component.apiFields.length) {
				for (let j = 0; j < props.component.apiFields.length; j++) {
					allData[i][props.component.apiFields[j].apiFieldName] = includedMainData[i][props.component.apiFields[j].apiFieldName];
				}
			}
		}
		state["options"] = allData;
		state["loading"] = false;

		props.setOldState(state);
	};
	useEffect(() => {
		// if ((state["mainSelectValue"] !== props.data[props.component.conditionalKey]?.value && props.data[props.component.conditionalKey]?.value !== undefined)) {
		//     props.form.triggerRedraw();
		// }
		setOptions();
	}, [props.oldState.mainData]);
	//add page data in global form data
	useEffect(() => {
		if (props.data) {
			let keys = Object.keys(props.data);
			for (let i = 0; i < keys.length; i++) {
				props.form._data[keys[i]] = props.data[keys[i]];
				props.form._submission.data[keys[i]] = props.data[keys[i]];
			}
			if (props.insideGrid === true) {
				delete props.form._data[props.component.key];
				delete props.form._submission.data[props.component.key];
			}

			// if (props.form._form.settings !== undefined && props.form._form.settings?.toEdit !== true) {

			//     //if a change happens in form data
			//     props.element.on('change', (event) => {

			//         if (event.changed && props.component.conditionalKey === event.changed.component.key && event.data && props.component.disabled !== true) {

			//             //check if the value of the field we're filtering on changes then get the data associated to it
			//             if (event.data[props.component.conditionalKey] === "" || event.data[props.component.conditionalKey] === null || event.data[props.component.conditionalKey] === undefined) {
			//                 if (JSON.stringify(state.value.value) !== "{}" && state.value.value !== null) {
			//                     props.component.defaultValue = "";
			//                     updateValue({ target: { value: {} } })
			//                 }
			//             }
			//             if ((props.data[props.component.conditionalKey]?.value !== undefined && state["mainSelectValue"] !== props.data[props.component.conditionalKey]?.value && !state.loading)) {

			//                 state["loading"] = true;
			//                 props.setOldState(state);
			//                 getAllValues();

			//             }

			//         }
			//     });
			// }
			//if value of the field we're filtering changes get the updated options
			// if (props.data[props.component.conditionalKey] && props.component.disabled !== true) {

			//     if (state["mainSelectValue"] !== props.data[props.component.conditionalKey]?.value) {
			//         state["mainSelectValue"] = props.data[props.component.conditionalKey]?.value;
			//         props.setOldState(state)
			//         getAllValues();

			//     }

			// }
		}
	}, [props.data]);
	//never allow same value to be selected more than once in grid
	useEffect(() => {
		if (props.insideGrid && props.oldState.mainData.length > 0) {
			let state = props.oldState;
			let localGridFiltersDataValues = props.form._data[props.gridKey];
			if (localGridFiltersDataValues.length === 0) {
				localGridFiltersDataValues = props.form._form.settings.data[props.gridKey] || [];
			}
			let finalData = state.mainData;
			for (let i = 0; i < finalData.length; i++) {
				let recordValue = finalData[i].value;
				let recordAlreadySelected = localGridFiltersDataValues.findIndex((g) => g[props.component.key].value === recordValue);
				if (recordAlreadySelected < 0) {
					finalData[i].isIncluded = true;
				} else {
					finalData[i].isIncluded = false;
				}
			}
			let indexOfValue = finalData.findIndex((e) => e.value === state.value.value);
			if ((!props.component.defaultValue && indexOfValue >= 0) || indexOfValue >= 0 || props.component.defaultValue) {
				finalData[indexOfValue].isIncluded = true;
			}
			state.mainData = finalData;
			props.setOldState(state);
			setOptions();
		}
	}, [props.form._data]);

	return (
		<div className="conditionalDropDownCustomComp">
			<CustomSelect
				component={props.component}
				disabled={props.component.disabled && props.component.disabled === true}
				value={props.oldState.value.value}
				options={props.oldState.options}
				updateValue={updateValue}
			/>
		</div>
	);
};

export default class ConditionalDropDown extends ReactComponent {
	static shouldSetValue = false; // Define shouldSetValue as a static property

	/**
	 * This is the first phase of component building where the component is instantiated.
	 *
	 * @param component - The component definition created from the settings form.
	 * @param options - Any options passed into the renderer.
	 * @param data - The submission data where this component's data exists.
	 */

	constructor(component, options, data) {
		options["oldState"] = {
			value: { value: null },
			conditionalKey: "",
			mainSelectValue: [],
			loading: false,
			indexChanged: 0,
			//records from db
			mainData: [],
			//use mainData to get the options translated according to system language
			options: [],
			initializedInsideGrid: false,
			previousValue: {},
		};

		super(component, options, data);
		this.reactInstance = null;
		this.state = {
			newData: {},
		};
	}

	/**
	 * This function is the default settings for the component. At a minimum you want to set the type to the registered
	 * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
	 *
	 * @param sources
	 * @returns {*}
	 */
	static schema(...extend) {
		return ReactComponent.schema({
			type: "conditionalDropDownCustomComp",
			label: "",
		});
	}
	static get builderInfo() {
		return {
			title: "Conditional Dropdown",
			icon: "square",
			group: "Basic",
			documentation: "",
			weight: -10,
			schema: ConditionalDropDown.schema(),
		};
	}
	static editForm = settingsForm;

	/**
	 * The second phase of component building where the component is rendered as an HTML string.
	 *
	 * @returns {string} - The return is the full string of the component
	 */
	render() {
		// For react components, we simply render as a div which will become the react instance.
		// By calling super.render(string) it will wrap the component with the needed wrappers to make it a full component.
		return super.render(`<div ref="react-${this.id}"></div>`);
	}
	/**
	 * The third phase of component building where the component has been attached to the DOM as 'element' and is ready
	 * to have its javascript events attached.
	 *
	 * @param element
	 * @returns {Promise<void>} - Return a promise that resolves when the attach is complete.
	 */
	attach(element) {
		super.attach(element);
		let randomId = getRandomId();

		// The loadRefs function will find all dom elements that have the "ref" setting that match the object property.
		// It can load a single element or multiple elements with the same ref.
		this.loadRefs(element, {
			[`react-${randomId}`]: "single",
		});

		// if (this.refs[`react-${randomId}`]) {
		//     this.attachReact(this.refs[`react-${randomId}`], this.setReactInstance.bind(this));
		//     if (this.shouldSetValue) {
		//         this.setValue(this.dataForSetting);
		//         this.updateValue(this.dataForSetting);
		//     }
		// }
		return Promise.resolve();
	}

	/**
	 * Override this function to insert your custom component.
	 *
	 * @param element
	 * @param ref - callback ref
	 */
	attachReact(element, ref) {
		const rootForm = this.getRoot(); // Get the root form object

		let insideGrid = false;
		let gridKey = "";
		let grid = "";
		let isGridDisabled = false;
		let key = this.component.key;
		const root = createRoot(element);

		Utils.eachComponent(
			rootForm.components,
			function (component) {
				if (component.component.type === "editgrid") {
					gridKey = component.component.key;
					grid = component;
					Utils.eachComponent(
						component.component.components,
						function (component2) {
							if (!insideGrid) {
								insideGrid = component2.key === key;
								isGridDisabled = component.component.disabled === true;
							}
						},
						true
					);
				}
			},
			true
		);
		if (insideGrid) {
			if (grid.editRows[this.rowIndex]?.state === "new" && !this.options["oldState"].initializedInsideGrid) {
				this.component.defaultValue = null;
				grid.editRows[this.rowIndex].data[key] = null;
				this.options["oldState"].initializedInsideGrid = true;
				this.options["oldState"].rowIndex = this.rowIndex;
			} else {
				this.component.defaultValue = grid.editRows[this.rowIndex]?.data[key] || rootForm._data[this.rowIndex]?.data[key];
			}
		}

		const setOldState = (value) => {
			this.options["oldState"] = value;
			this.updateOnChange({}, true);
			root.render(
				<ConditionalDropDownCustomComp
					component={this.component} // These are the component settings if you want to use them to render the component.
					onChange={this.updateValue} // Pass the onChange event handler
					value={this.dataValue}
					gridKey={gridKey}
					isGridDisabled={isGridDisabled}
					element={this}
					form={rootForm}
					data={this.data}
					insideGrid={insideGrid}
					setOldState={setOldState}
					oldState={this.options["oldState"]}
				/>
			);
		};
		root.render(
			<ConditionalDropDownCustomComp
				component={this.component} // These are the component settings if you want to use them to render the component.
				onChange={this.updateValue} // Pass the onChange event handler
				value={this.dataValue}
				gridKey={gridKey}
				isGridDisabled={isGridDisabled}
				element={this}
				form={rootForm}
				data={this.data}
				insideGrid={insideGrid}
				setOldState={setOldState}
				oldState={this.options["oldState"]}
			/>
		);
	}
}
