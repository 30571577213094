import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import keycloak from "KeycloakConf";
import { EncryptStorage } from "encrypt-storage";
import * as Constants from "./common/constants";
import * as General from "common/general";
import jwtDecode from "jwt-decode";

function KeycloakLogin() {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [kc, setKc] = useState(null);
	const dispatch = useDispatch();
	const keycloackObject = useSelector((state) => state);
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});

	const GetLoginPageSuccess = (responseObject) => {
		localStorage.setItem("domain", responseObject.DomainName);
		localStorage.setItem("domain_id", responseObject.id);
	};

	// Callback for failed API response
	const GetLoginPageFailure = () => {};

	useEffect(() => {
		const domainName = window.location.hostname;
		// Make the API call here using useEffect hook to fetch the login component
		const endPoint = `${Constants.GetEndPoint}/LoginPages/DomainName/${domainName}`;
		General.GetFromEndPoint(endPoint, null, null, GetLoginPageSuccess, GetLoginPageFailure);
		// localStorage.setItem('domain', domainName);
		var accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);
		if (accessToken) {
			const decodedAccessToken = jwtDecode(accessToken);
			const currentTime = Date.now() / 1000;

			if (decodedAccessToken.exp < currentTime) {
				encryptStorage1.removeItem(Constants.AccessTokenKeyInLocalStorage);
				initializeKeycloak();
			}
		} else {
			initializeKeycloak();
		}
	}, []);

	const initializeKeycloak = () => {
		let keycloakInstance = keycloak;
		setKc(keycloakInstance);

		keycloakInstance
			.init({
				checkLoginIframe: false,
				pkceMethod: "S256",
				redirectUri: Constants.RedirectUri,
				onLoad: "login-required",
				// onLoad: 'check-sso'
				// grantType: 'password'
			})
			.then(
				async (auth) => {
					if (!auth) {
						console.info("Not Authenticated");
					} else {
						encryptStorage1.setItem("keyckloakObject", keycloakInstance);

						encryptStorage1.setItem(Constants.AccessTokenKeyInLocalStorage, keycloakInstance.token);

						const decodedAccessToken = jwtDecode(keycloakInstance.token);
						var loggedInUserData = await General.GetLoggedInUserData(decodedAccessToken.sub);

						encryptStorage1.setItem(Constants.AccessTokenKeyInLocalStorage, keycloakInstance.token);

						encryptStorage1.setItem(Constants.LoggedInUserInfoKeyInLocalStorage, loggedInUserData);

						keycloakInstance.onTokenExpired = () => {
							console.log("token expired");
						};
						window.location.href = "/workflowList";
					}
				},
				() => {
					console.error("Authentication Failed");
				}
			);
	};

	const handleLogin = async (event) => {
		event.preventDefault();
		// const authenticated = await kc.login({ username: "ismail.ali@msiuk.com", password: "pass123" });
		kc.login();
		// const loginUrl = kc.createLoginUrl();
		// console.log(authenticated);
	};

	const handleRegister = async (event) => {
		if (kc) {
			event.preventDefault();
			kc.register();
		}
	};

	const handleResetPassword = async (event) => {
		if (kc) {
			event.preventDefault();
			kc.login({
				action: "UPDATE_PASSWORD",
			});
		}
	};

	const handleLogout = async (event) => {
		if (kc) {
			event.preventDefault();
			kc.logout();
		}
	};

	const handleGetUserInfo = async (event) => {
		try {
			event.preventDefault();

			const userInfo = await kc.loadUserInfo();
		} catch (error) {
			console.error("Failed to load user info", error);
		}
	};

	const handleGetToken = (event) => {
		event.preventDefault();
	};

	const handleIsAuthenticated = (event) => {
		event.preventDefault();

		console.log(kc.authenticated ? "Authenticated: TRUE" : "Authenticated: FALSE");
	};

	const handleParsedToken = (event) => {
		event.preventDefault();
	};

	const handleTokenExpired = (event) => {
		event.preventDefault();
	};

	return (
		<div>Loading...</div>
		// <form>
		//     <input
		//         type="text"
		//         placeholder="Username"
		//         value={username}
		//         onChange={(event) => setUsername(event.target.value)}
		//     />
		//     <input
		//         type="password"
		//         placeholder="Password"
		//         value={password}
		//         onChange={(event) => setPassword(event.target.value)}
		//     />
		//     <button className="btn btn-primary" onClick={(event) => handleLogin(event)}>login</button>
		//     <button className="btn btn-primary" onClick={(event) => handleRegister(event)}>register</button>
		//     <button className="btn btn-primary" onClick={(event) => handleResetPassword(event)}>reset password</button>

		//     <button className="btn btn-primary" onClick={(event) => handleLogout(event)}>logout</button>
		//     <button className="btn btn-primary" onClick={(event) => handleGetUserInfo(event)}>getUserInfo</button>
		//     <button className="btn btn-primary" onClick={(event) => handleGetToken(event)}>getToken</button>
		//     <button className="btn btn-primary" onClick={(event) => handleIsAuthenticated(event)}>isAuthenticated</button>
		//     <button className="btn btn-primary" onClick={(event) => handleParsedToken(event)}>parsedToken</button>
		//     <button className="btn btn-primary" onClick={(event) => handleTokenExpired(event)}>tokenExpired</button>
		// </form>
	);
}

export default KeycloakLogin;
