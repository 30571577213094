import React, { Component, useEffect, useState } from "react";
import { ReactComponent, Utils } from "@formio/react";
import settingsForm from "./Map.settingsForm";
import _ from "lodash";
import { createRoot } from "react-dom/client";
import { canConstructReadableStream } from "workbox-core/_private";

/*
This component renders a map and a marker on a location provided by its latitude and longitude
*/
const MapCustomComp = ({ ...props }) => {
	useEffect(() => {
		if (props.form._data[props.component?.x] && props.form._data[props.component?.y]) {
			// Where you want to render the map.
			var element = document.getElementById("osm-map" + props.component.id);
			if (element) {
				// Height has to be set. You can do this in CSS too.
				element.style = "height:300px;";

				// Create Leaflet map on map element.
				var map = L.map(element);

				// Add OSM tile layer to the Leaflet map.
				L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
					attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
				}).addTo(map);

				// Target's GPS coordinates.
				var target = L.latLng(props.form._data[props.component?.y], props.form._data[props.component?.x]);

				// Set map's center to target with zoom 14.
				map.setView(target, 14);

				// Place a marker on the same location.
				L.marker(target).addTo(map);

				// click event
				map.on('click', function(e) {
					var mapUrl = `https://www.google.com/maps/place/${props.form._data[props.component?.y]},${props.form._data[props.component?.x]}`;
					window.open(mapUrl, '_blank');
				});


				element.classList.add("leaflet-custom-map");
			}
		}
	}, []);

	//add page data in global form data
	useEffect(() => {
		if (props.data) {
			let keys = Object.keys(props.data);
			for (let i = 0; i < keys.length; i++) {
				props.form._data[keys[i]] = props.data[keys[i]];
				props.form._submission.data[keys[i]] = props.data[keys[i]];
			}
			if (props.insideGrid === true) {
				delete props.form._data[props.component.key];
				delete props.form._submission.data[props.component.key];
			}
		}
	}, [props.data]);

	return <div id={"osm-map" + props.component.id}></div>;
};

export default class Map extends ReactComponent {
	static shouldSetValue = false; // Define shouldSetValue as a static property

	/**
	 * This is the first phase of component building where the component is instantiated.
	 *
	 * @param component - The component definition created from the settings form.
	 * @param options - Any options passed into the renderer.
	 * @param data - The submission data where this component's data exists.
	 */

	constructor(component, options, data) {
		options["oldState"] = {};
		super(component, options, data);
		this.reactInstance = null;
	}

	/**
	 * This function is the default settings for the component. At a minimum you want to set the type to the registered
	 * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
	 *
	 * @param sources
	 * @returns {*}
	 */
	static schema(...extend) {
		return ReactComponent.schema({
			type: "mapCustomComp",
			label: "Map Information",
		});
	}
	static get builderInfo() {
		return {
			title: "Map Details",
			icon: "cubes",
			group: "Basic",
			documentation: "",
			weight: -10,
			schema: Map.schema(),
		};
	}
	static editForm = settingsForm;

	

	/**
	 * Override this function to insert your custom component.
	 *
	 * @param element
	 * @param ref - callback ref
	 */
	attachReact(element, ref) {
		let insideGrid = false;

		const root = createRoot(element);
		let key = this.component.key;

		const rootForm = this.getRoot(); // Get the root form object

		Utils.eachComponent(
			rootForm.components,
			function (component) {
				if (component.component.type === "editgrid") {
					Utils.eachComponent(
						component.component.components,
						function (component2) {
							if (!insideGrid) {
								insideGrid = component2.key === key;
							}
						},
						true
					);
				}
			},
			true
		);

		

		root.render(
			<MapCustomComp
				component={this.component} // These are the component settings if you want to use them to render the component.
				onChange={this.updateValue} // Pass the onChange event handler
				value={this.dataValue}
				data={this.data}
				form={rootForm}
				oldState={this.options["oldState"]}
				insideGrid={insideGrid}
			/>
		);
	}

	/**
	 * Override this function.
	 */
	detachReact(element) {
		return;
	}

	/**
	 * Something external has set a value and our component needs to be updated to reflect that. For example, loading a submission.
	 *
	 * @param value
	 */
	setValue(value) {
		if (this.reactInstance) {
			this.reactInstance.setState({
				value: value,
			});
			this.shouldSetValue = false;
		} else {
			this.shouldSetValue = true;
			this.dataForSetting = value;
		}
	}

	/**
	 * Override normal validation check to insert custom validation in react component.
	 *
	 * @param data
	 * @param dirty
	 * @param rowData
	 * @returns {boolean}
	 */
	checkValidity(data, dirty, rowData) {
		const valid = super.checkValidity(data, dirty, rowData);
		if (!valid) {
			return false;
		}
		return this.validate(data, dirty, rowData);
	}

	/**
	 * Do custom validation.
	 *
	 * @param data
	 * @param dirty
	 * @param rowData
	 * @returns {boolean}
	 */
	validate(data, dirty, rowData) {
		return true;
	}
}
