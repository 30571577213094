export default [
	{
		media: { icon: ["fab", "sass"], color: "info", className: "fs-4" },
		title: "Bootstrap 5.x",
		description: "Build your webapp with the world's most popular front-end component library along with Falcon's 32 sets of carefully designed elements.",
	},
	{
		media: { icon: ["fab", "node-js"], color: "success", className: "fs-5" },
		title: "SCSS & Javascript files",
		description: "With your purchased copy of Falcon, you will get all the uncompressed & documented SCSS and Javascript source code files.",
	},
	{
		media: { icon: ["fab", "gulp"], color: "danger", className: "fs-6" },
		title: "Gulp based workflow",
		description: "All the painful or time-consuming tasks in your development workflow such as compiling the SCSS or transpiring the JS are automated.",
	},
];
