import React, { useState, useEffect, useRef } from "react";
import { Card, ButtonGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./WorkflowList.css";
import { ToastContainer, toast } from "react-toastify";
import { Row, Col } from "react-bootstrap";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableFooter from "components/common/advance-table/AdvanceTableFooter";
import AdvanceTableSearchBox from "components/common/advance-table/AdvanceTableSearchBox";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";

import {
	GetAllWorflowsEndpoint,
	DeleteWorkflowEndPoint,
	UploadWorkflowEndPoint,
	StartProcessEndPoint,
	GetFormByIdEndPoint,
	GetWorkflowDiagramEndPoint,
	GetWorkflowsEndpoint,
} from "common/constants";
import { startWorkflowProcess } from "components/workflow/StartWorkflowProcess";
import { LogoutUser } from "common/general";
import IconButton from "components/common/IconButton";
import $ from "jquery";
import CardDropdown from "components/common/CardDropdown";
import { Dropdown } from "react-bootstrap";
import * as Constants from "common/constants";
import { useTranslation } from "react-i18next";
import db from "indexedDB";
import * as em from "common/ErrorMessages";
//import 'react-toastify/dist/ReactToastify.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Form, FormGroup } from "reactstrap";
import messages from "../../data/chat/messages";
import { uploadWorkflow, getCountTaskByAssignee, executePendingTasks } from "components/workflow/WorkflowHelper";
import jwtDecode from "jwt-decode";
import { EncryptStorage } from "encrypt-storage";
import store from "reducers/store";
import { INCREMENT_WORKFLOW } from "reducers/flagReducer";

function WorkflowList() {
	const [data, setData] = useState([]);
	const [files, setfiles] = useState([]);
	const [fileName, setfileName] = useState("");
	const navigate = useNavigate();
	const InputRef = useRef();
	const [deploymentId, setDeploymentId] = useState("");
	const [fetchFlag, setFetchFlag] = useState(false);

	const [isSuperAdmin, setIsSuperAdmin] = useState(false);
	const [isInspector, setIsInspector] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [showNotification, setShowNotification] = useState(false);
	const [showPreloader, setshowPreloader] = useState(false);
	const [t, i18n] = useTranslation();
	const location = useLocation();

	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});

	useEffect(() => {
		if (navigator.onLine) {
			executePendingTasks(setSubmitted, setshowPreloader, setShowNotification, navigate, location, t);
		}

		// window.addEventListener("online", async () => {
		// 	console.log("enteredd onlineEventHandler");
		// 	executePendingTasks(setSubmitted, setshowPreloader, setShowNotification, navigate);
		// });
	}, []);

	useEffect(() => {
		async function fetchData() {
			try {
				var accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);
				const decodedAccessToken = jwtDecode(accessToken);
				var selectedCompany = encryptStorage1.getItem(Constants.SelectedCompany);
				const response = await fetch(GetWorkflowsEndpoint + "?userId=" + decodedAccessToken.sub + "&companyId=" + selectedCompany, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + accessToken,
					},
				});

				if (!response.ok) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}

				const responseText = await response.text();

				if (!responseText) {
					throw new Error("Received empty response from the server.");
				}

				try {
					const newData = JSON.parse(responseText);
					let arrayOfWorkflows = Object.values(newData.workflowsResponseList);
					const pendingTasks2 = arrayOfWorkflows.flatMap((task) => {
						let parsedTask;
						try {
							parsedTask = typeof task === "string" ? JSON.parse(task) : task;
						} catch (e) {
							console.error("Error parsing task:", task, e);
							return []; // Skip this task if it can't be parsed
						}
						parsedTask.name = t(parsedTask.name);
						// console.log(t(parsedTask.name));
						// console.log(t("Yes"));
						parsedTask.action = (
							<>
								<OverlayTrigger
									overlay={
										<Tooltip style={{ position: "fixed" }} id="StartProcess">
											{t("Start Process")}
										</Tooltip>
									}
								>
									<div className="theme-control-toggle-label icon-position">
										<IconButton
											className="play-hover"
											iconClassName="fs--2"
											variant="falcon-default"
											size="sm"
											icon="fa-solid fa-play"
											style={{
												margin: "auto 0.25rem",
												backgroundColor: "#00a862",
												borderColor: "#00a862",
											}}
											onClick={() => startProcess(parsedTask.id)}
										></IconButton>
									</div>
								</OverlayTrigger>
								{isSuperAdmin && (
									<OverlayTrigger
										overlay={
											<Tooltip style={{ position: "fixed" }} id="StartProcess">
												{t(em.design_view)}
											</Tooltip>
										}
									>
										<div className="theme-control-toggle-label icon-position">
											<IconButton
												className="botton-hover"
												iconClassName="fs--2"
												variant="falcon-default"
												size="sm"
												icon="fa-solid fa-eye"
												style={{ margin: "auto 0.25rem" }}
												onClick={() => viewDiagram(parsedTask.id)}
											></IconButton>
										</div>
									</OverlayTrigger>
								)}
								{isSuperAdmin && (
									<OverlayTrigger
										overlay={
											<Tooltip style={{ position: "fixed" }} id="StartProcess">
												{t(em.edit_design)}
											</Tooltip>
										}
									>
										<div className="theme-control-toggle-label icon-position">
											<IconButton
												className="botton-hover"
												iconClassName="fs--2"
												variant="falcon-default"
												size="sm"
												icon="fa-solid fa-edit"
												style={{ margin: "auto 0.25rem" }}
												onClick={() => editDiagram(parsedTask.id)}
											></IconButton>
										</div>
									</OverlayTrigger>
								)}
								{isSuperAdmin && (
									<OverlayTrigger
										overlay={
											<Tooltip style={{ position: "fixed" }} id="StartProcess">
												{t("Delete")}
											</Tooltip>
										}
									>
										<div className="theme-control-toggle-label icon-position">
											<IconButton
												className="botton-hover"
												iconClassName="fs--2 text-danger"
												variant="falcon-default"
												size="sm"
												icon="fa-solid fa-trash"
												style={{ margin: "auto 0.25rem" }}
												onClick={() => openModal(parsedTask.deploymentId)}
											></IconButton>
										</div>
									</OverlayTrigger>
								)}
							</>
						);
						return parsedTask;
					});
					if (newData.httpResponse.status === 0) {
						toast.error(newData.httpResponse.message, { autoClose: 3000 });
					} else {
						setData(pendingTasks2);
					}
				} catch (jsonError) {
					console.error("Response text:", responseText);
				}
			} catch (networkError) {
				console.error("Fetch error:", networkError);
			}
			//}
		}

		fetchData();
	}, [fetchFlag]);

	const [modal, setModal] = useState(false);
	const openModal = (id) => {
		setModal(!modal);
		setDeploymentId(id);
	};
	const toggle = () => setModal(!modal);

	const handleAdd = () => {
		// Handle edit logic
		/*  let isExpired = CheckIfTokenExpired();
         if(isExpired)
         {
             LogoutUser();
             return;
         } */
		navigate("/workflowModeler", {
			replace: true,
			state: { data: null },
		});
	};

	const handleDelete = () => {
		/* let isExpired = CheckIfTokenExpired();
        if(isExpired)
        {
            LogoutUser();
            return;
        } */
		setModal(!modal);
		fetch(DeleteWorkflowEndPoint + deploymentId, {
			method: "DELETE",
		})
			.then((res) => res.json())
			.then((data) => {
				//useEffect();
				if (data.status === 1) {
					setFetchFlag(!fetchFlag);
					toast.success(t(em.deleted_successfully));
				} else {
					toast.error(t(em.failed_delete_workflow));
				}
			})
			.catch((err) => {
				console.log(err.message);
			});
	};
	const startProcess = async (processId) => {
		await db.table("temp_form_data").clear();

		// localStorage.removeItem("TempFormData")
		startWorkflowProcess(processId, true)
			.then((formData) => {
				localStorage.setItem(Constants.CurrentWorkflowStep, JSON.stringify(formData));
				navigate("/form_view_renderer", { state: { data: formData } });
				store.dispatch({ type: INCREMENT_WORKFLOW });
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const handleFileUpload = (e) => {
		/*  let isExpired = CheckIfTokenExpired();
         if(isExpired)
         {
             LogoutUser();
             return;
         } */
		let files = e.target.files;
		// let uploaded_file_name = e.target.files[0]?e.target.files[0].name:"";
		let uploaded_file_name = e.target.files[0].name;
		if (files) {
			setfiles(files[0]);
			setfileName(uploaded_file_name);
			/*InputRef.current.value = files;*/
			/*this.setState({ files: files[0] }, () => {
                console.log(this.state.files);
            });*/
			toggleCloseIcon();
		}
	};
	const handleReset = (e) => {
		InputRef.current.value = "";
		setfileName("");
		setfiles([]);
		$(".close-icon").attr("hidden", "hidden");
	};
	const fileValidation = (filePath) => {
		var allowedExtensions = /(\.bpmn)$/i; //|\.jpeg|\.png|\.gif
		if (!allowedExtensions.exec(filePath)) {
			toast.error(t(em.upload_file_extensions_bpmn), {
				position: "top-center",
				autoClose: 3000,
			});
			return false;
		} else return true;
	};
	const toggleCloseIcon = () => {
		if (InputRef.current.value != "") {
			$(".close-icon").removeAttr("hidden");
		} else {
			$(".close-icon").attr("hidden", "hidden");
		}
	};

	const columns = [
		{
			accessor: "name",
			Header: t("Name"),
		},
		{
			accessor: "action",
			Header: t("Action"),
		},
	];
	const uploadFile = (file) => {
		if (file.size != undefined && file.size > 0) {
			let is_valid = fileValidation(file.name);
			if (is_valid) {
				const formData = new FormData();
				formData.append("file", file);
				fetch(UploadWorkflowEndPoint, {
					mode: "cors",
					method: "POST",
					body: formData,
				})
					.then((response) => response.json()) //response.json())
					.then((data) => {
						if (data.status === 1) {
							handleReset();
							//InputRef.current.value = "";
							setFetchFlag(!fetchFlag);
							toast.success(t(em.uploaded_successfully));
						} else {
							toast.error(t(em.failed_upload_workflow) + " " + data.message);
						}
					})
					.catch((err) => {
						console.log(err.message);
					});
			}
		} else {
			toast.error(t(em.select_workflow_upload), {
				position: "top-center",
				autoClose: 3000,
			});
		}
	};
	const viewDiagram = (procesDefinitionId) => {
		// console.log(procesDefinitionId)
		fetch(GetWorkflowDiagramEndPoint + procesDefinitionId, {
			method: "GET",
		})
			.then((res) => res.json())
			.then((data) => {
				//useEffect();
				if (data.id != null || data.id != undefined) {
					toast.success(t(em.loaded_successfully));
					// navigate('/workflowModeler', { state: { data: data.data.workflowDiagram.bpmn20Xml } });

					navigate("/workflowDiagramViewer", {
						replace: true,
						state: { data: data.bpmn20Xml },
					});
				} else {
					toast.error(t(em.failed_load_workflow_diagram));
				}
			})
			.catch((err) => {
				console.log(err.message);
			});
	};

	const editDiagram = (procesDefinitionId) => {
		console.log(procesDefinitionId);
		fetch(GetWorkflowDiagramEndPoint + procesDefinitionId, {
			method: "GET",
		})
			.then((res) => res.json())
			.then((data) => {
				//useEffect();
				if (data.id != null || data.id != undefined) {
					toast.success("Loaded successfully in the editor");
					// navigate('/workflowModeler', { state: { data: data.data.workflowDiagram.bpmn20Xml } });

					navigate("/workflowModelerEditMode", {
						replace: true,
						state: { data: data.bpmn20Xml },
					});
				} else {
					toast.error("Failed to load desired workflow diagram in the editor");
				}
			})
			.catch((err) => {
				console.log(err.message);
			});
	};

	return (
		<div className="container">
			<div className="mb-3 card">
				<div className="card-body">
					<div className="col-md">
						<h5 className="mb-2 mb-md-0">{t(em.workflow_list)}</h5>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-12">
					{/* <h1>Workflow List</h1>*/}
					<div className="card">
						{isSuperAdmin && (
							<div className="tool-header">
								<OverlayTrigger
									overlay={
										<Tooltip style={{ position: "fixed" }} id="StartProcess">
											{t(em.new_workflow)}
										</Tooltip>
									}
								>
									<div className="theme-control-toggle-label icon-position">
										<IconButton
											className="btn-primary botton-circle"
											iconClassName="fs--5"
											size="sm"
											icon="fa-solid fa-circle-plus"
											style={{
												margin: "auto 0.25rem",
												padding: "9px 10px 10px",
											}}
											onClick={() => handleAdd()}
										></IconButton>
									</div>
								</OverlayTrigger>

								<OverlayTrigger
									overlay={
										<Tooltip style={{ position: "fixed" }} id="StartProcess">
											{t(em.upload)}
										</Tooltip>
									}
								>
									<div className="theme-control-toggle-label icon-position">
										<IconButton
											className="botton-hover botton-circle"
											iconClassName="fs--5"
											variant="falcon-default"
											size="sm"
											icon="fa-solid fa-upload"
											style={{ margin: "auto 0.25rem", padding: "9px 10px" }}
											onClick={() => uploadFile(files)}
										></IconButton>
									</div>
								</OverlayTrigger>
								<OverlayTrigger
									overlay={
										<Tooltip style={{ position: "fixed" }} id="StartProcess">
											{t("Choose File")}
										</Tooltip>
									}
								>
									<div className="theme-control-toggle-label icon-position">
										<label htmlFor="file-input" className="custom-file-upload2">
											<i className="fa fa-file"></i>
										</label>
										<input id="file-input" ref={InputRef} onChange={handleFileUpload} type="file" style={{ display: "none" }} accept=".bpmn" />
										<div id="display-file">{fileName}</div>
									</div>
								</OverlayTrigger>

								{/* <input
                {/* <input
                                id="file-input"
                                ref={InputRef}
                                onChange={handleFileUpload}
                                type="file"
                               
                                accept=".bpmn"
                                
                            /> */}
								<button className="close-icon" onClick={() => handleReset()} hidden></button>
							</div>
						)}
						<div className="card-body">
							<div className="mb-3">
								<AdvanceTableWrapper columns={columns} data={data} sortable pagination perPage={10}>
									<Row className="flex-end-center mb-3">
										<Col xs="auto" sm={6} lg={4}>
											<label>{t("Search")}</label>
											<AdvanceTableSearchBox table placeholder={t("Search")} />
										</Col>
									</Row>
									<AdvanceTable
										table
										headerClassName="bg-200 text-900 text-nowrap align-middle"
										rowClassName="align-middle white-space-nowrap"
										tableProps={{
											bordered: true,
											striped: true,
											className: "fs--1 mb-0 overflow-hidden",
										}}
									/>
									<div className="mt-3">
										<AdvanceTableFooter rowCount={data.length} table rowInfo navButtons rowsPerPageSelection />
									</div>
								</AdvanceTableWrapper>
							</div>
						</div>
					</div>

					<Modal isOpen={modal} toggle={toggle} className="WorkflowList" backdrop={false} keyboard={true}>
						<ModalHeader toggle={toggle}>Delete!!</ModalHeader>
						<ModalBody>Are you sure you want to delete?</ModalBody>
						<ModalFooter>
							<Button color="primary" onClick={() => handleDelete()}>
								Delete
							</Button>
							<Button color="secondary" onClick={toggle}>
								Cancel
							</Button>
						</ModalFooter>
					</Modal>
				</div>
			</div>
		</div>
	);
}

export default WorkflowList;
