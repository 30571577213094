import { GetFormByIdEndPoint, StartProcessByDefinitionKeyEndPoint } from "common/constants";
import { CheckIfTokenExists, GetFromEndPoint, PostToEndPoint } from "common/general";

async function startProcess(definitionKey, userName, hash, requestDateTime) {
	try {
		//CheckIfTokenExists();

		const startProcessResponse = await new Promise((resolve, reject) => {
			const PostSuccessCallBack = (response) => {
				resolve(response);
			};

			const PostFailureCallBack = (response) => {
				reject(response);
			};

			PostToEndPoint(StartProcessByDefinitionKeyEndPoint + definitionKey + "/" + true, {}, {}, PostSuccessCallBack, PostFailureCallBack);
		});

		const taskID = startProcessResponse.getCurrentServiceTask.id;
		const processInstanceId = startProcessResponse.getCurrentServiceTask.processInstanceId;

		const formData = {
			taskID,
			processInstanceId,
			userName,
			hash,
			requestDateTime,
		};

		return formData;
	} catch (error) {
		throw error;
	}
}

export { startProcess };
