import { RegistrationProcessDefinitionKey, DeleteProcessInstance } from "common/constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Card, ButtonGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableFooter from "components/common/advance-table/AdvanceTableFooter";
import AdvanceTableSearchBox from "components/common/advance-table/AdvanceTableSearchBox";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import IconButton from "components/common/IconButton";
import React, { useEffect, useState } from "react";
//import { startProcess } from "components/workflow/VerifyEmailWorkflow";
import { useNavigate, useLocation } from "react-router-dom";
import { parse } from "date-fns";
import {
	getCountTaskByAssignee,
	continueProcess,
	formatDate,
	getTaskVariables,
	getWorkflowListByAssignee,
	getWorkflowsHistoryByProcessInstance,
	modifyProcessInstanceVariable,
	parseDateAndTime,
	getDatabaseRecord,
	getTask,
	getExecutionLocalVariables,
	getAllDatabaseRecords,
	getFormById,
	executePendingTasks,
	parseQueryParams,
	getWorkflowListByGroups,
	claimTask,
	getPendingTasks,
	getData,
	renderCompanyData,
} from "./WorkflowHelper";
import * as Constants from "common/constants";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import db from "indexedDB";
import * as em from "common/ErrorMessages";
import jwtDecode from "jwt-decode";
import { EncryptStorage } from "encrypt-storage";
import * as General from "common/general";
import { toast } from "react-toastify";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import NavbarVerticalMenuItem from "components/navbar/vertical/NavbarVerticalMenuItem";

import { useDispatch, useSelector } from "react-redux";
import { DECREMENT_WORKFLOW } from "reducers/flagReducer";
import store from "reducers/store";
import LoaderIcon from "components/custom/Preloader";
import { Utils } from "formiojs";
import UpliftCss from "../common/upliftcss.module.css";

function GetWorkflowListByAssignee() {
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});
	const navigate = useNavigate();

	const [isCurrentUserEmail, setCurrentUserEmail] = useState("");
	const [isGeneratorAdmin, setisGeneratorAdmin] = useState(false);
	// const [isabledelete, setisabledelete] = useState(false);
	const [task_id, settask_id] = useState("");
	const [table_name, settable_name] = useState("");
	const [emanifestId, setemanifestId] = useState("");
	const [fetchFlag, setFetchFlag] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [isRTL, setIsRTL] = useState(JSON.parse(localStorage.getItem("isRTL")) || false);
	const [showNotification, setShowNotification] = useState(false);
	const [showPreloader, setshowPreloader] = useState(false);
	const [isFinish, setIsFinish] = useState(null);

	const [modal, setModal] = useState(false);
	const openModal = (id, tablename, emanifestid) => {
		setModal(!modal);
		settask_id(id);
		settable_name(tablename);
		setemanifestId(emanifestid);
	};

	useEffect(() => {
		const handleScan = async (generatedEmanifestId) => {
			var pendingTaskRecords = await db.pending_tasks.toArray();
			for (let i = 0; i < pendingTaskRecords.length; i++) {
				var pendingTask = JSON.parse(pendingTaskRecords[i].pendingTasks);
				if (pendingTask.generatedEmanifestID === generatedEmanifestId) {
					handleContinue(pendingTaskRecords[i].id);
					return;
				}
			}
			const companyId = encryptStorage1.getItem(Constants.SelectedCompany);

			const workflowList = await getWorkflowListByGroups(companyId);

			let workflows = await Promise.all(
				workflowList.workflowsByAssigneeList.map(async (workflow) => {
					workflow.taskId = workflow.id;
					const executionId = workflow.executionId;
					const tableName = workflow.processDefinition.key;
					const processInstanceId = workflow.processInstanceId;
					const taskVariables = await getTaskVariables(processInstanceId);
					let executionLocalVariables = await getExecutionLocalVariables(executionId);
					executionLocalVariables = executionLocalVariables;

					if (executionLocalVariables.gridInformation !== undefined) {
						let grid_information = "";
						for (const element of Object.values(executionLocalVariables.gridInformation.value)) {
							grid_information += ", " + element;
						}
						workflow.processInformation = grid_information;
						setProcessInformation(grid_information);
					}

					let generatedEmanifestID = taskVariables.find((variable) => variable.name === "generatedEmanifestID");
					if (generatedEmanifestID != undefined) {
						workflow.generatedEmanifestID = generatedEmanifestID.value;
					}

					return workflow;
				})
			);

			for (let i = 0; i < workflows.length; i++) {
				if (workflows[i].generatedEmanifestID === generatedEmanifestId) {
					const userInfo = encryptStorage1.getItem(Constants.LoggedInUserInfoKeyInLocalStorage);
					const loggedInUser = userInfo.userId;
					await claimTask(workflows[i].taskId, loggedInUser);
					setIsFinish(true);
					setFetchFlag(true);
					return;
				}
			}
		};
		if (!isFinish && isFinish !== null) {
			const currentUrl = window.location.href;

			var queryParams = parseQueryParams(currentUrl);

			if (queryParams.generatedEmanifestId) {
				handleScan(queryParams.generatedEmanifestId);
				//handleContinue();
			}
		}
	}, [isFinish]);

	useEffect(() => {
		const handleContinueWorkflowStep = async (currentStep) => {
			var current_workflow_step = await db.current_workflow_step.toArray();
			if (current_workflow_step.length > 0) {
				currentStep = JSON.parse(current_workflow_step[0].currentWorkflowStep);
			}
			var pendingTaskRecords = await db.pending_tasks.toArray();
			sessionStorage.setItem(Constants.HasRedirected, true);
			for (let i = 0; i < pendingTaskRecords.length; i++) {
				var pendingTask = JSON.parse(pendingTaskRecords[i].pendingTasks);
				var generatedEmanifestID = null;
				if (currentStep.taskVariables) {
					if (currentStep.taskVariables.find((variable) => variable.name === "generatedEmanifestID") !== undefined) {
						generatedEmanifestID = currentStep.taskVariables.find((variable) => variable.name === "generatedEmanifestID").value;
						if (pendingTask.generatedEmanifestID === generatedEmanifestID && generatedEmanifestID) {
							await db.table("current_workflow_step").clear();
							localStorage.removeItem(Constants.CurrentWorkflowStep);
							handleContinue(pendingTaskRecords[i].id);
							break;
						}
					}
				}
			}
			await db.table("current_workflow_step").clear();
			localStorage.removeItem(Constants.CurrentWorkflowStep);
		};

		const currentUrl = window.location.href;

		var queryParams = parseQueryParams(currentUrl);
		if (!isFinish && isFinish !== null && !queryParams.generatedEmanifestId) {
			const currentStep = localStorage.getItem(Constants.CurrentWorkflowStep);
			const hasRedirected = sessionStorage.getItem(Constants.HasRedirected);
			if (currentStep && hasRedirected === null) {
				handleContinueWorkflowStep(JSON.parse(currentStep));
			}
		}
	}, [isFinish]);

	const handleDelete = async () => {
		setModal(!modal);
		const response = await fetch(DeleteProcessInstance + task_id, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
			},
		})
			//  if (response.ok) {
			//   const data =await response.json();
			.then((response) => response.json()) //response.json())
			.then((data) => {
				if (data.status === 1) {
					const DeleteRecordSuccess = (responseObject) => {
						if (responseObject.status == Constants.SuccessStatus) {
							toast.success(t(em.deleted_successfully));
							setFetchFlag(true);

							store.dispatch({ type: DECREMENT_WORKFLOW });
						}
					};
					const DeleteRecordFailure = (error) => {};

					var deletereord = Constants.DeleteDBRecordEndPoint + table_name + "/" + task_id;
					General.DeleteFromEndPoint(deletereord, null, null, DeleteRecordSuccess, DeleteRecordFailure);
				} else {
					toast.error(t(em.failed_delete_workflow));
				}
			})
			.catch((err) => {
				console.error(err.message);
			});
	};

	const toggle = () => setModal(!modal);
	const [workflows, setWorkflows] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [originalData, setOriginalData] = useState([]);
	const [processInformation, setProcessInformation] = useState("");
	const [t, i18n] = useTranslation();
	const location = useLocation();
	// add table headers and accessor is the name of the field in the json
	const parseDate = (dateString) => parse(dateString, "dd-MM-yyyy HH:mm:ss", new Date());
	const columns = [
		{
			accessor: "generatedEmanifestID",
			Header: t("Process ID"),
		},
		{
			accessor: "name",
			Header: t("Process Name"),
		},
		{
			accessor: "taskName",
			Header: t("User Task Name"),
		},
		{
			accessor: "created",
			Header: t("Creation Date"),
			type: "date",
			sortType: (rowA, rowB) => {
				const dateA = parseDate(rowA.values.created);
				const dateB = parseDate(rowB.values.created);
				return dateA - dateB;
			},
		},
		{
			accessor: "action",
			Header: t("Action"),
		},
	];
	const [startDateFilter, setStartDateFilter] = useState();
	const [endDateFilter, setEndDateFilter] = useState();
	const handleStartDateChange = (date) => {
		if (date) {
			const formattedDate = moment(date).format("DD-MM-YYYY");
			setStartDateFilter(formattedDate);
		} else {
			// Handle the case when the date is cleared
			setStartDateFilter("");
		}
	};
	const handleEndDateChange = (date) => {
		if (date) {
			const formattedDate = moment(date).format("DD-MM-YYYY");
			setEndDateFilter(formattedDate);
		} else {
			// Handle the case when the date is cleared
			setEndDateFilter("");
		}
	};
	const filterdata = (data) => {
		let filter = [];
		data.map((workflow) => {
			const searchgeneratedEmanifestID = t(workflow.generatedEmanifestID);
			const searchname = t(workflow.name);
			const searchtaskName = t(workflow.taskName);
			if (startDateFilter && endDateFilter) {
				if (moment(workflow.created, "DD-MM-YYYY").isBetween(moment(startDateFilter, "DD-MM-YYYY"), moment(endDateFilter, "DD-MM-YYYY"), null, "[]")) {
					filter.push(workflow);
				}
			} else if (startDateFilter && !endDateFilter) {
				if (moment(workflow.created, "DD-MM-YYYY").isSameOrAfter(moment(startDateFilter, "DD-MM-YYYY"))) {
					filter.push(workflow);
				}
			} else if (!startDateFilter && endDateFilter) {
				if (moment(workflow.created, "DD-MM-YYYY").isSameOrBefore(moment(endDateFilter, "DD-MM-YYYY"))) {
					filter.push(workflow);
				}
			} else {
				filter.push(workflow);
			}
		});

		return filter;
	};

	useEffect(() => {
		let NewData = filterdata(originalData);
		setTableData(NewData);
		setWorkflows(NewData);
	}, [startDateFilter, endDateFilter]);

	useEffect(() => {
		async function fetchData() {
			setIsFinish(true);
			if (!navigator.onLine) {
				var submissionData = await db.submission_data.toArray();
				var pendingTasks = await db.pending_tasks.toArray();
				for (let i = 0; i < submissionData.length; i++) {
					var index = JSON.parse(submissionData[i].submissionData).index;
					if (index !== undefined) {
						for (let j = 0; j < pendingTasks.length; j++) {
							if (pendingTasks[j].id === parseInt(index, 10)) {
								const parsedPendingTasks = JSON.parse(pendingTasks[j].pendingTasks);
								parsedPendingTasks.name += ":OFFLINE-EXECUTED";
								pendingTasks[j].pendingTasks = JSON.stringify(parsedPendingTasks);
							}
						}
					}
				}
				pendingTasks = pendingTasks.flatMap((task) => {
					const parsedTask = JSON.parse(task.pendingTasks);
					parsedTask.created = moment(new Date(parsedTask.created)).format("DD-MM-YYYY HH:mm:ss");
					parsedTask.taskName = parsedTask.name ? t(parsedTask.name) : "";
					parsedTask.name = t(parsedTask.processDefinition.name) + (parsedTask.processInformation ? t(parsedTask.processInformation) : "");
					parsedTask.index = task.id;
					parsedTask.action = (
						<>
							{!parsedTask?.name.includes("OFFLINE") && (
								<OverlayTrigger
									overlay={
										<Tooltip style={{ position: "fixed" }} id="idsubmit">
											{t(em.execute_task)}
										</Tooltip>
									}
								>
									<div className="theme-control-toggle-label icon-position">
										<IconButton
											className={`play-hover  ${UpliftCss.forceButtonColorwhite}`}
											iconClassName="fs--2"
											variant="falcon-default"
											size="sm"
											icon="fa fa-play"
											style={{
												margin: "auto 0.25rem",
												backgroundColor: "#00a862",
												borderColor: "#00a862",
											}}
											onClick={() => handleContinue(parsedTask.index)}
										></IconButton>
									</div>
								</OverlayTrigger>
							)}
							{parsedTask.formData?.hyperLinkVariables && (
								<OverlayTrigger
									overlay={
										<Tooltip style={{ position: "fixed" }} id="idviewsummary">
											{t("View Summary")}
										</Tooltip>
									}
								>
									<div className="theme-control-toggle-label icon-position">
										<IconButton
											className={`play-hover  ${UpliftCss.forceButtonColorwhite}`}
											iconClassName="fs--2"
											variant="falcon-default"
											size="sm"
											icon="fa-solid fa-eye"
											style={{
												margin: "auto 0.25rem",
												backgroundColor: "#00a862",
												borderColor: "#00a862",
											}}
											onClick={() => viewSummary(parsedTask.index)}
										></IconButton>
									</div>
								</OverlayTrigger>
							)}

							{parsedTask !== null && parsedTask?.taskVariables?.find((variable) => variable.name === "renderId") && (
								<OverlayTrigger
									overlay={
										<Tooltip style={{ position: "fixed" }} id="DownloadPdfTooltip">
											{t("Download PDF")}
										</Tooltip>
									}
								>
									<div className="theme-control-toggle-label icon-position end-text">
										<IconButton
											className={`btn btn-primary  ${UpliftCss.forceButtonColorwhite}`}
											iconClassName="fs--2"
											variant=""
											size="sm"
											icon="fa-solid fa-download"
											style={{ margin: "auto 0.25rem" }}
											onClick={() => downloadPdf(parsedTask?.taskVariables?.find((variable) => variable.name === "renderId").value)}
										></IconButton>
									</div>
								</OverlayTrigger>
							)}

							{parsedTask !== null && parsedTask.ableToDelete !== "false" && parsedTask.enableDelete && (
								<OverlayTrigger
									overlay={
										<Tooltip style={{ position: "fixed" }} id="abletodelete">
											{t(em.delete_icon)}
										</Tooltip>
									}
								>
									<div className="theme-control-toggle-label icon-position">
										<IconButton
											className="btn btn-danger"
											iconClassName="fs--2"
											variant=""
											size="sm"
											icon="fa-solid fa-trash"
											style={{ margin: "auto 0.25rem" }}
											onClick={() => openModal(parsedTask.processInstanceId, parsedTask.processDefinition.key, parsedTask.generatedEmanifestID)}
										></IconButton>
									</div>
								</OverlayTrigger>
							)}
						</>
					);
					return parsedTask;
				});

				var sortedAssigneeList = pendingTasks.sort((a, b) => {
					const dateA = new Date(
						formatDate(a.created).slice(6, 10), // YYYY
						formatDate(a.created).slice(3, 5) - 1, // MM (subtract 1 because months are zero-based)
						formatDate(a.created).slice(0, 2), // DD
						formatDate(a.created).slice(11, 13), // HH
						formatDate(a.created).slice(14, 16), // mm
						formatDate(a.created).slice(17, 19) // ss
					);

					const dateB = new Date(
						formatDate(b.created).slice(6, 10),
						formatDate(b.created).slice(3, 5) - 1,
						formatDate(b.created).slice(0, 2),
						formatDate(b.created).slice(11, 13),
						formatDate(b.created).slice(14, 16),
						formatDate(b.created).slice(17, 19)
					);
					return dateB - dateA; // Compare as Date objects
				});
				setOriginalData(sortedAssigneeList);
				setTableData(sortedAssigneeList);
				setWorkflows(sortedAssigneeList);
			} else {
				// const delay1 = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
				// await delay1(2000);

				const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

				await executePendingTasks(setSubmitted, setshowPreloader, setShowNotification, navigate, location, t);
				await delay(100);
				var pendingTasks1 = await db.pending_tasks.toArray();
				var indexDbPendingIds = [];
				for (let j = 0; j < pendingTasks1.length; j++) {
					indexDbPendingIds.push(JSON.parse(pendingTasks1[j].pendingTasks).id);
				}

				// await Promise.all([db.table("submission_data").clear(), db.table("pending_tasks").clear()]);
				await Promise.all([db.table("submission_data").clear()]);

				const userInfo = encryptStorage1.getItem(Constants.LoggedInUserInfoKeyInLocalStorage);
				const loggedInUser = userInfo.userId;

				const workflowList = await getPendingTasks(loggedInUser, indexDbPendingIds);

				for (let j = pendingTasks1.length - 1; j >= 0; j--) {
					let parsedTask = JSON.parse(pendingTasks1[j].pendingTasks);
					if (workflowList.idsToBeRemoved.includes(parsedTask.id)) {
						await db.pending_tasks.delete(pendingTasks1[j].id);
					}
				}

				for (let i = 0; i < workflowList.lPendingTaskResponseDTO.length; i++) {
					const jsonString = JSON.stringify(workflowList.lPendingTaskResponseDTO[i]);
					let components = JSON.parse(jsonString).formData.components;
					Utils.eachComponent(
						components,
						function (component) {
							if (component.input) {
								if (component.type === "userCompanyCustomComp") {
									getData(component, workflowList.lPendingTaskResponseDTO[i].result);
									renderCompanyData(component);
								}
							} else if (component.type === "columns") {
								Utils.eachComponent(
									component.columns,
									function (component2) {
										if (component2.type === "userCompanyCustomComp") {
											getData(component2, workflowList.lPendingTaskResponseDTO[i].result);
											renderCompanyData(component);
										}
									},
									true
								);
							}
						},
						true
					);
					await db.pending_tasks.add({ pendingTasks: jsonString });
				}

				if ("serviceWorker" in navigator) {
					const registration = await navigator.serviceWorker.ready;
					registration.active.postMessage({
						action: "deleteFromCache",
						pattern: "/form_view_renderer",
					});

					const pendingTasks = await db.pending_tasks.toArray();
					for (const task of pendingTasks) {
						registration.active.postMessage({
							action: "cachePage",
							url: "/form_view_renderer?index=" + task.id,
						});
					}
				}

				const pendingTasks = await db.pending_tasks.toArray();
				const pendingTasks2 = pendingTasks.flatMap((task) => {
					const parsedTask = JSON.parse(task.pendingTasks);
					parsedTask.created = moment(new Date(parsedTask.created)).format("DD-MM-YYYY HH:mm:ss");
					parsedTask.taskName = parsedTask.name ? t(parsedTask.name) : "";
					parsedTask.name = t(parsedTask.processDefinition.name) + (parsedTask.processInformation ? t(parsedTask.processInformation) : "");
					parsedTask.index = task.id;
					parsedTask.action = (
						<>
							{!parsedTask?.name.includes("OFFLINE") && (
								<OverlayTrigger
									overlay={
										<Tooltip style={{ position: "fixed" }} id="idsubmit">
											{t(em.execute_task)}
										</Tooltip>
									}
								>
									<div className="theme-control-toggle-label icon-position">
										<IconButton
											className={`play-hover  ${UpliftCss.forceButtonColorwhite}`}
											iconClassName="fs--2"
											variant="falcon-default"
											size="sm"
											icon="fa fa-play"
											style={{
												margin: "auto 0.25rem",
												backgroundColor: "#00a862",
												borderColor: "#00a862",
											}}
											onClick={() => handleContinue(parsedTask.index)}
										></IconButton>
									</div>
								</OverlayTrigger>
							)}
							{parsedTask.formData?.hyperLinkVariables && (
								<OverlayTrigger
									overlay={
										<Tooltip style={{ position: "fixed" }} id="idviewsummary">
											{t("View Summary")}
										</Tooltip>
									}
								>
									<div className="theme-control-toggle-label icon-position">
										<IconButton
											className={`play-hover  ${UpliftCss.forceButtonColorwhite}`}
											iconClassName="fs--2"
											variant="falcon-default"
											size="sm"
											icon="fa-solid fa-eye"
											style={{
												margin: "auto 0.25rem",
												backgroundColor: "#00a862",
												borderColor: "#00a862",
											}}
											onClick={() => viewSummary(parsedTask.index)}
										></IconButton>
									</div>
								</OverlayTrigger>
							)}

							{parsedTask !== null && parsedTask?.taskVariables?.find((variable) => variable.name === "renderId") && (
								<OverlayTrigger
									overlay={
										<Tooltip style={{ position: "fixed" }} id="DownloadPdfTooltip">
											{t("Download PDF")}
										</Tooltip>
									}
								>
									<div className="theme-control-toggle-label icon-position end-text">
										<IconButton
											className={`btn btn-primary  ${UpliftCss.forceButtonColorwhite}`}
											iconClassName="fs--2"
											variant=""
											size="sm"
											icon="fa-solid fa-download"
											style={{ margin: "auto 0.25rem" }}
											onClick={() => downloadPdf(parsedTask?.taskVariables?.find((variable) => variable.name === "renderId").value)}
										></IconButton>
									</div>
								</OverlayTrigger>
							)}

							{parsedTask !== null && parsedTask.ableToDelete !== "false" && parsedTask.enableDelete && (
								<OverlayTrigger
									overlay={
										<Tooltip style={{ position: "fixed" }} id="abletodelete">
											{t(em.delete_icon)}
										</Tooltip>
									}
								>
									<div className="theme-control-toggle-label icon-position">
										<IconButton
											className="btn btn-danger"
											iconClassName="fs--2"
											variant=""
											size="sm"
											icon="fa-solid fa-trash"
											style={{ margin: "auto 0.25rem" }}
											onClick={() => openModal(parsedTask.processInstanceId, parsedTask.processDefinition.key, parsedTask.generatedEmanifestID)}
										></IconButton>
									</div>
								</OverlayTrigger>
							)}
						</>
					);
					return parsedTask;
				});

				if (!originalData || originalData.length === 0) {
					setOriginalData(pendingTasks2);
				}

				var sortedAssigneeList = pendingTasks2.sort((a, b) => {
					const dateA = new Date(
						formatDate(a.created).slice(6, 10), // YYYY
						formatDate(a.created).slice(3, 5) - 1, // MM (subtract 1 because months are zero-based)
						formatDate(a.created).slice(0, 2), // DD
						formatDate(a.created).slice(11, 13), // HH
						formatDate(a.created).slice(14, 16), // mm
						formatDate(a.created).slice(17, 19) // ss
					);

					const dateB = new Date(
						formatDate(b.created).slice(6, 10),
						formatDate(b.created).slice(3, 5) - 1,
						formatDate(b.created).slice(0, 2),
						formatDate(b.created).slice(11, 13),
						formatDate(b.created).slice(14, 16),
						formatDate(b.created).slice(17, 19)
					);
					return dateB - dateA; // Compare as Date objects
				});

				setTableData(sortedAssigneeList);
			}
			setIsFinish(false);
		}
		fetchData();
	}, [fetchFlag]);

	const handleContinue = async (index) => {
		var pendingTaskRecord = await db.pending_tasks.get(index);
		var formData = JSON.parse(pendingTaskRecord.pendingTasks).formData;

		formData.index = index;
		//await db.table("temp_form_data").clear();
		await db.current_workflow_step.add({ currentWorkflowStep: JSON.stringify(formData) });
		localStorage.setItem(Constants.CurrentWorkflowStep, true);

		navigate("/form_view_renderer?index=" + index, {
			state: { data: formData },
		});
	};

	const downloadPdf = async (renderId) => {
		if (renderId) {
			GetRender(renderId);
		}
	};

	const GetRender = (renderId) => {
		var downloadWindow = window.open(renderId);
		setTimeout(function () {
			downloadWindow.close();
		}, 3000);
	};

	const viewSummary = async (index) => {
		var pendingTaskRecord = await db.pending_tasks.get(index);
		var formData = JSON.parse(pendingTaskRecord.pendingTasks).formData;
		var summaryFormData = {};
		var dataHyperLink = formData.executionVariables.hyperLinkVariables?.value;

		//JSON.parse(formData.hyperLinkVariables);
		if (dataHyperLink) {
			// const hyperLinkVariablesElements = formData.hyperLinkVariables.split(",");

			// for (let i = 1; i < hyperLinkVariablesElements.length; i++) {
			//   var key = hyperLinkVariablesElements[i];
			//   var value = formData.taskVariables.find((variable) => variable.name === key)?.value || null;
			//   // Use set to add key-value pair to the summaryFormData object
			//   summaryFormData[key] = value;
			// }

			let form = await getFormById(dataHyperLink.formKey, 1, dataHyperLink, true, 1);
			if (form.status === 0) {
				return;
			}
			formData = {
				...JSON.parse(form.data.formData),
			};

			navigate("/workflow-history-view", { state: { data: formData } });
		}
	};
	return (
		<div className="container">
			<div className={`mb-3 card ${UpliftCss.marginTop25}`}>
				<div className={`card-body ${isRTL ? UpliftCss.gradiantTitleAr : UpliftCss.gradiantTitle}`}>
					<div className="col-md">
						<h5 className="mb-2 mb-md-0">{t(em.pending_task)}</h5>
					</div>
				</div>
			</div>
			{isFinish && <LoaderIcon />}
			<div className="row">
				<div className="col-12">
					<div className={`card ${UpliftCss.BodyBorder}`}>
						<div className="card-body">
							<div className={`mb-3 ${UpliftCss.tableHeader}`}>
								<AdvanceTableWrapper columns={columns} data={tableData} sortable pagination perPage={10}>
									<Row className="flex-end-center mb-3">
										<Col xs="auto" sm={6} lg={4}>
											<AdvanceTableSearchBox className={`${UpliftCss.filterFieldBoxes}`} table placeholder={t("Search")} />
										</Col>
										<Col xs="auto" sm={6} lg={4}>
											<DatePicker
												dateFormat="dd-MM-yyyy"
												className={`form-control ${UpliftCss.filterFieldBoxes}`}
												placeholderText={t("Start Date")}
												selected={startDateFilter ? moment(startDateFilter, "DD-MM-YYYY").toDate() : ""}
												maxDate={startDateFilter ? moment(endDateFilter, "DD-MM-YYYY").toDate() : null}
												onChange={(date) => handleStartDateChange(date)}
											/>
										</Col>
										<Col xs="auto" sm={6} lg={4}>
											<DatePicker
												dateFormat="dd-MM-yyyy"
												className={`form-control ${UpliftCss.filterFieldBoxes}`}
												placeholderText={t("Start Date")}
												selected={endDateFilter ? moment(endDateFilter, "DD-MM-YYYY").toDate() : ""}
												minDate={startDateFilter ? moment(startDateFilter, "DD-MM-YYYY").toDate() : null}
												onChange={(date) => handleEndDateChange(date)}
											/>
										</Col>
									</Row>
									<AdvanceTable
										table
										headerClassName="bg-200 text-900 text-nowrap align-middle"
										rowClassName="align-middle white-space-nowrap"
										tableProps={{
											bordered: true,
											striped: true,
											className: "fs--1 mb-0 overflow-hidden",
										}}
									/>
									<div className="mt-3">
										<AdvanceTableFooter rowCount={tableData.length} table rowInfo navButtons rowsPerPageSelection />
									</div>
									{/* <div className="mt-3">
									<AdvanceTablePagination table />
								</div> */}
								</AdvanceTableWrapper>
							</div>
						</div>
					</div>
					<Modal isOpen={modal} backdrop={false} keyboard={true}>
						<ModalHeader toggle={toggle}>{t(em.delete_icon)} !!</ModalHeader>
						<ModalBody>
							{t(em.areyousuredelete)}
							<br />
							<br />
							<span style={{ color: "red", fontWeight: "bold" }}>{t(emanifestId)}</span>
						</ModalBody>
						<ModalFooter>
							<Button color="primary" onClick={() => handleDelete()}>
								{t(em.delete_icon)}
							</Button>
							<Button color="secondary" onClick={toggle}>
								{t(em.Cancel)}
							</Button>
						</ModalFooter>
					</Modal>
				</div>
			</div>
		</div>
	);
}

export default GetWorkflowListByAssignee;
