import React, { Component, useEffect, useState } from "react";
import { ReactComponent, Utils } from "@formio/react";
import settingsForm from "./ConfirmationMessages.settingsForm";
import _ from "lodash";
import { createRoot } from "react-dom/client";
import { useTranslation } from "react-i18next";
import { getRandomId } from "helpers/utils";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

/*
This component get user information from local storage and fill them in text fields as per user request
*/
const ConfirmationMessagesCustomComp = ({ ...props }) => {
	const [t, i18n] = useTranslation();
	const [myState, setMyState] = useState({ ...props.options });

	useEffect(() => {
		let state = { ...myState }
		if (props.component.confirmationGrid) {
			//append notifications to form object to show them upon submission
			if (!state.notificationsAppended && props.form._form.settings) {
				props.form._form.settings["notifications"] = props.component.confirmationGrid;
				state.notificationsAppended = true;
				setMyState({...myState});
				// props.setComponentState(state);
				//fill the state with the initial values of components
				for (let i = 0; i < props.component.confirmationGrid.length; i++) {
					if (state.formData[props.component.confirmationGrid[i].fieldApiKey] === null || state.formData[props.component.confirmationGrid[i].fieldApiKey] === undefined) {
						state.formData[props.component.confirmationGrid[i].fieldApiKey] = props.data[props.component.confirmationGrid[i].fieldApiKey] || "";
					}
				}

			}
			//listen for form data modifications
			props.form.on("change", (event) => {
				if (event.changed && event.data) {
					let confirmationGrid = props.component.confirmationGrid;
					//check if a certain component value changed and is found in confirmationGrid (i.e: components that their values should be confirmed before) to confirm the answer selected by user
					let indexOfChangedComponent = confirmationGrid.findIndex((c) => c.fieldApiKey === event.changed.component.key);
					if (indexOfChangedComponent >= 0) {
						//if value was changed
						if (props.data[confirmationGrid[indexOfChangedComponent].fieldApiKey] !== state.formData[confirmationGrid[indexOfChangedComponent].fieldApiKey]) {
							let confirmationMessage = "";
							//fieldConfirmationMessages is used for components that have multiple values like checkbox group or radio group
							//get the associated confirmation message as per the answer selected
							if (
								props.component.confirmationGrid[indexOfChangedComponent].fieldConfirmationMessages &&
								props.component.confirmationGrid[indexOfChangedComponent].fieldConfirmationMessages?.length > 0
							) {
								confirmationMessage = t(
									props.component.confirmationGrid[indexOfChangedComponent].fieldConfirmationMessages.filter(
										(m) => m.fieldValue === props.data[confirmationGrid[indexOfChangedComponent].fieldApiKey] + ""
									)[0]?.fieldConfirmationMessage
								);
							} else {
									//generalConfirmationMessage is used for dropdowns
									let translatedMessage = props.component.confirmationGrid[indexOfChangedComponent].generalConfirmationMessage.split("{{value}}");
									//if value is empty so the user cancelled the answer selected
									if(props.component.confirmationGrid[indexOfChangedComponent].generalConfirmationMessage.trim()){
									if (!props.data[confirmationGrid[indexOfChangedComponent].fieldApiKey] || !props.data[confirmationGrid[indexOfChangedComponent].fieldApiKey]?.label) {
										if(state.formData[confirmationGrid[indexOfChangedComponent].fieldApiKey]){
											confirmationMessage = t("Are you sure you want to cancel your answer?");
										}
									} else {
										//else show the confirmation message
										confirmationMessage =
											t(translatedMessage[0].trim()) +
											" " +
											(props.data[confirmationGrid[indexOfChangedComponent].fieldApiKey]?.label || props.data[confirmationGrid[indexOfChangedComponent]?.fieldApiKey]) +
											" " +
											t(translatedMessage[1].trim());
									
								}
							}
							}
							if (confirmationMessage) {
								if (!myState.isOpen) {
									state.isOpen = true;
									state.details = {
										confirmationGrid,
										indexOfChangedComponent,
										confirmationMessage,
									};
									setMyState({ ...state });
									props.setComponentState({ ...state });

								}
							} else {
								state.formData[confirmationGrid[indexOfChangedComponent].fieldApiKey] = props.data[confirmationGrid[indexOfChangedComponent].fieldApiKey];
								setMyState({ ...state });
								props.setComponentState({ ...state });

							}
							//if the user confirms the answer selected it is saved in state
						}
					}
				}
			});
		}
	}, []);

	//add page data in global form data
	useEffect(() => {
		if (props.data) {
			let keys = Object.keys(props.data);
			for (let i = 0; i < keys.length; i++) {
				props.form._data[keys[i]] = props.data[keys[i]];
				props.form._submission.data[keys[i]] = props.data[keys[i]];
			}
		}
	}, [props.data]);
	//
	const processModal = (action) => {
		let state = { ...myState }
		if (action) {
			state.formData[state.details.confirmationGrid[state.details.indexOfChangedComponent].fieldApiKey] =
				props.data[state.details.confirmationGrid[state.details.indexOfChangedComponent].fieldApiKey];
			state.isOpen = false;
			var component = Utils.getComponent(props.form.components, state.details.confirmationGrid[state.details.indexOfChangedComponent].fieldApiKey);
			component.setValue(state.formData[state.details.confirmationGrid[state.details.indexOfChangedComponent].fieldApiKey]);
			component.triggerRedraw();
			setMyState({ ...state });
			props.setComponentState({ ...state });

		} else {
			//if component key not found in state add it
			if (
				!state.details.confirmationMessage ||
				state.formData[state.details.confirmationGrid[state.details.indexOfChangedComponent].fieldApiKey] === null ||
				state.formData[state.details.confirmationGrid[state.details.indexOfChangedComponent].fieldApiKey] === undefined
			) {
				state.formData[state.details.confirmationGrid[state.details.indexOfChangedComponent].fieldApiKey] = "";
			}
			//else if user presses cancel update component value to the previous one that is saved in state
			var component = Utils.getComponent(props.form.components, state.details.confirmationGrid[state.details.indexOfChangedComponent].fieldApiKey);
			component.setValue(state.formData[state.details.confirmationGrid[state.details.indexOfChangedComponent].fieldApiKey]);
			component.triggerRedraw();
			state.isOpen = false;

			//update form data to set the previous value of the component
			props.form._data[state.details.confirmationGrid[state.details.indexOfChangedComponent].fieldApiKey] =
				state.formData[state.details.confirmationGrid[state.details.indexOfChangedComponent].fieldApiKey];

			setMyState({ ...state });
			props.setComponentState({...state});
			// props.form.triggerRedraw();

		}
	};
	return (
		<Modal isOpen={myState.isOpen} backdrop={false} keyboard={true}>
			<ModalBody>
				{myState.details.confirmationMessage} <br />
			</ModalBody>
			<ModalFooter>
				<Button color="primary" onClick={() => processModal(true)}>
					{t("Yes")}
				</Button>
				<Button
					color="secondary"
					onClick={() => {
						processModal(false);
					}}
				>
					{t("No")}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default class ConfirmationMessages extends ReactComponent {
	static shouldSetValue = false; // Define shouldSetValue as a static property

	/**
	 * This is the first phase of component building where the component is instantiated.
	 *
	 * @param component - The component definition created from the settings form.
	 * @param options - Any options passed into the renderer.
	 * @param data - The submission data where this component's data exists.
	 */

	constructor(component, options, data) {
		options["oldState"] = {
			formData: {},
			notificationsAppended: false,
			isOpen: false,
			details: {},
		};
		super(component, options, data);
		this.reactInstance = null;
	}

	/**
	 * This function is the default settings for the component. At a minimum you want to set the type to the registered
	 * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
	 *
	 * @param sources
	 * @returns {*}
	 */
	static schema(...extend) {
		return ReactComponent.schema({
			type: "confirmationMessagesCustomComp",
			label: "Confirmation Messages",
		});
	}
	static get builderInfo() {
		return {
			title: "Confirmation Messages",
			icon: "cubes",
			group: "Basic",
			documentation: "",
			weight: -10,
			schema: ConfirmationMessages.schema(),
		};
	}
	static editForm = settingsForm;

	/**
	  * This method is called any time the component needs to be rebuilt. It is most frequently used to listen to other
	  * components using the this.on() function.
	  */
	init() {
		return super.init();
	}

	/**
	 * This method is called before the component is going to be destroyed, which is when the component instance is
	 * destroyed. This is different from detach which is when the component instance still exists but the dom instance is
	 * removed.
	 */
	destroy() {
		return super.destroy();
	}
	/**
	 * This method is called before a form is submitted.
	 * It is used to perform any necessary actions or checks before the form data is sent.
	 *
	 */

	beforeSubmit() {
		return super.beforeSubmit();
	}

	/**
	 * The second phase of component building where the component is rendered as an HTML string.
	 *
	 * @returns {string} - The return is the full string of the component
	 */
	render() {
		// For react components, we simply render as a div which will become the react instance.
		// By calling super.render(string) it will wrap the component with the needed wrappers to make it a full component.
		return super.render(`<div ref="react-${this.id}"></div>`);
	}

	/**
	 * Callback ref to store a reference to the node.
	 *
	 * @param element - the node
	 */
	setReactInstance(element) {
		this.reactInstance = element;
	}

	/**
	 * The third phase of component building where the component has been attached to the DOM as 'element' and is ready
	 * to have its javascript events attached.
	 *
	 * @param element
	 * @returns {Promise<void>} - Return a promise that resolves when the attach is complete.
	 */
	attach(element) {
		super.attach(element);
		// let randomId = getRandomId();
		// // The loadRefs function will find all dom elements that have the "ref" setting that match the object property.
		// // It can load a single element or multiple elements with the same ref.
		// this.loadRefs(element, {
		// 	[`react-${this.id}`]: "single",
		// });

		// if (this.refs[`react-${this.id}`]) {
		// 	this.attachReact(this.refs[`react-${this.id}`], this.setReactInstance.bind(this));
		// 	if (this.shouldSetValue) {
		// 		this.setValue(this.dataForSetting);
		// 		this.updateValue(this.dataForSetting);
		// 	}
		// }
		return Promise.resolve();
	}


	/**
	 * Override this function to insert your custom component.
	 *
	 * @param element
	 * @param ref - callback ref
	 */
	attachReact(element, ref) {
		let insideGrid = false;

		const root = createRoot(element);
		let key = this.component.key;

		const rootForm = this.getRoot(); // Get the root form object

		Utils.eachComponent(
			rootForm.components,
			function (component) {
				if (component.component.type === "editgrid") {
					Utils.eachComponent(
						component.component.components,
						function (component2) {
							if (!insideGrid) {
								insideGrid = component2.key === key;
							}
						},
						true
					);
				}
			},
			true
		);

		const setComponentState = (value) => {
			this.options["oldState"] = { ...value };
			this.updateOnChange({}, true);

		}

		root.render(
			<ConfirmationMessagesCustomComp
				component={this.component} // These are the component settings if you want to use them to render the component.
				onChange={this.updateValue} // Pass the onChange event handler
				value={this.dataValue}
				data={this.data}
				form={rootForm}
				setComponentState={setComponentState}
				options={this.options["oldState"]}
				insideGrid={insideGrid}
			/>
		);
	}

	/**
	 * Override this function.
	 */
	detachReact(element) {
		return;
	}

	/**
	 * Something external has set a value and our component needs to be updated to reflect that. For example, loading a submission.
	 *
	 * @param value
	 */
	setValue(value) {
		if (this.reactInstance) {
			this.reactInstance.setState({
				value: value,
			});
			this.shouldSetValue = false;
		} else {
			this.shouldSetValue = true;
			this.dataForSetting = value;
		}
	}

	/**
	 * Override normal validation check to insert custom validation in react component.
	 *
	 * @param data
	 * @param dirty
	 * @param rowData
	 * @returns {boolean}
	 */
	checkValidity(data, dirty, rowData) {
		const valid = super.checkValidity(data, dirty, rowData);
		if (!valid) {
			return false;
		}
		return this.validate(data, dirty, rowData);
	}

	/**
	 * Do custom validation.
	 *
	 * @param data
	 * @param dirty
	 * @param rowData
	 * @returns {boolean}
	 */
	validate(data, dirty, rowData) {
		return true;
	}
}
