// RadioButtonComponent.js

import React, { useEffect } from "react";
import { ReactComponent, Utils } from "@formio/react";
import CustomRadioButton from "./CustomRadioButton";
import CustomRadioButtonSetting from "./CustomRadioButtonSetting.SettingsForm";
import ReactDOM from "react-dom/client";

const RadioButtonComponent = class extends ReactComponent {
  static shouldSetValue = true;

  constructor(component, options, data) {
    options["oldState"] = {};
    super(component, options, data);
    this.reactInstance = null;
  }

  static schema(...extend) {
    return ReactComponent.schema({
      type: "radioButtonCustomComp",
      label: "Radio Button",
    });
  }

  static get builderInfo() {
    return {
      title: "Radio Button",
      icon: "circle-o",
      group: "Basic",
      documentation: "",
      weight: -10,
      schema: RadioButtonComponent.schema(),
    };
  }

  static editForm = CustomRadioButtonSetting; // Use settings form

  attachReact(element, ref) {
    let insideGrid = false;

    const root = ReactDOM.createRoot(element);
    let key = this.component.key;

    const rootForm = this.getRoot();

    Utils.eachComponent(
      rootForm.components,
      function (component) {
        if (component.component.type === "editgrid") {
          Utils.eachComponent(
            component.component.components,
            function (component2) {
              if (!insideGrid) {
                insideGrid = component2.key === key;
              }
            },
            true
          );
        }
      },
      true
    );

    root.render(
      <CustomRadioButton
        component={this.component}
        options={this.component.options || []}
        value={this.dataValue}
        onChange={this.updateValue} // Pass the updateValue function as onChange prop
        form={rootForm}
      />
    );
  }

  detachReact(element) {
    return;
  }

  setValue(value) {
    if (this.reactInstance) {
      this.reactInstance.setState({
        value: value,
      });
      this.shouldSetValue = false;
    } else {
      this.shouldSetValue = true;
      this.dataForSetting = value;
    }
  }

  checkValidity(data, dirty, rowData) {
    const valid = super.checkValidity(data, dirty, rowData);
    if (!valid) {
      return false;
    }
    return this.validate(data, dirty, rowData);
  }

  validate(data, dirty, rowData) {
    return true;
  }
};

export default RadioButtonComponent;
