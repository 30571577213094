import { Dropdown, Col, Modal, Row, Button, Image, Overlay, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as em from "common/ErrorMessages";
import IconButton from "./IconButton";
import moment from "moment";

const TableBody = ({
	tableData,
	tableName,
	columns,
	isCurrentUserEmail,
	openModalToChangeDriver,
	handleView = () => {},
	downloadPdf = () => {},
	openModalToDelete = () => {},
	handleContinue = () => {},
	assignTask = () => {},
	openModal = () => {},
	viewSummary = () => {},
}) => {
	const [t, i18n] = useTranslation();
	//	console.log("tableData", tableData);
	return (
		<tbody>
			{tableData.map((data, index) => (
				<tr key={index}>
					{columns.map(({ accessor }) => {
						if (data.pendingTasks) {
							var tempData = JSON.parse(data.pendingTasks);
							tempData.created = moment(new Date(tempData.created)).format("DD-MM-YYYY HH:mm:ss");
							tempData.formData.hyperLinkVariables = null;
							tempData.taskName = tempData.name;
							tempData.name = tempData.processDefinition.name;
							tempData.index = data.id;
							data = tempData;
						}
						const tData = data[accessor] ? data[accessor] : "";

						if (accessor === "action" && tableName == "pending") {
							return (
								<td key={accessor}>
									<div className="justify-content-start mb-2 ">
										{data !== null && (
											<>
												{!data?.taskName.includes("OFFLINE") && (
													<OverlayTrigger
														overlay={
															<Tooltip style={{ position: "fixed" }} id="idsubmit">
																{t(em.execute_task)}
															</Tooltip>
														}
													>
														<div className="theme-control-toggle-label icon-position">
															<IconButton
																className="play-hover"
																iconClassName="fs--2"
																variant="falcon-default"
																size="sm"
																icon="fa fa-play"
																style={{
																	margin: "auto 0.25rem",
																	backgroundColor: "#00a862",
																	borderColor: "#00a862",
																}}
																onClick={() => handleContinue(data.index)}
															></IconButton>
														</div>
													</OverlayTrigger>
												)}
												{data.formData?.hyperLinkVariables && (
													<OverlayTrigger
														overlay={
															<Tooltip style={{ position: "fixed" }} id="idviewsummary">
																{t("View Summary")}
															</Tooltip>
														}
													>
														<div className="theme-control-toggle-label icon-position">
															<IconButton
																className="play-hover"
																iconClassName="fs--2"
																variant="falcon-default"
																size="sm"
																icon="fa-solid fa-eye"
																style={{
																	margin: "auto 0.25rem",
																	backgroundColor: "#00a862",
																	borderColor: "#00a862",
																}}
																onClick={() => viewSummary(data.index)}
															></IconButton>
														</div>
													</OverlayTrigger>
												)}

												{data !== null && data?.taskVariables?.find((variable) => variable.name === "renderId") && (
													<OverlayTrigger
														overlay={
															<Tooltip style={{ position: "fixed" }} id="DownloadPdfTooltip">
																{t("Download PDF")}
															</Tooltip>
														}
													>
														<div className="theme-control-toggle-label icon-position end-text">
															<IconButton
																className="btn btn-primary"
																iconClassName="fs--2"
																variant=""
																size="sm"
																icon="fa-solid fa-download"
																style={{ margin: "auto 0.25rem" }}
																onClick={() => downloadPdf(data?.taskVariables?.find((variable) => variable.name === "renderId").value)}
															></IconButton>
														</div>
													</OverlayTrigger>
												)}

												{data !== null && data.ableToDelete !== "false" && data.enableDelete && (
													<OverlayTrigger
														overlay={
															<Tooltip style={{ position: "fixed" }} id="abletodelete">
																{t(em.delete_icon)}
															</Tooltip>
														}
													>
														<div className="theme-control-toggle-label icon-position">
															<IconButton
																className="btn btn-danger"
																iconClassName="fs--2"
																variant=""
																size="sm"
																icon="fa-solid fa-trash"
																style={{ margin: "auto 0.25rem" }}
																onClick={() => openModal(data.processInstanceId, data.processDefinition.key, data.generatedEmanifestID)}
															></IconButton>
														</div>
													</OverlayTrigger>
												)}
											</>
										)}
									</div>
								</td>
							);
						}

						if (accessor === "action" && tableName == "unassigned") {
							return (
								<td key={accessor}>
									<div className="d-flex justify-content-start mb-2 ">
										{data !== null && (
											<>
												{
													<OverlayTrigger
														overlay={
															<Tooltip style={{ position: "fixed" }} id="idsubmit">
																{t(em.execute_task)}
															</Tooltip>
														}
													>
														<div className="theme-control-toggle-label icon-position">
															<IconButton
																className="play-hover"
																iconClassName="fs--2"
																variant="falcon-default"
																size="sm"
																icon="fa fa-play"
																style={{
																	margin: "auto 0.25rem",
																	backgroundColor: "#00a862",
																	borderColor: "#00a862",
																}}
																onClick={() => assignTask(data)}
															></IconButton>
														</div>
													</OverlayTrigger>
												}

												{/* {data.formData?.hyperLinkVariables && (
													<OverlayTrigger
														overlay={
															<Tooltip style={{ position: "fixed" }} id="idviewsummary">
																{t("View Summary")}
															</Tooltip>
														}
													>
														<div className="theme-control-toggle-label icon-position">
															<IconButton
																className="play-hover"
																iconClassName="fs--2"
																variant="falcon-default"
																size="sm"
																icon="fa-solid fa-eye"
																style={{
																	margin: "auto 0.25rem",
																	backgroundColor: "#00a862",
																	borderColor: "#00a862",
																}}
																onClick={() => viewSummary(data)}
															></IconButton>
														</div>
													</OverlayTrigger>
												)} */}
											</>
										)}
									</div>
								</td>
							);
						}

						if (accessor === "action") {
							return (
								<td key={accessor}>
									<div className="d-flex justify-content-start mb-2 ">
										{data !== null && (
											<>
												<OverlayTrigger
													overlay={
														<Tooltip style={{ position: "fixed" }} id="ViewTooltip">
															{t("View")}
														</Tooltip>
													}
												>
													<div className="theme-control-toggle-label icon-position end-text">
														<IconButton
															className="btn btn-primary"
															iconClassName="fs--2"
															variant=""
															size="sm"
															icon="fa-solid fa-eye"
															style={{ margin: "auto 0.25rem" }}
															onClick={() => handleView(data.rootProcessInstanceId, data.applicationID, data.tableId, data.formKey)}
														></IconButton>
													</div>
												</OverlayTrigger>

												{data !== null && data.status === "COMPLETED" && (
													<OverlayTrigger
														overlay={
															<Tooltip style={{ position: "fixed" }} id="DownloadPdfTooltip">
																{t("Download PDF")}
															</Tooltip>
														}
													>
														<div className="theme-control-toggle-label icon-position end-text">
															<IconButton
																className="btn btn-primary"
																iconClassName="fs--2"
																variant=""
																size="sm"
																icon="fa-solid fa-download"
																style={{ margin: "auto 0.25rem" }}
																onClick={() => downloadPdf(data.rootProcessInstanceId, data.applicationID, data.workflowName, data.tableId)}
															></IconButton>
														</div>
													</OverlayTrigger>
												)}
												{data !== null && data.ableToDelete !== "false" && data.startUserId === isCurrentUserEmail && data.status !== "COMPLETED" && (
													<OverlayTrigger
														overlay={
															<Tooltip style={{ position: "fixed" }} id="abletodelete">
																{t(em.delete_icon)}
															</Tooltip>
														}
													>
														<div className="theme-control-toggle-label icon-position">
															<IconButton
																className="btn btn-danger"
																iconClassName="fs--2"
																variant=""
																size="sm"
																icon="fa-solid fa-trash"
																style={{ margin: "auto 0.25rem" }}
																onClick={() => openModalToDelete(data.rootProcessInstanceId, data.taskName, data.applicationID)}
															></IconButton>
														</div>
													</OverlayTrigger>
												)}
												{data !== null && data.status !== "COMPLETED" && data.enableModify === true && data.acceptEdit === true && (
													<OverlayTrigger
														overlay={
															<Tooltip style={{ position: "fixed" }} id="editdriver">
																{t("edit")}
															</Tooltip>
														}
													>
														<div className="theme-control-toggle-label icon-position">
															<IconButton
																className="btn btn-danger"
																iconClassName="fs--2"
																variant=""
																size="sm"
																icon="fa-solid fa-user-pen"
																style={{ margin: "auto 0.25rem" }}
																onClick={() => openModalToChangeDriver(data.rootProcessInstanceId, data.taskName, data.applicationID, data.userTaskAssginee)}
															></IconButton>
														</div>
													</OverlayTrigger>
												)}
											</>
										)}
									</div>
								</td>
							);
						}
						return <td key={accessor}>{t(tData)}</td>;
					})}
				</tr>
			))}
		</tbody>
	);
};

export default TableBody;
