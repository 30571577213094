import React, { Component, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { ReactComponent, Utils } from "@formio/react";
import settingsForm from "./DynamicDropdown.settingsForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import CustomSelect from "../customSelect";
import i18next from "i18next";
import _ from "lodash";
import { createRoot } from "react-dom/client";
import { getRandomId } from "helpers/utils";
import * as Constants from "common/constants";
import { EncryptStorage } from "encrypt-storage";

/*
This component is a dropdown where its options depends on another field value whose key supported by the user. The user can choose to add the 
options either static or using api
*/
const DynamicDropDownCustomComp = ({ ...props }) => {
	const [myState, setMyState] = useState(props.options);
	const [count, setCount] = useState(0);
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});
	// Update the setValue method to handle onChange event
	const updateValue = (e) => {
		if (props.form._form.settings && props.form._form.settings.toEdit !== true) {
			let state = { ...myState };

			const newValue = e.target.value; // Get the selected value from the event
			let selectedValues = state["options"].filter((v) => v.value === newValue)[0];
			state["value"] = newValue;
			setMyState(state);
			props.setComponentState(state, -1);

			if (selectedValues) {
				let json = { label: selectedValues.label, value: newValue, definedConditionalKeysIndex: state.definedConditionalKeysIndex };
				if (state.dependency.appendedResponseFields && state.dependency.appendedResponseFields.length) {
					for (let i = 0; i < state.dependency.appendedResponseFields.length; i++) {
						json[state.dependency.appendedResponseFields[i].fieldName] = selectedValues[state.dependency.appendedResponseFields[i].fieldName];
					}
				}

				props.component.defaultValue = json;
				props.form._data[props.component.key] = json;
				props.onChange(json, null);

			} else {
				props.component.defaultValue = "";
				props.form._data[props.component.key] = "";
				props.onChange("", null);
			}
			if (!props.insideGrid) {
				//fill value with the fields requested in form data
				if (state.dependency.componentsToFill && state.dependency.componentsToFill.length > 0) {
					for (let i = 0; i < state.dependency.componentsToFill.length; i++) {
						props.form._data[state.dependency.componentsToFill[i]["fieldApi"]] = selectedValues?.label;
						props.form._submission.data[state.dependency.componentsToFill[i]["fieldApi"]] = selectedValues?.label;
					}
				}
			}
		}
	};
	const setGridUniqueOptions = (state) => {
		let needsRedraw = false;
		let localGridFiltersDataValues = props.form._data[props.gridKey];
		if (localGridFiltersDataValues.length === 0) {
			localGridFiltersDataValues = props.form._form.settings.data[props.gridKey] || [];
		}
		let finalData = [...state.mainData];
		for (let i = 0; i < finalData.length; i++) {
			let recordValue = finalData[i].value;
			let recordAlreadySelected = localGridFiltersDataValues.findIndex((g) => g[props.component.key].value === recordValue);
			if (recordAlreadySelected < 0) {
				if (finalData[i].isIncluded === false) {
					needsRedraw = true;
				}
				finalData[i].isIncluded = true;
				state.mainData[i].isIncluded = true;
			} else {
				if (finalData[i].isIncluded === true && recordValue !== state.value) {
					needsRedraw = true;
				}
				if (recordValue !== state.value) {
					finalData[i].isIncluded = false;
					state.mainData[i].isIncluded = false;
				}
			}
		}
		let indexOfValue = finalData.findIndex((e) => e.value === state.value);
		if (indexOfValue >= 0) {
			if (finalData[indexOfValue]) {
				if (finalData[indexOfValue].isIncluded === false) {
					needsRedraw = true;
				}
				finalData[indexOfValue].isIncluded = true;
				state.mainData[indexOfValue].isIncluded = true;
			}
		}
		state.initialGridSize = props.grid.editRows.length || 0;
		if (needsRedraw) {
			state.options = finalData.filter((o) => o.isIncluded === true);
			filterOptionsBasedOnGrid(state);
		}
	};

	const filterOptionsBasedOnGrid = (state) => {
		let filtersDataValues = props.form._data[state.dependency.gridApiKey] || [];
		if (filtersDataValues.length === 0) {
			filtersDataValues = props.form._form.settings.data[state.dependency.gridApiKey] || [];
		}
		let tempVal = [];
		for (let i = 0; i < state.options.length; i++) {
			let recordValue = state.options[i].value;
			let recordFoundInGrid = filtersDataValues.findIndex((g) => g[state.dependency.fieldGridDataPath].value === recordValue);
			if (recordFoundInGrid >= 0) {
				tempVal.push(state.options[i]);
			}
		}
		if (filtersDataValues.length !== 0) {
			state.options = tempVal;
		}
		setMyState({ ...state });
		props.setComponentState({ ...state });
	};
	useEffect(() => {
		let state = { ...myState };
		let dependencies = props.component.dependencies;
		if (!props.component.defaultValue && state.options.length > 0) {
			const firstOption = state.options[0];
			if (firstOption && props.component.isDefaultValue) {
			//console.log("fisrtOption::",firstOption)
			  state.value = firstOption.value;
			  props.component.defaultValue = firstOption;
			  props.form._data[props.component.key] = firstOption;
			  props.onChange(firstOption, null);

			     // Force update of state to trigger a re-render
				 setMyState({ ...state, options: [...state.options] });
				 props.setComponentState(state); // Trigger a redraw
			}
		  }
		const handleChange = (formData, comState, updateDefaultValue) => {
			let state = comState || { ...myState };
			let loading = false;
			if (formData && !state.loading && dependencies && !props.component.disabled) {
				if (state.mainSelectedValues.length === 0) {
					for (let i = 0; i < dependencies.length; i++) {
						let fieldsDependencies = [];
						for (let j = 0; j < dependencies[i].dependentApiKeys.length; j++) {
							if (!formData[dependencies[i].dependentApiKeys[j].fieldFormApi]) {
								formData[dependencies[i].dependentApiKeys[j].fieldFormApi] = "";
							}
							fieldsDependencies.push({
								fieldFormApi: dependencies[i].dependentApiKeys[j].fieldFormApi,
								fieldDatabaseColumnName: dependencies[i].dependentApiKeys[j].fieldDatabaseColumnName,
								fieldValue: undefined,
							});
						}
						state.mainSelectedValues.push(fieldsDependencies);
					}
				}
				let definedConditionalKeysIndex = -1;
				for (let i = 0; i < state.mainSelectedValues.length; i++) {
					let numberOfFieldsChanged = 0;
					let allFieldsDefined = 0;
					for (let j = 0; j < state.mainSelectedValues[i].length; j++) {
						if (formData[state.mainSelectedValues[i][j].fieldFormApi]) {
							allFieldsDefined++;
						}
						if (formData[state.mainSelectedValues[i][j].fieldFormApi] !== state.mainSelectedValues[i][j].fieldValue) {
							numberOfFieldsChanged++;
							state.mainSelectedValues[i][j].fieldValue = formData[state.mainSelectedValues[i][j].fieldFormApi];
							if (!formData[state.mainSelectedValues[i][j].fieldFormApi]) {
								i = 0;
							}
						}
					}
					if (numberOfFieldsChanged !== 0) {
						if (allFieldsDefined === state.mainSelectedValues[i].length) {
							definedConditionalKeysIndex = i;
						}
					} else {
						if (allFieldsDefined === state.mainSelectedValues[i].length) {
							definedConditionalKeysIndex = -2;
						}
					}
				}
				if (definedConditionalKeysIndex !== -1 && definedConditionalKeysIndex !== -2) {
					if (updateDefaultValue !== false) {
						props.component.defaultValue = "";
						props.form._data[props.component.key] = "";
						state.options = [];
						state.value = "";
						state.mainData = [];
						setMyState(state);
						props.setComponentState(state);
						props.onChange("", null);
					} else {
						if (formData[props.component.key] && dependencies[definedConditionalKeysIndex].componentsToFill && dependencies[definedConditionalKeysIndex].componentsToFill.length > 0) {
							for (let i = 0; i < dependencies[definedConditionalKeysIndex].componentsToFill.length; i++) {
								props.form._data[dependencies[definedConditionalKeysIndex].componentsToFill[i]["fieldApi"]] = formData[props.component.key]?.label;
								props.form._submission.data[dependencies[definedConditionalKeysIndex].componentsToFill[i]["fieldApi"]] = formData[props.component.key]?.label;
							}
						}
					}

					loading = true;
					state.definedConditionalKeysIndex = definedConditionalKeysIndex;
					getDropdownOptions(dependencies[definedConditionalKeysIndex], formData, state);
				} else if (definedConditionalKeysIndex !== -2 && props.form._data[props.component.key]) {
					updateValue({ target: { value: "" } });
					state.options = [];
					state.value = "";
					setMyState(state);
					props.setComponentState(state);
				} else if (definedConditionalKeysIndex === -3) {
					setMyState(state);
					props.setComponentState(state);
				}

				if (!loading && props.insideGrid && myState.options.length > 0) {
					if (props.form._data[props.gridKey]?.length > 0) {
						setGridUniqueOptions({ ...myState });
					}
				setMyState(state);
				props.setComponentState(state);
				}
			}
		};
		let initialValue = props.component.defaultValue || props.form._data[props.component.key];

		if (initialValue) {
			let label = initialValue.label;
			i18next.language=i18next.language.includes("-")? i18next.language.split("-")[0]:i18next.language;
			//label = props.form._form.settings.translatedData[props.form._form.settings.language][label] || label;
			label=props.form.i18next.store.data[i18next.language][label]||[label];

			Object.keys(props.form.i18next.store.data[i18next.language].translation).map((key) => {
				let allLabels =props.form.i18next.store.data[i18next.language].translation;
				Object.keys(allLabels).map((key2) => {
					if (allLabels[key2] === label) {
						label = props.form.i18next.store.data[i18next.language].translation[key2];
					}
				});
			});
			//if not disbaled get options
			if (props.component.disabled !== true) {
				let val = initialValue.value;
				state["value"] = val;
				state.options = [];
				state.mainData = [];
				state.initialized = true;

				// props.onChange(props.component.defaultValue, null);

				handleChange(props.form._data, state, false);
			} else {
				state["value"] = label;
				state.options = [{ label: label, value: label }];
				state.initialized = true;
				if(props.component.dependencies[initialValue.definedConditionalKeysIndex]){
					for (let i = 0; i < props.component.dependencies[initialValue.definedConditionalKeysIndex].componentsToFill.length; i++) {
						props.form._data[props.component.dependencies[initialValue.definedConditionalKeysIndex].componentsToFill[i]["fieldApi"]] = label;
						props.form._submission.data[props.component.dependencies[initialValue.definedConditionalKeysIndex].componentsToFill[i]["fieldApi"]] = label;
					}
				}
				

				// props.onChange(props.component.defaultValue, null);
				setMyState(state);
				props.setComponentState(state);
			}
		}
		if (props.insideGrid) {
			let newGridSize = props.grid.editRows.length;
			if (newGridSize !== state.initialGridSize) {
				handleChange(props.form._data);
			} else {
				let dependentGridIndex = props.component.dependencies.findIndex((d) => d.gridApiKey !== "" && d.fieldGridDataPath !== "");
				if (dependentGridIndex >= 0) {
					handleChange(props.form._data);
				}
			}
		} else {
			if (props.component.disabled !== true) {
				props.form.on("change", (event) => {
					if (event.changed?.component.key !== props.component.key) {
						handleChange(event.data, state);
					}
				});
			}
		}
	}, [props.component.isDefaultValue,]);
	const getDropdownOptions = (dependency, formData, state) => {
		let requestUrl = Constants.base_url+dependency.requestUrl;
		let responseDataPath = dependency.responseDataPath;
		var accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);
		var headers = { "Content-Type": "application/json" };

		if (accessToken) {
			var headerToken = { Authorization: "Bearer " + accessToken };
			headers = { ...headers, ...headerToken };
		} else {
			headers["SuperAdminToken"] = Constants.SuperAdminToken;
		}
		for (let i = 0; i < dependency.dependentApiKeys.length; i++) {
			if (formData[dependency.dependentApiKeys[i].fieldFormApi] && dependency.dependentApiKeys[i].appendToUrl) {
				requestUrl +=
					"&&" +
					(dependency.dependentApiKeys[i].fieldDatabaseColumnName +
						"=eq." +
						(formData[dependency.dependentApiKeys[i].fieldFormApi].value || formData[dependency.dependentApiKeys[i].fieldFormApi]));
			}
		}
		fetch(requestUrl, {
			method: "GET",
			headers: { ...headers },
		})
			.then((response) => response.json())
			.then((res) => {
				let allData = responseDataPath ? _.get(res, responseDataPath, "") : res;
				if (typeof allData === "string") {
					allData = JSON.parse(allData);
				}
				allData.forEach((element, index) => {
					element["isIncluded"] = true;
				});
				setOptions(allData, dependency, state);
			});
	};

	const setOptions = (data, dependency, comState) => {
		let state = comState || { ...myState };
		let finalData = [];
		i18next.language=i18next.language.includes("-")? i18next.language.split("-")[0]:i18next.language;
		for (let i = 0; i < data.length; i++) {
			if (data[i].isIncluded) {
				let label = _.get(data[i], dependency.labelPath, "") || data[i].label;
				let value = _.get(data[i], dependency.valuePath, "") || data[i].value;
				finalData.push({
					label: dependency.translateData ? props.form.i18next.store.data[i18next.language].translation[label] || label : label,
					value,
					isIncluded: true,
					...data[i],
				});
				data[i].label = label;
				data[i].value = value;
			}
		}
		state.dependency = dependency;
		state.options = finalData;
		state.loading = false;
		state.mainData = data;

		if (props.insideGrid && state.options.length > 0 && props.form._data[props.gridKey]?.length > 0) {
			setMyState(state);
			props.setComponentState(state);
			setGridUniqueOptions(state);
		} else if (dependency.gridApiKey && dependency.fieldGridDataPath) {
			filterOptionsBasedOnGrid(state);
		} else {
			let initialValue = props.component.defaultValue || props.form._data[props.component.key];
			let val = initialValue?.value
			if (val) {
				state["value"] = val;
			}
			setMyState(state);
			props.setComponentState(state);

			// props.element.triggerRedraw();
		}
	};

	//add page data in global form data
	useEffect(() => {
		if (props.data) {
			let keys = Object.keys(props.data);
			for (let i = 0; i < keys.length; i++) {
				props.form._data[keys[i]] = props.data[keys[i]];
				props.form._submission.data[keys[i]] = props.data[keys[i]];
			}
			if (props.insideGrid === true) {
				delete props.form._data[props.component.key];
				delete props.form._submission.data[props.component.key];
			}
		}
	}, [props.data]);

	//
	return (
		<>
			<CustomSelect
				component={props.component}
				disabled={props.component.disabled && props.component.disabled === true}
				value={myState.value}
				options={myState.options}
				updateValue={updateValue}
				showOptions={props.options.showOptions}
				triggered={props.options.triggered}
				setShowOptions={(showOptions) => {
					props.setShowOptions(showOptions);
				}}
				setTriggered={() => {
					props.setComponentState({ ...props.options, triggered: false }, false);
				}}
			/>
		</>
	);
};

export default class DynamicDropDown extends ReactComponent {
	static shouldSetValue = false; // Define shouldSetValue as a static property

	/**
	 * This is the first phase of component building where the component is instantiated.
	 *
	 * @param component - The component definition created from the settings form.
	 * @param options - Any options passed into the renderer.
	 * @param data - The submission data where this component's data exists.
	 */

	constructor(component, options, data) {
		options["oldState"] = {
			value: "",
			conditionalKey: "",
			mainSelectedValues: [],
			loading: false,
			indexChanged: 0,
			initialized: false,
			dependency: {},
			definedConditionalKeysIndex: -1,
			//records from db
			mainData: [],
			showOptions: "none",
			//use mainData to get the options translated according to system language
			options: [],
			initializedInsideGrid: false,
			initialGridSize: 0,
			previousValue: {},
		};
		super(component, options, data);
		this.reactInstance = null;
	}

	/**
	 * This function is the default settings for the component. At a minimum you want to set the type to the registered
	 * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
	 *
	 * @param sources
	 * @returns {*}
	 */
	static schema(...extend) {
		return ReactComponent.schema({
			type: "dynamicDropDownCustomComp",
			label: "",
		});
	}
	static get builderInfo() {
		return {
			title: "Dynamic Dropdown",
			icon: "square",
			group: "Basic",
			documentation: "",
			weight: -10,
			schema: DynamicDropDown.schema(),
		};
	}
	static editForm = settingsForm;

	/**
	 * This method is called any time the component needs to be rebuilt. It is most frequently used to listen to other
	 * components using the this.on() function.
	 */
	init() {
		return super.init();
	}

	/**
	 * This method is called before the component is going to be destroyed, which is when the component instance is
	 * destroyed. This is different from detach which is when the component instance still exists but the dom instance is
	 * removed.
	 */
	destroy() {
		return super.destroy();
	}
	/**
	 * This method is called before a form is submitted.
	 * It is used to perform any necessary actions or checks before the form data is sent.
	 *
	 */

	beforeSubmit() {
		return super.beforeSubmit();
	}

	/**
	 * The second phase of component building where the component is rendered as an HTML string.
	 *
	 * @returns {string} - The return is the full string of the component
	 */
	render() {
		// For react components, we simply render as a div which will become the react instance.
		// By calling super.render(string) it will wrap the component with the needed wrappers to make it a full component.
		return super.render(`<div ref="react-${this.id}"></div>`);
	}

	/**
	 * Callback ref to store a reference to the node.
	 *
	 * @param element - the node
	 */
	setReactInstance(element) {
		this.reactInstance = element;
	}

	/**
	 * The third phase of component building where the component has been attached to the DOM as 'element' and is ready
	 * to have its javascript events attached.
	 *
	 * @param element
	 * @returns {Promise<void>} - Return a promise that resolves when the attach is complete.
	 */
	attach(element) {
		super.attach(element);

		return Promise.resolve();
	}

	attachReact(element, ref) {
		const rootForm = this.getRoot(); // Get the root form object
		const instanceRef = React.createRef();

		let insideGrid = false;
		let gridKey = "";
		let grid = "";
		let isGridDisabled = false;
		let key = this.component.key;
		const root = createRoot(element);

		Utils.eachComponent(
			rootForm.components,
			function (component) {
				if (component.component.type === "editgrid" && !insideGrid) {
					gridKey = component.component.key;
					grid = component;
					Utils.eachComponent(
						component.component.components,
						function (component2) {
							if (!insideGrid) {
								insideGrid = component2.key === key;
								isGridDisabled = component.component.disabled === true;
							}
						},
						true
					);
				}
			},
			true
		);
		if (insideGrid) {
			if (grid.editRows[this.rowIndex]?.state === "new" && !this.options["oldState"].initializedInsideGrid) {
				this.component.defaultValue = null;
				grid.editRows[this.rowIndex].data[key] = null;
				this.options["oldState"].initializedInsideGrid = true;
				this.options["oldState"].rowIndex = this.rowIndex;
			} else {
				this.component.defaultValue = grid.editRows[this.rowIndex]?.data[key] || rootForm._data[this.rowIndex]?.data[key];
			}
		}
		const setShowOptions = (showOptions) => {
			this.options["oldState"].showOptions = showOptions;
			this.options.showOptions = showOptions;
			this.updateOnChange({}, true);
			root.render(
				<DynamicDropDownCustomComp
					component={this.component} // These are the component settings if you want to use them to render the component.
					onChange={this.updateValue} // Pass the onChange event handler
					value={this.dataValue}
					gridKey={gridKey}
					isGridDisabled={isGridDisabled}
					element={this}
					form={rootForm}
					grid={grid}
					data={this.data}
					insideGrid={insideGrid}
					setComponentState={setComponentState}
					options={this.options["oldState"]}
					setShowOptions={setShowOptions}
				/>
			);
		};
		const setComponentState = (value, redraw) => {
			this.options["oldState"] = { ...value, showOptions: this.options.showOptions };
			if (redraw !== -1 && redraw !== false) {
				this.options["oldState"].triggered = true;
				this.options.triggered = true;
			}
			this.updateOnChange({}, true);
			if (redraw !== false && this.component.disabled !== true) {
				Utils.eachComponent(
					rootForm.components,
					function (component) {
						if (component.component.input) {
							if (component.component.key === key) {
								component.triggerRedraw();
							}
						} else if (component.component.type === "columns") {
							Utils.eachComponent(
								component.components,
								function (component2) {
									if (component2.component.input) {
										if (component2.component.key === key) {
											component2.triggerRedraw();
										}
									}
								},
								true
							);
						}
					},
					true
				);
			} else {
				this.options["oldState"].triggered = false;
				this.options.triggered = false;
				this.updateOnChange({}, true);

				root.render(
					<DynamicDropDownCustomComp
						component={this.component} // These are the component settings if you want to use them to render the component.
						onChange={this.updateValue} // Pass the onChange event handler
						value={this.dataValue}
						gridKey={gridKey}
						isGridDisabled={isGridDisabled}
						element={this}
						form={rootForm}
						grid={grid}
						data={this.data}
						insideGrid={insideGrid}
						setComponentState={setComponentState}
						options={this.options["oldState"]}
						setShowOptions={setShowOptions}
					/>
				);
			}
		};
		root.render(
			<DynamicDropDownCustomComp
				component={this.component} // These are the component settings if you want to use them to render the component.
				onChange={this.updateValue} // Pass the onChange event handler
				value={this.dataValue}
				gridKey={gridKey}
				isGridDisabled={isGridDisabled}
				element={this}
				form={rootForm}
				grid={grid}
				data={this.data}
				insideGrid={insideGrid}
				setComponentState={setComponentState}
				options={this.options["oldState"]}
				setShowOptions={setShowOptions}
			/>
		);
	}
}
