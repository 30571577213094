import React from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ToastifyNotification(props) {
	const [t, i18n] = useTranslation();
	const showToastMessage = () => {
		toast.success(t("Success Notification") + "!", {
			position: toast.POSITION.TOP_RIGHT,
			autoClose: 1000,
		});
	};
	return (
		<div>
			{props.show && showToastMessage()}
			<ToastContainer />
		</div>
	);
}

export default ToastifyNotification;
