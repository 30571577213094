import React, { useState, useContext } from "react";

import PropTypes from "prop-types";
import AppContext from "context/Context";
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Col, Modal, Row, Button, Image } from "react-bootstrap";
import IconButton from "components/common/IconButton";
import * as Constants from "common/constants";
import * as General from "common/general";
import Loader from "components/custom/Loader";
import { useTranslation } from "react-i18next";

const DeleteConfirmation = ({ roleID, roleName, hideDeleteConfirmation, successCallBackFunction, showComponent }) => {
	const [errorContainerVisiblity, showErrorContainer] = useState(false);
	const [loaderContainerVisibility, showLoaderContainer] = useState(false);
	const [buttonsVisibility, showHideButtons] = useState(true);
	const [t, i18n] = useTranslation();
	const handleCancelDelete = () => {
		hideDeleteConfirmation();
	};

	const handleConfirmDelete = async () => {
		showLoaderContainer(true);
		showHideButtons(false);
		showErrorContainer(false);

		const DeleteRoleSuccess = (responseObject) => {
			var isSuccess = false;
			if (responseObject.status == Constants.SuccessStatus) {
				if (responseObject.data != null) {
					if (responseObject.data.token != null) {
						if (responseObject.data.token.accessToken != "") {
							isSuccess = true;
							General.SaveToken(responseObject);
							handleCancelDelete();
							successCallBackFunction();
						}
					}
				}
			}

			if (isSuccess == false) {
				showErrorContainer(true);
				setTimeout(function () {
					var errorContainer = document.getElementById("errorContainer");
					if (responseObject.message != null) {
						if (responseObject.message != "") {
							errorContainer.innerHTML = responseObject.message;
						}
					}
					if (errorContainer.innerHTML == "") {
						errorContainer.innerHTML = "An error occured, please try again later.";
					}
					showLoaderContainer(false);
					showHideButtons(true);
				}, 1000);
			} else {
				showErrorContainer(false);
				showLoaderContainer(false);
				showHideButtons(true);
			}
		};

		const DeleteRoleFailure = (error) => {
			showErrorContainer(true);
			setTimeout(function () {
				var errorContainer = document.getElementById("errorContainer");
				if (errorContainer.innerHTML == "") {
					errorContainer.innerHTML = "An error occured, please try again later. " + error;
				}
				showLoaderContainer(false);
				showHideButtons(true);
			}, 1000);
		};

		var DeleteRoleJSON = '{"language":"' + Constants.Language + '","RoleData":{ "id":"' + roleID + '", "name":"' + roleName + '" }}';
		General.DeleteFromEndPoint(Constants.DeleteRoleEndPoint, null, DeleteRoleJSON, DeleteRoleSuccess, DeleteRoleFailure);
	};

	const {
		config: { isRTL },
	} = useContext(AppContext);

	return (
		<Modal show={showComponent} onHide={handleCancelDelete} size="lg">
			<Modal.Header closeButton className="border-200">
				<Modal.Title as="h5">
					<Flex alignItems="center">
						<div className="icon-item bg-soft-success shadow-none">
							<FontAwesomeIcon icon="user" className="text-success" />
						</div>
						<div className="ms-2">{t("Delete Role")}</div>
					</Flex>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row className="g-0 align-items-center">
					<Col>
						<div className="mb-3">
							{t("Are you sure you want to delete this role?")}
							<br />
							{t("Role Name")}: <strong>{roleName}</strong>
							<br />
							{t("Role ID")}: <strong>{roleID}</strong>
							<br />
						</div>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer className="border-200">
				{buttonsVisibility && (
					<div>
						<Button onClick={handleCancelDelete} size="sm" variant="secondary">
							{t("No")}
						</Button>
						<IconButton onClick={handleConfirmDelete} icon="" size="sm" className="ms-2" variant="primary">
							{t("Yes")}
						</IconButton>
					</div>
				)}

				{loaderContainerVisibility && <Loader></Loader>}

				{errorContainerVisiblity && <div id="errorContainer" className="loginAlert popupAlert"></div>}
			</Modal.Footer>
		</Modal>
	);
};

DeleteConfirmation.propTypes = {
	roleID: PropTypes.string,
	roleName: PropTypes.string,
	showComponent: PropTypes.bool,
};

export default DeleteConfirmation;
