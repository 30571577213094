import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bg2 from "assets/img/slider1.jpg";
import bgright from "assets/img/landing_background-right.jpg";
import Section from "components/common/Section";
import AppContext from "context/Context";
import React, { useContext } from "react";
import { Button, Col, Row, Carousel } from "react-bootstrap";
import styles from "./landing.module.css";

const Hero = ({ titleText }) => {
	const {
		config: { isDark, isRTL },
	} = useContext(AppContext);

	return (
		<Carousel className={`${styles.carousellButtons} `} prevIcon={null} nextIcon={null}>
			{titleText.map((text, index) => (
				<Carousel.Item key={index} className={`${isRTL ? styles.fixCarossell : ""}`}>
					<Section className="py-0 overflow-hidden light" image={bg2} position="top" overlay>
						<Row className={`${styles.verticalheight100} justify-content-center align-items-center pt-8  pb-lg-9 pb-xl-0`}>
							<Col md={3} lg={3} xl={3} className="pb-7 pb-xl-3 text-center text-xl-start"></Col>
							<Col md={6} lg={6} xl={6} className={`pb-7 pb-xl-9 ${styles.textCenter} ${styles.lineHeight15}`}>
								<h1 className={`${styles.textWhite} ${styles.size25} ${styles.lineHeight15}`}>
									{text}
									<br />
								</h1>
							</Col>
							<Col md={3} lg={3} xl={3} className="pb-7 pb-xl-3 text-center text-xl-start"></Col>
						</Row>
					</Section>
				</Carousel.Item>
			))}
		</Carousel>
	);
};

export default Hero;
