import React, { useState, useContext } from "react";

import PropTypes from "prop-types";
import AppContext from "context/Context";
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Col, Modal, Row, Button, Image } from "react-bootstrap";
import IconButton from "components/common/IconButton";

import * as General from "common/general";
import Loader from "components/custom/Loader";

const CreatedFormAlert = ({ formID, formTitle, formName, formPath, status = "1", message, showComponent, hideDeleteConfirmation, successCallBackFunction }) => {
	const [errorContainerVisiblity, showErrorContainer] = useState(false);
	const [loaderContainerVisibility, showLoaderContainer] = useState(false);
	const [buttonsVisibility, showHideButtons] = useState(false);

	const {
		config: { isRTL },
	} = useContext(AppContext);

	return (
		<Modal show={showComponent} size="lg">
			<Modal.Header closeButton className="border-200">
				<Modal.Title as="h5">
					<Flex alignItems="center">
						<div className="icon-item bg-soft-success shadow-none">
							<FontAwesomeIcon icon="form" className="text-success" />
						</div>
						<div className="ms-2">Save Form Alert</div>
					</Flex>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row className="g-0 align-items-center">
					<Col>
						<div className="mb-3">
							{status == "1" ? "Form Created Successfully" : "Form Error :" + message}
							<br />
							Form title: <strong>{formTitle}</strong>
							<br />
							Form name: <strong>{formName}</strong>
							<br />
							Form path: <strong>{formPath}</strong>
							<br />
							Form ID: <strong>{formID}</strong>
							<br />
						</div>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer className="border-200">
				{buttonsVisibility && (
					<div>
						<Button onClick={handleCancelDelete} size="sm" variant="secondary">
							No
						</Button>
						<IconButton onClick={handleConfirmDelete} icon="" size="sm" className="ms-2" variant="primary">
							Yes
						</IconButton>
					</div>
				)}

				{loaderContainerVisibility && <Loader></Loader>}

				{errorContainerVisiblity && <div id="errorContainer" className="loginAlert popupAlert"></div>}
			</Modal.Footer>
		</Modal>
	);
};

CreatedFormAlert.propTypes = {
	formID: PropTypes.string,
	formName: PropTypes.string,
	formPath: PropTypes.string,
	formTitle: PropTypes.string,
	status: PropTypes.string,
	message: PropTypes.string,
	showComponent: PropTypes.bool,
};

export default CreatedFormAlert;
