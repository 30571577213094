import React, { useState, useEffect } from "react";
import TableBody from "./TableBody";
import TableHead from "./TableHead";

const Table = ({
	columns,
	tableName = "",
	tableData,
	isCurrentUserEmail,
	handleView,
	downloadPdf,
	openModalToDelete,
	handleContinue,
	openModal,
	openModalToChangeDriver,
	viewSummary,
	assignTask,
}) => {
	const [sortedTableData, setSortedTableData] = useState([...tableData]);

	useEffect(() => {
		setSortedTableData([...tableData]);
	}, [tableData]);

	const handleSorting = (sortField, sortOrder) => {
		if (sortField) {
			const sorted = [...sortedTableData].sort((a, b) => {
				if (a[sortField] === null) return 1;
				if (b[sortField] === null) return -1;
				if (a[sortField] === null && b[sortField] === null) return 0;

				const stringA = String(a[sortField]);
				const stringB = String(b[sortField]);
				return (
					stringA.localeCompare(stringB, "en", {
						numeric: true,
					}) * (sortOrder === "asc" ? 1 : -1)
				);
			});
			setSortedTableData(sorted);
		}
	};

	return (
		<>
			<table className="table">
				<TableHead columns={columns} handleSorting={handleSorting} />
				<TableBody
					tableName={tableName}
					columns={columns}
					tableData={sortedTableData}
					isCurrentUserEmail={isCurrentUserEmail}
					handleView={handleView}
					downloadPdf={downloadPdf}
					openModalToDelete={openModalToDelete}
					openModal={openModal}
					handleContinue={handleContinue}
					assignTask={assignTask}
					openModalToChangeDriver={openModalToChangeDriver}
					viewSummary={viewSummary}
				/>
			</table>
		</>
	);
};

export default Table;
