import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import AuthWizardProvider from "../wizard/AuthWizardProvider";
import { Card, Form, Nav, ProgressBar } from "react-bootstrap";
import classNames from "classnames";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import db from "indexedDB";
import CircularStepper from './CircularStepper';
library.add(fas);

const WizardStepper = ({
	setCurrentPage,
	currentPage,
	variant,
	progressBar,
	form,
	resources = {},
	formioFormRef,
	language,
	readyState,
	navigateIntoPage,
	formKey,
	setFormKeyUpdated,
	formKeyUpdated,
	myForm,
	nextPage
}) => {
	const [step, setStep] = useState(-1);
	const [readyToSetPage, setReadyToSetPage] = useState(false);
	const [navItems, setNavItems] = useState([]);
	const [initialized, setInitialized] = useState(false);
	const [reNavigate, setRenavigate] = useState(0);
	const [navigated, setNavigated] = useState(false);
	const [currntPageLoaded, setCurrentPageLoaded] = useState(false);
	let formKeyRef = useRef();
	
	const [isShowAllStep, setIsShowAllStep] = useState(false); 
	const [isWidthCard,setIsWidthCard]=useState();
   
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

	const [containerTop, setContainerTop] = useState(0);
    const containerRef = useRef(null);
	// useEffect(() => {
	//     if (parentStep !== -1 && parentStep !== step) {
	//         setStep(parentStep);
	//         setCurrentPage(parentStep)
	//         setParentStep(-1)
	//     }
	// }, [parentStep])

	// //redraw the form for any reason
	// useEffect(() => {
	// 	formKeyRef.current = formKey;
	// }, [formKey]);

	//form is ready
	const calculateContainerTop = () => {
        if (containerRef.current) {
            const containerRect = containerRef.current.getBoundingClientRect();
            const stepperRect = containerRef.current.parentNode.getBoundingClientRect();
            const top = stepperRect.bottom - containerRect.top;
            setContainerTop(top);
			
        }
    };

	const calculateWithCard=()=>{
		
		const parentContainer = document.getElementById('parentContainer');
        if (parentContainer) {
			const parentWidth1 = parentContainer.clientWidth;
			const newWidth = parentWidth1 * 1; 
			setIsWidthCard(newWidth)
			
		}else{
			const parentWidth1 = 1500;
			const newWidth = parentWidth1 * 1; 
			setIsWidthCard(newWidth)
		}
	
		
	}

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 999);
			calculateWithCard();
		//	console.log("win size", window.innerWidth);
		};
	
		calculateContainerTop();
		
			calculateWithCard();
		
		
	
		window.addEventListener("resize", handleResize);
	
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);
	//redraw the form for any reason
	useEffect(() => {
		formKeyRef.current = formKey;
	}, [formKey]);

	useEffect(() => {
		if (formioFormRef.current && !readyToSetPage) {
			if (typeof formioFormRef.current.setPage === "function") {
				setReadyToSetPage(true);
			}
		}
	}, [formioFormRef.current]);

	//navigate through form pages to get already submitted data
	useEffect(() => {
		if (readyState && !formioFormRef.current) {
			setStep(-1);

			setNavigated(true);
			return;
		}
		if (readyToSetPage && !navigated) {
			let pages = form.components.filter((p) => p.id !== null && p.id !== undefined);

			for (let i = 0; i < pages.length; i++) {
				try {
					formioFormRef.current.setPage(i);
					setStep(i);
				} catch (e) {}
			}
			setStep(-1);

			setNavigated(true);
		}
	}, [readyToSetPage, readyState]);

	//clicking on error list takes for the page of this error
	useEffect(() => {
		if (currntPageLoaded) {
			if (navigateIntoPage >= 0) {
				setStep(navigateIntoPage);
				setCurrentPage(navigateIntoPage);
			}
		}
	}, [navigateIntoPage, currntPageLoaded]);

	//initialize initial step of wizard
	useEffect(() => {
		async function setTempData() {
			if (form && form._id && navigated) {
				let pageToNavigate = form.initialFormPage;
				var myData = await db.temp_form_data.toArray();
				let tempFormData = myData[0] === undefined ? {} : JSON.parse(myData[0].tempFormData);

				if (tempFormData["recordInTableId"]) {
					if (tempFormData && tempFormData["formId"] === form._id && tempFormData["recordInTableId"] === form?.executionLocalVariables?.recordInTableId) {
						if (tempFormData["pageNumber"] !== null && tempFormData["pageNumber"] !== undefined) {
							pageToNavigate = tempFormData["pageNumber"];
						}
					}
				} else if (tempFormData["processInstanceId"]) {
					if (tempFormData && tempFormData["formId"] === form._id && tempFormData["processInstanceId"] === form?.processInstanceId) {
						if (tempFormData["pageNumber"] !== null && tempFormData["pageNumber"] !== undefined) {
							pageToNavigate = tempFormData["pageNumber"];
						}
					}
				}

				if (pageToNavigate !== null && pageToNavigate !== undefined && step === -1) {
					setStep(pageToNavigate);
					setCurrentPage(pageToNavigate);
					setCurrentPageLoaded(true);
				}
			}
		}

		setTempData();
	}, [form, navigated]);

	//fill wizard stepper with form pages
	useEffect(() => {
		if (myForm?.display === "wizard" && formioFormRef.current !== null) {
			let tempComponents = myForm.components.filter((p) => p.hidden === false);
			let tempNavItems = [];
			for (let i = 0; i < tempComponents.length; i++) {
				if (tempComponents[i].title) {
					tempNavItems.push({
						icon: "lock",
						label: resources[tempComponents[i].title] || tempComponents[i].title,
						skip: tempComponents[i].skip,
					});
				}
			}
			setNavItems([...tempNavItems]);
		}
	}, [form, resources, language, formioFormRef.current, myForm,nextPage]);

	useEffect(() => {
		if (currentPage !== -1 && currentPage !== step && step !== -1) {
			setStep(currentPage);
		}
	}, [currentPage]);

	const handleNavs = (targetStep) => {
		setStep(targetStep);
		setCurrentPage(targetStep);
		setIsShowAllStep(false)
	};
	const handleToggleSteps = () => {
        setIsShowAllStep(!isShowAllStep);

    };


	return (
		form?.display === "wizard" &&
		readyState && (
			<AuthWizardProvider>
				<Card as={Form} noValidate className="theme-wizard mb-3" ref={containerRef} id="parentContainer">
					<Card.Header
						className={classNames("bg-light", {
							"px-4 py-3": variant === "pills",
							"pb-2": !variant,
						})}
						
					>
						{isMobile ? (
					<Nav  className="justify-content-center" variant={variant} onClick={handleToggleSteps}>
						
						{navItems.map(
								(item, index) =>
									item.skip !== true && <CircularItem key={index} navItems={navItems} index={index} step={step} handleNavs={handleNavs} isMobileStepper={true} isWidthCard={isWidthCard}/>
							)}

					</Nav>
						):(
						<>	<Nav className="justify-content-center" >
							{navItems.map((item, index) =>
									!item.skip && <NavItem key={index} label={item.label} navItems={navItems} index={index} onPageChange={(index) => handleNavs(index)} currentStep={index} handleNavs={handleNavs} step={step}  isMobileStepper={false} isWidthCard={isWidthCard}/>
							)}
						
						</Nav>	
		
						</>)}
					</Card.Header>
					{progressBar && (
						<ProgressBar now={(step - navItems.filter((i) => i.skip !== true).length) * Math.floor(100 / navItems.filter((i) => i.skip !== true).length)} style={{ height: 2 }} />
					)}


				</Card>
				{isShowAllStep && (
                    <div className="floating-steps" style={{top: `${containerTop+60}px`,width: '100%' , position: 'absolute', left: '50%', transform: 'translateX(-50%)', zIndex: 999, background: 'white', padding: '5px', borderRadius: '5px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)' }} >
                        {navItems.map((item, index) => (
                            !item.skip && (
                                <CircularStepper key={index} steps={navItems} currentStep={index} onPageChange={(index) => handleNavs(index)} isOnlyLabel={true} isMobileStepper={false}/>
                            )
                        ))}
                    </div>
					
                )}
			</AuthWizardProvider>
		)
	);

};

const CircularItem = ({ index, step, handleNavs, icon, label, navItems,isMobileStepper,isWidthCard }) => {
	
	return index === step ? <CircularStepper key={index} steps={navItems} currentStep={index} onPageChange={(index) => handleNavs(index)} isOnlyLabel={false} isMobileStepper={isMobileStepper} isWidthCard={isWidthCard}/> : null;
	
//	return 	<CircularStepper steps={navItems} currentStep={index} />;
	
};


const NavItem = ({ index, step, handleNavs, icon, label, navItems }) => {
	return (
		<Nav.Item>
			<Nav.Link
				className={classNames("fw-semi-bold", {
					done: index < navItems.filter((i) => i.skip !== true).length ? step > index : step > navItems.filter((i) => i.skip !== true).length - 1,
					active: step === index,
				})}
				onClick={() => handleNavs(index)}
			>
				<span className="nav-item-circle-parent">
					<span className={index!==step?"nav-item-circle":"nav-item-square"}>{/* <FontAwesomeIcon icon={step === index ? ""  : "lock"} /> */}</span>
				</span>
				<span className="d-none d-md-block mt-1 fs--1" style={{ fontFamily: "Cairo-Regular" }}>
					{label}
				</span>
			</Nav.Link>
		</Nav.Item>
	);
};

WizardStepper.propTypes = {
	variant: PropTypes.oneOf(["pills"]),
	validation: PropTypes.bool,
	progressBar: PropTypes.bool,
	form: PropTypes.any,
};

export default WizardStepper;
