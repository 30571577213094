import React, { useEffect } from "react";
import { ReactComponent, Utils } from "@formio/react";
import qrCodeSettingsForm from "./QRCode.SettingsForm"; // Import settings form for QR Code
import QRCode from "qrcode.react"; // Import QRCode component
import ReactDOM from "react-dom/client"; // Import ReactDOM

const QRCodeCustomComp = ({ ...props }) => {
	useEffect(() => {
		const qrCodeData = props.form._data[props.component?.key];
	//	console.log("qrcode", props);
	}, []);

	useEffect(() => {
		if (props.data) {
			let keys = Object.keys(props.data);
			for (let i = 0; i < keys.length; i++) {
				props.form._data[keys[i]] = props.data[keys[i]];
				props.form._submission.data[keys[i]] = props.data[keys[i]];
			}
			if (props.insideGrid === true) {
				delete props.form._data[props.component.key];
				delete props.form._submission.data[props.component.key];
			}
		}
	}, [props.data]);

	return <QRCode value={props.form._data[props.component?.key]} />;
};

export default class QRCodeComponent extends ReactComponent {
	static shouldSetValue = false;

	constructor(component, options, data) {
		options["oldState"] = {};
		super(component, options, data);
		this.reactInstance = null;
	}

	static schema(...extend) {
		return ReactComponent.schema({
			type: "qrcodeCustomComp",
			label: "QR Code",
			dataKey: "qrCodeData",
		});
	}

	static get builderInfo() {
		return {
			title: "QR Code",
			icon: "qrcode",
			group: "Basic",
			documentation: "",
			weight: -10,
			schema: QRCodeComponent.schema(),
		};
	}

	static editForm = qrCodeSettingsForm; // Use settings form for QR Code

	attachReact(element, ref) {
		let insideGrid = false;

		const root = ReactDOM.createRoot(element); // Use ReactDOM.createRoot
		let key = this.component.key;

		const rootForm = this.getRoot();

		Utils.eachComponent(
			rootForm.components,
			function (component) {
				if (component.component.type === "editgrid") {
					Utils.eachComponent(
						component.component.components,
						function (component2) {
							if (!insideGrid) {
								insideGrid = component2.key === key;
							}
						},
						true
					);
				}
			},
			true
		);

		root.render(
			<QRCodeCustomComp
				component={this.component}
				onChange={this.updateValue}
				value={this.dataValue}
				data={this.data}
				form={rootForm}
				oldState={this.options["oldState"]}
				insideGrid={insideGrid}
			/>
		);
	}

	detachReact(element) {
		return;
	}

	setValue(value) {
		if (this.reactInstance) {
			this.reactInstance.setState({
				value: value,
			});
			this.shouldSetValue = false;
		} else {
			this.shouldSetValue = true;
			this.dataForSetting = value;
		}
	}

	checkValidity(data, dirty, rowData) {
		const valid = super.checkValidity(data, dirty, rowData);
		if (!valid) {
			return false;
		}
		return this.validate(data, dirty, rowData);
	}

	validate(data, dirty, rowData) {
		return true;
	}
}
