const CustomInput = ({ title, value, placeholder, onChange, type = "text", errors, disabled, customStyle = {} }) => {
	return (
		<div style={customStyle}>
			<label className="form-label">{title}</label>
			<input type={type} disabled={disabled} className="form-control" value={value} placeholder={placeholder} onChange={(event) => onChange(event.target.value)} />
			{errors && <b style={{ color: "red" }}>{errors}</b>}
		</div>
	);
};
export default CustomInput;
