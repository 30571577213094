import React, { useState } from 'react';
import { useTranslation } from "react-i18next";

const CircularStepper = ({ steps, currentStep, onPageChange, isOnlyLabel, isMobileStepper, isWidthCard }) => {
    const [t, i18n] = useTranslation();
    const [isRTL, setIsRTL] = useState(JSON.parse(localStorage.getItem("isRTL")) || false);
    const filteredSteps = steps.filter(step => !step.skip);
    const stepCount = filteredSteps.length;

    // Calculate radius and ensure it does not result in NaN
    const radius = isMobileStepper || isOnlyLabel ? 45 : (isWidthCard && stepCount > 1 ? ((isWidthCard / stepCount) / 3) / 1.2 : isWidthCard/6);
    const strokeWidth = 8;
    const stepAngle = (2 * Math.PI) / stepCount;
    const activeColor = '#385723';
    const completedColor = '#28a745';
    const incompleteColor = 'lightgray';

    const centerX = radius + strokeWidth;
    const centerY = 40 + strokeWidth;
    const diamondSize = 12;
    const centerXX = radius;

    const renderStep = (step, index) => {
        const angle = index * stepAngle;
        const x1 = (centerX * 2.5) + radius * Math.cos(angle);
        const y1 = centerY + radius * Math.sin(angle);
        const x2 = (centerX * 2.5) + radius * Math.cos(angle + stepAngle);
        const y2 = centerY + radius * Math.sin(angle + stepAngle);
        const isActive = index === currentStep;
        const isCompleted = index < currentStep;

        return (
            <g key={index} onClick={() => onPageChange(currentStep)}>
                {index < stepCount - 1 && (
                    <path
                        d={`M ${x1},${y1} A ${radius},${radius} 0 0,1 ${x2},${y2}`}
                        fill="none"
                        stroke={isCompleted ? completedColor : incompleteColor}
                        strokeWidth={strokeWidth}
                    />
                )}

                <circle
                    cx={x1}
                    cy={y1}
                    r={strokeWidth / 2}
                    fill={isActive ? activeColor : isCompleted ? completedColor : incompleteColor}
                />
                {isMobileStepper ? (
                    <text
                        x={centerX * 2.5}
                        y={centerY}
                        textAnchor="middle"
                        alignmentBaseline="central"
                        fill={completedColor}
                        fontSize="18"
                        fontFamily="Cairo-Regular"
                    >
                        {currentStep + 1} {t("of")} {stepCount}
                    </text>
                ) : (
                    <text
                        x={centerX * 2.5}
                        y={centerY}
                        textAnchor="middle"
                        alignmentBaseline="central"
                        fill={completedColor}
                        fontSize="18"
                        style={{ cursor: 'pointer' }}
                        fontFamily="Cairo-Regular"
                    >
                        {currentStep + 1}
                    </text>
                )}
            </g>
        );
    };

    const renderLinearSteps = () => (
        <div className="fw-normal px-md-6 py-4" style={{ display: 'flex', justifyContent: 'center' }}>
            <svg width={isWidthCard} height={centerY * 2.5} style={{ margin: 'auto', display: 'block', transform: isRTL ? "scale(-1,1)" : "scale(1,1)" }} >
                {filteredSteps.map((step, index) => (
                    <g key={index}>
                        {index < steps.length && index > 0 && (
                            <line
                                x1={centerXX * (index) * 3}
                                y1={centerY}
                                x2={centerXX * (index + 1) * 3}
                                y2={centerY}
                                stroke={index <= currentStep ? completedColor : incompleteColor}
                                strokeWidth={3}
                                strokeLinecap="round"
                            />
                        )}
                        {index === currentStep ? (
                            <polygon
                                points={`${centerXX * (index + 1) * 3},${centerY - diamondSize} ${centerXX * (index + 1) * 3 + diamondSize},${centerY} ${centerXX * (index + 1) * 3},${centerY + diamondSize} ${centerXX * (index + 1) * 3 - diamondSize},${centerY}`}
                                fill={activeColor}
                                stroke="none"
                            />
                        ) : (
                            <circle
                                cx={centerXX * (index + 1) * 3}
                                cy={centerY}
                                r={8}
                                fill={index <= currentStep ? completedColor : incompleteColor}
                                stroke="none"
                                onClick={() => onPageChange(index)}
                            />
                        )}
                        <text
                            x={isRTL ? -(centerXX * (index + 1) * 3) : (centerXX * (index + 1) * 3)}
                            y={centerY + 30}
                            textAnchor="middle"
                            fontWeight="bold"
                            fontFamily="Cairo-Regular"
                            fontSize="12"
                            fill={completedColor}
                            transform={isRTL ? "scale(-1, 1)" : "scale(1, 1)"}
                            style={{ cursor: 'pointer' }}
                            onClick={() => onPageChange(index)}
                           
                        >
                            {step.label}
                        </text>
                    </g>
                ))}
            </svg>
        </div>
    );

    return isOnlyLabel === false ? (
        isMobileStepper ? (
            <svg height={centerY * 2.7} style={{ display: 'block', margin: 'auto' }} onClick={() => onPageChange(currentStep)}>
                <circle cx={centerX * 2.5} cy={centerY} r={radius} fill="none" stroke={incompleteColor} strokeWidth={strokeWidth} />
                {filteredSteps.map((step, index) => renderStep(step, index))}
                <text
                    x={centerX * 2.5}
                    y={centerY + 70}
                    textAnchor="middle"
                    fontWeight="bold"
                    fontSize="12"
                    fontFamily="Cairo-Regular"
                    fill={completedColor}
                    style={{ cursor: 'pointer' }}
                >
                    {steps[currentStep].label}
                </text>
            </svg>
        ) : renderLinearSteps()
    ) : (
        <svg height={35} style={{ display: 'block', margin: 'auto' }} onClick={() => onPageChange(currentStep)}>
            <text
                x={centerX * 3}
                y={centerY - 30}
                textAnchor="middle"
                fontWeight="bold"
                fontFamily="Cairo-Regular"
                style={{ cursor: 'pointer' }}
                fontSize="16"
                fill={completedColor}
            >
                {steps[currentStep].label}
            </text>
        </svg>
    );
};

export default CircularStepper;
