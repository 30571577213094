import React, { useState, useContext } from "react";

import PropTypes from "prop-types";
import AppContext from "context/Context";
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Col, Modal, Row, Button, Image } from "react-bootstrap";
import IconButton from "components/common/IconButton";
import * as Constants from "common/constants";
import * as General from "common/general";
import Loader from "components/custom/Loader";
import { useTranslation } from "react-i18next";

const DeleteConfirmation = ({ userID, name, email, companyId, getCompanyUsers, showComponent, hideDeleteConfirmation, keycloackCompanyId, successCallBackFunction }) => {
	const [errorContainerVisiblity, showErrorContainer] = useState(false);
	const [loaderContainerVisibility, showLoaderContainer] = useState(false);
	const [buttonsVisibility, showHideButtons] = useState(true);
	const [t, i18n] = useTranslation();
	const handleCancelDelete = () => {
		hideDeleteConfirmation();
	};

	const handleConfirmDelete = async () => {
		showLoaderContainer(true);
		showHideButtons(false);
		showErrorContainer(false);
		await General.UnassignUser(userID, companyId, keycloackCompanyId);

		showLoaderContainer(false);
		showHideButtons(true);
		showErrorContainer(true);
		getCompanyUsers();
		hideDeleteConfirmation();
	};

	const {
		config: { isRTL },
	} = useContext(AppContext);

	return (
		<Modal show={showComponent} onHide={handleCancelDelete} size="lg">
			<Modal.Header closeButton className="border-200">
				<Modal.Title as="h5">
					<Flex alignItems="center">
						<div className="icon-item bg-soft-success shadow-none">
							<FontAwesomeIcon icon="user" className="text-success" />
						</div>
						<div className="ms-2">{t("Delete User")}</div>
					</Flex>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row className="g-0 align-items-center">
					<Col>
						<div className="mb-3">
							{t("Are you sure you want to delete this user?")}
							<br />
							{t("Full Name")}: <strong>{name}</strong>
							<br />
							{t("Email")}: <strong>{email}</strong>
							<br />
						</div>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer className="border-200">
				{buttonsVisibility && (
					<div>
						<Button onClick={handleCancelDelete} size="mm" variant="secondary">
							{t("No")}
						</Button>
						<IconButton onClick={handleConfirmDelete} icon="" size="mm" className="ms-2" variant="danger">
							{t("Yes")}
						</IconButton>
					</div>
				)}

				{loaderContainerVisibility && <Loader></Loader>}

				{errorContainerVisiblity && <div id="errorContainer" className="loginAlert popupAlert"></div>}
			</Modal.Footer>
		</Modal>
	);
};

DeleteConfirmation.propTypes = {
	userID: PropTypes.string,
	fullname: PropTypes.string,
	email: PropTypes.string,
	showComponent: PropTypes.bool,
};

export default DeleteConfirmation;
