import React from "react";
import { ReactComponent } from "@formio/react";
import DynamicTable from "./DynamicTable"; // Corrected import name
import DynamicTableDataSettings from "./DynamicTableData.settingsForm";
import { createRoot } from "react-dom/client";

class DynamicTableData extends ReactComponent {
  static shouldSetValue = false;

  constructor(component, options, data) {
    super(component, options, data);
    this.reactInstance = null;
    this.validationErrors = {}; // Manage validation errors using internal properties

    this.setComponentState = this.setComponentState.bind(this);
    this.setValidationErrors = this.setValidationErrors.bind(this);
  }

  static schema(...extend) {
    return ReactComponent.schema({
      type: "dynamicTableDataComp",
      label: "Dynamic Table Data",
    });
  }

  static get builderInfo() {
    return {
      title: "Dynamic Table Data",
      icon: "table",
      group: "Basic",
      documentation: "",
      weight: -10,
      schema: DynamicTableData.schema(),
    };
  }

  static editForm = DynamicTableDataSettings;
  /**
   * This method is called any time the component needs to be rebuilt. It is most frequently used to listen to other
   * components using the this.on() function.
   */
  init() {
    return super.init();
  }
  /**
       * This method is called before the component is going to be destroyed, which is when the component instance is
       * destroyed. This is different from detach which is when the component instance still exists but the dom instance is
       * removed.
       */
  destroy() {
    return super.destroy();
  }
  /**
       * This method is called before a form is submitted.
       * It is used to perform any necessary actions or checks before the form data is sent.
       *
     */
  beforeSubmit() {
    return super.beforeSubmit();
  }
  /**
       * The second phase of component building where the component is rendered as an HTML string.
       *
       * @returns {string} - The return is the full string of the component
       */
  render() {
    return super.render(`<div ref="react-${this.id}"></div>`);
  }
  /**
       * Callback ref to store a reference to the node.
       *
       * @param element - the node
       */
  setReactInstance(element) {
    this.reactInstance = element;
  }
  /**
       * The third phase of component building where the component has been attached to the DOM as 'element' and is ready
       * to have its javascript events attached.
       *
       * @param element
       * @returns {Promise<void>} - Return a promise that resolves when the attach is complete.
       */
  attach(element) {
    super.attach(element);
    return Promise.resolve();
  }

  attachReact(element) {
    const rootForm = this.getRoot(); // Get the root form object
    const root = createRoot(element); // Use createRoot to create a root for rendering

    

    root.render(
      <DynamicTable
        component={this.component}
        options={this.component.options || []}
        value={this.dataValue}
        onChange={this.updateValue}
        form={rootForm}
        setComponentState={this.setComponentState}
        setValidationErrors={this.setValidationErrors}
      />
    );
  }

  detachReact(element) {
    return;
  }
  setComponentState = (value) => {
    this.options["oldState"] = { ...value };
    this.updateOnChange({}, true);
  };
  
  setValidationErrors(errors) {
    this.validationErrors = errors;
    this.updateOnChange({}, true);
  }

  setValue(value) {
    if (this.reactInstance) {
      this.reactInstance.setState({
        value: value,
      });
      this.shouldSetValue = false;
    } else {
      this.shouldSetValue = true;
      this.dataForSetting = value;
    }
  }

  checkValidity(data, dirty, rowData) {
    const valid = super.checkValidity(data, dirty, rowData);
    if (!valid) {
      return false;
    }
    return this.validate(data, dirty, rowData);
  }

  validate(data, dirty, rowData) {
    if (Object.keys(this.validationErrors).length > 0) {
      return false; // Validation errors present
    }
    return true;
  }
}

export default DynamicTableData;
