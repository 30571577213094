import facilitiesCustomComp from "./Facilities";
import photoCapture from "./capturePhoto";
import geolocation from "./Geolocation";
import conditionalDropDownCustomComp from "./ConditionalDropDown";
import companyCustomComp from "./Company";
import companyUsersCustomComp from "./CompanyUsersDropdown";
import companyRelationCustomComp from "./CompanyRelation";
import getAllCustomComp from "./GetAll";
import generalAPICustomComp from "./GeneralAPI";
import normalSelectCustomComp from "./NormalSelectAPI";
import loggedInUserDetailsCustomComp from "./LoggedInUserDetails";
import setValuesCustomComp from "./SetValues";
import confirmationCustomComp from "./Confirmation";
import textFieldValidationCustomComp from "./TextFieldValidation";
import mapCustomComp from "./map";
import gisMapCustomComp from "./GisMap";
import dynamicDropDownCustomComp from "./DynamicDropdown";
import dropdownCustomComp from "./Dropdown";
import autoCompleteCustomComp from "./AutoComplete";
import companyContractsCustomComp from "./CompanyContracts";
import companyMembersCustomComp from "./CompanyMembers";
import photoCaptureCustomComp from "./PhotoCapture";
import userCompanyCustomComp from "./UserCompany";
import userGeolocation from "./UserGeolocation";
import textFieldInputValidationCustomComp from "./TextFieldInputValidation";
import confirmationMessagesCustomComp from "./ConfirmationMessages";
import qrcodeCustomComp from "./qrCode"
import radioButtonCustomComp from "./CustomRadioButton"
import dynamicTableDataComp from "./DynamicTableData"

export default {
	facilitiesCustomComp,
	photoCapture,
	geolocation,
	conditionalDropDownCustomComp,
	companyCustomComp,
	companyUsersCustomComp,
	companyRelationCustomComp,
	getAllCustomComp,
	generalAPICustomComp,
	normalSelectCustomComp,
	loggedInUserDetailsCustomComp,
	setValuesCustomComp,
	confirmationCustomComp,
	gisMapCustomComp,
	mapCustomComp,
	textFieldValidationCustomComp,
	dynamicDropDownCustomComp,
	dropdownCustomComp,
	autoCompleteCustomComp,
	companyContractsCustomComp,
	companyMembersCustomComp,
	photoCaptureCustomComp,
	userCompanyCustomComp,
	userGeolocation,
	textFieldInputValidationCustomComp,
	confirmationMessagesCustomComp,
	qrcodeCustomComp,
	radioButtonCustomComp,
	dynamicTableDataComp,
};
