import React, { useState, useContext, useEffect } from "react";

import PropTypes from "prop-types";
import AppContext from "context/Context";
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Col, Modal, Row, Button, Image } from "react-bootstrap";
import IconButton from "components/common/IconButton";
import * as Constants from "common/constants";
import * as General from "common/general";
import Loader from "components/custom/Loader";
import CompaniesDropDown from "./CompaniesDropDown";
import { enable } from "workbox-navigation-preload";
import { useTranslation } from "react-i18next";
import * as em from "common/ErrorMessages";
import Background from "components/common/Background";
import { List } from "reactstrap";
import { replacePlaceholders, sendNotification, sendSms } from "components/workflow/WorkflowHelper";
import { emailTemplates } from "common/SmsTemplates";
import { EncryptStorage } from "encrypt-storage";
import { ToastContainer, toast } from "react-toastify";

const AddUser = ({ showComponent, hideAddUser, successCallBackFunction, companyId, keycloakCompanyId, getCompanyUsers }) => {
	const [errorContainerVisiblity, showErrorContainer] = useState(false);
	const [loaderContainerVisibility, showLoaderContainer] = useState(false);
	const [buttonsVisibility, showHideButtons] = useState(true);
	const [roles, setRoles] = useState([]);
	const [firstName, updateFirstName] = useState("");
	const [lastName, updateLastName] = useState("");
	const [email, updateEmail] = useState("");
	const [password, updatePassword] = useState("");
	const [passwordConfirm, updatePasswordConirm] = useState("");
	const [enabled, updateEnabled] = useState(true);

	const [mobileNumber, updateMobileNumber] = useState("");
	const [iDType, updateIDType] = useState("");
	const [iDNumber, updateIDNumber] = useState("");
	const [checkedRoles, setCheckedRoles] = useState([]);
	const [errorDropDownClassCompany, seterrorDropDownClassCompany] = useState("");
	const [errorDropDownClassCompanyType, seterrorDropDownClassCompanyType] = useState("");
	const [t, i18n] = useTranslation();

	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});
	const handleCancelNew = () => {
		hideAddUser();
	};

	const handleEnabledChange = () => {
		updateEnabled(!enabled);
	};

	const handleCheckboxChange = (event) => {
		const roleName = event.target.value;
		const isChecked = event.target.checked;
		if (isChecked) {
			setCheckedRoles([...checkedRoles, roleName]);
		} else {
			setCheckedRoles(checkedRoles.filter((role) => role !== roleName));
		}
	};

	useEffect(() => {
		fetchRoles();
	}, []);

	const fetchRoles = async () => {
		showLoaderContainer(true);
		var compnayRoles = await General.GetCompanyRoles(companyId);
		compnayRoles = compnayRoles.filter((record) => record.hidden === false);
		setRoles(compnayRoles);

		showLoaderContainer(false);
	};

	const handleAddUser = async () => {
		showLoaderContainer(true);
		showHideButtons(false);
		showErrorContainer(false);

		var firstNameInput = document.getElementById("txtFirstName");
		var lastNameInput = document.getElementById("txtLastName");
		var emailInput = document.getElementById("txtEmail");

		var passwordInput = document.getElementById("txtPassword");
		var passwordConfirmInput = document.getElementById("txtPasswordConfirm");
		var enabledCheckbox = document.getElementById("chkEnabled");

		var mobileNumberInput = document.getElementById("txtMobileNumber");
		var iDTypeDropDown = document.getElementById("ddIDType");
		var iDNumberInput = document.getElementById("txtIDNumber");

		var errorContainerFirstName = document.getElementById("errorContainerFirstName");
		var errorContainerLastName = document.getElementById("errorContainerLastName");
		var errorContainerEmail = document.getElementById("errorContainerEmail");
		var errorContainerMobileNumber = document.getElementById("errorContainerMobileNumber");
		var errorContainerIDNumber = document.getElementById("errorContainerIDNumber");
		var errorContainerConfirmPassword = document.getElementById("errorContainerConfirmPassword");
		var errorContainerPassword = document.getElementById("errorContainerPassword");
		var errorContainerIdType = document.getElementById("errorContainerIdType");
		var errorContainerCampany = document.getElementById("errorContainerCampany");

		var isValidForm = true;
		firstNameInput.classList.remove("is-invalid");
		emailInput.classList.remove("is-invalid");

		lastNameInput.classList.remove("is-invalid");
		passwordInput.classList.remove("is-invalid");
		passwordConfirmInput.classList.remove("is-invalid");

		mobileNumberInput.classList.remove("is-invalid");
		iDTypeDropDown.classList.remove("is-invalid");
		iDNumberInput.classList.remove("is-invalid");

		errorContainerEmail.innerHTML = "&nbsp;";
		errorContainerMobileNumber.innerHTML = "&nbsp;";
		errorContainerIDNumber.innerHTML = "&nbsp;";
		errorContainerLastName.innerHTML = "&nbsp;";
		errorContainerFirstName.innerHTML = "&nbsp;";
		errorContainerPassword.innerHTML = "&nbsp;";
		errorContainerConfirmPassword.innerHTML = "&nbsp;";
		errorContainerIdType.innerHTML = "&nbsp;";
		errorContainerCampany.innerHTML = "";

		seterrorDropDownClassCompany("");
		seterrorDropDownClassCompanyType("");

		if (firstName === "") {
			errorContainerFirstName.innerText = t(em.fistName_Empty);
			firstNameInput.classList.add("is-invalid");
			isValidForm = false;
		}
		if (lastName === "") {
			lastNameInput.classList.add("is-invalid");
			errorContainerLastName.innerText = t(em.lastname_empty);
			isValidForm = false;
		}

		if (password === "") {
			errorContainerPassword.innerText = t(em.password_required);
			passwordInput.classList.add("is-invalid");
			isValidForm = false;
		}

		if (passwordConfirm === "") {
			errorContainerConfirmPassword.innerText = t(em.confirm_password_required);
			passwordConfirmInput.classList.add("is-invalid");
			isValidForm = false;
		}

		if (email === "" || !General.isValidEmail(email)) {
			errorContainerEmail.innerText = t(em.invalid_email);
			emailInput.classList.add("is-invalid");
			isValidForm = false;
		}

		if (password != "" || passwordConfirm != "") {
			if (password != passwordConfirm) {
				passwordInput.classList.add("is-invalid");
				passwordConfirmInput.classList.add("is-invalid");
				errorContainerConfirmPassword.innerText = t(em.password_no_match);
				isValidForm = false;
			}
		}

		if (mobileNumber === "" || !General.isKSAPhoneNumber(mobileNumber)) {
			errorContainerMobileNumber.innerText = t(em.format_phone_number);

			mobileNumberInput.classList.add("is-invalid");
			isValidForm = false;
		}

		if (iDType === "") {
			errorContainerIdType.innerText = t(em.id_type_unselected);
			iDTypeDropDown.classList.add("is-invalid");
			isValidForm = false;
		}

		if (iDNumber === "" || iDNumber.length != 10) {
			errorContainerIDNumber.innerText = t(em.id_number);
			iDNumberInput.classList.add("is-invalid");
			isValidForm = false;
		}

		if (isValidForm == false) {
			showLoaderContainer(false);
			showHideButtons(true);
			showErrorContainer(false);
		} else {
			var addUserJSON = {
				enabled: enabled,
				emailVerified: true,
				password: password,
				temporary: false,
				firstName: firstName,
				lastName: lastName,
				email: email,
				idType: iDType,
				idNumber: iDNumber,
				mobileNumber: mobileNumber,
				roles: checkedRoles,
				userName: iDNumber,
			};

			var addUserJSONString = JSON.stringify(addUserJSON);

			let response = await General.AddUser(companyId, keycloakCompanyId, false, addUserJSONString);
			if (response.status === 1) {
				var loggedInUserInfo = encryptStorage1.getItem(Constants.LoggedInUserInfoKeyInLocalStorage);
				var userBodyVariable = { USERNAME: firstName + " " + lastName, PASSWORD: password };
				var adminBodyVariable = { ADMINNAME: loggedInUserInfo.fullName, NEWUSERNAME: firstName + " " + lastName };
				var adminSmsBody = replacePlaceholders(emailTemplates["adduseradmin"], adminBodyVariable);
				var userSmsBody = replacePlaceholders(emailTemplates["adduser"], userBodyVariable);

				if (loggedInUserInfo.mobileNumber !== null && loggedInUserInfo.mobileNumber !== "") {
					await sendSms(loggedInUserInfo.mobileNumber, adminSmsBody);
				}

				if (loggedInUserInfo.email !== null && loggedInUserInfo.email !== "") {
					await sendNotification("en", loggedInUserInfo.email, "adduseradmin", adminBodyVariable);
				}
				if (mobileNumber !== null && mobileNumber !== "") {
					await sendSms(mobileNumber, userSmsBody);
				}
				if (email !== null && email !== "") {
					await sendNotification("en", email, "adduser", userBodyVariable);
				}
				toast.success(t("Successfully Added"), {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 3000,
				});
			} else {
				toast.error(t("User Conflict"), {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 3000,
				});
			}

			showErrorContainer(false);
			showLoaderContainer(false);
			showHideButtons(true);
			hideAddUser();
			getCompanyUsers();
		}
	};

	const {
		config: { isRTL },
	} = useContext(AppContext);

	return (
		<Modal show={showComponent} onHide={handleCancelNew} size="lg">
			<Modal.Header closeButton className="border-200">
				<Modal.Title as="h5">
					<Flex alignItems="center">
						<div className="icon-item bg-soft-success shadow-none">
							<FontAwesomeIcon icon="user" className="text-success" />
						</div>
						<div className="ms-2">{t("New User")}</div>
					</Flex>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row className="g-0 align-items-center">
					<Col>
						{/* style={LoggedInUserCompanyID != "" && LoggedInUserCompanyID != "0" && LoggedInUserCompanyID != null ? { display: 'none' } : { display: 'block' }}*/}
						<div>
							<span id="errorContainerCampany" style={{ color: "red" }}></span>
						</div>
						<div className="mb-3">
							{/* <CompaniesDropDown
                                CompanyDropDownCSSClassExtra={errorDropDownClassCompany}
                                CompanyTypeDropDownCSSClassExtra={errorDropDownClassCompanyType}

                                PreselectedGroups={[]}

                                CompanyDropDownChangeCallBackFunction={GetSelectedCompanyIDFromTheProject}
                                CompanyTypeDropDownChangeCallBackFunction={GetSelectedCompanyTypeIDFromTheProject} ></CompaniesDropDown> */}
						</div>
						<div className="mb-3">
							<label className="form-label">{t("First Name")}</label>
							<span id="errorContainerFirstName" className="loginAlert popupAlert inlineError">
								&nbsp;
							</span>
							<input type="text" className="form-control" id="txtFirstName" value={firstName} placeholder={t("First Name")} onChange={(event) => updateFirstName(event.target.value)} />
						</div>
						<div className="mb-3">
							<label className="form-label">{t("Last Name")}</label>
							<span id="errorContainerLastName" className="loginAlert popupAlert inlineError">
								&nbsp;
							</span>
							<input type="text" className="form-control" id="txtLastName" value={lastName} placeholder={t("Last Name")} onChange={(event) => updateLastName(event.target.value)} />
						</div>
						<div className="mb-3">
							<label className="form-label">{t("Email")}</label>
							<span id="errorContainerEmail" className="loginAlert popupAlert inlineError">
								&nbsp;
							</span>
							<input type="text" className="form-control" id="txtEmail" value={email} placeholder={t("Email")} onChange={(event) => updateEmail(event.target.value)} />
						</div>

						<div className="mb-3">
							<label className="form-label">{t("Password")} </label>
							<span id="errorContainerPassword" className="loginAlert popupAlert inlineError">
								&nbsp;
							</span>
							<input type="password" className="form-control" id="txtPassword" value={password} placeholder={t("Password")} onChange={(event) => updatePassword(event.target.value)} />
						</div>

						<div className="mb-3">
							<label className="form-label">{t("Confirm Password")} </label>
							<span id="errorContainerConfirmPassword" className="loginAlert popupAlert inlineError">
								&nbsp;
							</span>
							<input
								type="password"
								className="form-control"
								id="txtPasswordConfirm"
								value={passwordConfirm}
								placeholder={t("Confirm Password")}
								onChange={(event) => updatePasswordConirm(event.target.value)}
							/>
						</div>

						<div className="mb-3">
							<label className="form-label">{t("Mobile Number")}</label>
							<span id="errorContainerMobileNumber" className="loginAlert popupAlert inlineError">
								&nbsp;
							</span>
							<input
								type="text"
								className="form-control"
								id="txtMobileNumber"
								value={mobileNumber}
								placeholder={t("KSA Mobile Number")}
								onChange={(event) => updateMobileNumber(event.target.value)}
							/>
						</div>
						<div className="mb-3">
							<label className="form-label">{t("ID Type")}</label>
							<span id="errorContainerIdType" className="loginAlert popupAlert inlineError">
								&nbsp;
							</span>
							<select name="productCategory" id="ddIDType" className="form-select" onChange={(event) => updateIDType(event.target.value)}>
								<option value="">{t("Select ID Type")}</option>
								<option value="National ID">{t("National ID")}</option>
								<option value="Iqama">{t("Iqama")}</option>
							</select>
						</div>
						<div className="mb-3">
							<label className="form-label">{t("ID Number")}</label>
							<span id="errorContainerIDNumber" className="loginAlert popupAlert inlineError">
								&nbsp;
							</span>
							<input type="text" className="form-control" id="txtIDNumber" value={iDNumber} placeholder={t("ID Number")} onChange={(event) => updateIDNumber(event.target.value)} />
						</div>
						<div className="mb-3" hidden>
							<div className="form-check">
								<div className="checkBoxItem form-check">
									<input type="checkbox" className="form-check-input" id="chkEnabled" checked={enabled} onChange={handleEnabledChange} />
									<label className="form-check-label" htmlFor="chkEnabled">
										{t("Enable")}
									</label>
								</div>
							</div>
						</div>

						<div className="mb-3">
							<label>{t("Assign Roles")}</label>
							<div className="form-check">
								<div className="row">
									{roles != undefined &&
										roles.length > 0 &&
										roles.map((role) => (
											<div key={role.roleId} className="checkBoxItem form-check col-4">
												<input type="checkbox" className="form-check-input" id={`checkbox-${role.roleName}`} value={role.roleName} checked={role.isChecked} onChange={handleCheckboxChange} />
												<label className="form-check-label" htmlFor={`checkbox-${role.roleName}`}>
													{t(role.roleName)}
												</label>
											</div>
										))}
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer className="border-200">
				{buttonsVisibility && (
					<div>
						<Button size="sm" onClick={handleCancelNew} variant="secondary">
							{t("Cancel")}
						</Button>
						<IconButton size="sm" icon="" className="ms-2" variant="primary" onClick={handleAddUser}>
							{t("Confirm")}
						</IconButton>
					</div>
				)}

				{loaderContainerVisibility && <Loader></Loader>}

				{errorContainerVisiblity && <div id="errorContainer" className="loginAlert popupAlert"></div>}
			</Modal.Footer>
		</Modal>
	);
};

AddUser.propTypes = {
	showComponent: PropTypes.bool,
};

export default AddUser;
