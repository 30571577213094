import React, { useEffect, useRef, useState } from "react";
import { ReactComponent, Utils } from "@formio/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as FiIcons from "react-icons/fi";
import { useTranslation } from "react-i18next";
import settingsForm from "./PhotoCapture.settingsForm";
import { Modal } from "react-bootstrap";
import Flex from "components/common/Flex";
import moment from "moment";
import { createRoot } from "react-dom/client";
import { getSystemPropertyValue } from "commonFunctions";
/**
 This component allows user to capture photo (back camera or front) with additional information like the date and time the photo taken and 
 the location coordinates (latitude and longitude) of the user when taking it
 */

const PhotoCapture = ({ ...props }) => {
	const [myState, setMyState] = useState({ ...props.options });

	const [t, i18n] = useTranslation();
	const errorRef = useRef();
	//stores user stream
	const imageRef = React.createRef();

	//on start camera button get user camera and save it in imageRef
	const getVideo = async () => {
		let state = { ...myState };
		if (imageRef.current) {
			//set imageRef to user stream
			navigator.mediaDevices
				.getUserMedia({ video: { facingMode: state["facingMode"] } })
				.then((stream) => {
					imageRef.current.srcObject = stream;
					state.imageRef = stream;
					state.startCamera = true;
					setMyState(state);
					props.setComponentState(state);
				})
				.catch((e) => {
					//alert user if camera permission is blocked
					console.log(e);
					errorRef.current.innerText = t("Camera Permission is required");
				});
		}
	};
	//on clicking flip camera, camera direction is flipped (either back or front)
	const flipCamera = async (mode) => {
		let state = { ...myState };

		let oldStream = state.imageRef;
		//stop all stream tracks
		state.imageRef.getTracks().forEach(function (track) {
			track.stop();
		});
		//get a new user stream with the updated facing mode and replace the main stream (imageRef) with the new one
		navigator.mediaDevices.getUserMedia({ video: { facingMode: mode } }).then((stream) => {
			const prevTrack = oldStream.getVideoTracks()[0];

			var newTrack = stream.getVideoTracks()[0];
			oldStream.removeTrack(prevTrack);

			oldStream.addTrack(newTrack);
			state.newStream = stream;
			state.imageRef = oldStream;
			state.facingMode = mode;
			setMyState(state);
			props.setComponentState(state);
		});
	};
	//stop user stream by stopping all its tracks
	const stopCamera = () => {
		let state = { ...myState };
		state.showModal = false;
		if (state.startCamera === true) {
			state.imageRef.getTracks().forEach(function (track) {
				track.stop();
			});
			state.startCamera = false;
		}
		state.datetime = "";

		setMyState(state);
		props.setComponentState(state);
	};

	useEffect(() => {
		let initialValue = props.form._data[props.component.key] || props.component.defaultValue;

		//fill state if the component has default value
		if (initialValue) {
			let state = { ...myState };

			let details = initialValue;

			state.imageRef = details.imageRef;
			state.datetime = details.datetime;
			state.coordinates = details.coordinates;
			setMyState(state);
			props.setComponentState(state);
		}
	}, []);

	//get location coordinates of the user who's capturing the photo
	const takePhoto = async () => {
		let state = { ...myState };

		if (navigator.geolocation) {
			const options = {
				enableHighAccuracy: false,
				maximumAge: Infinity,
			};

			function success(pos) {
				const crd = pos.coords;
				state.coordinates = {
					latitude: crd.latitude,
					longitude: crd.longitude,
				};

				navigator.geolocation.clearWatch(id);

				capturePhotoFunction(imageRef.current, state);
			}

			function error(err) {
				console.warn(`ERROR(${err.code}): ${err.message}`);
				state.coordinates = {
					latitude: -1,
					longitude: -1,
				};
				capturePhotoFunction(imageRef.current, state);
			}

			let id = navigator.geolocation.watchPosition(success, error, options);
		} else {
			state.coordinates = {
				latitude: -1,
				longitude: -1,
			};
			capturePhotoFunction(imageRef.current, state);
		}
	};

	//this function is used to capture photo of the user
	const capturePhotoFunction = async (imageRef, state) => {
		if (!state["imageRef"].toString().startsWith("data")) {
			state.showModal = false;
			const canvas = document.createElement("canvas");

			canvas.width = imageRef.videoWidth;
			canvas.height = imageRef.videoHeight;
			canvas.getContext("2d").drawImage(imageRef, 0, 0);
			const imageDataURL = canvas.toDataURL("image/jpeg", parseFloat(state["quality"]));
			state.imageRef?.getTracks()?.forEach(function (track) {
				track.stop();
			});
			state.imageRef = imageDataURL;
			state.startCamera = false;
			state.datetime = moment().format("DD-MM-YYYY hh:mm a");
			setMyState(state);
			props.setComponentState(state);

			//if user is filling the form and not editing it then update its value
			if (props.form._form.settings && props.form._form.settings.toEdit !== true) {
				props.onChange(
					{
						imageRef: imageDataURL,
						datetime: moment().format("DD-MM-YYYY hh:mm a"),
						coordinates: state.coordinates,
					},
					null
				);
			}
		} else {
			let stream = await navigator.mediaDevices.getUserMedia({
				video: { facingMode: state["facingMode"] },
			});
			imageRef.srcObject = stream;
			state.imageRef = stream;
			state.startCamera = true;
			state.datetime = "";

			setMyState(state);
			props.setComponentState(state);

			if (props.form._form.settings && props.form._form.settings.toEdit !== true) {
				props.onChange("", null);
			}
		}
	};

	//add page data in global form data
	useEffect(() => {
		if (props.data) {
			let keys = Object.keys(props.data);
			for (let i = 0; i < keys.length; i++) {
				props.form._data[keys[i]] = props.data[keys[i]];
				props.form._submission.data[keys[i]] = props.data[keys[i]];
			}

			if (props.insideGrid === true) {
				delete props.form._data[props.component.key];
				delete props.form._submission.data[props.component.key];
			}
		}
	}, [props.data]);
	useEffect(() => {
		let state = { ...myState };

		//always make sure form data has the component value
		if (props.component.validate.required && state.imageRef !== "") {
			props.onChange(
				{
					imageRef: state.imageRef,
					datetime: state.datetime,
					coordinates: state.coordinates,
				},
				null
			);
		}
	}, [props.form]);
	const hideModal = () => {
		let state = { ...myState };
		state.showModal = false;
		if (myState.startCamera === true) {
			myState.imageRef.getTracks().forEach(function (track) {
				track.stop();
			});
			state.startCamera = false;
			setMyState(state);
			props.setComponentState(state);
		} else {
			setMyState(state);
			props.setComponentState(state);
		}
	};
	const isImageTaken = () => {
		return myState["imageRef"].toString().startsWith("data");
	};
	const capturePhoto = () => {
		let state = { ...myState };
		state.showModal = true;
		setMyState(state);
		props.setComponentState(state);
	};
	const myModal = (
		<Modal
			show={myState.showModal}
			onHide={() => {
				hideModal();
			}}
			fullscreen={true}
		>
			<Modal.Header closeButton className="border-200">
				<Modal.Title as="h5">
					<Flex alignItems="center">
						<div className="ms-2">{t("Capture Photo")}</div>
					</Flex>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{props.disabled !== true && (
					<div
						style={{
							position: "relative",
							width: "100%",
							height: "100%",
							display: myState["startCamera"] ? "block" : "none",
						}}
					>
						<div
							title={"Camera Mode"}
							style={{
								position: "absolute",
								right: 0,
								top: 0,
								cursor: "pointer",
								zIndex: 5,
							}}
							onClick={() => {
								flipCamera(myState["facingMode"] === "user" ? "environment" : "user");
							}}
						>
							<FontAwesomeIcon icon={"rotate"} />
						</div>
						<video autoPlay={true} playsInline={true} muted={true} width={"100%"} height={"100%"} style={{ objectFit: "fill" }} ref={imageRef} />
					</div>
				)}
				{isImageTaken() && <img width={"100%"} height={"100%"} style={{ objectFit: "fill" }} src={myState["imageRef"]} />}
			</Modal.Body>
			<Modal.Footer>
				{props.disabled !== true && (
					<div className="d-flex w-100 justify-content-between">
						<div></div>

						<button
							className="btn btn-primary btn-wizard-nav-submit takephoto"
							title={myState["startCamera"] ? t("Take Photo") : t("Retake Photo")}
							style={{ marginTop: "10px" }}
							onClick={() => takePhoto()}
						>
							{" "}
							<FiIcons.FiCamera />
						</button>
						{!isImageTaken() &&
							(!myState["startCamera"] ? (
								<button title={t("Start Camera")} className="btn btn-primary btn-wizard-nav-submit" onClick={() => getVideo()} style={{ marginTop: "10px" }}>
									<FiIcons.FiCamera />{" "}
								</button>
							) : (
								<button
									onClick={() => {
										stopCamera();
									}}
									style={{ marginTop: "10px" }}
									title="Stop Camera"
									className="btn btn-danger btn-wizard-nav-submit"
								>
									<FiIcons.FiCameraOff />
								</button>
							))}
					</div>
				)}
				<div ref={errorRef} id="photo-error"></div>
			</Modal.Footer>
		</Modal>
	);

	useEffect(() => {
		if (!isImageTaken()) {
			if (myState["startCamera"] === false) {
				if (myState["showModal"] === true) {
					getVideo();
				}
			}
		}
	}, [myState["startCamera"], myState["imageRef"], myState["showModal"]]);

	return (
		<div className="photoCapture">
			{myModal}
			{props.disabled !== true && (
				<button
					title={t("Capture Photo")}
					className="btn btn-primary btn-wizard-nav-submit"
					onClick={() => {
						capturePhoto();
					}}
					style={{ marginTop: "10px" }}
				>
					<FiIcons.FiCamera />{" "}
				</button>
			)}
			<div style={{ position: "relative" }}>
				{isImageTaken() && (
					<ul
						style={{
							paddingRight: "15px",
							backgroundColor: "black",
							opacity: 0.7,
							position: "absolute",
							top: "5px",
							left: "5px",
							color: "#fff",
						}}
					>
						<li>{myState.datetime}</li>
						{myState.coordinates?.longitude !== -1 && <li>{myState.coordinates.longitude + "," + myState.coordinates.latitude}</li>}
					</ul>
				)}
				{isImageTaken() && <img width={"100%"} style={{ marginTop: "5px" }} src={myState["imageRef"]} />}
			</div>

			{ }
		</div>
	);
};

export default class Photo extends ReactComponent {
	static shouldSetValue = false; // Define shouldSetValue as a static property

	/**
	 * This is the first phase of component building where the component is instantiated.
	 *
	 * @param component - The component definition created from the settings form.
	 * @param options - Any options passed into the renderer.
	 * @param data - The submission data where this component's data exists.
	 */
	constructor(component, options, data) {
		options["oldState"] = {
			targetWidth: 640,
			quality: getSystemPropertyValue("imageQuality"),
			imageRef: "",
			facingMode: "environment",
			isBack: false,
			startCamera: false,
			newStream: "",
			showModal: false,
			datetime: "",
			coordinates: { latitude: -1, longitude: -1 },
			showDetails: false,
		};
		super(component, options, data);
		this.reactInstance = null;
	}

	/**
	 * This function is the default settings for the component. At a minimum you want to set the type to the registered
	 * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
	 *
	 * @param sources
	 * @returns {*}
	 */
	static schema(...extend) {
		return ReactComponent.schema({
			type: "photoCaptureCustomComp",
			label: "Capture Photo",
		});
	}
	static get builderInfo() {
		return {
			title: "PhotoCaptureNew",
			icon: "cubes",
			group: "Basic",
			documentation: "",
			weight: -10,
			schema: Photo.schema(),
		};
	}
	static editForm = settingsForm;

	/**
	 * Override this function to insert your custom component.
	 *
	 * @param element
	 * @param ref - callback ref
	 */
	attachReact(element, ref) {
		const root = createRoot(element);
		const rootForm = this.getRoot(); // Get the root form object
		let insideGrid = false;
		let key = this.component.key;
		let disabled = this.component.disabled;

		Utils.eachComponent(
			rootForm.components,
			function (component) {
				if (component.component.type === "editgrid") {
					Utils.eachComponent(
						component.component.components,
						function (component2) {
							if (!insideGrid) {
								insideGrid = component2.key === key;
							}
						},
						true
					);
				}

			},
			true
		);
		Utils.eachComponent(
			rootForm?.currentPanel?.components,
			function (component) {
				if (component.components) {
					Utils.eachComponent(
						component.components,
						function (component) {

							if (component.input) {
								if (component.key === key) {
									disabled = component.disabled
								}
							} else if (component.type === "columns") {
								Utils.eachComponent(
									component.columns,
									function (component2) {
										if (component2.input) {
											if (component2.key === key) {
												disabled = component2.disabled


											}
										}
									},
									true
								);
							}

						},
						true
					);
				}
				else {
					if (component.columns) {
						Utils.eachComponent(
							component.columns,
							function (component2) {
								if (component2.input) {
									if (component2.key === key) {

										disabled = component2.disabled


									}
								}
							},
							true
						);
					}
					else {
						if (component.input) {
							if (component.key === key) {
								disabled = component.disabled
							}
						}
					}
				}
			},
			true
		);
		const setComponentState = (value) => {
			this.options["oldState"] = { ...value };
			this.updateOnChange({}, true);

			root.render(
				<PhotoCapture
					component={this.component} // These are the component settings if you want to use them to render the component.
					onChange={this.updateValue} // Pass the onChange event handler
					value={this.dataValue}
					data={this.data}
					form={rootForm}
					disabled={disabled}
					insideGrid={insideGrid}
					setComponentState={setComponentState}
					options={this.options["oldState"]}
				/>
			);
		};

		root.render(
			<PhotoCapture
				component={this.component} // These are the component settings if you want to use them to render the component.
				onChange={this.updateValue} // Pass the onChange event handler
				value={this.dataValue}
				data={this.data}
				disabled={disabled}
				form={rootForm}
				insideGrid={insideGrid}
				setComponentState={setComponentState}
				options={this.options["oldState"]}
			/>
		);
	}
}
