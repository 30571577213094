import React, { Component, useEffect, useState } from "react";
import db from "indexedDB";
import { handleSubmit } from "components/forms/FormViewRenderer";
import { useNavigate, useLocation } from "react-router-dom";
import { executePendingTasks } from "components/workflow/WorkflowHelper";

import { useTranslation } from "react-i18next";
import axios from "axios";
function Redash(props) {
	const [submitted, setSubmitted] = useState(false);
	const [showNotification, setShowNotification] = useState(false);
	const [showPreloader, setshowPreloader] = useState(false);
	const { isSettingsPressed, isDashboardsPressed, isQueriesPressed, isMain } = props;
	const navigate = useNavigate();
	const location = useLocation();
	const [t, i18n] = useTranslation();
	const [content, setContent] = useState(null);
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});
	const RedashUrl = "https://visualization.msisolutions.co.uk/";
	useEffect(() => {
		var accessToken = encryptStorage1.getItem("accessToken");

		if (navigator.onLine) {
			executePendingTasks(setSubmitted, setshowPreloader, setShowNotification, navigate, location, t);
		}
		if (isMain) {
			setContent(<iframe src={RedashUrl + "login?token=" + accessToken + "&next=/"} width="100%" height="1000" />);
		} else if (isSettingsPressed) {
			setContent(<iframe src={RedashUrl + "login?token=" + accessToken + "&next=/data_sources"} width="100%" height="1000" />);
		} else if (isDashboardsPressed) {
			setContent(<iframe src={RedashUrl + "login?token=" + accessToken + "&next=/dashboards"} width="100%" height="1000" />);
		} else if (isQueriesPressed) {
			setContent(<iframe src={RedashUrl + "login?token=" + accessToken + "&next=/queries"} width="100%" height="1000" />);
		} else {
			setContent(<p>No component/content selected.</p>);
		}
	}, [location.pathname]);

	return <div>{content}</div>;
}

export default Redash;
