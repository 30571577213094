import classNames from "classnames";
import React, { Component } from "react";

/*
This component is the dropdown used by formio
i.e: obliged to write it from scratch to use it in the custom dropdowns created
*/
class CustomSelect extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showOptions: props.showOptions || "none",
			focusedInput: false,
			searchText: "",
			isDeleteHover: false,
		};
		this.dropdownRef = React.createRef();
		// if(props.setTriggered && props.triggered){
		// 	props.setTriggered();
		// }
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClickOutside);
	}

	// ON LOAD
	async componentDidMount() {
		document.addEventListener("mousedown", this.handleClickOutside);
	}

	handleClickOutside = (event) => {
		if (this.dropdownRef && !this.dropdownRef.current.contains(event.target)) {
			this.setState({ showOptions: "none" });
		}
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevState.showOptions) {
			const option = this.dropdownRef.current.querySelector(`[value="${this.props.value}"]`);
			if (option) {
				option.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" });
			}
		}
	}

	render() {
		const { showOptions, focusedInput, searchText, isDeleteHover } = this.state;

		const filteredOptions = this.props.options?.filter((option) => {
			if (typeof option?.label === 'string') {
				return option.label.toLowerCase().includes(searchText?.toLowerCase());
			}
			return false;
		});

		return (
			<div
				onClick={() => {
					if (!isDeleteHover) {
						this.setState({
							showOptions: this.props.disabled !== true ? (showOptions === "none" ? "block" : focusedInput ? "block" : "none") : "none",
						});
						if (this.props.setShowOptions) {
							this.props.setShowOptions(this.props.disabled !== true ? (showOptions === "none" ? "block" : focusedInput ? "block" : "none") : "none");
						}
					}
				}}
				ref={this.dropdownRef}
				className="form-control form_main_select flex"
				style={{ minHeight: "36px" }}
			>
				<div style={{ width: !this.props.value ? "100%" : "94%", display: "flex" }}>
					<span>{this.props.options?.filter((op) => op?.value === this.props.value)[0]?.label}</span>
				</div>
				<div
					style={{
						display: "flex",
						gap: "10px",
						width: "fit-content",
						alignItems: "center",
						justifyContent: "end",
						width: "6%",
					}}
				>
					{this.props.value !== "" && this.props.value !== undefined && this.props.value !== null && JSON.stringify(this.props.value) !== "{}" && this.props.disabled !== true && (
						<img
							style={{ width: "8px", height: "8px", opacity: ".5" }}
							onMouseEnter={() => this.setState({ isDeleteHover: true })}
							onMouseLeave={() => this.setState({ isDeleteHover: false })}
							src={
								"data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg=="
							}
							onClick={() => {
								this.setState({ showOptions: "none", isDeleteHover: false });
								if (this.props.setShowOptions) {
									this.props.setShowOptions("none");
								}
								if (this.props.component.disabled === false) {
									// if(this.props.setTriggered && this.props.triggered){
									// 	this.props.setTriggered();
									// }
									this.props.updateValue({ target: { value: "" } });
									this.setState({ isDeleteHover: false });
								}
							}}
						/>
					)}
					<div
						className={classNames("", {
							"form-svg-rotated": showOptions === "block",
						})}
					>
						{" "}
						<svg
							xmlns="http://www.w3.org/2000/svg"
							xlink="http://www.w3.org/1999/xlink"
							fill="#000000"
							height="800px"
							width="800px"
							version="1.1"
							id="Layer_1"
							viewBox="0 0 386.257 386.257"
							space="preserve"
						>
							<polygon points="0,96.879 193.129,289.379 386.257,96.879 " />
						</svg>
					</div>
				</div>

				<div style={{ display: this.state.showOptions }} className="form_choices_list">
					<input
						type="text"
						onChange={(e) => this.setState({ searchText: e.target.value })}
						value={searchText}
						onMouseLeave={() => this.setState({ focusedInput: false })}
						onFocus={() => this.setState({ focusedInput: true })}
						onBlur={() => this.setState({ focusedInput: false })}
						className="form_choices_input"
						placeholder="Type to search"
					/>
					<div style={{ display: showOptions }}>
						{filteredOptions?.length > 0 ? (
							filteredOptions.map((option, index) => {
								return (
									<div
										style={{ backgroundColor: option.value === this.props.value ? "#d8e2ef" : "" }}
										key={this.props.component.key + "_" + index}
										onClick={() => {
											if (this.props.setTriggered && this.props.triggered) {
												this.props.setTriggered();
											}
											this.props.updateValue({
												target: { value: option?.value },
												option,
											});
											this.setState({
												showOptions: "none",
												isDeleteHover: false,
											});
										}}
										className={`form_choices_item id-${option?.value} ${option.value === this.props.value ? "darkselected" : ""}`}
										// className={option.value === this.props.value ? "form_choices_item darkselected" : "form_choices_item"}
										value={option?.value}
									>
										{option.label}
									</div>
								);
							})
						) : (
							<div style={{ padding: "10px", fontSize: "14px" }}>No choices to choose from</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}
export default CustomSelect;
