import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import Card from "react-bootstrap/Card";

import { Container } from "react-bootstrap";
import "components/workflow/WorkflowList.css";
import { GetFromEndPoint } from "common/general";
import * as Constants from "common/constants";
import jwtDecode from "jwt-decode";
import { EncryptStorage } from "encrypt-storage";
import keycloakService from "keycloakService";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import logocompany from "assets/img/logo/comp.jpg";

function GetUserCompanies() {
	// const fun=()=>{
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});

	const [t, i18n] = useTranslation();
	const [modal, setModal] = useState(false);
	const [modalIsEmpty, setModalIsEmpty] = useState(false);
	const toggle = () => setModal(!modal);
	const [companies, setCompanies] = useState([""]);
	//const [selectedValue, setSelectedValue] = useState(['']);
	const [selectedItem, setSelectedItem] = useState(null);
	const [selectedItemkeycloakCompanyId, setSelectedItemkeycloakCompanyId] = useState(null);

	const [showError, setShowError] = useState(false);

	const handlecontinue = () => {
		if (selectedItem === null) {
			setShowError(true);
		} else {
			encryptStorage1.setItem(Constants.SelectedCompany, selectedItem);
			encryptStorage1.setItem(Constants.SelectedKeycloakCompany, selectedItemkeycloakCompanyId);

			window.location.reload();
		}
	};
	const handleSelect = (clickedItem, keycloakCompanyId) => {
		setSelectedItem(clickedItem);
		setSelectedItemkeycloakCompanyId(keycloakCompanyId);
		setShowError(false);
	};

	const handlelogout = () => {
		keycloakService.logout();
	};

	useEffect(() => {
		const GetSuccess = (responseObject) => {
			if (isEmpty(responseObject)) {
				// setModal(false)
			} else {
				if (responseObject.length === 1) {
					encryptStorage1.setItem(Constants.SelectedCompany, responseObject[0].companyId);
					encryptStorage1.setItem(Constants.SelectedKeycloakCompany, responseObject[0].keycloakCompanyId);
					//  localStorage.setItem('selectedCompany', responseObject);
					window.location.reload();
				} else {
					//  setModal(true)
					setCompanies(responseObject);
					// encryptStorage1.setItem(Constants.SelectedCompany, selectedItem);
				}
			}
		};

		const GetFailure = (error) => {
			console.error(error);
		};

		const accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);
		const decodedAccessToken = jwtDecode(accessToken);

		var endpoint = Constants.GetUserCompaniesData + decodedAccessToken.sub;
		GetFromEndPoint(endpoint, null, null, GetSuccess, GetFailure);
	}, []);

	return (
		<>
			<Container
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					minHeight: "100vh",
					padding: "20px",
				}}
			>
				<div style={{ maxWidth: "800px", width: "100%" }}>
					<div
						style={{
							textAlign: "center",
							fontWeight: "bold",
							fontSize: "32px",
							marginBottom: "20px",
						}}
					>
						{t("Companies")}
					</div>
					{showError && <p style={{ color: "red", fontWeight: "bold", textAlign: "center" }}>{t("Please Select Company")}</p>}
					<div
						style={{
							display: "flex",
							flexWrap: "wrap",
							justifyContent: "center",
						}}
					>
						{companies.map((item, index) => (
							<Card
								key={index}
								style={{
									width: "15rem",
									height: "13rem",
									margin: "10px",
									border: selectedItem === item?.companyId ? "5px solid green" : "none",
									display: "flex",
									flexDirection: "column",
									justifyContent: "space-between",
								}}
								onClick={() => handleSelect(item?.companyId, item?.keycloakCompanyId)}
							>
								<div style={{ textAlign: "center", marginTop: "20px" }}>
									<img
										src={logocompany}
										alt="Company Logo"
										style={{
											maxWidth: "100%",
											maxHeight: "100px",
											objectFit: "contain",
										}}
									/>
								</div>
								<Card.Body style={{ textAlign: "center" }}>
									<Card.Title>{item.companyId}</Card.Title>
								</Card.Body>
							</Card>
						))}
					</div>
					{selectedItem && (
						<div style={{ textAlign: "center", marginTop: "40px" }}>
							<p>
								{t("You have chosen")} <span style={{ color: "green", fontWeight: "bold" }}>{selectedItem}</span>
							</p>
						</div>
					)}
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							marginTop: "40px",
						}}
					>
						<Button style={{ margin: "10px" }} color="primary" onClick={handlecontinue}>
							{t("Accept")}
						</Button>
						<Button style={{ margin: "10px" }} color="secondary" onClick={handlelogout}>
							{t("Cancel")}
						</Button>
					</div>
				</div>
			</Container>
		</>
	);
}

export default GetUserCompanies;
