import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const CustomRadioButton = ({ options, value, onChange, component,form }) => {
	const [selectedValue, setValue] = useState(-1);
	const [t, i18n] = useTranslation();
	const handleOptionChange = (options) => {
		if(options.isSubmitButton===true){
			form.submit();
		}else{
			if(component.disabled!==true){
				onChange(options.value); // Update the selected value
			setValue(options.value); // Update the local state value
			//console.log("select value :", optionValue);
			}
			
		}
		
		
	};
	useEffect(() => {
		let newValue = value || options[0]?.value;
		setValue(newValue);
		if (!value) {
			onChange(newValue);
		}
	}, []);
	return (
		<div className="custom-radio-group">
			{options.map((option, index) => (
				<div key={index} className="radio-option">
					<div className="image-container">
						{/* <img src={option.image[0]?.url} alt={option.label} onClick={() => handleOptionChange(option.value)} /> */}
						<img disabled={component.disabled === true} src={option.image[0]?.url} alt={option.label} onClick={() => handleOptionChange(option)} className={selectedValue === option.value &&option.isSubmitButton!==true? "image-container selected" : "image-container"} />
	
					</div>
					{!option.isHidden && (
					<div className="radio-container">
						<input disabled={component.disabled === true} type="radio" id={`radio_${index}`} value={option.value} checked={selectedValue === option.value} onChange={() => handleOptionChange(option)} />
					</div>)}
					<div className="label-container">
						<label style={{fontFamily:'Cairo-Regular'}} htmlFor={`radio_${index}`}>{t(option.label)}</label>
					</div>
				</div>
			))}
		</div>
	);
};

export default CustomRadioButton;
