import React, { Component, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { ReactComponent, Utils } from "@formio/react";
import settingsForm from "./CompanyUsers.settingsForm";
import CustomSelect from "../customSelect";
import _ from "lodash";
import { createRoot } from "react-dom/client";
import { printPDF } from "components/workflow/WorkflowHelper";
import { getRandomId } from "helpers/utils";
import * as Constants from "common/constants";
import { EncryptStorage } from "encrypt-storage";

/*
This component get all facilities, transporters, supervisors or inspectors within user company
 with additional text fields to fill their info in as per user request
*/
const CompanyUsersCustomComp = ({ ...props }) => {
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});
	// Update the setValue method to handle onChange event
	const updateValue = (e) => {
		if (props.form._form.settings && props.form._form.settings.toEdit !== true) {
			const newValue = e.target.value; // Get the selected value from the event
			let state = props.oldState;

			//get the associated record from options based on te value selected and set it in form data

			let selectedValues = props.oldState["options"].filter((v) => v.value === newValue)[0];
			if (selectedValues) {
				let json = { value: newValue };
				//fill value with the fields requested
				if (props.component.companyGrid) {
					for (let i = 0; i < props.component.companyGrid.length; i++) {
						json[props.component.companyGrid[i].companyFieldPath] = selectedValues[props.component.companyGrid[i].companyFieldPath];
					}
				}
				json["label"] = selectedValues.label;
				state["selectedValues"] = json;
				props.component.defaultValue = json;

				props.setOldState(state);
				props.onChange(json, null);
				props.form._data[props.component.key] = json;
			} else {
				props.component.defaultValue = "";
				state["selectedValues"] = {};
				props.setOldState(state);
				props.onChange("", null);
				props.form._data[props.component.key] = "";
			}
		}
	};
	const initialize = () => {
		let state = props.oldState;

		//if component has default value and so its additional fields, get their values and set them in state
		if (props.component.defaultValue && props.component.defaultValue !== "") {
			state["selectedValues"] = props.component.defaultValue?.value ? props.component.defaultValue : JSON.parse(props.component.defaultValue);

			let label = state["selectedValues"]?.label;

			if (props.form._form.settings) {
				if (props.form._form.settings.translatedData) {
					if (props.form._form.settings.translatedData[props.form._form.settings.language]) {
						label = props.form._form.settings.translatedData[props.form._form.settings.language][state["selectedValues"]?.label] || label;
						state["selectedValues"].label = label;
					}
				}
			}
		}
		//if component has no default value and it is not disabled get options

		if (props.component.disabled !== true) {
			state["loading"] = false;
			props.setOldState(state);
			if (props.oldState.options.length === 0 || !state.loaded) {
				loadData();
			}
		} else {
			let options = [{ label: state["selectedValues"]?.label, value: state["selectedValues"]?.value }];
			state["options"] = options;
			props.setOldState(state);
		}
	};

	useEffect(() => {
		let state = props.oldState;
		props.setOldState(state);
		initialize();
	}, [props.component.disabled]);

	useEffect(() => {
		setInterval(() => {
			initialize();
		}, 1000);
	}, []);

	const loadData = async () => {
		if (props.component.requestUrl !== "" && props.component.requestUrl && props.component.roleName !== "" && props.component.roleName) {
			var accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);
			var headers = { "Content-Type": "application/json" };

			if (accessToken) {
				var headerToken = { Authorization: "Bearer " + accessToken };
				headers = { ...headers, ...headerToken };
			}
			let companyID = encryptStorage1.getItem("selectedCompany") || "GeneratorCompany";

			fetch(props.component.requestUrl + "/&&companyId=eq." + companyID + "&&tbl_users.tbl_roles_users.roleId=eq." + props.component.roleName, { method: "GET", headers: headers })
				.then((response) => response.json())
				.then((res) => {
					let path = props.component.dataPath;
					let result = path ? _.get(res, path, "") : res;

					let val = result?.map((e) => ({ label: e.fullName, value: e.email, ...e }));
					if (props.component.checkEmpty) {
						val = val.filter((u) => u[props.component.checkEmpty].length !== 0);
					}
					let state = props.oldState;

					state["options"] = val;
					if (val.length > 0) {
						state["loaded"] = true;
					}
					state["loading"] = false;

					props.setOldState(state);
					initialize();
				})
				.then(() => {})
				.catch((error) => {
					console.error("Error:", error);
				});
		}
	};

	//add page data in global form data
	useEffect(() => {
		if (props.data) {
			let state = props.oldState;
			if (props.data[props.component.key]?.value !== props.oldState["selectedValues"].value && props.component.disabled !== true) {
				state["selectedValues"] = props.data[props.component.key];
				props.setOldState(state);
			}
			let keys = Object.keys(props.data);
			for (let i = 0; i < keys.length; i++) {
				props.form._data[keys[i]] = props.data[keys[i]];
				props.form._submission.data[keys[i]] = props.data[keys[i]];
			}

			if (props.insideGrid === true) {
				delete props.form._data[props.component.key];
				delete props.form._submission.data[props.component.key];
			}
		}
	}, [props.data]);
	return (
		<div>
			<div className="row mx-0 ms-0 companyUsersCustomComp">
				<div style={{ display: props.component.dropdownLabel ? "block" : "none" }}>
					{props.form._form.settings && props.form._form.settings.translatedData
						? props.form._form.settings.translatedData[props.form._form.settings.language]
							? props.form._form.settings.translatedData[props.form._form.settings.language][props.component.dropdownLabel] || props.component.dropdownLabel
							: props.component.dropdownLabel
						: props.component.dropdownLabel}
				</div>

				<CustomSelect
					component={props.component}
					disabled={props.component.disabled && props.component.disabled === true}
					value={props.oldState["selectedValues"].value}
					options={props.oldState.options}
					updateValue={updateValue}
				/>

				{/* <select disabled={props.component.disabled && props.component.disabled === true} className="form-control custom-dropdown" value={value} onChange={this.updateValue}>
                            <option disabled value="">Select option</option>

                            {
                                options?.map((option) => {
                                    return <option value={option.value}>
                                        {option.label}
                                    </option>
                                })
                            }
                        </select> */}

				{props.component.companyGrid?.map((grid, index) => {
					return (
						grid.companyFieldPath.replaceAll(" ", "") !== "" &&
						grid.companyFieldName.replaceAll(" ", "") !== "" && (
							<div key={props.component.id + "_" + index} className="col-sm-6 mt-1 p-0">
								<label>
									{props.form._form.settings && props.form._form.settings.translatedData
										? props.form._form.settings.translatedData[props.form._form.settings.language]
											? props.form._form.settings.translatedData[props.form._form.settings.language][grid.companyFieldName] || grid.companyFieldName
											: grid.companyFieldName
										: grid.companyFieldName}
								</label>
								<input type="text" className="form-control" value={props.oldState.selectedValues[grid.companyFieldPath] || ""} disabled={true} />
							</div>
						)
					);
				})}
			</div>
		</div>
	);
};

export default class CompanyUsers extends ReactComponent {
	static shouldSetValue = false; // Define shouldSetValue as a static property

	/**
	 * This is the first phase of component building where the component is instantiated.
	 *
	 * @param component - The component definition created from the settings form.
	 * @param options - Any options passed into the renderer.
	 * @param data - The submission data where this component's data exists.
	 */

	constructor(component, options, data) {
		options["oldState"] = {
			options: [],
			value: null,
			requestUrl: "",
			loaded: false,
			loading: false,
			selectedValues: {},
		};
		super(component, options, data);
		this.reactInstance = null;
	}

	/**
	 * This function is the default settings for the component. At a minimum you want to set the type to the registered
	 * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
	 *
	 * @param sources
	 * @returns {*}
	 */
	static schema(...extend) {
		return ReactComponent.schema({
			type: "companyUsersCustomComp",
			label: "",
		});
	}
	static get builderInfo() {
		return {
			title: "Company Users",
			icon: "cubes",
			group: "Basic",
			documentation: "",
			weight: -10,
			schema: CompanyUsers.schema(),
		};
	}
	static editForm = settingsForm;

	/**
	 * This method is called any time the component needs to be rebuilt. It is most frequently used to listen to other
	 * components using the this.on() function.
	 */
	init() {
		return super.init();
	}

	/**
	 * This method is called before the component is going to be destroyed, which is when the component instance is
	 * destroyed. This is different from detach which is when the component instance still exists but the dom instance is
	 * removed.
	 */
	destroy() {
		return super.destroy();
	}
	/**
	 * This method is called before a form is submitted.
	 * It is used to perform any necessary actions or checks before the form data is sent.
	 *
	 */

	beforeSubmit() {
		return super.beforeSubmit();
	}

	/**
	 * The second phase of component building where the component is rendered as an HTML string.
	 *
	 * @returns {string} - The return is the full string of the component
	 */
	render() {
		// For react components, we simply render as a div which will become the react instance.
		// By calling super.render(string) it will wrap the component with the needed wrappers to make it a full component.
		return super.render(`<div ref="react-${this.id}"></div>`);
	}

	/**
	 * Callback ref to store a reference to the node.
	 *
	 * @param element - the node
	 */
	setReactInstance(element) {
		this.reactInstance = element;
	}

	/**
	 * The third phase of component building where the component has been attached to the DOM as 'element' and is ready
	 * to have its javascript events attached.
	 *
	 * @param element
	 * @returns {Promise<void>} - Return a promise that resolves when the attach is complete.
	 */
	attach(element) {
		super.attach(element);
		let randomId = getRandomId();
		// The loadRefs function will find all dom elements that have the "ref" setting that match the object property.
		// It can load a single element or multiple elements with the same ref.
		this.loadRefs(element, {
			[`react-${randomId}`]: "single",
		});

		if (this.refs[`react-${randomId}`]) {
			this.attachReact(this.refs[`react-${randomId}`], this.setReactInstance.bind(this));
			if (this.shouldSetValue) {
				this.setValue(this.dataForSetting);
				this.updateValue(this.dataForSetting);
			}
		}
		return Promise.resolve();
	}

	/**
	 * The fourth phase of component building where the component is being removed from the page. This could be a redraw
	 * or it is being removed from the form.
	 */
	detach() {
		if (this.refs[`react-${this.id}`]) {
			this.detachReact(this.refs[`react-${this.id}`]);
		}
		super.detach();
	}

	/**
	 * Override this function to insert your custom component.
	 *
	 * @param element
	 * @param ref - callback ref
	 */
	attachReact(element, ref) {
		const rootForm = this.getRoot(); // Get the root form object
		let insideGrid = false;
		let key = this.component.key;
		let newValue = null;
		let tempComponent = this.component;
		const root = createRoot(element);

		Utils.eachComponent(
			rootForm.components,
			function (component) {
				if (component.component.type === "editgrid") {
					Utils.eachComponent(
						component.component.components,
						function (component2) {
							if (!insideGrid) {
								insideGrid = component2.key === key;
							}
						},
						true
					);
				}
			},
			true
		);

		const setOldState = (value) => {
			this.options["oldState"] = value;
			root.render(
				<CompanyUsersCustomComp
					component={this.component} // These are the component settings if you want to use them to render the component.
					onChange={this.updateValue} // Pass the onChange event handler
					value={this.dataValue}
					form={rootForm}
					data={this.data}
					insideGrid={insideGrid}
					tempComponent={tempComponent}
					setOldState={setOldState}
					oldState={this.options["oldState"]}
				/>
			);
		};

		root.render(
			<CompanyUsersCustomComp
				component={this.component} // These are the component settings if you want to use them to render the component.
				onChange={this.updateValue} // Pass the onChange event handler
				value={this.dataValue}
				form={rootForm}
				data={this.data}
				insideGrid={insideGrid}
				tempComponent={tempComponent}
				setOldState={setOldState}
				oldState={this.options["oldState"]}
			/>
		);
	}

	/**
	 * The user has changed the value in the component and the value needs to be updated on the main submission object and other components notified of a change event.
	 *
	 * @param value
	 */
	updateValue = (value, flags) => {
		if (value !== null) {
			flags = flags || {};
			const newValue = value === undefined || value === null ? this.getValue() : value;
			const changed = newValue !== undefined ? this.hasChanged(newValue, this.dataValue) : false;
			this.dataValue = Array.isArray(newValue) ? [...newValue] : newValue;

			this.updateOnChange(flags, changed);
			return changed;
		}
	};
}
