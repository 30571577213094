import React, { useState, useContext, useEffect } from "react";

import * as Constants from "common/constants";
import * as General from "common/general";
import * as em from "common/ErrorMessages";

import IconButton from "components/common/IconButton";
import { Col, Modal, Row, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import Flex from "components/common/Flex";
import cloudUpload from "assets/img/icons/cloud-upload.svg";

import { useTranslation } from "react-i18next";
import LoaderIcon from "components/custom/Preloader";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";

const AddTemplate = ({ setShowAddTemplateModal, addComplete = () => {} }) => {
	const [templateFile, setTemplateFile] = useState("");
	const [templateName, setTemplateName] = useState("");
	const [fileUpload, setFileUpload] = useState(false);
	const [templateFileName, setTemplateFileName] = useState("");
	const [templateFileExtension, setTemplateFileExtension] = useState("");

	const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

	const [t, i18n] = useTranslation();

	const [loading, setLoading] = useState(false);

	const addNewTemplate = () => {
		setLoading(true);
		const AddTemplateSuccess = (responseObject) => {
			if (responseObject.status == Constants.SuccessStatus) {
				setLoading(false);
				setShowAddTemplateModal(false);
				toast.success(t("Success"));
				addComplete();
			}
		};

		const AddTemplateFailure = (error) => {
			setLoading(false);

			toast.error("An error occured, please try again later.", {
				autoClose: 5000,
			});
		};
		if (templateFile === "" || templateName === "") {
			setLoading(false);
			toast.error(t("All fields are required"), {
				autoClose: 5000,
			});
		} else {
			var addNewTemplateEndPoint = Constants.AddTemplateEndPoint + "/" + templateName + "/" + templateFileExtension;
			General.PostToEndPoint(addNewTemplateEndPoint, null, templateFile, AddTemplateSuccess, AddTemplateFailure);
		}
	};
	const fileValidation = (filePath) => {
		var allowedExtensions = /(\.docx)$/i;
		if (!allowedExtensions.exec(filePath)) {
			toast.error(t(em.upload_file_extensions_pdf), {
				position: "top-center",
				autoClose: 3000,
			});
			return false;
		} else return true;
	};
	const handleFileChange = (file) => {
		let is_valid = fileValidation(file.name);
		if (file.size != undefined && file.size > 0) {
			if (file.size < 5242880) {
				if (is_valid) {
					const reader = new FileReader();

					reader.onload = (event) => {
						const base64 = reader.result.split(",")[1];
						setTemplateFile(base64);
						setTemplateFileName(file.name);
						setTemplateFileExtension(file.name.split(".")[1]);
					};

					reader.readAsDataURL(file);
					setFileUpload(true);
				}
			} else {
				setFileUpload(false);
				toast.error(t("File Should be less than 5MB"), {
					position: "top-center",
					autoClose: 3000,
				});
			}
		}
	};

	const handleRemoveFile = () => {
		setTemplateFile("");
		setTemplateFileName("");
		setTemplateFileExtension("");

		setFileUpload(false);
	};

	return (
		<Modal show={true} onHide={() => setShowAddTemplateModal(false)} size="lg">
			{loading && <LoaderIcon />}

			<Modal.Header closeButton className="border-200">
				<Modal.Title as="h5">
					<Flex alignItems="center">
						<div className="ms-2">{t(`New Template`)}</div>
					</Flex>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row className="g-0 align-items-center">
					<Col>
						<div className="mb-3">
							<label className="form-label">{t("Template Name")}</label>
							<input type="text" onChange={(e) => setTemplateName(e.target.value)} className="form-control" value={templateName} />
						</div>
						<div className="mb-3">
							<label className="form-label">{t("Upload Template")}</label>
							{!fileUpload && (
								<div {...getRootProps({ className: "dropzone-area py-6" })}>
									<input
										{...getInputProps({
											multiple: false,
											onChange: (event) => handleFileChange(event.target.files[0]),
										})}
									/>
									<Flex justifyContent="center">
										<img src={cloudUpload} alt="" width={25} className="me-2" />
										<p className="fs-0 mb-0 text-700">{t("Drop your file here")}</p>
									</Flex>
								</div>
							)}
							<div className="mt-3">
								{fileUpload && ( //acceptedFiles.length > 0
									<>
										<h6>{t("File")}</h6>
										<ul className="d-inline-flex align-items-center">
											{templateFileName}
											<div style={{ marginLeft: "10px" }}>
												<OverlayTrigger
													overlay={
														<Tooltip style={{ position: "fixed" }} id="abletodelete">
															{t(em.delete_icon)}
														</Tooltip>
													}
												>
													<div className="theme-control-toggle-label icon-position">
														<IconButton
															className="btn btn-danger"
															iconClassName="fs--2"
															variant=""
															size="sm"
															icon="fa-solid fa-trash"
															style={{ margin: "auto 0.25rem" }}
															onClick={() => handleRemoveFile()}
														></IconButton>
													</div>
												</OverlayTrigger>
											</div>
										</ul>
									</>
								)}
							</div>
						</div>
					</Col>
				</Row>
			</Modal.Body>
			{!loading && (
				<Modal.Footer className="border-200">
					<div>
						<Button
							size="sm"
							onClick={() => {
								setShowAddTemplateModal(false);
							}}
							variant="secondary"
						>
							{t("Cancel")}
						</Button>
						<Button size="sm" className="ms-2" variant="primary" onClick={addNewTemplate}>
							{t("Confirm")}
						</Button>
					</div>
				</Modal.Footer>
			)}
		</Modal>
	);
};

export default AddTemplate;
