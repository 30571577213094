import { RegistrationProcessDefinitionKey } from "common/constants";
import { startWorkflowProcess } from "components/workflow/StartWorkflowProcess";
import React, { useEffect } from "react";
//import { startProcess } from "components/workflow/VerifyEmailWorkflow";
import { useNavigate } from "react-router-dom";

function CreateAccount() {
	const navigate = useNavigate();

	useEffect(() => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const type = urlParams.get("type");
		let companyType = 0;
		if (type === "generator") {
			companyType = 1;
		} else if (type === "facility") {
			companyType = 2;
		} else {
			companyType = 3;
		}
		startWorkflowProcess(RegistrationProcessDefinitionKey)
			.then((formData) => {
				navigate("/form_view_renderer", {
					state: { data: formData, companyType: companyType },
				});
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	return <></>;
}

export default CreateAccount;
