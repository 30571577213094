import Keycloak from "keycloak-js";
import { RedirectUri } from "common/constants";
import { getkeycloackConnectionInformation } from "components/workflow/WorkflowHelper";

var keycloackConnectionInformation = await getkeycloackConnectionInformation();

const keycloak = new Keycloak({
	url: keycloackConnectionInformation.url,
	realm: keycloackConnectionInformation.realm,
	clientId: keycloackConnectionInformation.clientId,
	redirectUri: RedirectUri,
});

export default keycloak;
