import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TableHead = ({ columns, handleSorting }) => {
	const [sortField, setSortField] = useState("");
	const [order, setOrder] = useState("asc");
	const handleSortingChange = (accessor) => {
		const sortOrder = accessor === sortField && order === "asc" ? "desc" : "asc";
		setSortField(accessor);
		setOrder(sortOrder);
		handleSorting(accessor, sortOrder);
	};
	return (
		<thead>
			<tr>
				{columns.map(({ label, accessor, sortable }) => {
					const cl = sortable ? (
						sortField === accessor && order === "asc" ? (
							<FontAwesomeIcon icon="fa-solid fa-arrow-up" />
						) : sortField === accessor && order === "desc" ? (
							<FontAwesomeIcon icon="fa-solid fa-arrow-down" />
						) : (
							<FontAwesomeIcon icon="fa-solid fa-arrows-up-down" />
						)
					) : (
						""
					);
					return (
						<th key={accessor} onClick={sortable ? () => handleSortingChange(accessor) : null}>
							{label} {cl}
						</th>
					);
				})}
			</tr>
		</thead>
	);
};

export default TableHead;
