import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
//import logo from 'assets/img/illustrations/falcon.png'
import logo from "assets/img/logo/mwanLogo.png";
import logodark from "assets/img/logo/mwanLogo-white.png";
import db from "indexedDB";
import { EncryptStorage } from "encrypt-storage";
import { LoggedInUserInfoKeyInLocalStorage } from "common/constants";

const Logo = ({ at, width, className, textClass, logoStyle, ...rest }) => {
	let pathLogo = "";
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});
	var userInfo = encryptStorage1.getItem(LoggedInUserInfoKeyInLocalStorage);
	var hasWorkflowList = false;
	Object.keys(userInfo.userCompanyDictionary).forEach((key) => {
		userInfo.userCompanyDictionary[key].forEach((item) => {
			for (let j = 0; j < item.permissionResponseList.length; j++) {
				for (let k = 0; k < item.permissionResponseList[j].menuResponseList.length; k++) {
					if (item.permissionResponseList[j].menuResponseList[k].to === "/workflowList") {
						hasWorkflowList = true;
						break;
					}
				}
			}
		});
	});

	if (hasWorkflowList) {
		pathLogo = "/workflowList";
	} else {
		pathLogo = "/userTasks";
	}

	// Function to refresh the database
	const refreshDatabase = async () => {
		// Close the existing connection
		db.close();

		// Reopen the database
		await db.open();
	};
	return (
		<div style={logoStyle}>
			<Link
				to={pathLogo}
				onClick={async () => {
					//clear indexedDB temp_form_data (last filled form data) when pathname changes
					await db
						.table("temp_form_data")
						.clear()
						.then(() => {
							refreshDatabase().then(() => {});
						});
				}}
				className={classNames("text-decoration-none", { "navbar-brand text-left": at === "navbar-vertical" }, { "navbar-brand text-left": at === "navbar-top" })}
				{...rest}
			>
				<div
					className={classNames(
						"d-flex",
						{
							"align-items-center py-3": at === "navbar-vertical",
							"align-items-center": at === "navbar-top",
							"flex-center fw-bolder fs-5 mb-4": at === "auth",
						},
						className
					)}
				>
					<img className="me-2 lightlogo" src={logo} alt="Logo" width={width} />
					<img className="me-2 darklogo" src={logodark} alt="Logo" width={width} />
					{/*<span className={classNames('font-sans-serif', textClass)}>INSIGHT</span>*/}
				</div>
			</Link>
		</div>
	);
};

Logo.propTypes = {
	at: PropTypes.oneOf(["navbar-vertical", "navbar-top", "auth"]),
	width: PropTypes.number,
	className: PropTypes.string,
	textClass: PropTypes.string,
};

Logo.defaultProps = { at: "auth", width: 58 };

export default Logo;
