// src/IndexedDB.js
import Dexie from "dexie";

const db = new Dexie("MwanLocalDB");

db.version(3).stores({
	pending_tasks: "++id, pendingTasks",
	submission_data: "++id, submissionData",
	temp_form_data: "++id, tempFormData",
	current_workflow_step: "++id, currentWorkflowStep",
});

export default db;
