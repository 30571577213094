import React, { useState, useContext, useEffect } from "react";

import PropTypes from "prop-types";
import AppContext from "context/Context";
import Flex from "components/common/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Col, Modal, Row, Button, Image } from "react-bootstrap";
import IconButton from "components/common/IconButton";
import * as Constants from "common/constants";
import * as General from "common/general";
import Loader from "components/custom/Loader";
import CompaniesDropDown from "./CompaniesDropDown";
import { useTranslation } from "react-i18next";
import * as em from "common/ErrorMessages";

const EditUser = ({
	userID,
	firstName,
	lastName,
	email,
	enabled,
	mobileNumber,
	IDNumber,
	IDType,
	getCompanyUsers,
	showComponent,
	hideEditUser,
	successCallBackFunction,
	dropdownVisible, //used to know if the admin changes companyid and companytypeid for user being editted
}) => {
	const [errorContainerVisiblity, showErrorContainer] = useState(false);
	const [loaderContainerVisibility, showLoaderContainer] = useState(false);
	const [buttonsVisibility, showHideButtons] = useState(true);
	const [firstNameEdit, updateFirstName] = useState(firstName);
	const [lastNameEdit, updateLastName] = useState(lastName);
	const [emailEdit, updateEmail] = useState(email);
	const [enabledEdit, updateEnabled] = useState(enabled);
	const [mobileNumberEdit, updateMobileNumber] = useState(mobileNumber);
	const [IDNumberEdit, updateIDNumber] = useState(IDNumber);
	const [IDTypeEdit, updateIDType] = useState(IDType);
	// var TypeIDEdit = SelectedGroups[0];
	// var IDEdit = SelectedGroups[1];
	// const [CompanyIDEdit, updateCompanyID] = useState(TypeIDEdit);
	// const [CompanyTypeIDEdit, updateCompanyTypeID] = useState(IDEdit);
	//   console.log('Invalid type for SelectedGroups:', typeof SelectedGroups);
	// const [CompanyIDEdit, updateCompanyID] = useState(SelectedGroups[1]);
	// const [CompanyTypeIDEdit, updateCompanyTypeID] = useState(SelectedGroups[0]);
	const [errorDropDownClassCompany, seterrorDropDownClassCompany] = useState("");
	const [errorDropDownClassCompanyType, seterrorDropDownClassCompanyType] = useState("");
	// console.log("groups all:"+SelectedGroups);
	// console.log("CompanyIDEdit:"+CompanyIDEdit);
	// console.log("CompanyTypeIDEdit:"+CompanyTypeIDEdit);

	const [t, i18n] = useTranslation();

	// useEffect(()=>{
	//     if(TypeIDEdit){
	//         updateCompanyID(TypeIDEdit)
	//     }
	//    if(IDEdit){
	//     updateCompanyTypeID(IDEdit)
	//    }
	// },[TypeIDEdit,IDEdit])

	const handleCancelEdit = () => {
		hideEditUser();
	};

	const GetSelectedCompanyIDFromTheProject = (compID) => {
		if (dropdownVisible) {
			updateCompanyID(compID);
		}
	};
	const GetSelectedCompanyTypeIDFromTheProject = (comtypeid) => {
		if (dropdownVisible) {
			updateCompanyTypeID(comtypeid);
		}
	};

	const handleEditUser = async () => {
		showLoaderContainer(true);
		showHideButtons(false);
		showErrorContainer(false);

		var firstNameInput = document.getElementById("txtFirstNameEdit");
		var lastNameInput = document.getElementById("txtLastNameEdit");
		var emailInput = document.getElementById("txtEmailEdit");

		var mobileNumberInput = document.getElementById("txtMobileNumberEdit");
		var iDTypeDropDown = document.getElementById("ddIDTypeEdit");
		var iDNumberInput = document.getElementById("txtIDNumberEdit");

		var errorContainerEmail = document.getElementById("errorContainerEmail");
		var errorContainerMobileNumber = document.getElementById("errorContainerMobileNumber");
		var errorContainerIDNumber = document.getElementById("errorContainerIDNumber");

		var isValidForm = true;
		lastNameInput.classList.remove("is-invalid");
		firstNameInput.classList.remove("is-invalid");
		emailInput.classList.remove("is-invalid");

		mobileNumberInput.classList.remove("is-invalid");
		iDTypeDropDown.classList.remove("is-invalid");
		iDNumberInput.classList.remove("is-invalid");

		errorContainerEmail.innerHTML = "&nbsp;";
		errorContainerMobileNumber.innerHTML = "&nbsp;";
		errorContainerIDNumber.innerHTML = "&nbsp;";
		seterrorDropDownClassCompany("");
		seterrorDropDownClassCompanyType("");

		if (firstNameEdit === "") {
			firstNameInput.classList.add("is-invalid");
			isValidForm = false;
		}
		if (lastNameEdit === "") {
			lastNameInput.classList.add("is-invalid");
			isValidForm = false;
		}
		if (emailEdit === "" || !General.isValidEmail(emailEdit)) {
			errorContainerEmail.innerText = t(em.invalid_email);
			emailInput.classList.add("is-invalid");
			isValidForm = false;
		}
		if (mobileNumberEdit === "" || !General.isKSAPhoneNumber(mobileNumberEdit)) {
			errorContainerMobileNumber.innerText = t(em.format_phone_number);

			mobileNumberInput.classList.add("is-invalid");
			isValidForm = false;
		}

		if (IDTypeEdit === "") {
			iDTypeDropDown.classList.add("is-invalid");
			isValidForm = false;
		}

		if (IDNumberEdit === "" || IDNumberEdit.length != 10) {
			errorContainerIDNumber.innerText = t(em.id_number);
			iDNumberInput.classList.add("is-invalid");
			isValidForm = false;
		}

		if (isValidForm == false) {
			showLoaderContainer(false);
			showHideButtons(true);
			showErrorContainer(false);
		} else {
			const EditUserSuccess = (responseObject) => {
				showLoaderContainer(false);
				showHideButtons(true);
				successCallBackFunction("true");
			};

			const EditUserFailure = (error) => {
				showLoaderContainer(false);
				showHideButtons(true);
			};

			var EditUserEndPoint = Constants.EditUserEndPoint.replace("#ID#", userID);

			var EditUserJSON = {
				language: Constants.Language,
				userData: {
					enabled: enabledEdit,
					emailVerified: true,
					password: "-",
					temporary: false,
					firstName: firstNameEdit,
					lastName: lastNameEdit,
					email: emailEdit,
					idType: IDTypeEdit,
					idNumber: IDNumberEdit,
					mobileNumber: mobileNumberEdit,
					GroupIDs: null,
				},
			};
			console.log({ EditUserJSON });

			var EditUserJSONString = JSON.stringify(EditUserJSON);

			console.log(EditUserJSON);
			General.PutToEndPoint(EditUserEndPoint, null, EditUserJSONString, EditUserSuccess, EditUserFailure);
			getCompanyUsers();
			hideEditUser();
		}
	};

	const {
		config: { isRTL },
	} = useContext(AppContext);

	const handleEnabledChange = (event) => {
		const { value, checked } = event.target;
		updateEnabled(checked);
	};

	return (
		<Modal show={showComponent} onHide={handleCancelEdit} size="lg">
			<Modal.Header closeButton className="border-200">
				<Modal.Title as="h5">
					<Flex alignItems="center">
						<div className="icon-item bg-soft-success shadow-none">
							<FontAwesomeIcon icon="user" className="text-success" />
						</div>
						<div className="ms-2">{t("Edit User")}</div>
					</Flex>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row className="g-0 align-items-center">
					<Col>
						<div className="mb-3">
							<label className="form-label">{t("User ID")}</label>
							<input type="text" className="form-control" value={userID} disabled="true" />
						</div>
						{/* <div className="mb-3" style={showCompaniesDropDown == false ? { display: 'none' } : { display: 'block' }}>
                            <CompaniesDropDown CompanyDropDownCSSClassExtra={errorDropDownClassCompany} CompanyTypeDropDownCSSClassExtra={errorDropDownClassCompanyType}
                                CompanyDropDownChangeCallBackFunction={GetSelectedCompanyIDFromTheProject} CompanyTypeDropDownChangeCallBackFunction={GetSelectedCompanyTypeIDFromTheProject}
                                PreselectedGroups={SelectedGroups}
                            ></CompaniesDropDown>
                        </div> */}
						<div className="mb-3">
							<label className="form-label">{t("First Name")}</label>
							<input
								type="text"
								className="form-control"
								id="txtFirstNameEdit"
								value={firstNameEdit}
								placeholder={t("First Name")}
								onChange={(event) => updateFirstName(event.target.value)}
							/>
						</div>
						<div className="mb-3">
							<label className="form-label">{t("Last Name")}</label>
							<input
								type="text"
								className="form-control"
								id="txtLastNameEdit"
								value={lastNameEdit}
								placeholder={t("Last Name")}
								onChange={(event) => updateLastName(event.target.value)}
							/>
						</div>
						<div className="mb-3">
							<label className="form-label">{t("Email")}</label>
							<span id="errorContainerEmail" className="loginAlert popupAlert inlineError">
								&nbsp;
							</span>
							<input disabled type="text" className="form-control" id="txtEmailEdit" value={emailEdit} placeholder={t("Email")} onChange={(event) => updateEmail(event.target.value)} />
						</div>

						<div className="mb-3">
							<label className="form-label">{t("Mobile Number")}</label>
							<span id="errorContainerMobileNumber" className="loginAlert popupAlert inlineError">
								&nbsp;
							</span>
							<input
								type="text"
								className="form-control"
								id="txtMobileNumberEdit"
								value={mobileNumberEdit}
								placeholder={t("KSA Mobile Number")}
								onChange={(event) => updateMobileNumber(event.target.value)}
							/>
						</div>

						<div className="mb-3">
							<label className="form-label">{t("ID Type")}</label>
							<select name="productCategory" id="ddIDTypeEdit" className="form-select" value={IDTypeEdit} onChange={(event) => updateIDType(event.target.value)}>
								<option value="">{t("Select ID Type")}</option>
								<option selected={IDTypeEdit == "1"} value="National ID">
									{t("National ID")}
								</option>
								<option selected={IDTypeEdit == "2"} value="Iqama">
									{t("Iqama")}
								</option>
							</select>
						</div>
						<div className="mb-3">
							<label className="form-label">{t("ID Number")}</label>
							<span id="errorContainerIDNumber" className="loginAlert popupAlert inlineError">
								&nbsp;
							</span>
							<input
								type="text"
								className="form-control"
								id="txtIDNumberEdit"
								value={IDNumberEdit}
								placeholder={t("ID Number")}
								onChange={(event) => updateIDNumber(event.target.value)}
							/>
						</div>

						<div className="mb-3">
							<div className="form-check">
								<div className="checkBoxItem form-check" hidden>
									<input type="checkbox" className="form-check-input" id="enabledEdit" checked={enabledEdit} onChange={(event) => handleEnabledChange(event)} />
									<label className="form-check-label" htmlFor="enabledEdit">
										{t("Enable")}
									</label>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer className="border-200">
				{buttonsVisibility && (
					<div>
						<Button size="sm" onClick={handleCancelEdit} variant="secondary">
							{t("Cancel")}
						</Button>
						<IconButton size="sm" className="ms-2" variant="primary" onClick={handleEditUser}>
							{t("Confirm")}
						</IconButton>
					</div>
				)}

				{loaderContainerVisibility && <Loader></Loader>}

				{errorContainerVisiblity && <div id="errorContainer" className="loginAlert popupAlert"></div>}
			</Modal.Footer>
		</Modal>
	);
};

EditUser.propTypes = {
	userID: PropTypes.string,
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	email: PropTypes.string,
	showComponent: PropTypes.bool,
};

export default EditUser;
