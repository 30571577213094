import { local } from "d3";
import { EncryptStorage } from "encrypt-storage";
import jwtDecode from "jwt-decode";
const encryptStorage1 = new EncryptStorage("secret-key-value", {
	prefix: "@mwan",
});
export const validToken = (accessToken) => {
	const decodedToken = jwtDecode(accessToken);
	const currentTime = Date.now() / 1000;
	if (decodedToken.exp > currentTime) {
		return true;
	} else {
		return false;
	}
};

export const getSystemPropertyValue = (key) => {
	let systemProperties = encryptStorage1.getItem("systemProperties");
	let record = systemProperties.filter((r) => r.propertyName === key)[0];
	return record.propertyValue;
};
