import google from "assets/img/logos/google.png";
import netflix from "assets/img/logos/netflix.png";
import amazon from "assets/img/logos/paypal.png";

export default [
	{
		description:
			"Falcon is the best option if you are looking for a theme built with Bootstrap. On top of that, Falcon's creators and support staff are very brilliant and attentive to users' needs.",
		author: "Scott Tolinski",
		designation: "Web Developer",
		companyImage: google,
		size: 45,
	},
	{
		description:
			"We've become fanboys! Easy to change the modular design, great dashboard UI, enterprise-class support, fast loading time. What else do you want from a Bootstrap Theme?",
		author: "Jeff Escalante",
		designation: "Developer",
		companyImage: netflix,
		size: 30,
	},
	{
		description:
			"When I first saw Falcon, I was totally blown away by the care taken in the interface. It felt like something that I'd really want to use and something I could see being a true modern replacement to the current class of Bootstrap themes.",
		author: "Liam Martens",
		designation: "Designer",
		companyImage: amazon,
		size: 45,
	},
];
