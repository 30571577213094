import {
	AccessTokenKeyInLocalStorage,
	GetFormByIdEndPoint,
	LoggedInEmailOrUserNameKeyInLocalStorage,
	LoggedInUserInfoKeyInLocalStorage,
	StartProcessByDefinitionKeyEndPoint,
	StartProcessEndPoint,
} from "common/constants";
import { CheckIfTokenExists, GetFromEndPoint, PostToEndPoint } from "common/general";
import { claimTask, getFormById, putProcessInstanceVariable } from "./WorkflowHelper";
import moment from "moment-timezone";
import { EncryptStorage } from "encrypt-storage";
import jwtDecode from "jwt-decode";

async function startWorkflowProcess(definitionKey, isProcessID = false) {
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});

	const userInfo = encryptStorage1.getItem(LoggedInUserInfoKeyInLocalStorage);
	var id = userInfo?.userId;
	try {
		//CheckIfTokenExists();
		var startProcessResponse = null;
		if (!isProcessID) {
			startProcessResponse = await new Promise((resolve, reject) => {
				const PostSuccessCallBack = (response) => {
					resolve(response);
				};

				const PostFailureCallBack = (response) => {
					reject(response);
				};

				PostToEndPoint(StartProcessByDefinitionKeyEndPoint + definitionKey + "/" + false, {}, {}, PostSuccessCallBack, PostFailureCallBack);
			});
		} else {
			startProcessResponse = await new Promise((resolve, reject) => {
				const PostSuccessCallBack = (response) => {
					resolve(response);
				};

				const PostFailureCallBack = (response) => {
					reject(response);
				};
				if (id == null) {
					PostToEndPoint(StartProcessEndPoint + definitionKey, {}, {}, PostSuccessCallBack, PostFailureCallBack);
				} else {
					PostToEndPoint(StartProcessEndPoint + definitionKey + "/" + id, {}, {}, PostSuccessCallBack, PostFailureCallBack);
				}
			});
		}

		// const taskID = startProcessResponse.data.form.taskId;
		// const processInstanceId = startProcessResponse.data.form.processInstanceId;
		// const formPageNumber = startProcessResponse.data.form.formPageNumber;
		let form_by_task_response = startProcessResponse.getCurrentUserTaskResponse.getFormByTaskResponse;
		const taskID = form_by_task_response.taskId;
		const processInstanceId = form_by_task_response.processInstanceId;
		const formPageNumber = form_by_task_response.formPageNumber;

		let getEndPointResponse = null;

		if (formPageNumber === null || formPageNumber === undefined) {
			getEndPointResponse = await getFormById(form_by_task_response.key);
		} else {
			getEndPointResponse = await getFormById(form_by_task_response.key, formPageNumber, {});
		}

		var assignee = null;

		const formData = {
			...JSON.parse(getEndPointResponse.data.formData),
			taskID,
			processInstanceId,
			assignee,
		};

		var accessToken = encryptStorage1.getItem(AccessTokenKeyInLocalStorage);

		if (accessToken) {
			if (formPageNumber !== null || formPageNumber !== undefined) {
				formData.step = formPageNumber;
				formData.formKey = form_by_task_response.key;
				formData.processInstanceId = processInstanceId;
				const userTimezone = moment.tz.guess();
				let currentDate = moment().tz(userTimezone).format("DD-MM-YYYY HH:mm:ss");
				formData.creationDate = currentDate;
				formData.modificationDate = currentDate;
			}
			assignee = id;
			formData.assignee = assignee;
			await claimTask(taskID, id);
		}
		formData.redirectionPage = startProcessResponse.redirectionPage;
		return formData;
	} catch (error) {
		throw error;
	}
}

export { startWorkflowProcess };
