import React, { Component, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { ReactComponent, Utils } from "@formio/react";
import settingsForm from "./CompanyRelation.settingsForm";
import CustomSelect from "../customSelect";
import _ from "lodash";
import { createRoot } from "react-dom/client";
import moment from "moment";
import { labels } from "data/kanban";
import { getRandomId } from "helpers/utils";
import * as Constants from "common/constants";
import { EncryptStorage } from "encrypt-storage";

/*
This component get all facilities or transporters with additional text fields to fill their info in as per user request
*/

const CompanyRelationCustomComp = ({ ...props }) => {
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});

	// Update the setValue method to handle onChange event
	const updateValue = (e) => {
		if (props.form._form.settings && props.form._form.settings.toEdit !== true) {
			const newValue = e.target.value; // Get the selected value from the event

			//get the associated record from options based on the value selected and set it in form data
			let selectedValues = props.oldState["options"].filter((v) => v.value === newValue)[0];
			let state = props.oldState;

			if (selectedValues) {
				let json = { value: newValue };
				//fill value with the fields requested
				if (props.component.companyGrid) {
					for (let i = 0; i < props.component.companyGrid.length; i++) {
						json[props.component.companyGrid[i].companyFieldPath] = selectedValues[props.component.companyGrid[i].companyFieldPath];
					}
				}
				json["label"] = selectedValues.label;
				state["selectedValues"] = json;

				props.setOldState(state);
				props.onChange(json, null);
				props.form._data[props.component.key] = json;
			} else {
				props.component.defaultValue = "";
				state["selectedValues"] = {};
				props.setOldState(state);
				props.onChange("", null);
				props.form._data[props.component.key] = "";
			}
		}
	};

	useEffect(() => {
		let state = props.oldState;
		if (!state.loaded) {
			//if component has default value and so its additional fields, get their values and set them in state
			if (props.component.defaultValue && props.component.defaultValue !== "") {
				state["selectedValues"] = props.component.defaultValue.value ? props.component.defaultValue : JSON.parse(props.component.defaultValue);

				let label = state["selectedValues"]?.label;
				let value = state["selectedValues"]?.value;

				if (props.form._form.settings) {
					if (props.form._form.settings.translatedData) {
						if (props.form._form.settings.translatedData[props.form._form.settings.language]) {
							label = props.form._form.settings.translatedData[props.form._form.settings.language][state["selectedValues"]["label"]] || label;
						}
					}
				}
				state["selectedValues"].value = props.component.disabled === true ? state["selectedValues"]?.label : value;

				props.setOldState(state);
			}
			//if component has no default value and it is required get options
			if (props.component.disabled !== true) {
				loadData();
			} else {
				let options = [
					{
						label: state["selectedValues"]?.label,
						value: props.component.disabled === true ? state["selectedValues"]?.label : state["selectedValues"]?.value,
					},
				];
				state["options"] = options;
				state["loaded"] = true;

				props.setOldState(state);
			}
		}
	}, []);
	// //if dropdown becomes enabled get data from db
	// useEffect(() => {
	//     if (props.oldState.loaded && props.component.disabled !== true) {
	//         let state = props.oldState;
	//         state["options"] = [];
	//         props.setOldState(state);
	//         loadData();
	//     }
	// }, [props.oldState.loaded, props.component.disabled])
	const loadData = async () => {
		if (
			props.component.requestUrl !== "" &&
			props.component.requestUrl &&
			props.component.companyRelation !== "" &&
			props.component.companyRelation &&
			props.oldState.options.length === 0
		) {
			let companyID = encryptStorage1.getItem("selectedCompany") || "GeneratorCompany";
			var accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);
			var headers = { "Content-Type": "application/json" };

			if (accessToken) {
				var headerToken = { Authorization: "Bearer " + accessToken };
				headers = { ...headers, ...headerToken };
			}

			let fetchParams = { method: "GET", headers: headers };

			fetch(props.component.requestUrl + "/" + companyID, { ...fetchParams })
				.then((response) => response.json())
				.then((res) => {
					if (JSON.stringify(res) !== "{}") {
						let path = props.component.dataPath;
						let result = path ? _.get(res, path, "") : res;
						let val = result?.map((e) => ({
							label: e[props.component.labelName],
							value: e[props.component.valueName],
							...e,
						}));
						//get all facilities where their assignments exceed one month
						if (
							props.component.table2DataPath &&
							props.component.table2DataPath !== "" &&
							props.component.joinTableUrl &&
							props.component.joinTableUrl !== "" &&
							props.component.suggstedFilter &&
							props.component.table2FieldNameToJoinName &&
							props.component.table2FieldNameToJoinName !== "" &&
							props.component.table1FieldNameToJoinName &&
							props.component.table1FieldNameToJoinName !== ""
						) {
							fetch(props.component.joinTableUrl, {
								method: "GET",
								headers: {
									"Content-Type": "application/json",
									AccessToken: accessToken,
									RefreshToken: refreshToken,
								},
							})
								.then((response) => response.json())
								.then((res2) => {
									let table2DataPath = props.component.table2DataPath;
									let resultTable2 = path ? _.get(res2, table2DataPath, "") : res2;
									let filteredData = [];
									for (let i = 0; i < val.length; i++) {
										let index = resultTable2.findIndex((v) => v[props.component.table2FieldNameToJoinName] === val[i][props.component.table1FieldNameToJoinName]);
										if (index >= 0) {
											if (
												moment(moment(resultTable2[index]["created_at"]).add(1, "month").format("DD-MM-YYYY"), "DD-MM-YYYY").isSameOrBefore(moment(moment().format("DD-MM-YYYY"), "DD-MM-YYYY"))
											) {
												filteredData.push(val[i]);
											}
										}
									}
									val = filteredData;
									//filter dropdown according to another dropdown values in a certain grid that has gridKeyToFilter given by user
									let gridKeyToFilter = props.component.gridKeyToFilter;
									//the dropdown key to filter on
									let gridDataPath = props.component.gridDataPath;
									if (props.form._form.settings && props.form._form.settings.toEdit !== true) {
										props.component.values = val;
									}
									if (gridKeyToFilter !== null && gridKeyToFilter !== undefined && gridKeyToFilter !== "") {
										//get all the values chosen that has gridKeyToFilter given by user
										let filtersDataValues = props.form._data[gridKeyToFilter];
										let tempVal = [];
										//compare the result we got with the values already selected by the user and get the intersection
										for (let i = 0; i < val.length; i++) {
											let recordValue = val[i].value;

											let recordFoundInGrid = filtersDataValues.findIndex((g) => g[gridDataPath].value === recordValue);
											if (recordFoundInGrid >= 0) {
												tempVal.push(val[i]);
											}
										}

										val = tempVal;
									}

									let state = props.oldState;
									state["options"] = val;
									state["loaded"] = true;
									props.setOldState(state);
								});
						} else {
							let gridKeyToFilter = props.component.gridKeyToFilter;
							let gridDataPath = props.component.gridDataPath;
							if (props.form._form.settings && props.form._form.settings.toEdit !== true) {
								props.component.values = val;
							}
							if (gridKeyToFilter !== null && gridKeyToFilter !== undefined && gridKeyToFilter !== "") {
								let filtersDataValues = props.form._data[gridKeyToFilter];
								let tempVal = [];
								for (let i = 0; i < val.length; i++) {
									let recordValue = val[i].value;
									let recordFoundInGrid = filtersDataValues.findIndex((g) => g[gridDataPath].value === recordValue);
									if (recordFoundInGrid >= 0) {
										tempVal.push(val[i]);
									}
								}

								val = tempVal;
							}

							let state = props.oldState;
							state["options"] = val;
							state["loaded"] = true;
							props.setOldState(state);
						}
					}
				})
				.then(() => {})
				.catch((error) => {
					console.error("Error:", error);
				});
		}
	};
	//add page data in global form data
	useEffect(() => {
		if (props.data) {
			let keys = Object.keys(props.data);
			for (let i = 0; i < keys.length; i++) {
				props.form._data[keys[i]] = props.data[keys[i]];
				props.form._submission.data[keys[i]] = props.data[keys[i]];
			}

			if (props.insideGrid === true) {
				delete props.form._data[props.component.key];
				delete props.form._submission.data[props.component.key];
			}
		}
	}, [props.data]);

	return (
		<div>
			<div className="row mx-0 ms-0 companyRelationCustomComp">
				<label style={{ display: props.component.dropdownLabel ? "block" : "none" }}>
					{props.form._form.settings && props.form._form.settings.translatedData
						? props.form._form.settings.translatedData[props.form._form.settings.language]
							? props.form._form.settings.translatedData[props.form._form.settings.language][props.component.dropdownLabel] || props.component.dropdownLabel
							: props.component.dropdownLabel
						: props.component.dropdownLabel}
				</label>
				<CustomSelect
					component={props.component}
					disabled={props.component.disabled && props.component.disabled === true}
					value={props.oldState["selectedValues"].value}
					options={props.oldState.options}
					updateValue={updateValue}
				/>

				{props.component.companyGrid?.map((grid, index) => {
					return (
						grid.companyFieldPath.replaceAll(" ", "") !== "" &&
						grid.companyFieldName.replaceAll(" ", "") !== "" &&
						!grid.isHidden &&   (
							<div key={props.component.key + "_" + index} className="p-0 col-sm-6 mt-1">
								<label>
									{props.form._form.settings && props.form._form.settings.translatedData
										? props.form._form.settings.translatedData[props.form._form.settings.language]
											? props.form._form.settings.translatedData[props.form._form.settings.language][grid.companyFieldName] || grid.companyFieldName
											: grid.companyFieldName
										: grid.companyFieldName}
								</label>
								<input type="text" className="form-control" value={props.oldState["selectedValues"][grid.companyFieldPath] || ""} disabled={true} />
							</div>
						)
					);
				})}
			</div>
		</div>
	);
};

export default class CompanyRelation extends ReactComponent {
	static shouldSetValue = false; // Define shouldSetValue as a static property

	/**
	 * This is the first phase of component building where the component is instantiated.
	 *
	 * @param component - The component definition created from the settings form.
	 * @param options - Any options passed into the renderer.
	 * @param data - The submission data where this component's data exists.
	 */

	constructor(component, options, data) {
		options["oldState"] = {
			options: [],
			value: null,
			requestUrl: "",
			selectedValues: {},
			loaded: false,
		};
		super(component, options, data);
		this.reactInstance = null;
	}

	/**
	 * This function is the default settings for the component. At a minimum you want to set the type to the registered
	 * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
	 *
	 * @param sources
	 * @returns {*}
	 */
	static schema(...extend) {
		return ReactComponent.schema({
			type: "companyRelationCustomComp",
			label: "",
		});
	}
	static get builderInfo() {
		return {
			title: "Company Relation",
			icon: "cubes",
			group: "Basic",
			documentation: "",
			weight: -10,
			schema: CompanyRelation.schema(),
		};
	}
	static editForm = settingsForm;

	/**
	 * The second phase of component building where the component is rendered as an HTML string.
	 *
	 * @returns {string} - The return is the full string of the component
	 */
	render() {
		// For react components, we simply render as a div which will become the react instance.
		// By calling super.render(string) it will wrap the component with the needed wrappers to make it a full component.
		return super.render(`<div ref="react-${this.id}"></div>`);
	}
	/**
	 * The third phase of component building where the component has been attached to the DOM as 'element' and is ready
	 * to have its javascript events attached.
	 *
	 * @param element
	 * @returns {Promise<void>} - Return a promise that resolves when the attach is complete.
	 */
	attach(element) {
		super.attach(element);
		let randomId = getRandomId();
		// The loadRefs function will find all dom elements that have the "ref" setting that match the object property.
		// It can load a single element or multiple elements with the same ref.
		this.loadRefs(element, {
			[`react-${randomId}`]: "single",
		});

		if (this.refs[`react-${randomId}`]) {
			this.attachReact(this.refs[`react-${randomId}`], this.setReactInstance.bind(this));
			if (this.shouldSetValue) {
				this.setValue(this.dataForSetting);
				this.updateValue(this.dataForSetting);
			}
		}
		return Promise.resolve();
	}

	/**
	 * Override this function to insert your custom component.
	 *
	 * @param element
	 * @param ref - callback ref
	 */
	attachReact(element, ref) {
		const rootForm = this.getRoot(); // Get the root form object
		let insideGrid = false;
		let key = this.component.key;
		Utils.eachComponent(
			rootForm.components,
			function (component) {
				if (component.component.type === "editgrid") {
					Utils.eachComponent(
						component.component.components,
						function (component2) {
							if (!insideGrid) {
								insideGrid = component2.key === key;
							}
						},
						true
					);
				}
			},
			true
		);

		const root = createRoot(element);

		const setOldState = (value) => {
			this.options["oldState"] = value;
			root.render(
				<CompanyRelationCustomComp
					component={this.component} // These are the component settings if you want to use them to render the component.
					onChange={this.updateValue} // Pass the onChange event handler
					value={this.dataValue || ""}
					form={rootForm}
					data={this.data}
					insideGrid={insideGrid}
					setOldState={setOldState}
					oldState={this.options["oldState"]}
				/>
			);
		};

		root.render(
			<CompanyRelationCustomComp
				component={this.component} // These are the component settings if you want to use them to render the component.
				onChange={this.updateValue} // Pass the onChange event handler
				value={this.dataValue || ""}
				form={rootForm}
				data={this.data}
				insideGrid={insideGrid}
				setOldState={setOldState}
				oldState={this.options["oldState"]}
			/>
		);
	}

	/**
	 * The user has changed the value in the component and the value needs to be updated on the main submission object and other components notified of a change event.
	 *
	 * @param value
	 */
	updateValue = (value, flags) => {
		flags = flags || {};
		const newValue = value === undefined || value === null ? this.getValue() : value;
		const changed = newValue !== undefined ? this.hasChanged(newValue, this.dataValue) : false;
		this.dataValue = Array.isArray(newValue) ? [...newValue] : newValue;

		this.updateOnChange(flags, changed);
		return changed;
	};
}
