import React from "react";
import "./Preloader.css";
import { useTranslation } from "react-i18next";

const LoaderIcon = ({ ...props }) => {
	const [t, i18n] = useTranslation();
	return (
		<div className="preloader-wrapper">
			<div className="circles-container">
				<div className="preloader"></div>
				<div className="preloader"></div>
				<div className="preloader"></div>
			</div>
			<div className="preloader-text">{t("Processing")}</div>
		</div>
	);
};
export default LoaderIcon;
