import React, { useState, useContext } from "react";
import PageHeader from "components/common/PageHeader";
import Hero from "./Hero";
import AppContext from "context/Context";
import Services from "./Services";
import { Accordion, Card, Col, Container, Form, Row } from "react-bootstrap";
import FaqAccordionItem from "../faq/faq-accordion/FaqAccordionItem";
import { faqAccordions as faqsData } from "data/faqs";
import PdfDownload from "./PdfDownload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import NavbarStandard from "./NavbarStandard";
import { useTranslation } from "react-i18next";
import Footer from "./Footer";
import LandingBackground from "assets/img/landing_background_bottom.jpg";
import WhatsApp from "assets/img/whatsapp.png";
import DownloadDoc from "assets/img/download_document.png";
import styles from "./landing.module.css";
import LandingButtons from "./LandingButtons";
import Carousel from "./Carousel";
import carouselImg from "assets/img/e-learning/courses/course8.png";
import carouselImg2 from "assets/img/jeddah-municipality.png";
import carouselImg3 from "assets/img/jeddah-municipality.png";
import carouselImg4 from "assets/img/jeddah-municipality.png";
import CarouselPartners from "./CarouselPartners";

document.addEventListener("DOMContentLoaded", function () {
	const cssToRemove = document.getElementById("css-to-remove");
	if (cssToRemove) {
		cssToRemove.parentNode.removeChild(cssToRemove);
	}
});
const Landing = () => {
	const {
		config: { isDark, isRTL },
	} = useContext(AppContext);
	const [t, i18n] = useTranslation();
	const [faqs] = useState(faqsData);

	const items = [
		{ id: 1, title: "Item 1", image: carouselImg, date: "2024-09-15" },
		{ id: 2, title: "Item 2", image: carouselImg, date: "2024-10-22" },
		{ id: 3, title: "Item 3", image: carouselImg, date: "2024-11-22" },
		{ id: 4, title: "Item 4", image: carouselImg, date: "2024-05-12" },
		{ id: 5, title: "Item 5", image: carouselImg, date: "2024-01-25" },
	];
	const items2 = [
		{ id: 1, title: "Item 1", image: carouselImg2 },
		{ id: 2, title: "Item 2", image: carouselImg3 },
		{ id: 3, title: "Item 3", image: carouselImg4 },
		{ id: 4, title: "Item 4", image: carouselImg3 },
		{ id: 5, title: "Item 5", image: carouselImg2 },
		{ id: 6, title: "Item 5", image: carouselImg2 },
		{ id: 7, title: "Item 5", image: carouselImg2 },
		{ id: 8, title: "Item 5", image: carouselImg2 },
	];
	const language = isRTL ? "ar" : "en";
	const formatDate = (date, language) => {
		const locale = language === "ar" ? "ar-EG" : "en-US"; // Choose Arabic or English locale
		return new Intl.DateTimeFormat(locale, {
			year: "numeric",
			day: "numeric",
			month: "long",
		}).format(new Date(date));
	};
	return (
		<div className={`${styles.backgroundWhite}`}>
			<NavbarStandard />
			<Hero
				titleText={[
					t(
						"The electronic manifest for the waste management sector is a form for tracking the movement of waste and its disposal from the point of generation to the point of storage, treatment or safe disposal."
					),
				]}
			/>
			<div
				style={{
					backgroundImage: `url(${LandingBackground})`,
					backgroundSize: "cover",
					backgroundPosition: "top",
					justifyContent: "center",
				}}
			>
				<Container
					style={{
						padding: "3.5em",
					}}
				>
					<h2 className={`${isRTL ? styles.landingTitleAr : styles.landingTitle}`}>{t("About the platform")}</h2>
					<p className={styles.landingText}>
						{t(
							"The electronic manifest is an essential tool to ensure safe and responsible waste management, protect the environment and promote sustainability. The platform's main objectives include accurate tracking and documentation of waste and increasing compliance, collecting data on various shipments across the Kingdom, monitoring and preventing unsound and irregular practices, and protecting citizens and the environment."
						)}
					</p>
					<button className={styles.whiteButton}>
						{t("Know more")} <FontAwesomeIcon icon={`fa-solid  ${isRTL ? "fa-arrow-left" : "fa-arrow-right"}`} />
					</button>
				</Container>
				<LandingButtons />
			</div>
			<Container className={`${styles.padding35em} `}>
				<h2 className={`${isRTL ? styles.landingTitleAr : styles.landingTitle}`}>{t("Latest news")}</h2>
				<Carousel dots={true} slidesToShowLarge={3} slidesToShowMid={2} slidesToShowS={1}>
					{items.map((item) => (
						<div key={item.id} className={styles.carouselcontainer} style={{ padding: "10px" }}>
							<div className={styles.carouselImage} style={{ backgroundImage: `url(${item.image})` }}></div>
							<div className={isRTL ? styles.carouselTitleAr : styles.carouselTitle}>{t(item.title)}</div>
							<div className={isRTL ? styles.carouselDateAr : styles.carouselDate}>
								<FontAwesomeIcon icon="fa-regular fa-clock" className={styles.textGreen} />
								{formatDate(item.date, language)}
							</div>
						</div>
					))}
				</Carousel>
			</Container>
			<Container className={`${styles.padding35em} `}>
				<h2 className={`${isRTL ? styles.landingTitleAr : styles.landingTitle}`}>{t("Success partners")}</h2>
				<Carousel items={items} dots={false} slidesToShowLarge={5} slidesToShowMid={3} slidesToShowS={2}>
					{items2.map((item) => (
						<div key={item.id} className={styles.carouselPartnercontainer} style={{ padding: "20px", margin: "10px" }}>
							<img className={styles.carouselPartnersImage} src={item.image} />
						</div>
					))}
				</Carousel>
			</Container>
			<Footer pageName="Landing"></Footer>
		</div>
	);
};

export default Landing;
