import React, { useState } from "react";

import * as Constants from "common/constants";
import * as General from "common/general";

import IconButton from "components/common/IconButton";
import Loader from "components/custom/Loader";
import { Col, Modal, Row, Button } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import LoaderIcon from "components/custom/Preloader";
import Flex from "components/common/Flex";
import { toast } from "react-toastify";

const DeleteTemplate = ({ setShowDeleteTemplateModal, processedTemplateId, addComplete = () => {} }) => {
	const [t, i18n] = useTranslation();
	const [loading, setLoading] = useState(false);

	const handleConfirmDelete = () => {
		setLoading(true);
		const DeleteTemplateSuccess = (responseObject) => {
			if (responseObject.status == Constants.SuccessStatus) {
				setLoading(false);

				setShowDeleteTemplateModal(false);
				toast.success(t("Success"));
				addComplete();
			}
		};
		const DeleteTemplateFailure = (error) => {
			setLoading(false);

			toast.error("An error occured, please try again later.", {
				autoClose: 5000,
			});
		};
		var deleteTemplateEndPoint = Constants.DeleteTemplateEndPoint + "/" + processedTemplateId;
		General.DeleteFromEndPoint(deleteTemplateEndPoint, null, null, DeleteTemplateSuccess, DeleteTemplateFailure);
	};
	return (
		<Modal show={true} onHide={() => setShowDeleteTemplateModal(false)} size="lg">
			{loading && <LoaderIcon />}
			<Modal.Header closeButton className="border-200">
				<Modal.Title as="h5">
					<Flex alignItems="center">
						<div className="ms-2">{t(`Remove Template`)}</div>
					</Flex>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row className="g-0 align-items-center">
					<Col>
						<div className="mb-3">{t(`Are you sure you want to remove this template?`)}</div>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer className="border-200">
				{!loading && (
					<div>
						<Button onClick={() => setShowDeleteTemplateModal(false)} size="sm" variant="secondary">
							{t("No")}
						</Button>
						<Button onClick={handleConfirmDelete} size="sm" className="ms-2" variant="danger">
							{t("Yes")}
						</Button>
					</div>
				)}
			</Modal.Footer>
		</Modal>
	);
};

export default DeleteTemplate;
