import React, { useEffect, useRef, useState } from "react";
import { Form } from "@formio/react";
import { Card, Col, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Translation from "components/forms/Translation";
import Loader from "components/custom/Loader";
import WizardStepper from "components/forms/WizardStepper";
import Formioform from "components/forms/FormioForm";

function WorkflowHistoryView() {
	//const location = useLocation();
	const state = useLocation().state;
	const [form, setForm] = useState(null);

	//const form = location.state.data;
	useEffect(() => {
		//Runs only on the first render
		setForm(state.data);
	}, []);

	return (
		<Card title="Result" className="p-4">
			<Formioform form={form} />
		</Card>
	);
}

export default WorkflowHistoryView;
