import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import Main from "./Main";
import "helpers/initFA";
import { Form } from "@formio/react";
import components from "../src/components/forms/custom";
import { Components } from "@formio/react";
import i18next from "../src/i18n";
import { I18nextProvider } from "react-i18next";
import { register } from "./serviceWorkerRegistration"; // Adjust the path as needed
import sessionReducer from "reducers/sessionReducer";
import { Provider } from "react-redux";
import store from "reducers/store";

// register();
//These are the custom components created registered within formio components and thus can be used within formio builder
Components.setComponents(components);

const container = document.getElementById("main");
const root = createRoot(container);

root.render(
	<Provider store={store}>
		<Main>
			<I18nextProvider i18n={i18next}>
				<App />
			</I18nextProvider>
		</Main>
	</Provider>
);
