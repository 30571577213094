import { VerificationEmailProcessDefinitionKey } from "common/constants";
import { startProcess } from "components/workflow/VerifyEmailWorkflow";
import React, { useEffect } from "react";
import "./styles/Style.css";
import { useNavigate } from "react-router-dom";

function EmailVerification() {
	const navigate = useNavigate();

	useEffect(() => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const email = urlParams.get("email");
		const key = urlParams.get("key");
		const dateTimeString = urlParams.get("dt");
		// const userID = urlParams.get('userID');
		startProcess(VerificationEmailProcessDefinitionKey, email, key, dateTimeString)
			.then((formData) => {
				navigate("/service_executor_workflow", { state: { data: formData } });
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);
	return <></>;
}

export default EmailVerification;
