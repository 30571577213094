import React, { useState, useContext, useEffect } from "react";

import * as Constants from "common/constants";
import * as General from "common/general";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const CompaniesDropDown = ({
	CompanyDropDownChangeCallBackFunction,
	CompanyTypeDropDownChangeCallBackFunction,
	CompanyDropDownCSSClassExtra,
	CompanyTypeDropDownCSSClassExtra,
	PreselectedGroups,
	setDropdownVisible,
	disabled = false,
}) => {
	const [loaderContainerVisibility, showLoaderContainer] = useState(true);
	const [dropDownVisibility, updatedropDownVisibility] = useState(false);
	const [loggedInUserGroupsState, updateloggedInUserGroupsState] = useState([]);

	const [companyTypes, setcompanyTypes] = useState([]);

	const [companies, setcompanies] = useState([]);
	const [errorContainerVisiblity, showErrorContainer] = useState(false);
	var finalCompanyDropDownCssClass = "form-select " + CompanyDropDownCSSClassExtra;
	var finalCompanyTypeDropDownCssClass = "form-select " + CompanyTypeDropDownCSSClassExtra;
	var loggedInUserGroups = [];
	const [t, i18n] = useTranslation();

	useEffect(() => {
		var userInfoString = localStorage.getItem(Constants.LoggedInUserInfoKeyInLocalStorage);
		var user = JSON.parse(userInfoString);
		if (user.groups != null && user.groups.length > 0) {
			var groups = [];
			user.groups.forEach(function (item, index) {
				groups[index] = item.id;
			});
			loggedInUserGroups = groups;
			updateloggedInUserGroupsState(groups);
		}
		bindCompanyTypes(groups);
	}, []);

	/*************** LOAD COMPANY TYPES START ************************/
	const bindCompanyTypes = (groups) => {
		const GetAllCompanyTypesSuccess = (responseObject) => {
			var isSuccess = false;
			if (responseObject.status == Constants.SuccessStatus) {
				if (responseObject.data != null) {
					if (responseObject.data.token != null) {
						if (responseObject.data.token.accessToken != "") {
							let included = false;
							setcompanyTypes(responseObject.data.groups);
							responseObject.data.groups.forEach(function (item) {
								if (groups.includes(item.id)) {
									included = true;
									bindCompanies(item.id);
									CompanyTypeDropDownChangeCallBackFunction(item.id);
								}
								if (PreselectedGroups.includes(item.id)) {
									included = true;
									bindCompanies(item.id);
									CompanyTypeDropDownChangeCallBackFunction(item.id);
								}
							});
							if (!included) {
								if (setDropdownVisible) {
									setDropdownVisible(true);
								}
								updatedropDownVisibility(true);
							}
							isSuccess = true;
							General.SaveToken(responseObject);
						}
					}
				}
			}

			if (isSuccess == false) {
				setTimeout(() => {
					var errorContainer = document.getElementById("errorContainerAllCompanies");
					if (responseObject.message != null) {
						if (responseObject.message != "") {
							errorContainer.innerHTML = responseObject.message;
						}
					}
					if (errorContainer.innerHTML == "") {
						errorContainer.innerHTML = "An error occured, please try again later.";
					}
					showLoaderContainer(false);
				}, 1000);
			} else {
				showErrorContainer(false);
				showLoaderContainer(false);
			}
		};

		const GetAllCompanyTypesFailure = (error) => {
			showErrorContainer(true);
			setTimeout(() => {
				var errorContainer = document.getElementById("errorContainerAllCompanies");
				if (errorContainer.innerHTML == "") {
					errorContainer.innerHTML = "An error occured, please try again later." + error;
				}
				showLoaderContainer(false);
			}, 1000);
		};
		General.GetFromEndPoint(Constants.GetAllGroupsEndPoint + "?parentGroupID=" + Constants.CompaniesGroupID, null, null, GetAllCompanyTypesSuccess, GetAllCompanyTypesFailure);
	};

	const handleCompanyTypeChange = (event) => {
		updateloggedInUserGroupsState([]);
		const selectedTypeId = event.target.value;
		bindCompanies(selectedTypeId);

		var dropdownCompanies = document.getElementById("dropdownCompanies");
		dropdownCompanies.index = 0;
		CompanyTypeDropDownChangeCallBackFunction(selectedTypeId);
		CompanyDropDownChangeCallBackFunction("");
	};
	/*************** LOAD COMPANY TYPES END ************************/

	/*************** LOAD COMPANIES START ************************/
	const bindCompanies = (groupParentID) => {
		const GetAllCompaniesSuccess = (responseObject) => {
			var isSuccess = false;
			if (responseObject.status == Constants.SuccessStatus) {
				if (responseObject.data != null) {
					if (responseObject.data.token != null) {
						if (responseObject.data.token.accessToken != "") {
							var companies = responseObject.data.groups;
							setcompanies(companies);

							isSuccess = true;
							General.SaveToken(responseObject);
							let included = false;
							responseObject.data.groups.forEach(function (item) {
								if (loggedInUserGroups.includes(item.id)) {
									included = true;
								}
							});

							if (included) {
								CompanyDropDownChangeCallBackFunction(item.id);
							} else {
								if (setDropdownVisible) {
									setDropdownVisible(true);
								}
								updatedropDownVisibility(true);
							}
						}
					}
				}
			}

			if (isSuccess == false) {
				showErrorContainer(true);
				setTimeout(() => {
					var errorContainer = document.getElementById("errorContainerAllCompanies");
					if (responseObject.message != null) {
						if (responseObject.message != "") {
							errorContainer.innerHTML = responseObject.message;
						}
					}
					if (errorContainer.innerHTML == "") {
						errorContainer.innerHTML = "An error occured, please try again later.";
					}
					showLoaderContainer(false);
				}, 1000);
			} else {
				showErrorContainer(false);
				showLoaderContainer(false);
			}
		};

		const GetAllCompanyCompaniesFailure = (error) => {
			showErrorContainer(true);
			setTimeout(() => {
				var errorContainer = document.getElementById("errorContainerAllCompanies");

				if (errorContainer.innerHTML == "") {
					errorContainer.innerHTML = "An error occured, please try again later.";
				}
				showLoaderContainer(false);
			}, 1000);
		};

		if (groupParentID != "0") {
			var link = Constants.GetAllGroupsEndPoint + "?parentGroupID=" + groupParentID;

			General.GetFromEndPoint(link, null, null, GetAllCompaniesSuccess, GetAllCompanyCompaniesFailure);
		} else {
			setcompanies([]);
		}
	};

	const handleCompanyChange = (event) => {
		const selectedCompanyId = event.target.value;

		CompanyDropDownChangeCallBackFunction(selectedCompanyId);
	};
	/*************** LOAD COMPANIES END ************************/

	return (
		<div className="gx-2 gy-3 row" style={!dropDownVisibility ? { display: "none" } : { background: "transparent" }}>
			<div class="col-md-6">
				<div>
					<label className="form-label">{t("Select company type")}:</label>
					<select disabled={disabled} name="productCategory" id="dropdownCompanyType" className={finalCompanyTypeDropDownCssClass} onChange={handleCompanyTypeChange}>
						<option value="0">{t("Select company type")}</option>
						{companyTypes.map((companyType) => (
							<option selected={loggedInUserGroupsState.includes(companyType.id) || PreselectedGroups.includes(companyType.id)} value={companyType.id} key={companyType.id}>
								{companyType.name}
							</option>
						))}
					</select>
					<div className="invalid-feedback"></div>
				</div>
			</div>
			<div className="col-md-6">
				<div>
					<label className="form-label">{t("Select company")}:</label>
					<select disabled={disabled} name="productSubCategory" id="dropdownCompanies" className={finalCompanyDropDownCssClass} onChange={handleCompanyChange}>
						<option value="0">{t("Select company")}</option>

						{companies.map((company) => (
							<option selected={loggedInUserGroupsState.includes(company.id) || PreselectedGroups.includes(company.id)} key={company.id} value={company.id}>
								{company.name}
							</option>
						))}
					</select>
					<div className="invalid-feedback"></div>
				</div>
			</div>

			<div className="loginAlert" id="errorContainerAllCompanies"></div>
		</div>
	);
};

CompaniesDropDown.propTypes = {};

export default CompaniesDropDown;
