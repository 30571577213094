import React, { useContext, useEffect } from "react";
import { Route, BrowserRouter as Router, Routes, useLocation, Navigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import is from "is_js";
import AppContext from "context/Context";
import FalconRoutes from "routes";
import { CloseButton } from "components/common/Toast";
//import SettingsToggle from 'components/settings-panel/SettingsToggle';
import SettingsPanel from "components/settings-panel/SettingsPanel";
import "react-datepicker/dist/react-datepicker.css";
// import 'react-toastify/dist/ReactToastify.min.css';
import "./CustomToastify.css";
import jwtDecode from "jwt-decode";
import LoginPage from "./LoginPage";
import MasterLoginPage from "./MasterLoginPage";

import ResetPassword from "./ResetPassword";
import { useState } from "react";
import * as Constants from "./common/constants";
import "./App.css";
import "./styles/Style-ar.css";
import ServiceExecutorWorfklow from "components/workflow/ServiceExecutorWorkflow";
import CreateAccount from "CreateAccount";
import FormViewRenderer from "components/forms/FormViewRenderer";
import EmailVerification from "EmailVerification";
import * as General from "common/general";
import db from "indexedDB";
import { useTranslation } from "react-i18next";

//import { useLocation } from 'react-router-dom';

// import { ReactKeycloakProvider } from "@react-keycloak/web";
// import keycloak from 'KeycloackConf';

import Keycloak from "keycloak-js";
import KeycloackLogin from "KeycloakLogin";
import { useDispatch, useSelector } from "react-redux";
import keycloak from "KeycloakConf";
import { EncryptStorage } from "encrypt-storage";
import keycloakService from "keycloakService";
import GetUserCompanies from "components/usercompanies/GetUserCompanies";
import GetWorkflowListByAssignee from "components/workflow/GetWorkflowListByAssignee";
import Landing from "components/pages/landing/Landing";

const App = () => {
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});
	const HTMLClassList = document.getElementsByTagName("html")[0].classList;
	const [authenticated, setAuthenticated] = useState(false);
	const [isCompany, setIsCompany] = useState(false);

	const dispatch = useDispatch();
	const keycloackObject = useSelector((state) => state);
	const { setConfig } = useContext(AppContext);
	const [t, i18n] = useTranslation();

	//const location = useLocation();
	const {
		config: { navbarPosition },
	} = useContext(AppContext);

	useEffect(() => {
		if (location.pathname === "/" && location.host.includes("emanifest") && location.hash == "") {
			return;
		}
		var accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);

		if (accessToken != undefined && accessToken) {
			const decodedAccessToken = jwtDecode(accessToken);
			const currentTime = Date.now() / 1000;
			if (decodedAccessToken.exp > currentTime) {
				if (decodedAccessToken.locale?.toLowerCase() === "ar".toLowerCase() && (localStorage.getItem("firstLogin") === "true" || localStorage.getItem("firstLogin") === null)) {
					localStorage.setItem("firstLogin", false);
					if (localStorage.getItem("i18nextLng") !== "ar") {
						localStorage.setItem("isRTL", true);
						setConfig("isRTL", true);
						i18n.changeLanguage("ar");
						window.location.reload();
					}
				}
				setAuthenticated(true);
			} else {
				keycloakService.logout();
			}
		} else {
			keycloakService
				.initializeKeycloak()
				.then((authenticated) => {
					if (authenticated) {
						if (decodedAccessToken.locale?.toLowerCase() === "ar".toLowerCase() && localStorage.getItem("firstLogin")) {
							if (localStorage.getItem("i18nextLng") !== "ar") {
								localStorage.setItem("isRTL", true);
								setConfig("isRTL", true);
								i18n.changeLanguage("ar");
								setAuthenticated(true);
								window.location.reload();
							}
						}
					} else {
						return <>Not Authorized...</>;
					}
				})
				.catch((err) => {
					alert(err);
				});
		}

		if (encryptStorage1.getItem(Constants.SelectedCompany) === undefined) {
			setIsCompany(false);
		} else {
			setIsCompany(true);
		}
	}, []);

	useEffect(() => {
		if (navbarPosition === "double-top") {
			HTMLClassList.add("double-top-nav-layout");
		}
		return () => HTMLClassList.remove("double-top-nav-layout");
	}, [navbarPosition]);

	if (authenticated && isCompany) {
		return (
			<Router basename={process.env.PUBLIC_URL}>
				<FalconRoutes />
				<SettingsPanel />
				<ToastContainer closeButton={CloseButton} icon={true} position={toast.POSITION.TOP_RIGHT} autoClose={1000} />
			</Router>
		);
	} else if (authenticated && !isCompany) {
		return <GetUserCompanies />;
	} else {
		if (location.pathname.includes("create-account")) {
			return (
				<Router basename={process.env.PUBLIC_URL}>
					<CreateAccount />
					<Routes>
						<Route path="form_view_renderer" element={<FormViewRenderer />} />
						<Route path="login" element={<LoginPage />} />
						<Route path="/" element={<LoginPage />} />
						<Route path="service_executor_workflow" element={<ServiceExecutorWorfklow />} />
					</Routes>
				</Router>
			);
		}
		if (location.pathname.includes("email-verification")) {
			return (
				<Router basename={process.env.PUBLIC_URL}>
					<ToastContainer closeButton={CloseButton} icon={true} position={toast.POSITION.TOP_RIGHT} autoClose={1000} />
					<Routes>
						<Route path="form_view_renderer" element={<FormViewRenderer />} />
						<Route path="service_executor_workflow" element={<ServiceExecutorWorfklow />} />
						<Route path="email-verification" element={<EmailVerification />} />
						<Route path="login" element={<LoginPage />} />
					</Routes>
				</Router>
			);
		}
		if (location.pathname.includes("service_executor_workflow")) {
			return (
				<Router basename={process.env.PUBLIC_URL}>
					<Routes>
						<Route path="form_view_renderer" element={<FormViewRenderer />} />
						<Route path="service_executor_workflow" element={<ServiceExecutorWorfklow />} />
					</Routes>
				</Router>
			);
		}

		if (location.pathname.includes("form_view_renderer")) {
			return (
				<Router basename={process.env.PUBLIC_URL}>
					<Routes>
						<Route path="form_view_renderer" element={<FormViewRenderer />} />
						<Route path="service_executor_workflow" element={<ServiceExecutorWorfklow />} />
						<Route path="login" element={<LoginPage />} />
					</Routes>
				</Router>
			);
		}
		if (location.pathname.includes("landing")) {
			return (
				<Router basename={process.env.PUBLIC_URL}>
					<Routes>
						<Route path="landing" element={<Landing />} />
					</Routes>
				</Router>
			);
		}

		if (location.pathname.includes("reset-password")) {
			return <ResetPassword />;
		} else {
			if (location.pathname.includes("create-account")) {
				return <CreateAccount />;
			} else {
				if (location.pathname === "/" && location.host.includes("emanifest") && location.hash == "") {
					return (
						<Router basename={process.env.PUBLIC_URL}>
							<Routes>
								<Route path="/" element={<Landing />} />
							</Routes>
						</Router>
					);
				} else if (location.pathname.includes("test")) {
					return (
						<Router basename={process.env.PUBLIC_URL}>
							<Routes>
								<Route path="form_view_renderer" element={<FormViewRenderer />} />
								<Route path="service_executor_workflow" element={<ServiceExecutorWorfklow />} />
							</Routes>
						</Router>
					);
				} else {
					<>Loading...</>;
					// return <MasterLoginPage/>;
					// return <ReactKeycloakProvider authClient={keycloak}><KeycloackLogin /></ReactKeycloakProvider>
					// return <KeycloackLogin />;
				}
			}
		}
	}
};

export default App;
