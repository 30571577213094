
export const SET_COUNT = 'SET_COUNT';
export const INCREMENT_WORKFLOW='INCREMENT_WORKFLOW';
export const DECREMENT_WORKFLOW='DECREMENT_WORKFLOW';

  const flagReducer = (state =[], action) => {
    switch (action.type) {
  
      
        case SET_COUNT:
          return{
            ...state,
            data:action.payload.data
          };
          case INCREMENT_WORKFLOW:
            return{
              ...state,
              data:state.data+1
            };
            case DECREMENT_WORKFLOW:
              return{
                ...state,
                data:state.data-1
              };
  
      default:
        return state;
  
    }
  }
  
  export default flagReducer;