import React, { Component, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { ReactComponent, Utils } from "@formio/react";
import settingsForm from "./AutoComplete.settingsFrom";
import _ from "lodash";
import { createRoot } from "react-dom/client";
import * as General from "common/general";
import { getRandomId } from "helpers/utils";
import * as Constants from "common/constants";
import { EncryptStorage } from "encrypt-storage";
import { useTranslation } from "react-i18next";


/*
This component behaves as searchable dropdown where user can search for a certain field from the result we got from api 
*/
const AutoCompleteCustomComp = ({ ...props }) => {
	const [myState, setMyState] = useState({ ...props.options });
	const [isOpen, setIsOpen] = useState(false);
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});
	const [t, i18n] = useTranslation();
	const dropdownRef = useRef(null);


	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current) {
				if (!dropdownRef.current.contains(event.target)) {
					setIsOpen(false);
				}
			}

		};
		let state = { ...myState };
		let initialValue = props.component.defaultValue || props.form._data[props.component.key];
		if (initialValue) {
			state["selectedValues"] = initialValue;
			if (props.component.companyGrid) {
				let valid = true;
				for (let i = 0; i < props.component.companyGrid.length; i++) {

					valid =
						props.component.companyGrid[i]["companyFieldValidation"] === "email" && state["selectedValues"][props.component.companyGrid[i]["companyFieldPath"]].trim()
							? General.isValidEmail(state["selectedValues"][props.component.companyGrid[i]["companyFieldPath"]])
							: props.component.companyGrid[i]["companyFieldValidation"] === "phoneNumber" && state["selectedValues"][props.component.companyGrid[i]["companyFieldPath"]].trim()
								? General.isKSAPhoneNumber(state["selectedValues"][props.component.companyGrid[i]["companyFieldPath"]])
								: true;
					if (!valid) {
						state["errors"][props.component.companyGrid[i].companyFieldPath] = t("Validation Error");
					}

				}
				if (!valid || !state["selectedValues"].value) {
					props.onChange("", null); // Call onChange with the new value
				}
			}
		} else {
			if (props.component.companyGrid) {
				for (let i = 0; i < props.component.companyGrid.length; i++) {
					if (!state.selectedValues[props.component.companyGrid[i].companyFieldPath]) {
						state.selectedValues[props.component.companyGrid[i].companyFieldPath] = "";
					}
				}
			}
		}
		setMyState(state);
		props.setComponentState(state);

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);

		}
	}, []);

	const loadData = (e) => {
		let state = { ...myState };

		var accessToken = encryptStorage1.getItem(Constants.AccessTokenKeyInLocalStorage);
		var headers = { "Content-Type": "application/json" };

		if (accessToken) {
			var headerToken = { Authorization: "Bearer " + accessToken };
			headers = { ...headers, ...headerToken };
		}
		fetch(Constants.base_url+props.component.requestUrl + "/" + props.component.searchedFieldName + "=ilike.*" + e + "*", { method: "GET", headers: headers })
			.then((response) => response.json())
			.then((res) => {
				if (res) {
					let path = props.component.dataPath;
					let result = path ? _.get(res, path, "") : res;
					state["options"] = [];
					for (let i = 0; i < result.length; i++) {
						state["options"].push({ ...JSON.parse(result[i][props.component.searchedFieldName]), id: getRandomId() })
					}
					state.selectedValues.value = e;
					state.loading = false;

					setMyState(state);
					props.setComponentState(state);


				}
			})
			.then(() => { })
			.catch((error) => {
				console.error("Error:", error);
			});

	};
	const updateComponentAutoFillValues = (e, grid) => {
		let state = { ...myState };

		if (e.target.value.trim() === "") {
			state["selectedValues"][grid.companyFieldPath] = e.target.value;
			state["errors"][grid.companyFieldPath] = "";
			setMyState(state);
			props.setComponentState(state);
			props.onChange("", null); // Call onChange with the new value
		} else {
			let value = e.target.value;
			state["selectedValues"][grid.companyFieldPath] = value;
			let isValid =
				grid["companyFieldValidation"] === "email" ? General.isValidEmail(value) : grid["companyFieldValidation"] === "phoneNumber" ? General.isKSAPhoneNumber(value) : true;
			if (isValid) {
				state["errors"][grid.companyFieldPath] = "";
				setMyState(state);
				props.setComponentState(state);
				props.onChange(state["selectedValues"], null); // Call onChange with the new value
			} else {
				state["errors"][grid.companyFieldPath] = t("Validation Error");
				setMyState(state);
				props.setComponentState(state);
				props.onChange("", null); // Call onChange with the new value
			}
		}
	}
	const setComponentValue = (option) => {
		let state = { ...myState };
		state["selectedValues"] = { ...option };
		setIsOpen(false)
		setMyState(state);
		props.setComponentState(state);
		props.onChange(state["selectedValues"], null); // Call onChange with the new value
	}

	const updateSearchedNameValue = (e) => {
		let state = { ...myState };
		state["selectedValues"].value = e;

		if ((e + "").trim().length > 2) {
			state.loading = true;
			setIsOpen(true);
			setMyState(state);
			props.setComponentState(state);
			loadData(e);
		}
		else {
			state.options = [];
			setMyState(state);
			props.setComponentState(state);
		}

	}
	//add page data in global form data
	useEffect(() => {
		if (props.data) {
			let keys = Object.keys(props.data);
			for (let i = 0; i < keys.length; i++) {
				props.form._data[keys[i]] = props.data[keys[i]];
				props.form._submission.data[keys[i]] = props.data[keys[i]];
			}

			if (props.insideGrid === true) {
				delete props.form._data[props.component.key];
				delete props.form._submission.data[props.component.key];
			}
		}
	}, [props.data]);
	console.log("myState" , myState)
	return (
		<div className="autoCompleteCustomComp">
			<div className="row">
				<div className="col-sm-6 mt-3">
					<label style={{ display: props.component.dropdownLabel ? "block" : "none" }}>
						{props.component.translateData && props.form._form.settings ? props.form._form.settings.translatedData[props.form._form.settings.language][props.component.dropdownLabel] : props.component.dropdownLabel}
					</label>

					<div style={{ position: "relative" }}>
						<input
							onFocus={() => {
								setIsOpen(true);
							}}
							placeholder={t("Search") + "..."}
							list={props.component.key + "_datalist"}
							className="form-control"
							onChange={(e) => {
								updateSearchedNameValue(e.target.value);

							}}
							disabled={(props.component.disabled && props.component.disabled === true) || myState.loading}
							type="text"
							value={myState.selectedValues.value}
						/>
						{
							isOpen && <div ref={dropdownRef} className="form_choices_list" style={{ position: "absolute", top: "" }}>
								{myState.loading ? t("Loading") : myState.options.length > 0 ? (
									myState.options.map((option, index) => {
										return <div
											style={{ backgroundColor: option.id === myState.selectedValues?.id ? "#d8e2ef" : "" }}
											key={props.component.key + "_" + index}
											onClick={() => {
												setComponentValue(option)

											}}
											className="form_choices_item"
											value={option.id}
										>
											{option.value}
										</div>

									})
								) : (
									<div style={{ padding: "10px", fontSize: "14px" }}>{t("No choices to choose from")}</div>
								)}
							</div>
						}

					</div>
				</div>

				{props.component.companyGrid?.map((grid, index) => {
					return (

						<div key={props.component.key + "_" + index} className="mt-3 col-sm-6">
							<label>
								{props.component.translateData ? props.form._form.settings.translatedData[props.form._form.settings.language][grid.companyFieldName] : grid.companyFieldName}

							</label>
							<input
								type="text"
								className="form-control"
								style={{
									border: myState.errors[grid.companyFieldPath] ? "1px solid red" : "",
								}}
								onChange={(e) => {
									updateComponentAutoFillValues(e, grid)
								}}
								value={myState["selectedValues"] ? myState["selectedValues"][grid.companyFieldPath] || "" : ""}
								disabled={props.component.disabled && props.component.disabled === true}
							/>
							<div style={{ width: "100%", color: "red" }}>{myState.errors && myState.errors[grid.companyFieldPath]}</div>
						</div>
					)
				})}
			</div>
		</div>
	);
};

export default class AutoComplete extends ReactComponent {
	static shouldSetValue = false; // Define shouldSetValue as a static property

	/**
	 * This is the first phase of component building where the component is instantiated.
	 *
	 * @param component - The component definition created from the settings form.
	 * @param options - Any options passed into the renderer.
	 * @param data - The submission data where this component's data exists.
	 */

	constructor(component, options, data) {
		options["oldState"] = {
			options: [],
			value: null,
			loading: false,
			requestUrl: "",
			searchedName: "",
			dropdownSelection: false,
			errors: {},
			valuesRendered: false,
			lastFocusedId: "",
			showDropdown: false,
			isFocused: false,
			selectedValues: {
				value: "",
			},
		};
		super(component, options, data);
		this.reactInstance = null;
		this.dirty = false;
		this.state = {
			updated: "",
		};
	}

	/**
	 * This function is the default settings for the component. At a minimum you want to set the type to the registered
	 * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
	 *
	 * @param sources
	 * @returns {*}
	 */
	static schema(...extend) {
		return ReactComponent.schema({
			type: "autoCompleteCustomComp",
			label: "",
			errorLabel: "Fields Required",
		});
	}
	static get builderInfo() {
		return {
			title: "Auto Complete",
			icon: "cubes",
			group: "Basic",
			documentation: "",
			weight: -10,
			schema: AutoComplete.schema(),
		};
	}
	static editForm = settingsForm;


	/**
	 * Override this function to insert your custom component.
	 *
	 * @param element
	 * @param ref - callback ref
	 */
	attachReact(element, ref) {
		const rootForm = this.getRoot(); // Get the root form object
		let insideGrid = false;
		let key = this.component.key;
		const root = createRoot(element);

		Utils.eachComponent(
			rootForm.components,
			function (component) {
				if (component.component.type === "editgrid") {
					Utils.eachComponent(
						component.component.components,
						function (component2) {
							if (!insideGrid) {
								insideGrid = component2.key === key;
							}
						},
						true
					);
				}
			},
			true
		);
		const setComponentState = (value) => {
			this.options["oldState"] = { ...value };
			this.updateOnChange({}, true);

		}
		root.render(
			<AutoCompleteCustomComp
				component={this.component} // These are the component settings if you want to use them to render the component.
				onChange={this.updateValue} // Pass the onChange event handler
				value={this.dataValue}
				data={this.data}
				dirty={this.dirty}
				updateOnChange={this.updateOnChange}
				element={this}
				form={rootForm}
				insideGrid={insideGrid}
				setComponentState={setComponentState}
				options={this.options["oldState"]}
			/>
		);
	}

	/**
	 * Something external has set a value and our component needs to be updated to reflect that. For example, loading a submission.
	 *
	 * @param value
	 */
	setValue(value) {
		if (this.reactInstance) {
			this.reactInstance.setState({
				value: value,
			});
			this.shouldSetValue = false;
		} else {
			this.shouldSetValue = true;
			this.dataForSetting = value;
		}
	}

	/**
	 * The user has changed the value in the component and the value needs to be updated on the main submission object and other components notified of a change event.
	 *
	 * @param value
	 */
	updateValue = (value, flags) => {
		flags = flags || {};
		if (value !== null) {
			const newValue = value === undefined || value === null ? this.getValue() : value;
			const changed = newValue !== undefined ? this.hasChanged(newValue, this.dataValue) : false;
			this.dataValue = Array.isArray(newValue) ? [...newValue] : newValue;

			this.updateOnChange(flags, changed);
			return changed;
		}
	};

	/**
	 * Get the current value of the component. Should return the value set in the react component.
	 *
	 * @returns {*}
	 */
	getValue() {
		if (this.reactInstance) {
			return this.reactInstance.state.value;
		}
		return this.defaultValue;
	}

	/**
	 * Override normal validation check to insert custom validation in react component.
	 *
	 * @param data
	 * @param dirty
	 * @param rowData
	 * @returns {boolean}
	 */
	checkValidity(data, dirty, rowData) {
		const valid = super.checkValidity(data, dirty, rowData);
		if (!valid) {
			return false;
		}
		return this.validate(data, dirty, rowData);
	}

}
