import React, { Component, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { ReactComponent, Utils } from "@formio/react";
import { createRoot } from "react-dom/client";
import { getRandomId } from "helpers/utils";

/*
This component is responsible to get the user location coordinates (longitude and latitude)
*/
const UserGeolocationComponent = ({ ...props }) => {
	const [myState, setMyState] = useState({ ...props.options });

	useEffect(() => {
		let state = { ...myState }
		let initialValue =  props.component.defaultValue || props.form._data[props.component.key] ;

		//if default value supported get the coordinates from it
		if (initialValue) {
			let details = initialValue;

			state["latitude"] = details.latitude;
			state["longitude"] = details.longitude;
			setMyState(state);
			props.setComponentState(state);
		}
		//else get it using geolocation
		else if (navigator.geolocation) {
			navigator.geolocation.watchPosition((position) => {
				state["latitude"] = position.coords.latitude;
				state["longitude"] = position.coords.longitude;

				setMyState(state);
				props.setComponentState(state);
				if (props.form._form.settings && props.form._form.settings.toEdit !== true) {
					props.onChange(
						{
							latitude: position.coords.latitude,
							longitude: position.coords.longitude,
						},
						null
					);
				}
			});
		}
	}, []);
	//add page data in global form data
	useEffect(() => {
		if (props.data) {
			let keys = Object.keys(props.data);
			for (let i = 0; i < keys.length; i++) {
				props.form._data[keys[i]] = props.data[keys[i]];
				props.form._submission.data[keys[i]] = props.data[keys[i]];
			}
			if (props.insideGrid === true) {
				delete props.form._data[props.component.key];
				delete props.form._submission.data[props.component.key];
			}
		}
	}, [props.data]);

	return (
		<div className="row geolocationComponent">
			<div className="col-sm-6">
				<label>
					{" "}
					{props.form._form.settings && props.form._form.settings.translatedData
						? props.form._form.settings.translatedData[props.form._form.settings.language]
							? props.form._form.settings.translatedData[props.form._form.settings.language]["Longitude"] || "Longitude"
							: "Longitude"
						: "Longitude"}
				</label>
				<input type="text" className="form-control" value={myState.longitude} disabled={true} />
			</div>
			<div className="col-sm-6">
				<label>
					{" "}
					{props.form._form.settings && props.form._form.settings.translatedData
						? props.form._form.settings.translatedData[props.form._form.settings.language]
							? props.form._form.settings.translatedData[props.form._form.settings.language]["Latitude"] || "Latitude"
							: "Latitude"
						: "Latitude"}
				</label>

				<input type="text" className="form-control" value={myState.latitude} disabled={true} />
			</div>
		</div>
	);
};

export default class UserGeolocation extends ReactComponent {
	static shouldSetValue = false; // Define shouldSetValue as a static property

	/**
	 * This is the first phase of component building where the component is instantiated.
	 *
	 * @param component - The component definition created from the settings form.
	 * @param options - Any options passed into the renderer.
	 * @param data - The submission data where this component's data exists.
	 */
	constructor(component, options, data) {
		options["oldState"] = {
			longitude: -1,
			latitude: -1,
		};
		super(component, options, data);
		this.reactInstance = null;
	}

	/**
	 * This function is the default settings for the component. At a minimum you want to set the type to the registered
	 * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
	 *
	 * @param sources
	 * @returns {*}
	 */
	static schema(...extend) {
		return ReactComponent.schema({
			type: "userGeolocation",
			label: "User Geolocation",
		});
	}
	static get builderInfo() {
		return {
			title: "UserGeolocation",
			icon: "cubes",
			group: "Basic",
			documentation: "",
			weight: -10,
			schema: UserGeolocation.schema(),
		};
	}

	



	/**
	 * Override this function to insert your custom component.
	 *
	 * @param element
	 * @param ref - callback ref
	 */
	attachReact(element, ref) {
		const rootForm = this.getRoot(); // Get the root form object
		let insideGrid = false;
		let key = this.component.key;
		const root = createRoot(element);

		Utils.eachComponent(
			rootForm.components,
			function (component) {
				if (component.component.type === "editgrid") {
					Utils.eachComponent(
						component.component.components,
						function (component2) {
							if (!insideGrid) {
								insideGrid = component2.key === key;
							}
						},
						true
					);
				}
			},
			true
		);
		const setComponentState = (value) => {
			this.options["oldState"] = { ...value };
			this.updateOnChange({}, true);
			root.render(
				<UserGeolocationComponent
					component={this.component} // These are the component settings if you want to use them to render the component.
					onChange={this.updateValue} // Pass the onChange event handler
					value={this.dataValue}
					data={this.data}
					form={rootForm}
					insideGrid={insideGrid}
					setComponentState={setComponentState}
					options={this.options["oldState"]}
				/>
			);
		}
		root.render(
			<UserGeolocationComponent
				component={this.component} // These are the component settings if you want to use them to render the component.
				onChange={this.updateValue} // Pass the onChange event handler
				value={this.dataValue}
				data={this.data}
				form={rootForm}
				insideGrid={insideGrid}
				setComponentState={setComponentState}
				options={this.options["oldState"]}
			/>
		);
	}


}
