export default [
	{
		title: "Interactive graphs and charts",
		date: "Jan 15",
		read: "8min",
	},
	{
		title: "Lifetime free updates",
		date: "Jan 5",
		read: "3min",
		star: true,
	},
	{
		title: "Merry Christmas From us",
		date: "Dec 25",
		read: "2min",
	},
	{
		title: "The New Falcon Theme",
		date: "Dec 23",
		read: "10min",
	},
];
