import React, { useState, useContext } from "react";

import PropTypes from "prop-types";
import AppContext from "context/Context";

const Loader = ({ cssClass }) => {
	const {
		config: { isRTL },
	} = useContext(AppContext);

	return (
		<div className={`loaderContainer ${cssClass}`}>
			<div className="lds-ellipsis">
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	);
};

Loader.propTypes = {
	cssClass: PropTypes.string,
};

export default Loader;
