import React, { useState, useContext, useEffect } from "react";

import PropTypes from "prop-types";
import AppContext from "context/Context";
import * as Constants from "common/constants";
import * as General from "common/general";

import IconButton from "components/common/IconButton";
import Loader from "components/custom/Loader";
import CardDropdown from "components/common/CardDropdown";
import AddRelatedCompany from "components/custom/relatedCompanies/AddRelatedCompany";
import EditRelatedCompany from "components/custom/relatedCompanies/EditRelatedCompany";
import DeleteRelatedCompanyConfirmation from "components/custom/relatedCompanies/DeleteRelatedCompanyConfirmation";
import { EncryptStorage } from "encrypt-storage";
import { Dropdown, Col, Modal, Row, Button, Image, Tooltip, overlay, OverlayTrigger } from "react-bootstrap";

import { useTranslation } from "react-i18next";

const ManageRelatedCompanies = ({ RelatedCompanytypeID, CompanyRelationshipTypeID }) => {
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});

	const [errorContainerVisiblity, showerrorContainer] = useState(false);
	const [loaderContainerVisibility, showLoaderContainer] = useState(false);

	const [addRelatedCompanyPopupVisibility, showAddRelatedCompanyPopup] = useState(false);
	const [editRelatedCompanyPopupVisibility, showEditRelatedCompanyPopup] = useState(false);
	const [deleteRelatedCompanyPopupVisibility, showDeleteRelatedCompanyPopup] = useState(false);
	const [selectedCompany, setSelectedCompany] = useState([]);

	const [LoggedInCompanyID, setLoggedInCompanyID] = useState("");
	const [LoggedInCompanyLicenseNumber, setLoggedInCompanyLicenseNumber] = useState("");
	const [RelatedCompanytypeIDFromState, setRelatedCompanytypeID] = useState(RelatedCompanytypeID);
	const [CompanyRelationshipTypeIDFromState, setCompanyRelationshipTypeID] = useState(CompanyRelationshipTypeID);

	const [RelatedCompanytypeName, setRelatedCompanytypeName] = useState("");
	const [RelatedCompanytypeNamePlural, setRelatedCompanytypeNamePlural] = useState("");

	const [companies, setCompanies] = useState([]);
	const [companiesIDs, setCompaniesIDs] = useState([]);
	const [tblCompanie, setTblComapanie] = useState("");

	const [t, i18n] = useTranslation();
	const [onRefresh, setOnRefresh] = useState(null);

	//first function that's called
	useEffect(() => {
		setOnRefresh(null);
		var selectedCompany = encryptStorage1.getItem(Constants.SelectedCompany);
		//  selectedCompany = "GeneratorCompany"
		bindCompanies(selectedCompany);
		//console.log("success",);
	}, [window.location.href, onRefresh]);

	const handlerefresh = (value) => {
		setOnRefresh(value);
	};

	/*************** LOAD Companeis START ************************/
	const bindCompanies = (currentcompid) => {
		showerrorContainer(false);
		showLoaderContainer(true);

		const GetAllRelatedCompaniesSuccess = (responseObject) => {
			setCompanies(responseObject.data.dataBaseContent);
			showerrorContainer(false);
			showLoaderContainer(false);
		};

		const GetAllRelatedCompaniesFailure = (error) => {
			showerrorContainer(false);
			showLoaderContainer(false);
		};

		const currentURL = window.location.href;
		if (currentURL.includes("manageFacilities")) {
			var link = Constants.GetAllEndPoint + "/tbl_company_facility_relationship/" + "generatorCompanyId=eq." + currentcompid;
			setTblComapanie("Facility");
			setRelatedCompanytypeName("Facility");
			setRelatedCompanytypeNamePlural("Facilities");
		} else {
			var link = Constants.GetAllEndPoint + "/tbl_company_transporter_relationship/" + "generatorCompanyId=eq." + currentcompid;
			setTblComapanie("Transporter");
			setRelatedCompanytypeName("Transporter");
			setRelatedCompanytypeNamePlural("Transporters");
		}

		var header = null;

		General.GetFromEndPoint(link, header, null, GetAllRelatedCompaniesSuccess, GetAllRelatedCompaniesFailure);
	};
	/*************** LOAD Companeis END ************************/

	/*************** ADD related companies START ************************/

	const handleClickAddRelateCompany = () => {
		showAddRelatedCompanyPopup(true);
	};

	const handleCancelAddRelateCompany = () => {
		showAddRelatedCompanyPopup(false);
	};
	/*************** ADD related companies END ************************/

	/*************** ADD related companies START ************************/

	const handleClickEditRelateCompany = (comp) => {
		setSelectedCompany(comp);
		showEditRelatedCompanyPopup(true);
	};

	const handleCancelEditRelateCompany = () => {
		showEditRelatedCompanyPopup(false);
	};
	/*************** ADD related companies END ************************/

	/*************** delete related companies START ************************/
	const handleClickDeleteRelatedCompany = (comp) => {
		setSelectedCompany(comp);
		showDeleteRelatedCompanyPopup(true);
	};
	const handleCancelDelete = () => {
		showDeleteRelatedCompanyPopup(false);
	};
	/*************** delete related companies END ************************/

	const {
		config: { isRTL },
	} = useContext(AppContext);

	return (
		<div>
			<div className="mb-3 card">
				<div className="card-body">
					<div className="col-md">
						<h5 className="mb-2 mb-md-0">{t(`Manage Related ${RelatedCompanytypeNamePlural}`)}</h5>
					</div>
				</div>
			</div>
			<div className="card">
				<div className="tool-header">
					<OverlayTrigger
						overlay={
							<Tooltip style={{ position: "fixed" }} id="newcontract">
								{t(`New ${RelatedCompanytypeName} Contract`)}
							</Tooltip>
						}
					>
						<div className="theme-control-toggle-label icon-position end-text">
							<IconButton
								className="btn-primary botton-circle"
								iconClassName="fs--5"
								size="sm"
								icon="fa-solid fa-circle-plus"
								style={{ margin: "auto 0.25rem", padding: "9px 10px 10px" }}
								onClick={handleClickAddRelateCompany}
							></IconButton>
						</div>
					</OverlayTrigger>
				</div>

				{/*       <div className="addNewButton">
                    <IconButton
                        iconClassName="fs--2 me-1"
                        variant="falcon-default"
                        size="sm"
                        icon="plus"
                        onClick={handleClickAddRelateCompany}  >
                        {t(`New ${RelatedCompanytypeName} Contract`)} 
                    </IconButton>
                </div>*/}
				<div className="table-responsive">
					<table className="customTable table table-striped" striped="true" responsive="true">
						<thead>
							<tr>
								<th scope="col">{t(`${RelatedCompanytypeName} id`)}</th>
								<th scope="col">{t("Name")}</th>
								<th scope="col">{t("Contract Number")}</th>
								<th scope="col">{t("Contract End Date")}</th>
								<th className="text-end" scope="col">
									{t("Actions")}
								</th>
							</tr>
						</thead>
						<tbody>
							{companies.map((company) => (
								<tr key={company.id}>
									<td>
										{company.facilityCompanyName}
										{company.transporterCompanyName}{" "}
									</td>

									<td>
										{company.facilityCompanyId}
										{company.transporterCompanyId}
									</td>
									<td>{company.contractNumber}</td>
									<td>{company.contractEndDate == "1900-01-01" ? "-" : company.contractEndDate}</td>

									<td className="text-end">
										<CardDropdown>
											<div className="py-2">
												<Dropdown.Item onClick={() => handleClickEditRelateCompany(company)}>{t("Edit")}</Dropdown.Item>
												<Dropdown.Item className="text-danger" onClick={() => handleClickDeleteRelatedCompany(company)}>
													{t(`Remove ${RelatedCompanytypeName} Contract`)}
												</Dropdown.Item>{" "}
												{/**/}
											</div>
										</CardDropdown>
									</td>
								</tr>
							))}
						</tbody>
					</table>

					{errorContainerVisiblity ? <div id="errorContainer" className="loginAlert"></div> : ""}
					{loaderContainerVisibility ? <Loader></Loader> : ""}

					{addRelatedCompanyPopupVisibility && (
						<AddRelatedCompany
							successCallBackFunction={handlerefresh}
							RelatedCompanytypeID={RelatedCompanytypeID}
							RelatedCompanytypeName={RelatedCompanytypeName}
							LoggedInCompanyID={LoggedInCompanyID}
							CompaniesAlreadyRelated={companiesIDs}
							LoggedInCompanyLicenseNumber={LoggedInCompanyLicenseNumber}
							hideAddRelatedCompany={handleCancelAddRelateCompany}
							showComponent={addRelatedCompanyPopupVisibility}
							tblCompanie={tblCompanie}
							RelatedCompany={selectedCompany}
						></AddRelatedCompany>
					)}

					{editRelatedCompanyPopupVisibility && (
						<EditRelatedCompany
							showComponent={editRelatedCompanyPopupVisibility}
							RelatedCompanytypeID={RelatedCompanytypeID}
							hideEditRelatedCompany={handleCancelEditRelateCompany}
							successCallBackFunction={handlerefresh}
							LoggedInCompanyID={LoggedInCompanyID}
							LoggedInCompanyLicenseNumber={LoggedInCompanyLicenseNumber}
							CompaniesAlreadyRelated={companiesIDs}
							RelatedCompany={selectedCompany}
							tblCompanie={tblCompanie}
						></EditRelatedCompany>
					)}

					{deleteRelatedCompanyPopupVisibility && (
						<DeleteRelatedCompanyConfirmation
							successCallBackFunction={handlerefresh}
							LoggedInCompanyID={LoggedInCompanyID}
							hideDeleteConfirmation={handleCancelDelete}
							showComponent={deleteRelatedCompanyPopupVisibility}
							company={selectedCompany}
							tblCompanie={tblCompanie}
						></DeleteRelatedCompanyConfirmation>
					)}
				</div>
			</div>
		</div>
	);
};

ManageRelatedCompanies.propTypes = {
	RelatedCompanytypeID: PropTypes.string,
	CompanyRelationshipTypeID: PropTypes.string,
};

export default ManageRelatedCompanies;
