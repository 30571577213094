import React from "react";
import "../../styles/Builder.css";
import { useState, useEffect } from "react";
//import axios from 'axios';
import { Form } from "@formio/react";
import { FormBuilder as FormioFormBuilder } from "@formio/react";
import Formio from "formiojs/Formio";
import { Card, Col, Row } from "react-bootstrap";
import { atomOneDark, CopyBlock, dracula } from "react-code-blocks";
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { Alert } from "reactstrap";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

function FormRenderer() {
	//const handleSubmit = event => {
	//    event.preventDefault();
	//    //handleSearch(query);
	//};
	const navigate = useNavigate();
	const { id } = useParams();
	const [jsonSchema, setSchema] = useState({ components: [] });
	const [query, setQuery] = useState("");
	const [formName, setFormName] = useState("");
	// const [formDisplay, setFormDisplay] = useState('form');
	const [showAlert, setShowAlert] = useState(false);
	const [showFailAlert, setShowFailAlert] = useState(false);
	const [alertmessage, setAlertMessage] = useState("");
	const [formdisplay, setFormdisplay] = useState("");
	const handleChange = (event) => {
		setQuery(event.target.value);
	};
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const toggle = () => setDropdownOpen((prevState) => !prevState);
	const formdata = {
		title: query,
		display: "form",
		type: "form",
		name: query,
		path: query.replaceAll(" ", ""),
		components: jsonSchema.components,
	};

	const [form, setForm] = useState([]);
	useEffect(() => {
		fetch("https://localhost:7098/api/Forms/getFormbyId/" + id)
			.then((res) => res.json())
			.then((data) => {
				// console.log("data", data);
				//console.log("data.name", data.name);
				setForm(data);
				setSchema(data.components);
				setQuery(data.name);
				setFormdisplay(data.display);
				formdata.title = data.title;
				formdata.type = data.type;
				formdata.name = data.name;
				formdata.path = data.path;
				console.log("formdata.path", formdata.path);
				formdata.components = data.components;
				formdata.display = data.display;
				// console.log("query", query);
			})
			.catch((err) => {
				console.log(err.message);
			});
		// console.log("formtitle", form.title);
		setFormName(form.title);
	}, []);

	const handleSubmit = (e) => {
		//console.log(formdata);
		//console.log(form);
		//console.log(form._id);
		// var myformdata = JSON.stringify(formdata).toString().replaceAll("\"", "'");
		e.preventDefault();
		fetch("https://localhost:7098/api/Forms/editForm/" + form._id, {
			method: "PUT",
			body: '"' + JSON.stringify(formdata).toString().replaceAll('"', "'").replaceAll("?", '\\"') + '"',
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			},
		})
			//.then((res) => res.json())
			.then((res) => {
				if (!res.ok) {
					console.log("response not ok", res);
					return res.text().then((text) => {
						throw new Error(text);
					});
				} else {
					return res.status;
				}
			})
			.then((data) => {
				console.log(data);
				if (data.status === 400) {
					setShowAlert(false);
					setShowFailAlert(true);
					setAlertMessage(data.statusText);
				} else {
					setShowFailAlert(false);
					setShowAlert(true);
				}
			})
			.catch((err) => {
				console.log(err.message);
				setShowFailAlert(true);
				setAlertMessage(err.message);
			});
	};

	const handledisplayChange = (code) => {
		formdata.display = code;
		form.display = code;
		setFormdisplay(code);
	};

	//useEffect(() => {
	//    Formio.setBaseUrl('https://localhost:7098/api/Forms')
	//}, [{ jsonSchema }]);

	return (
		<>
			<form onSubmit={handleSubmit}>
				<div className="input-group mb-3">
					<input type="text" className="form-control" defaultValue={form.name} onChange={(e) => setFormName(e.target.value)} placeholder="Form Title" onChange={handleChange} />
					<div className="input-group-append">
						<button className="btn btn-outline-secondary" type="submit">
							Save Form
						</button>
					</div>
				</div>
				<div className="input-group mb-3">
					<Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down">
						<DropdownToggle caret>Display</DropdownToggle>
						<DropdownMenu>
							<DropdownItem onClick={() => handledisplayChange("form")}>Form</DropdownItem>
							<DropdownItem onClick={() => handledisplayChange("wizard")}>Wizard</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				</div>
				<Card title="Result" className="p-4">
					<FormioFormBuilder form={form} onChange={(schema) => setSchema(schema)} />
					{/*<FormioFormBuilder form={formdata.components, { display: formdata.display }} onChange={(schema) => setSchema(schema)} />*/}
				</Card>
				{showAlert && (
					<Alert variant="success" onClose={() => setShowAlert(false)}>
						Form Saved!
					</Alert>
				)}
				{showFailAlert && (
					<Alert color="danger" variant="fail" onClose={() => setShowFailAlert(false)}>
						{alertmessage}
					</Alert>
				)}
			</form>
		</>
	);
}

export default FormRenderer;
