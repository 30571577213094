import React, { useEffect, useState } from "react";
import "../../styles/Builder.css";
//import axios from 'axios';
//import { FormBuilder as FormioFormBuilder } from '@formio/react';
import {
	completeExternalTask,
	editUser,
	fetchAndLock,
	getAllWorkflows,
	getExternalServiceTask,
	getFormById,
	getFormByUserTask,
	patch,
	post,
	put,
	sendNotification,
	verifyEmailValidity,
} from "components/workflow/WorkflowHelper";
import jQuery from "jquery";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LoaderIcon from "components/custom/Preloader";
import { toast } from "react-toastify";
import { PostToEndPoint } from "common/general";
import { LoginEndPoint } from "common/constants";
import * as em from "common/ErrorMessages";

function ServiceExecutorWorfklow() {
	const navigate = useNavigate();
	const location = useLocation();
	const [showPreloader, setshowPreloader] = useState(true);

	const form = location.state.data;
	useEffect(() => {
		const executeTask = async () => {
			let no_user_task = false;
			let no_service_task = false;
			while (no_user_task == false) {
				let res = await getFormByUserTask(form.processInstanceId);
				if (jQuery.isEmptyObject(res.data)) {
					no_user_task = true;
					while (true) {
						// loop for consecutive external tasks
						// setshowPreloader(true);
						let external_task_res = await getExternalServiceTask(form.processInstanceId);
						if (jQuery.isEmptyObject(external_task_res)) {
							no_service_task = true;
							break;
						} else {
							no_user_task = false; //reset user task flag
							var taskID = external_task_res.id;
							var processInstanceId = external_task_res.processInstanceId;
							var topic = external_task_res.topicName;
							let fetch_and_lock_res = await fetchAndLock(topic);
							var external_task_id = fetch_and_lock_res[0].id;
							var external_activity_id = fetch_and_lock_res[0].activityId;
							var containsHyphen = external_activity_id.includes("-");
							var functionName = "";
							if (containsHyphen) {
								functionName = external_activity_id.split("-")[0];
							} else {
								functionName = external_activity_id;
							}
							let executed_script_res;
							switch (functionName) {
								case "get_all_workflows":
									executed_script_res = await getAllWorkflows();

									break;
								case "Post":
									executed_script_res = await post(submission.data, external_activity_id.split("-")[1]);
									break;

								case "VerifyEmailValidity":
									executed_script_res = await verifyEmailValidity(form.userName, form.hash, form.requestDateTime);
									break;

								// case 'VerifyEmailValidity2':
								//   executed_script_res = await verifyEmailValidity(form.userName, form.hash, form.requestDateTime, form.userID)
								//   console.log(executed_script_res);
								//   break;

								case "Put":
									executed_script_res = await put({ isVerified: true }, external_activity_id.split("-")[1], form.userID);
									console.log(executed_script_res);
									break;

								case "EditUser":
									executed_script_res = await editUser(form.userName, true, true);
									console.log(executed_script_res);
									break;

								case "Patch":
									executed_script_res = await patch({ isVerified: true }, external_activity_id.split("-")[1], form.userID);
									console.log(executed_script_res);
									break;

								case "SendNotification":
									executed_script_res = await sendNotification("en", submission.data.email, "Verification Email");
									console.log(executed_script_res);
									break;
								default:
									break;
							}
							if (executed_script_res.status == 1) {
								let complete_external_task = await completeExternalTask(external_task_id);
								console.log(complete_external_task);
							}
						}
					}
				} else {
					no_service_task = false; //reset service task flag
					var taskID = res.data.form.taskId;
					var processInstanceId = res.data.form.processInstanceId;
					var form_key = res.data.form.key;
					let getFormByIdEndPointResponse = await getFormById(form_key);
					console.log(getFormByIdEndPointResponse);
					setshowPreloader(false);
					getFormByIdEndPointResponse.formData = JSON.parse(getFormByIdEndPointResponse.formData);
					getFormByIdEndPointResponse.formData.taskID = taskID;
					getFormByIdEndPointResponse.formData.processInstanceId = processInstanceId;
					navigate("/form_view_renderer", {
						replace: true,
						state: { data: getFormByIdEndPointResponse.formData },
					});
					break;
				}

				if (no_user_task && no_service_task) {
					toast.success(t(em.workflow_complete));
					setshowPreloader(false);
					setTimeout(() => navigate("/login"), 1000);
					return;
				}
			}
		};
		executeTask();
	}, []);

	return <>{showPreloader && <LoaderIcon />}</>;
}

export default ServiceExecutorWorfklow;
