import logoApple from "assets/img/logos/apple.png";
import logoG from "assets/img/logos/g.png";
import logoIntel from "assets/img/logos/intel.png";
import logofacebook from "assets/img/logos/facebook.png";
import mailblusterLogo from "assets/img/logos/mailbluster.png";
import logoDigitalocean from "assets/img/logos/digitalocean.png";
import logoNvidia from "assets/img/logos/nvidia.png";
import logoHP from "assets/img/logos/hp.png";
import logoSpotify from "assets/img/logos/spotify.png";

export default [
	{
		image: logoApple,
		title: "Apple",
		description: "3243 associates",
	},
	{ image: logoG, title: "Google", description: "34598 associates", to: "#!" },
	{
		image: logoIntel,
		title: "Intel",
		description: "7652 associates",
	},
	{
		image: logofacebook,
		title: "Facebook",
		description: "765 associates",
	},
	{
		image: mailblusterLogo,
		title: "Mailbluster",
		description: "54 associates",
	},
	{
		image: logoDigitalocean,
		title: "Digital Ocean",
		description: "487 associates",
	},
	{
		image: logoNvidia,
		title: "Nvidia",
		description: "98732 associates",
	},
	{
		image: logoHP,
		title: "Hewlett Packard",
		description: "2345 associates",
	},
	{
		image: logoSpotify,
		title: "Spotify",
		description: "2345 associates",
	},
];
