import React, { Component, ChangeEvent, useState, useEffect, useRef } from "react";
import BpmnModeler from "bpmn-js/lib/Modeler";
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-font/dist/css/bpmn-embedded.css";
import { emptyBpmn } from "./asset/empty.bpmn";
import propertiesPanelModule from "bpmn-js-properties-panel";
import propertiesProviderModule from "bpmn-js-properties-panel/lib/provider/camunda";
import camundaModdleDescriptor from "camunda-bpmn-moddle/resources/camunda";
import "bpmn-js-properties-panel/dist/assets/bpmn-js-properties-panel.css";
import { Card, Button, ButtonGroup } from "react-bootstrap";
import { UploadWorkflowEndPoint } from "common/constants";
import { toast } from "react-toastify";
import * as em from "common/ErrorMessages";
import $ from "jquery";
import { LogoutUser } from "common/general";
//import { Link, useNavigate, useLocation } from "react-router-dom";
//import * as fs from "fs";

// import BpmnModelerTest from '../Modeler/BpmnModeler';

import Aux from "./Auxiliary";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { disable } from "workbox-navigation-preload";

function WorkflowModeler() {
	const location = useLocation();
	//let modeler = null;
	const [modeler, setModeler] = useState(null);
	let downloadLink = $("#js-download-diagram");
	let containerRef = React.createRef();
	const [files, setfiles] = useState([]);
	const [isEdited, setIsEdited] = useState(false);
	const [isEmpty, setIsEmpty] = useState(false);
	let title = "Create and Save Your Model Below";

	// implement a method to fire the HTTP request from the backend

	const saveModelHandler = async (event) => {
		//debounce(async function () {
		/* let isExpired = CheckIfTokenExpired();
        if(isExpired)
        {
            LogoutUser();
        } */
		var encoded;
		event.preventDefault();
		try {
			const { xml } = await modeler.saveXML({ format: true });
			setEncoded(downloadLink, "diagram.bpmn", xml);
			console.log(encoded);
		} catch (err) {
			console.error("Error happened saving diagram: ", err);
			// setEncoded(downloadLink, "diagram.bpmn", null);
		}
	};

	// post request to save the model
	// implement a method to run the request from the backend for POST Model - Interpretation Engine
	useEffect(() => {
		const container = containerRef.current;
		console.log(container);
		setModeler(
			new BpmnModeler({
				container: "#bpmnview",
				propertiesPanel: {
					parent: "#propview",
				},
				additionalModules: [propertiesPanelModule, propertiesProviderModule],
				moddleExtensions: {
					camunda: camundaModdleDescriptor,
				},
			})
		);
	}, []);
	if (modeler) {
		if (isEdited == false) {
			newBpmnDiagram();
		}
		modeler.on("element.changed", (event) => {
			console.log("element.changed", event.element);
			setIsEdited(true);
		});
	}

	function newBpmnDiagram() {
		openBpmnDiagram(emptyBpmn);
	}
	function openBpmnDiagram(xml) {
		modeler.importXML(xml, (error) => {
			if (error) {
				return console.log("fail import xml");
			}

			var canvas = modeler.get("canvas");

			canvas.zoom("fit-viewport");
		});
	}
	/*  useEffect(() => {
       
        const container = containerRef.current;
        console.log(container);
        modeler = new BpmnModeler({
            container: "#bpmnview",
            propertiesPanel: {
                parent: "#propview",
            },
            additionalModules: [propertiesPanelModule, propertiesProviderModule],
            moddleExtensions: {
                camunda: camundaModdleDescriptor,
            },
            });
        
        modeler.on("element.changed",(event) => {
            console.log("element.changed", event.element)
            setIsEdited(true)
          });
        newBpmnDiagram();
        // window.location.reload(false);
    }, [location]);

    const newBpmnDiagram = () => {
        if(diagram != null)
        {
            openBpmnDiagram(diagram);
        }
        else{

            openBpmnDiagram(emptyBpmn);
            // setIsEmpty(true)
        }
    };

    const openBpmnDiagram = (xml) => {
        modeler.importXML(xml, (error) => {
            if (error) {
                return console.log("fail import xml");
            }

            var canvas = modeler.get("canvas");

            canvas.zoom("fit-viewport");
        });
    }; */
	const setEncoded = (link, name, data) => {
		var encodedData = encodeURIComponent(data);
		const aTag = document.createElement("a");
		aTag.href = "data:application/bpmn20-xml;charset=UTF-8," + encodedData;
		aTag.setAttribute("download", "diagram.bpmn");
		document.body.appendChild(aTag);
		aTag.click();
		uploadDiagram(data);
	};

	const uploadDiagram = (data) => {
		const file = new File([data], "workflow.bpmn", {
			type: "",
		});
		const formData = new FormData();
		formData.append("file", file);
		fetch(UploadWorkflowEndPoint, {
			mode: "cors",
			method: "POST",
			body: formData,
		})
			.then((response) => response.json()) //response.json())
			.then((data) => {
				console.log(data);
				if (data.status === 1) {
					toast.success(t(em.uploaded_successfully));
				} else {
					toast.error(t(em.failed_upload_workflow));
				}
			})
			.catch((err) => {
				console.log(err.message);
			});
	};

	return (
		// <React.Fragment><h1 className="Camunda Title">Camunda Modeler</h1></React.Fragment>
		<Aux>
			<div
				className="container text-white"
				style={{
					border: "1px solid #007bff",
					borderRadius: "10px",
					marginBottom: "40px",
					marginTop: "40px",
				}}
			>
				<p
					style={{
						fontFamily: "Trocchi",
						color: "#007bff",
						fontSize: "20px",
						fontWeight: "normal",
						lineHeight: "48px",
						textAlign: "center",
					}}
				>
					{title}
				</p>

				<div style={{ marginTop: "10px" }}> </div>
			</div>

			<div>
				<div id="bpmncontainer">
					<div
						id="propview"
						style={{
							width: "25%",
							height: "98vh",
							float: "right",
							maxHeight: "98vh",
							overflowX: "auto",
						}}
					></div>
					<div id="bpmnview" style={{ width: "75%", height: "98vh", float: "left" }}></div>
				</div>
			</div>
			<ButtonGroup>
				<Button onClick={saveModelHandler} variant="primary" type="submit" style={{ border: "1px solid #008B8B", marginTop: "10px" }} disabled={!isEdited}>
					Save And Upload
				</Button>
			</ButtonGroup>
			<div style={{ marginTop: "40px", paddingTop: "10px" }}></div>
		</Aux>
	);
}

export default WorkflowModeler;
