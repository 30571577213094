import BaseComponent from '../base';

class EditGridComponent extends BaseComponent {
  constructor (component, data, options) {
    super(component, data, options);
    console.log(this);
  }
}

export default EditGridComponent;
