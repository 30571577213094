export const orderList = [
	{
		id: "#181",
		name: "Ricky Antony",
		email: "ricky@example.com",
		date: "20/04/2019",
		address: "Ricky Antony, 2392 Main Avenue, Penasauka, New Jersey 02149",
		shippingType: "Via Flat Rate",
		status: "completed",
		amount: 99,
	},
	{
		id: "#182",
		name: "Kin Rossow",
		email: "kin@example.com",
		date: "20/04/2019",
		address: "Kin Rossow, 1 Hollywood Blvd,Beverly Hills, California 90210",
		shippingType: "Via Free Shipping",
		status: "processing",
		amount: 120,
	},
	{
		id: "#183",
		name: "Merry Diana",
		email: "merry@example.com",
		date: "30/04/2019",
		address: "Merry Diana, 1 Infinite Loop, Cupertino, California 90210",
		shippingType: "Via Link Road",
		status: "onhold",
		amount: 70,
	},
	{
		id: "#184",
		name: "Bucky Robert",
		email: "bucky@example.com",
		date: "30/04/2019",
		address: "Bucky Robert, 1 Infinite Loop, Cupertino, California 90210",
		shippingType: "Via Free Shipping",
		status: "pending",
		amount: 92,
	},
	{
		id: "#185",
		name: "Rocky Zampa",
		email: "rocky@example.com",
		date: "30/04/2019",
		address: "Rocky Zampa, 1 Infinite Loop, Cupertino, California 90210",
		shippingType: "Via Free Road",
		status: "onhold",
		amount: 120,
	},
	{
		id: "#186",
		name: "Ricky John",
		email: "ricky@example.com",
		date: "30/04/2019",
		address: "Ricky John, 1 Infinite Loop, Cupertino, California 90210",
		shippingType: "Via Free Shipping",
		status: "processing",
		amount: 145,
	},
	{
		id: "#187",
		name: "Cristofer Henric",
		email: "cristofer@example.com",
		date: "30/04/2019",
		address: "Cristofer Henric, 1 Infinite Loop, Cupertino, California 90210",
		shippingType: "Via Flat Rate",
		status: "completed",
		amount: 55,
	},
	{
		id: "#188",
		name: "Brate Lee",
		email: "lee@example.com",
		date: "29/04/2019",
		address: "Brate Lee, 1 Infinite Loop, Cupertino, California 90210",
		shippingType: "Via Link Road",
		status: "onhold",
		amount: 90,
	},
	{
		id: "#189",
		name: "Thomas Stephenson",
		email: "Stephenson@example.com",
		date: "29/04/2019",
		address: "Thomas Stephenson, 116 Ballifeary Road, Bamff",
		shippingType: "Via Flat Rate",
		status: "processing",
		amount: 52,
	},
	{
		id: "#190",
		name: "Evie Singh",
		email: "eviewsing@example.com",
		date: "29/04/2019",
		address: "Evie Singh, 54 Castledore Road, Tunstead",
		shippingType: "Via Flat Rate",
		status: "completed",
		amount: 90,
	},
	{
		id: "#191",
		name: "David Peters",
		email: "peter@example.com",
		date: "29/04/2019",
		address: "David Peters, Rhyd Y Groes, Rhosgoch, LL66 0AT",
		shippingType: "Via Link Road",
		status: "completed",
		amount: 69,
	},
	{
		id: "#192",
		name: "Jennifer Johnson",
		email: "jennifer@example.com",
		date: "28/04/2019",
		address: "Jennifer Johnson, Rhyd Y Groes, Rhosgoch, LL66 0AT",
		shippingType: "Via Flat Rate",
		status: "processing",
		amount: 112,
	},
	{
		id: "#193",
		name: " Demarcus Okuneva",
		email: "okuneva@example.com",
		date: "28/04/2019",
		address: " Demarcus Okuneva, 90555 Upton Drive Jeffreyview, UT 08771",
		shippingType: "Via Flat Rate",
		status: "completed",
		amount: 99,
	},
	{
		id: "#194",
		name: "Simeon Harber",
		email: "simeon@example.com",
		date: "27/04/2019",
		address: "Simeon Harber, 702 Kunde Plain Apt. 634 East Bridgetview, HI 13134-1862",
		shippingType: "Via Free Shipping",
		status: "onhold",
		amount: 129,
	},
	{
		id: "#195",
		name: "Lavon Haley",
		email: "lavon@example.com",
		date: "27/04/2019",
		address: "Lavon Haley, 30998 Adonis Locks McGlynnside, ID 27241",
		shippingType: "Via Free Shipping",
		status: "pending",
		amount: 70,
	},
	{
		id: "#196",
		name: "Ashley Kirlin",
		email: "ashley@example.com",
		date: "26/04/2019",
		address: "Ashley Kirlin, 43304 Prosacco Shore South Dejuanfurt, MO 18623-0505",
		shippingType: "Via Link Road",
		status: "processing",
		amount: 39,
	},
	{
		id: "#197",
		name: "Johnnie Considine",
		email: "johnnie@example.com",
		date: "26/04/2019",
		address: "Johnnie Considine, 6008 Hermann Points Suite 294 Hansenville, TN 14210",
		shippingType: "Via Flat Rate",
		status: "pending",
		amount: 70,
	},
	{
		id: "#198",
		name: "Trace Farrell",
		email: "trace@example.com",
		date: "26/04/2019",
		address: "Trace Farrell, 431 Steuber Mews Apt. 252 Germanland, AK 25882",
		shippingType: "Via Free Shipping",
		status: "completed",
		amount: 70,
	},
	{
		id: "#199",
		name: "Estell Nienow",
		email: "nienow@example.com",
		date: "26/04/2019",
		address: "Estell Nienow, 4167 Laverna Manor Marysemouth, NV 74590",
		shippingType: "Via Free Shipping",
		status: "completed",
		amount: 59,
	},
	{
		id: "#200",
		name: "Daisha Howe",
		email: "howe@example.com",
		date: "25/04/2019",
		address: "Daisha Howe, 829 Lavonne Valley Apt. 074 Stehrfort, RI 77914-0379",
		shippingType: "Via Free Shipping",
		status: "completed",
		amount: 39,
	},
	{
		id: "#201",
		name: "Miles Haley",
		email: "haley@example.com",
		date: "24/04/2019",
		address: "Miles Haley, 53150 Thad Squares Apt. 263 Archibaldfort, MO 00837",
		shippingType: "Via Flat Rate",
		status: "completed",
		amount: 55,
	},
	{
		id: "#202",
		name: "Brenda Watsica",
		email: "watsica@example.com",
		date: "24/04/2019",
		address: "Brenda Watsica, 9198 O'Kon Harbors Morarborough, IA 75409-7383",
		shippingType: "Via Free Shipping",
		status: "completed",
		amount: 89,
	},
	{
		id: "#203",
		name: "Ellie O'Reilly",
		email: "ellie@example.com",
		date: "24/04/2019",
		address: "Ellie O'Reilly, 1478 Kaitlin Haven Apt. 061 Lake Muhammadmouth, SC 35848",
		shippingType: "Via Free Shipping",
		status: "completed",
		amount: 47,
	},
	{
		id: "#204",
		name: "Garry Brainstrow",
		email: "garry@example.com",
		date: "23/04/2019",
		address: "Garry Brainstrow, 13572 Kurt Mews South Merritt, IA 52491",
		shippingType: "Via Free Shipping",
		status: "completed",
		amount: 139,
	},
	{
		id: "#205",
		name: "Estell Pollich",
		email: "estell@example.com",
		date: "23/04/2019",
		address: "Estell Pollich, 13572 Kurt Mews South Merritt, IA 52491",
		shippingType: "Via Free Shipping",
		status: "onhold",
		amount: 49,
	},
	{
		id: "#206",
		name: "Ara Mueller",
		email: "ara@example.com",
		date: "23/04/2019",
		address: "Ara Mueller, 91979 Kohler Place Waelchiborough, CT 41291",
		shippingType: "Via Flat Rate",
		status: "onhold",
		amount: 19,
	},
	{
		id: "#207",
		name: "Lucienne Blick",
		email: "blick@example.com",
		date: "23/04/2019",
		address: "Lucienne Blick, 6757 Giuseppe Meadows Geraldinemouth, MO 48819-4970",
		shippingType: "Via Flat Rate",
		status: "onhold",
		amount: 59,
	},
	{
		id: "#208",
		name: "Laverne Haag",
		email: "haag@example.com",
		date: "22/04/2019",
		address: "Laverne Haag, 2327 Kaylee Mill East Citlalli, AZ 89582-3143",
		shippingType: "Via Flat Rate",
		status: "onhold",
		amount: 49,
	},
	{
		id: "#209",
		name: "Brandon Bednar",
		email: "bednar@example.com",
		date: "22/04/2019",
		address: "Brandon Bednar, 25156 Isaac Crossing Apt. 810 Lonborough, CO 83774-5999",
		shippingType: "Via Flat Rate",
		status: "onhold",
		amount: 39,
	},
	{
		id: "#210",
		name: "Dimitri Boehm",
		email: "dimitri@example.com",
		date: "23/04/2019",
		address: "Dimitri Boehm, 71603 Wolff Plains Apt. 885 Johnstonton, MI 01581",
		shippingType: "Via Flat Rate",
		status: "onhold",
		amount: 111,
	},
];
