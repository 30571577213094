import React, { Component } from "react";
import { useState } from "react";
import LoginPage from "../LoginPage";
import { validToken } from "../commonFunctions";

class LoginActivity extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activities: [],
		};
	}

	async componentDidMount() {
		const accessToken = localStorage.getItem("accessToken");
		if (accessToken && accessToken != "undefined" && validToken(accessToken)) {
			const apiData = {
				AccessToken: accessToken,
			};
			const jsonAPIData = JSON.stringify(apiData);
			var url = "https://localhost:7098/api/Authentication/GetLoginActivity";

			const response = await fetch(url, {
				method: "POST",
				body: jsonAPIData,
				headers: {
					"Content-Type": "application/json",
				},
			});

			const data = await response.json();

			if (response.ok) {
				this.setState({
					activities: data,
				});
			} else {
				console.error("Error:", data.error);
			}
		} else {
			window.location.reload();
		}
	}

	render() {
		const { activities } = this.state;

		return (
			<div>
				<table className="customTable">
					<thead>
						<tr>
							<th>Username</th>
							<th>Ip Address</th>
							<th>Timestamp</th>
						</tr>
					</thead>
					<tbody>
						{activities.map((activity) => (
							<tr key={activity.username}>
								<td>{activity.username}</td>
								<td>{activity.ipAddress}</td>
								<td>{activity.timestamp}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		);
	}
}

export default LoginActivity;
