import React, { Component } from "react";
import { useState } from "react";
import ActionButton from "components/common/ActionButton";
import CardTextDropdown from "components/common/CardTextDropdown";
import { Dropdown, Col, Modal, Row, Button, Image } from "react-bootstrap";
import Flex from "components/common/Flex";
import IconButton from "components/common/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Constants from "common/constants";
import * as General from "common/general";
import DeleteConfirmation from "components/custom/forms/DeleteConfirmation";
import SaveFormAlert from "components/custom/forms/SaveFormAlert";
import Loader from "components/custom/Loader";
import { Link } from "react-router-dom";
import { Form, FormBuilder as FormioFormBuilder } from "@formio/react";
import Formio from "formiojs/Formio";
import { Alert } from "reactstrap";
import "../../styles/Builder.css";
import { useParams } from "react-router-dom";
import Select from "react-select";
import Formioform from "./FormioForm";
import { toast, ToastContainer } from "react-toastify";

function withParams(Component) {
	return (props) => <Component {...props} params={useParams()}></Component>;
}

class EditForm extends Component {
	constructor(props) {
		super(props);
		// console.log("props", props);
		// const { id } = useParams();
		//console.log("props.match", props.match);
		this.state = {
			formId: "",
			form: {},
			loggedInEmail: "",
			showFormSavedAlert: false,
			errorOccuredVisibility: false,
			loaderContainerVisibility: false,
			jsonSchema: { components: [] },
			title: "",
			display: "form",
			type: "form",
			name: "",
			path: "",
			showAlert: false,
			translationUrl: "",
			languages: [],
			mainLanguage: "en",
		};
		this.formioFormRef = React.createRef("en");
		this.timer = null; 
	}

	//ON LOAD
	async componentDidMount() {
		this.setState({
			loggedInEmail: localStorage.getItem(Constants.LoggedInEmailOrUserNameKeyInLocalStorage),
		});
		var myid = this.props.params;
		this.setState({ formId: myid.id });
		this.bindForm(myid.id);
	}
	/*************** COMMON FUNCTIONS START ************************/

	showErrorContainer = (shown) => {
		this.setState({ errorOccuredVisibility: shown });
	};

	showLoaderContainer = (shown) => {
		this.setState({ loaderContainerVisibility: shown });
	};

	showFormSaved = (savedForm) => {
		// console.log("createdForm", savedForm);
		this.setState({
			formId: savedForm._id,
			title: savedForm.title,
			name: savedForm.name,
			path: savedForm.path,
			//status: createdForm.status,
			//message:createdForm.message,
			showFormSavedAlert: true,
		});
	};

	/*************** COMMON FUNCTIONS END ************************/

	/*************** LOAD Form START ************************/
	bindForm = (formId) => {
		///console.log("formId", this.state.formId);
		this.showErrorContainer(false);
		this.showLoaderContainer(true);

		const GetFormByIdSuccess = (responseObject) => {
			var isSuccess = false;
			if (responseObject.status == Constants.SuccessStatus) {
				if (responseObject.data != null) {
					var myformdata = JSON.parse(responseObject.data.formData);
					this.setState({
						formId: myformdata._id,
						form: myformdata,
						jsonSchema: myformdata.components,
						title: myformdata.title,
						display: myformdata.display,
						type: myformdata.type,
						name: myformdata.name,
						path: myformdata.path,
						translationUrl: myformdata.settings?.translationUrl,
						languages: myformdata.settings?.languages,
						mainLanguage: "en",
					});
					// console.log("jsonSchema", this.state.jsonSchema);
					isSuccess = true;
				}
			}

			if (isSuccess == false) {
				this.showErrorContainer(true);
				setTimeout(() => {
					var errorContainer = document.getElementById("errorContainer");
					if (responseObject.message != null) {
						if (responseObject.message != "" && errorContainer) {
							errorContainer.innerHTML = responseObject.message;
						}
					}
					if (errorContainer.innerHTML == "") {
						errorContainer.innerHTML = "An error occured, please try again later.";
					}
					this.showLoaderContainer(false);
				}, 1000);
			} else {
				this.showErrorContainer(false);
				this.showLoaderContainer(false);
			}
		};

		const GetFormByIdFailure = (error) => {
			// this.showErrorContainer(true);
			// setTimeout(() => {
			//     var errorContainer = document.getElementById('errorContainer');
			//     if (errorContainer.innerHTML == "") {
			//         errorContainer.innerHTML = "An error occured, please try again later.";
			//     }
			//     this.showLoaderContainer(false);
			// }, 1000)
			console.log("error", error);
		};
		var GetFormByIdEndPoint = Constants.GetFormByIdEndPoint + formId + "?hideHeader=true&toEdit=true";
		General.PostToEndPoint(GetFormByIdEndPoint, null, {}, GetFormByIdSuccess, GetFormByIdFailure);
	};
	/*************** LOAD FORM END ************************/

	/*************** SAVE FORM START ************************/
	saveForm = () => {
		var formData = {
			title: this.state.title,
			display: this.state.display,
			type: this.state.type,
			name: this.state.name,
			path: this.state.path,
			components: this.state.jsonSchema.components,
			settings: {
				languages: this.state.languages,
				translationUrl: this.state.translationUrl,
			},
		};
		const EditFormSuccess = (responseObject) => {
			var isSuccess = false;
			if (responseObject.status == Constants.SuccessStatus) {
				if (responseObject.data != null) {
					isSuccess = true;
					this.setState({ showAlert: true });
				}
			}

			if (isSuccess == false) {
				this.showErrorContainer(true);
				setTimeout(() => {
					var errorContainer = document.getElementById("errorContainer");
					if (responseObject.message != null) {
						if (responseObject.message != "") {
							errorContainer.innerHTML = responseObject.message;
						}
					}
					if (errorContainer.innerHTML == "") {
						errorContainer.innerHTML = "An error occured, please try again later.";
					}
					this.showLoaderContainer(false);
					// this.showHideButtons(true);
				}, 1000);
			} else {
				this.showErrorContainer(false);
				this.showLoaderContainer(false);
				// this.showHideButtons(true);
			}
		};

		const EditFormFailure = (error) => {
			console.log("error", error);
			// showErrorContainer(true);
			// setTimeout(function () {
			//     var errorContainer = document.getElementById('errorContainer');
			//     if (errorContainer) {
			//         if (errorContainer.innerHTML == "") {
			//             errorContainer.innerHTML = "An error occured, please try again later." + error;
			//         }
			//     }
			//     showLoaderContainer(false);
			//     // showHideButtons(true);
			// }, 1000)
		};

		var EditFormJson = JSON.stringify(formData);

		var EditFormEndPoint = Constants.EditFormEndPoint.replace("#FORMID#", this.state.formId);
		if (this.state.languages?.length !== 0) {
			if (this.state.translationUrl?.replaceAll(" ", "") === "") {
				toast.error("Translation Url is required", { autoClose: 3000 });
				return;
			}
		}
		if (this.state.translationUrl?.replaceAll(" ", "") !== "") {
			if (this.state.languages?.length === 0) {
				toast.error("Languages are required", { autoClose: 3000 });
				return;
			}
		}
		General.PutToEndPoint(EditFormEndPoint, null, EditFormJson, EditFormSuccess, EditFormFailure);
	};
	/*************** SAVE FORM END ************************/

	/*************** CHANGE FORM DISPLAY START (FORM/WIZARD)************************/
	handleDisplayChange = (code) => {
		this.setState({ display: code });
	};
	/*************** CHANGE FORM DISPLAY END (FORM/WIZARD)************************/

	/*************** HANDLE SCHEMA CHANGES START ************************/
	handleSchemaChange = (schema) => {
		this.setState({ jsonSchema: schema });
	};
	/*************** HANDLE SCHEMA CHANGES END ************************/

	/*************** HANDLE NAME CHANGE START ************************/
	handleNameChange = (name) => {
		this.setState({ name: name, title: name, path: name.replaceAll(" ", "") });
	};
	/*************** HANDLE NAME CHANGE END ************************/
	handleTranslationUrlChange = (url) => {
		this.setState({ translationUrl: url });
	};
	handleLanguagesChange = (lngs) => {
		this.setState({ languages: lngs });
	};
	handleMainLanguageChange = (lng) => {
		this.setState({ mainLanguage: lng });
		if (this.formioFormRef.current) {
			this.formioFormRef.current.language = lng;
		}
	};
	componentDidUpdate(prevProps, prevState) {
		// Check if showAlert state changed to true
		if (this.state.showAlert && !prevState.showAlert) {
		  this.timer = setTimeout(() => {
			this.setState({ showAlert: false });
		  }, 5000); // Hide alert after 3 seconds
		}
	  }
	
	  componentWillUnmount() {
		// Clear the timer if the component is unmounted to prevent memory leaks
		if (this.timer) {
		  clearTimeout(this.timer);
		}
	  }
	
	  // Function to manually show the alert
	  handleShowAlert = () => {
		this.setState({ showAlert: true });
	  }
	
	
	render() {
		const { showAlert } = this.state;
		const {
			formId,
			loggedInEmail,
			errorOccuredVisibility,
			loaderContainerVisibility,
			showFormSavedAlert,
			jsonSchema,
			title,
			display,
			type,
			name,
			path,
			//showAlert,
			dropdownOpen,
			form,
			translationUrl,
			languages,
			mainLanguage,
		} = this.state;
		//console.log(form)
		return (
			
			<div className="card">
				{/* <Formioform form={form} onSubmit={(sub) => console.log(sub)} /> */}
				{showAlert && (
					<Alert variant="success" onClose={() => this.setState({ showAlert: false })}>
						Form Saved!
					</Alert>
				)}
				<div className="row">
					<div className="col-sm-7">
						<input type="text" className="form-control" defaultValue={form.name} placeholder="Form Title" onChange={(e) => this.handleNameChange(e.target.value)} />
					</div>
					<div className="col-sm-1">
						<CardTextDropdown customtext="Display">
							<div className="py-2">
								<Dropdown.Item onClick={() => this.handleDisplayChange("form")}>Form</Dropdown.Item>
								<Dropdown.Item onClick={() => this.handleDisplayChange("wizard")}>Wizard</Dropdown.Item>
							</div>
						</CardTextDropdown>
					</div>
					<div className="col-sm-4 savebtn">
						<button className="btn btn-outline-secondary" type="submit" onClick={() => this.saveForm()}>
							Save Form
						</button>
					</div>
				</div>

				<div className="row mt-3">
					<div className="col-sm-7">
						<input type="text" className="form-control" defaultValue={translationUrl} placeholder="Translation Url" onChange={(e) => this.handleTranslationUrlChange(e.target.value)} />
					</div>
					<div className="col-sm-5">
						<Select closeMenuOnSelect={false} isMulti options={Constants.FormLanguages} value={languages} onChange={this.handleLanguagesChange} />
					</div>
				</div>
				<FormioFormBuilder
					options={{
						builder: {
							custom: {
								title: "Custom",
								weight: 10,
								components: {
									photoCapture: true,
									facilitiesCustomComp: true,
									geolocation: true,
									conditionalDropDownCustomComp: true,
									companyCustomComp: true,
									companyUsersCustomComp: true,
									companyRelationCustomComp: true,
									getAllCustomComp: true,
									normalSelectCustomComp: true,
									loggedInUserDetailsCustomComp: true,
									setValuesCustomComp: true,
									confirmationCustomComp: true,
									textFieldValidationCustomComp: true,
									mapCustomComp: true,
									gisMapCustomComp: true,
									dynamicDropDownCustomComp: true,
									dropdownCustomComp: true,
									autoCompleteCustomComp: true,
									companyContractsCustomComp: true,
									companyMembersCustomComp: true,
									photoCaptureCustomComp: true,
									userCompanyCustomComp: true,
									userGeolocation: true,
									textFieldInputValidationCustomComp: true,
									confirmationMessagesCustomComp: true,
									qrcodeCustomComp:true,
									radioButtonCustomComp:true,
									dynamicTableDataComp:true	
									// generalAPICustomComp: true,
								},
							},
					//		advanced: false,
						},
					}}
					onSubmit={() => this.saveForm()}
					form={form}
					onChange={(schema) => this.handleSchemaChange(schema)}
				/>

				{errorOccuredVisibility ? <div id="errorContainer" className="loginAlert"></div> : ""}
				{loaderContainerVisibility ? <Loader></Loader> : ""}

				{showFormSavedAlert && <SaveFormAlert showComponent={showFormSavedAlert} formTitle={title} formName={name} formPath={path}></SaveFormAlert>}

				{showAlert && (
					<Alert variant="success" onClose={() => this.setState({ showAlert: false })}>
						Form Saved!
					</Alert>
				)}
			</div>
		);
	}
}

export default withParams(EditForm);
