import React from "react";
import "../../styles/Builder.css";
import { useState, useEffect } from "react";
//import axios from 'axios';
import { Form } from "@formio/react";
//import Formio from 'formiojs/Formio';
//import { Formio } from 'formiojs';
//import { FormBuilder as FormioFormBuilder } from '@formio/react';
import { Card, Col, Row } from "react-bootstrap";
import { atomOneDark, CopyBlock, dracula } from "react-code-blocks";
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { Alert } from "reactstrap";
//import { Formiojs } from 'formiojs';

function FormSubmision() {
	const [form, setForm] = useState({});
	const [presetSub, setPresetSub] = useState({});
	const navigate = useNavigate();
	const { path } = useParams();
	const [jsonSchema, setJsonSchema] = useState({ components: [] });
	const [formDisplay, setFormDisplay] = useState("");
	//const [showAlert, setShowAlert] = useState(false);
	//const [showFailAlert, setShowFailAlert] = useState(false);
	//const [alertmessage, setAlertMessage] = useState("");

	const formdata = {
		title: "",
		display: formDisplay,
		type: "form",
		name: "",
		path: "",
		components: jsonSchema.components,
		submission: {},
	};
	const data = {
		textField: "Helloooo",
		textField1: "Worlddd",
	};

	useEffect(() => {
		// var myform = {};
		fetch("https://localhost:7258/api/Forms/getFormbyPath/" + path)
			.then((res) => res.json())
			.then((data) => {
				console.log("data", data);
				// console.log("data.path", data.path);
				myform = data.formdata;
				//console.log("myform- in data", myform);
				setForm(myform);
				// formdata.title = data.title;
				// formdata.type = data.type;
				// formdata.name = data.name;
				// formdata.path = data.path;
				///* console.log("formdata.path", formdata.path);*/
				// formdata.components = data.components;
				// formdata.display = data.display;
			})
			.catch((err) => {
				console.log(err.message);
			});

		//  useEffect(() => {
		// console.log("formsubmission path", path);
		fetch("https://localhost:7258/api/Forms/getSubmissionbyId/" + path + "/64534fe6f5c943ee5756635d")
			.then((res) => res.json())
			.then((data) => {
				console.log("submission data", data.formdata);
				// console.log("submission data data", data[0].data);
				//console.log("data.path", data.path);
				//formdata.submission = data[0].data;
				// myform.submission = data[0].data;
				// console.log("formdata", formdata);
				//console.log("myform- submission", myform);

				setPresetSub(data);
				// console.log("PresetSub", presetSub);
			})
			.catch((err) => {
				console.log(err.message);
			});
		//setForm(myform);
		//console.log("form-final", form);
	}, []);
	// }, []);

	const handleSubmission = (sub) => {
		fetch("https://localhost:7258/api/Forms/createSubmission/" + form.path, {
			method: "POST",
			body: '"' + JSON.stringify(sub).toString().replaceAll('"', "'").replaceAll("?", '\\"') + '"',
			headers: {
				"Content-type": "application/json; charset=UTF-8",
			},
		})
			.then((res) => {
				if (!res.ok) {
					console.log("response not ok", res);
					return res.text().then((text) => {
						throw new Error(text);
					});
				} else {
					return res.status;
				}
			})
			.then((data) => {
				console.log(data);
				if (data.status === 400) {
					console.log(data.statusText);
				}
			})
			.catch((err) => {
				console.log(err.message);
			});
	};

	var formio = new Formio("https://examples.form.io/wizard/submission/5a542c9e2a40bf0001e0f8a9");
	formio.loadForm().then(function (form) {
		form.display = "form";
		Formio.createForm(document.getElementById("formio-full"), form, {
			readOnly: true,
			renderMode: "html",
		}).then(function (instance) {
			formio.loadSubmission().then(function (submission) {
				instance.submission = submission;
			});
		});
	});

	return (
		<>
			<Card title="Result" className="p-4">
				<Form form={form} submission={presetSub} onSubmit={(sub) => handleSubmission(sub)} />
			</Card>
		</>
	);
}

export default FormSubmision;
