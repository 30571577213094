import baseEditForm from "formiojs/components/_classes/component/Component.form";

export default (...extend) => {
    return baseEditForm(
        [
            {
                label: "Display",
                key: "display",
                weight: 0,
                components: [
                
                ],
            },
            {
                label: "Data",
                key: "data",
                weight: 20,
                components: [
                   
                    {
                        type: "datagrid",
                        label: "Options",
                        key: "options",
                        addAnother: "Add Option", // Text for the "Add" button
                        components: [
                          {
                            type: "textfield",
                            label: "Label",
                            key: "label",
                            input: true,
                          },
                          {
                            type: "textfield",
                            label: "value",
                            key: "value",
                            input: true,
                          },
                          {
                            type: "file",
                            label: "Image",
                            key: "image",
                            image: true, // Display as an image upload field
                            input: true,
                            storage: "base64", 
                          },
                          {
                            type: "checkbox",
                            label: "Hide Radio Button",
                            key: "isHidden",
                            input: true,
                            inline: true,
                      
                        },
                        {
                            type: "checkbox",
                            label: "is Submit Button",
                            key: "isSubmitButton",
                            input: true,
                            inline: true,
                      
                        },
                        ],
                      },

                ],
            },
            {
                label: "Validation",
                key: "validation",
                weight: 20,
                components: [],
            },
            {
                label: "API",
                key: "api",
                weight: 30,
                components: [],
            },
            {
                label: "Logic",
                key: "logic",
                weight: 50,
                components: [],
            },
            {
                label: "Layout",
                key: "layout",
                weight: 60,
                components: [],
            },
        ],
        ...extend
    );
};
