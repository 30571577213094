import React, { useState, useContext, useEffect } from "react";
import "./styles/Style.css";
import { toast } from "react-toastify";
import Flex from "components/common/Flex";
import LoginForm from "components/authentication/LoginForm";
import * as Constants from "common/constants";
import * as General from "common/general";
import Loader from "components/custom/Loader";
import ForgotPasswordRequest from "components/custom/users/ForgotPasswordRequest";
import logo from "assets/img/logo/mwanLogo-ver.png";
import { useTranslation } from "react-i18next";
import AppContext from "context/Context";
import { EncryptStorage } from "encrypt-storage";
import * as em from "common/ErrorMessages";

function LoginPage() {
	const encryptStorage1 = new EncryptStorage("secret-key-value", {
		prefix: "@mwan",
	});
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [errorOccured, showErrorContainer] = useState(false);
	const [loaderContainerVisibility, showHideLoaderContainer] = useState(false);
	const [checkingLanaugeLoaderContainerVisibility, showCheckingLanaugeLoaderContainerVisibility] = useState(false);
	const [rememberMe, setRememberMe] = useState(false);

	const [FullContainerVisibility, showHideFillContainer] = useState(true);
	const [LoginButtonVisibility, showHideLoginButton] = useState(true);
	const [t, i18n] = useTranslation();

	const [isRTL, setIsRTL] = useState(JSON.parse(localStorage.getItem("isRTL")) || false);

	const [forgotPasswordVisibility, showHideForgotPassword] = useState(false);

	const { setConfig } = useContext(AppContext);
	useEffect(() => {
		let user = encryptStorage1.getItem("user");
		if (user) {
			setUsername(user.UserName);
			setPassword(user.Password);
			setRememberMe(true);
		}
	}, []);
	useEffect(() => {
		if (!localStorage.getItem("isRTL")) {
			showHideFillContainer(false);
			showCheckingLanaugeLoaderContainerVisibility(true);
			showHideLoaderContainer(true);
			showHideLoginButton(false);
			const GetAllLanguagesSuccess = (responseObject) => {
				var isSuccess = false;

				if (responseObject != null) {
					if (responseObject.Code != null) {
						isSuccess = true;
						var Language = responseObject;

						var langCode = Language.Code;
						var isRTLLang = Language.isRTL;

						i18n.changeLanguage(langCode);
						setConfig("isRTL", isRTLLang);
						localStorage.setItem("isRTL", isRTLLang);

						window.location.reload();
					}
				}

				if (isSuccess == false) {
					showErrorContainer(true);
					setTimeout(() => {
						var errorContainer = document.getElementById("errorContainer");
						if (responseObject.message != null) {
							if (responseObject.message != "") {
								errorContainer.innerHTML = t(em.error_credential);
							}
						}
						if (errorContainer.innerHTML == "") {
							errorContainer.innerHTML = t(em.error_later);
						}
						showCheckingLanaugeLoaderContainerVisibility(false);
						showHideLoaderContainer(false);
						showHideFillContainer(true);
						showHideLoginButton(true);
					}, 1000);
				} else {
					showErrorContainer(false);
					showCheckingLanaugeLoaderContainerVisibility(false);
					showHideLoaderContainer(false);
					showHideFillContainer(true);
					showHideLoginButton(true);
				}
			};

			const GetAllCompanyLanguagesFailure = (error) => {
				showErrorContainer(true);
				setTimeout(() => {
					var errorContainer = document.getElementById("errorContainer");
					if (errorContainer.innerHTML == "") {
						errorContainer.innerHTML = t(em.error_later);
					}
					showHideLoaderContainer(false);
					showCheckingLanaugeLoaderContainerVisibility(false);
					showHideFillContainer(true);
					showHideLoginButton(true);
				}, 1000);
			};

			var endPoint = Constants.GetEndPoint + "/" + Constants.LanguageTableName + "/isDefault/TRUE";

			General.GetFromEndPoint(endPoint, null, null, GetAllLanguagesSuccess, GetAllCompanyLanguagesFailure);
		}
	});

	/***************  ForgotPassword START ************************/

	const handleCancelForgotPasswordRequest = () => {
		showHideForgotPassword(false);
	};
	const handleShowForgotPasswordRequest = (e) => {
		e.preventDefault();
		showHideForgotPassword(true);
	};
	/*************** ForgotPassword END ************************/

	const handleChangeLanguage = (e) => {
		e.preventDefault();
		setIsRTL((prevIsRTL) => {
			const newIsRTL = !prevIsRTL;
			localStorage.setItem("isRTL", JSON.stringify(newIsRTL));
			setConfig("isRTL", newIsRTL);

			if (newIsRTL) {
				i18n.changeLanguage("ar");
			} else {
				i18n.changeLanguage("en");
			}

			return newIsRTL;
		});
		window.location.reload();
	};

	const modifyRememberMe = (val) => {
		setRememberMe(val);
	};
	const { keycloak, initialized } = useKeycloak();

	const loginFormSubmit = async (event) => {
		showHideLoaderContainer(true);
		showHideLoginButton(false);
		event.preventDefault();
		showErrorContainer(false);
		const emailInput = document.getElementById("txtEmail");
		const passwordInput = document.getElementById("txtPassword");
		emailInput.classList.remove("is-invalid");
		passwordInput.classList.remove("is-invalid");

		if (username.trim() === "" || password.trim() === "") {
			emailInput.classList.add("is-invalid");
			passwordInput.classList.add("is-invalid");
			showErrorContainer(true);
			showHideLoaderContainer(false);
			showHideLoginButton(true);
		} else {
			var jsonBody = JSON.stringify({
				Language: "en",
				LoginData: { UserName: username, Password: password },
			});
			General.PostToEndPoint(Constants.LoginEndPoint, null, jsonBody, LoginSuccess, LoginFailure);
			function LoginSuccess(responseObject) {
				var isSuccess = false;
				if (responseObject.status == Constants.SuccessStatus) {
					if (responseObject.data != null) {
						if (responseObject.data.token != null) {
							if (responseObject.data.token.accessToken != "") {
								isSuccess = true;

								General.SaveToken(responseObject);

								localStorage.setItem(Constants.LoggedInEmailOrUserNameKeyInLocalStorage, username.toLowerCase());

								var userAttributes = [];
								if (responseObject.data.roles != undefined) {
									for (var obj of responseObject.data.roles) {
										if (obj.attributes != undefined) {
											for (var field in obj.attributes) {
												if (!userAttributes.includes(field)) {
													userAttributes.push(field);
												}
											}
										}
									}
								}

								localStorage.setItem(Constants.LoggedInRolesAttributesKeyInLocalStorage, JSON.stringify(userAttributes));
								if (responseObject.data.user != null) {
									localStorage.setItem(Constants.LoggedInUserInfoKeyInLocalStorage, JSON.stringify(responseObject.data.user));
								}

								if (rememberMe) {
									encryptStorage1.setItem("user", {
										UserName: username,
										Password: password,
									});
								} else {
									encryptStorage1.removeItem("user");
								}
								var pathuser = null;
								if (userAttributes.includes("Workflow-List")) {
									pathuser = "/workflowList";
								} else {
									pathuser = "/userTasks";
								}

								window.location.href = pathuser;
								//     window.location.href = '/workflowList';
							}
						}
					}
				}

				if (isSuccess == false) {
					showErrorContainer(true);
					setTimeout(function () {
						var errorContainer = document.getElementById("errorContainer");
						if (responseObject.message != null) {
							if (responseObject.message != "") {
								errorContainer.innerHTML = t(em.error_credential);
							}
						}

						if (errorContainer.innerHTML == "") {
							errorContainer.innerHTML = t(em.error_later);
						}

						showHideLoaderContainer(false);
						showHideLoginButton(true);
					}, 1000);
				}
			}

			function LoginFailure(error) {
				console.log(error);

				showErrorContainer(true);
				setTimeout(function () {
					var errorContainer = document.getElementById("errorContainer");

					errorContainer.innerHTML = t(em.error_later);

					showHideLoaderContainer(false);
					showHideLoginButton(true);
				}, 1000);
			}
		}
	};

	return (
		<div className="container">
			<div className="flex-center min-vh-100  row loginPageFlexContainer">
				<div className="col-xxl-4 col-xl-5 col-lg-6 col-md-8 col-sm-10">
					<a className="text-decoration-none" href="/">
						<div className="d-flex flex-center fw-bolder fs-5 mb-4">
							<img className="me-2 loginLogo" src={logo} alt="Logo" />
							{/*<span className="font-sans-serif">INSIGHT</span>*/}
						</div>
						<label className="loginLabel">{t("WasteEManifestPlatform")}</label>
					</a>
					<div className="card">
						<div className="p-4 p-sm-5 card-body">
							{FullContainerVisibility && (
								<div className="d-flex justify-content-between align-items-center mb-2">
									<h5>{t("LogIn")}</h5>{" "}
									<div className="changeLanguage">
										<a className="fs--1 mb-0" href="/" onClick={(event) => handleChangeLanguage(event)}>
											{t("ChangeLanguage")}
										</a>
									</div>
								</div>
							)}
							<form className="" onSubmit={loginFormSubmit}>
								<div>
									{FullContainerVisibility && (
										<div>
											<div className="mb-3">
												<input
													placeholder={t("EmailAddress")}
													id="txtEmail"
													name="email"
													type="email"
													className="form-control"
													value={username}
													onChange={(event) => setUsername(event.target.value)}
												/>
											</div>
											<div className="mb-3">
												<input
													placeholder={t("Password")}
													id="txtPassword"
													name="password"
													type="password"
													className="form-control"
													value={password}
													onChange={(event) => setPassword(event.target.value)}
												/>
											</div>
											<div className="justify-content-between align-items-center row">
												<div className="col-auto">
													<div className="mb-0 form-check">
														<input onChange={() => modifyRememberMe(!rememberMe)} checked={rememberMe} name="remember" type="checkbox" id="rememberMe" className="form-check-input" />
														<label htmlFor="rememberMe" className="mb-0 text-700 form-check-label">
															{t("RememberMe")}
														</label>
													</div>
												</div>
												<div className="col-auto">
													<a className="fs--1 mb-0" href="/" onClick={(event) => handleShowForgotPasswordRequest(event)}>
														{t("ForgotPassword?")}
													</a>
												</div>
											</div>
										</div>
									)}

									{LoginButtonVisibility && (
										<div>
											<button type="submit" disabled="" color="primary" className="mt-3 w-100 btn btn-primary">
												{t("LogIn")}
											</button>
											<div className="createAccount">
												<p className="fs--1 text-600 mb-0">
													{t("or")} <br />
													{t("CreateAnAccountAsA")}
													<br />
													<a href="/create-account?type=generator">{t("Generator")}</a>
													&nbsp;/&nbsp;
													<a href="/create-account?type=transporter">{t("Transporter")}</a>
													&nbsp;/&nbsp;
													<a href="/create-account?type=facility">{t("Facility")}</a>
												</p>
											</div>
										</div>
									)}
									{checkingLanaugeLoaderContainerVisibility && (
										<div
											style={{
												textAlign: "center",
												fontWeight: "bold",
												width: "100%",
											}}
										>
											Checking Language
											<br />
											<br />
											التحقق من اللغة
										</div>
									)}

									{loaderContainerVisibility && (
										<div>
											<Loader></Loader>
										</div>
									)}
								</div>
							</form>

							{errorOccured && <div id="errorContainer" className="loginAlert"></div>}
						</div>
					</div>
				</div>
			</div>

			{forgotPasswordVisibility && (
				<ForgotPasswordRequest hideForgotPasswordRequest={handleCancelForgotPasswordRequest} showComponent={forgotPasswordVisibility}></ForgotPasswordRequest>
			)}
		</div>
	);
}

export default LoginPage;
